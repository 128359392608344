<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">

        <li class="nav-item">
            <router-link class="nav-link" to="/referensi/organisasi/index" inactive-class="collapsed">
                <!-- <i class="bi bi-grid"></i> -->
                <img src="../assets/icon/reference-icon-1.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Struktural</span>
            </router-link>
        </li>
        <!-- End Dashboard Nav -->

        <li class="nav-item">
            <router-link to="/referensi/besaran/index" class="nav-link" inactive-class="collapsed">
                <!-- <i class="bi bi-person"></i> -->
                <img src="../assets/icon/reference-icon-2.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Metrik</span>
            </router-link>
        </li>
        <!-- End Profile Page Nav -->

        </ul>
    </aside>
</template>

<script>
export default {
    name: 'SidebarReference'
}
</script>