<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarReference/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/referensi/jenis/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/referensi/jenis/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="store" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row mt-5">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">Data Lokasi</div>
                                        <form action="">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label for="">Nama Jenis Lokasi</label>
                                                        <input type="text" name="name" class="form-control" v-model="name" placeholder="Jenis Lokasi">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label for="">Keterangan</label>
                                                        <!-- <input type="text" name="description" class="form-control" v-model="description"> -->
                                                        <textarea class="form-control" v-model="description" cols="30" rows="5" placeholder="Keterangan"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarReference from '../../../components/SidebarReference.vue'
    
    export default{
        data(){
          return{
            name_reference: 'Tambah Referensi',

            // Need
            is_fullpage: true,
            is_loading: false,
            token: null,
            is_saluran: false,
            divisions:[],
            types: [],
            saluran_types: [{text: 'Induk', id:'induk'}, {text:'Sekunder', id:'sekunder'}],
            frequencies: [{text: 'Jam 00.00 dan 07.00', id:'Jam 00.00 dan 07.00'}, {text: 'Per Jam', id: 'Per Jam'}, {text:'Harian', id: 'Harian'}, {text:'Bulanan', id:'Bulanan'}],
            name: '',
            code: '',
            division_id: '',
            type_id: '',
            frequency: '',
            type: '',
            lat: '',
            lng: '',
          }
        },
        mounted(){
            this.token = localStorage.getItem('token');
        },
        components:{
            Navbar,
            SidebarReference,
            Loading,
        },
        methods:{
            store(){
				if(this.name.length > 0){
					let fd = new FormData();
					fd.append('name', this.name);
					fd.append('description', this.description);

					this.is_loading = true;

					axios.post(this.$endpoints.reference+'types?token='+this.token, fd)
					.then(() => {
						this.is_loading = false;
						this.$router.push({path:'/referensi/jenis/index'});
					}).catch(() => {
						this.is_loading = false;
						alert('there was an error while creating UOM. please contact developer.');
					});
				}else{
					alert("Name could not be blank");
				}
			}
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>