<template>
    <div>
        <loading :active.sync="is_loading"
        :can-cancel="true"
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Perbandingan Data (Grafik)'}]"/>
                <h2><b>Perbandingan Data (Grafik)</b></h2>
                <!-- Container 1 -->
                <div class="document">
                    <form method="POST" autocomplete="OFF">
                        <!-- Row 1 -->
                        <div class="row mb-3" v-for="(lok, index) in lokasi" :key="index">
                            <!-- Tipe Lokasi  -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="">Pilih Tipe Lokasi</label>
                                    <Select2 class="vue-select2" v-model="lok.type_id" :options="data_types" @change="(selected) => getLocationByType(selected) "></Select2>
                                </div>
                            </div>
                            <!-- Lokasi -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="">Pilih Lokasi</label>
                                    <Select2 class="vue-select2" v-model="lok.location_id" :options="locations" @change="(selected) => getDataPeriodic(selected, lok)"></Select2>
                                </div>
                            </div>
                            <!-- Data Periodik -->
                            <div class="col-sm-4">
                                <label for="">Pilih Data Periodik</label>
                                <div class="form-group">
                                    <div class="btn-group" style="width: 100%">
                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih Data Periodic</button>
                                        <span class="sr-only">Toggle Dropdown</span>
                                        <div class="dropdown-menu" style="width: 100%">
                                            <div class="container">
                                                <div class="form-group" v-for="(periodik_data, index) in lok.periodics" :key="periodik_data.id" v-bind:index="index">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            name="periodik[]"
                                                            type="checkbox"
                                                            :value="periodik_data.id"
                                                            :id="'id_' + periodik_data.id"
                                                            v-model="lok.selected_periodics">
                                                        <label class="form-check-label" :for="'id_' + periodik_data.id">
                                                            {{ periodik_data.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-success mt-3" @click.prevent="addLocation"><i class="fa fa-plus"></i> Tambah lokasi</button>
                        <hr>

                        <!-- Row 2 -->
                        <div class="row">
                            <!-- Tanggal -->
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="">Tanggal mulai</label>
                                    <Datepicker v-model="date_start" input-class="form-control" />
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="">Tanggal akhir</label>
                                    <Datepicker v-model="date_end" input-class="form-control" />
                                </div>
                            </div>

                            <!-- Waktu & Rata - rata -->
                            <div class="col-sm-4">
                                <div class="row">
                                    <!-- Waktu -->
                                    <div class="col-sm-6">
                                        <label for="">Waktu</label>
                                        <div class="form-group">
                                            <vue-timepicker input-class="form-control" v-model="waktu" :minute-interval="5" input-width="100%" style="width: 100%"></vue-timepicker>
                                        </div>
                                    </div>
                                    <!-- Rata - rata -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Rata - rata</label>
                                            <select name="avg" v-model="avg" id="avg" class="form-select">
                                                <option value="" selected="selected">-- Pilih Rata - rata --</option>
                                                <option value="d">Harian</option>
                                                <option value="w">Mingguan</option>
                                                <option value="m">Bulanan</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- Button  -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <button type="button" v-on:click="advanceFilter" class="btn btn-primary form-control mt-4">Tampilkan</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="document" v-if="is_filtered">
                    <line-chart :chart-data="data_series" :options="chartOptions"></line-chart>
                </div>

                <!-- Container 2 -->
                <!-- <div v-for="chart in charts" :key="chart.name" class="document">
                    <h4><b>{{ chart.name }}</b></h4>
                    <line-chart :chart-data="chart" :options="chart_options"></line-chart>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import Select2 from 'v-select2-component';

// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'

// Moment
import moment from 'moment'

// Date range picker
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import Datepicker from 'vuejs-datepicker'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

// Import Line Charts
import LineChart from '@/components/chart/LineChart'
import $ from 'jquery'

export default
{
    name:'OperationCompareDataGrafik',
    // Component
    components:{
        Loading,
        Select2,
        Datepicker,
        VueTimepicker,
        LineChart,
        Navbar,
        Breadcrumb,
        OperationSidebar,
    },
    data()
    {
        //
        return{
            breadcrumb:[
                {
                    name:'Perbandingan Data',
                    url:'/seridata/grafik'
                },
                {
                    name:'Grafik'
                }
            ],
            is_fullpage: true,
            is_loading: false,
            token: null,
            parents: [],
            types : [],

            // Array Data
            data_lokasi: [],
            data_tipe_lokasi: [],
            data_periodik: [],

            // Variable
            location_count: 1,
            lokasi: [{location_id: null, type_id: null, periodics: [], selected_periodics: []}],
            locations: [],
            tipe_lokasi: [],
            periodik: [],
            waktu: {
                HH: '',
                mm: ''
            },
            avg : '',

            // Variable data
            data_types: [],

            // Date Range Picker
            is_filtered: false,
            startDate: '1990-05-30',
            endDate: '2017-09-15',
            date_start: null,
            date_end: null,
            data_series: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'DD MMM YYYY HH:mm' // or any desired format
                            }
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },
        }

    },

    // Fungsi yang dijalankan pertama kali
    mounted()
    {
        $('.dropdown-menu').click(function(event){
            event.stopPropagation();
        })

        // Token
        this.token = localStorage.getItem('token');
        this.data_types = [];
        this.is_loading = true;

        // API Lokasi
        axios.get(this.$endpoints.reference + 'data/type?token=' + this.token)
            .then(response => {
                for(var i=0; i<response.data.length; i++)
                {
                    this.data_types.push(response.data[i]);
                }
                this.is_loading = false;
            });
    },
    // Fungsi
    methods:
    {
        addLocation(){
            this.lokasi.push({
                location_id: null,
                type_id: null,
                periodics: [],
                selected_periodics: []
            });
        },
        getDataPeriodic(selected, lokasi)
        {
            this.is_loading = true;
            this.periodik = [];
            this.chart_loaded = false;

            // // API
            axios.get(this.$endpoints.reference + 'periodik/data/location', {
                params: {
                    token: this.token,
                    type_id: lokasi.type_id,
                    location_id: selected
                }
            })
                .then(response => {
                    // this.data_periodik = response.data.data;
                    lokasi.periodics = response.data.data;
                    this.is_loading = false;
                });

            // this.advanceFilter();

        },
        getLocationByType(selected)
        {
            this.is_loading = true;
            this.locations = [];
            this.periodik = [];
            this.chart_loaded = false;

            axios.get(this.$endpoints.reference + 'data/location/type/' + selected, {
                params: {
                    token: this.token
                }
            })
            .then(response => {
                for(var i=0; i<response.data.length; i++)
                {
                    this.locations.push(response.data[i])
                }

                this.is_loading = false;
            })
        },
        advanceFilter()
        {
            this.is_filtered = true;

            let params = {
                token: this.token,
                locations: this.lokasi.map( lok => {
                    return {
                        location_id: lok.location_id,
                        type_id: lok.type_id,
                        periodics: lok.selected_periodics
                    };
                })
            };

            if(this.avg !== null){
                params['avg'] = this.avg;
            }

            if(this.date_start !== null && this.date_end !== null){
                params['start_date'] = moment(this.date_start).format('YYYY-MM-DD');
                params['end_date'] = moment(this.date_end).format('YYYY-MM-DD');
            }

            if(this.waktu.HH !== '' && this.waktu.mm !== ''){
                params['time'] = this.waktu.HH + ':' + this.waktu.mm;
            }

            this.is_loading = true;

            axios.get(`${this.$endpoints.operasi}compare`, {params})
            .then(response => {
                this.data_series = response.data.datasets;
                this.chartOptions.scales.yAxes = response.data.axis;
            })
            .finally(() => {
                this.is_loading = false;
            })
        },

    },
    filters: {
        date(val)
        {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    }

}
</script>

<style scoped>
.vue-daterange-picker{width: 100%}
[contenteditable]{
    border: 1px solid #ccc;
}

.btn-group>button{
    display: flex;
    text-align: left;
}

.btn-group>button:after{
    margin-left:auto;
    align-self: center;
}
</style>