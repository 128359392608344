<template>
    <div id="spatial-legend" class="card">
        <div class="card-header pb-2" ref="header">
            Map Legend
        </div>
        <div id="inner-legend" class="card-body expanded" ref="body">
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="legend-item status-normal me-2"></div>
                        <label for="">Normal</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="legend-item status-siap me-2"></div>
                        <label for="">Siap</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="legend-item status-siaga me-2"></div>
                        <label for="">Siaga</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="legend-item status-awas me-2"></div>
                        <label for="">Awas</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="legend-item status-non-aktif me-2"></div>
                        <label for="">Tidak Aktif</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="legend-item status-selected me-2"></div>
                        <label for="">Terpilih</label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "MapLegend",
    mounted(){
        this.$refs.header.addEventListener('click', () => {
            let _body = this.$refs.body;

            if(_body.classList.contains('expanded')){
                _body.classList.remove('expanded');
                _body.classList.add('folded');
            }else{
                _body.classList.remove('folded');
                _body.classList.add('expanded');
            }
        });
    }
}
</script>

<style scoped>
    .card-header{
        cursor: pointer;
    }
    
    .legend-item{
        width: 14px;
        height: 14px;
        border-radius: 100%;
    }

    .status-normal{
        background-color: #509ee3;
    }

    .status-siap{
        background-color: #fff200;
    }

    .status-siaga{
        background-color: #f26c4f;
    }

    .status-awas{
        background-color: #e93a3a;
    }

    .status-non-aktif{
        background-color: #444;
    }

    .status-selected{
        background-color: #0c0;
    }

    .expanded{
        height: 240px;
        transition: height .3s;
    }

    .folded{
        height: 0;
        padding: 0;
        transition: height .3s;
    }
</style>