<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true">
        </loading>

        <!-- Navbar -->
        <Navbar/>

        <!-- Sidebar -->
        <SidebarSkema/>

        <main class="main" id="main">
            <Breadcrumb :links="links"/>
            <section class="section">
                <router-link to="/skema" class="btn btn-secondary mb-3"><i class="fas fa-chevron-left"></i> Kembali</router-link>
                <div class="text-center">
                    <h4 class="border-bottom bg-secondary p-3 mb-2 text-white"><b>Kondisi Ketersediaan dan Distribusi Air di {{ headers[$route.query.location] }}</b></h4>
                    <img :src="require('@/assets/subgrafik/' + $route.query.location + '.svg')" alt="">
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="card">
                            <div class="card-header bg-secondary text-white pb-2">Data Debit Aktual Jam {{ $route.query.time }} WIB</div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr v-for="hourly_data in hourly" :key="hourly_data.id">
                                            <td width="80%">{{ hourly_data.title }}</td>
                                            <td width="3%">:</td>
                                            <td>{{ hourly_data.value }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card">
                            <div class="card-header bg-secondary text-white pb-2">Data Debit harian</div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr v-for="daily_data in daily" :key="daily_data.id">
                                            <td width="80%">{{ daily_data.title }}</td>
                                            <td width="3%">:</td>
                                            <td>{{ daily_data.value }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Navbar from '@/components/Navbar.vue'
import SidebarSkema from '@/components/SidebarSkema.vue'

export default {
    name: "Subgrafik",
    components: {
        Loading,
        Breadcrumb,
        Navbar,
        SidebarSkema
    },
    data(){
        return {
            is_loading: false,
            location: '',
            links: [
                {url: '/dashboard', text:'Home'}, 
                {url: '/skema', text: 'Skema Gambar Utama'}, 
                {url: '#', text: 'Subgrafik'}, 
            ],
            headers: {
                barugbug: 'Bendung Barugbug',
                bekasi: 'Bendung Bekasi',
                cibeet: 'Bendung Cibeet',
                cijengkol: 'Bendung Cijengkol',
                cikarang: 'Bendung Cikarang',
                cipamingkis: 'Bendung Cipamingkis',
                curug: 'Bendung Curug',
                gadung: 'Bendung Gadung',
                macan: 'Bendung Macan',
                pab: 'Penyediaan Air Baku DKI Jakarta',
                pundong: 'Bendung Pundong',
                salamdarma: 'Bendung Salamdarma',
                walahar: 'Bendung Walahar'
            },
            hourly: [],
            daily: [],
        }
    },
    mounted(){
        this.is_loading = true;

        axios.get(`${this.$endpoints.operasi}skema/subgrafik?location=${this.$route.query.location}&date=${this.$route.query.date}&time=${this.$route.query.time}`)
        .then((response) => {
            this.hourly = response.data.hourly;
            this.daily = response.data.daily;
        })
        .finally(() => {
            this.is_loading = false;
        })
    }
}
</script>