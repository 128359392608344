<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSkema/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <Breadcrumb :links="links"/>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard py-0">
            <div class="row">
                <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                        <!-- Recent Sales -->
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4>Skema Distribusi Air</h4>
                                    <div class="row mt-4">
                                        <div class="col-lg-9">
                                            <div class="row align-items-center">
                                                <div class="col-auto">
                                                    <input type="checkbox" id="show_avg" class="me-2" v-model="avg">
                                                    <label for="show_avg">Nilai rata-rata per hari</label>
                                                </div>
                                                <div class="col-auto">
                                                    <datepicker v-model="date_input" name="date_input" placeholder="Select date" input-class="form-control"></datepicker>
                                                </div>
                                                <div class="col-auto">
                                                    <vue-timepicker v-model="time_input" input-class="idih form-control" :minute-interval="10"></vue-timepicker>
                                                </div>
                                                <div class="col-auto">
                                                    <button class="btn btn-primary" @click="getData()"><i class="bi bi-funnel"></i> Tampilkan</button>
                                                </div>
                                            </div>
                                            <div class="mb-5">
                                                <SkemaDistribusi :data="data" @updated="reload" @subgrafik_changed="showSubGrafik" />
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <h4 class="mb-3">Keterangan</h4>
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="line me-2" style="background: #f00"></div>
                                                        <span>Sungai</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #64A0DE"></div>
                                                        <span>Q1 (Q Dimanfaatkan)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: yellow"></div>
                                                        <span>Q2 (Q tersedia/Q.SS)</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #DC7974"></div>
                                                        <span>TMA Bendung</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #FF37FF"></div>
                                                        <span>Air Masuk</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="line me-2" style="background: #1959B1"></div>
                                                        <span>Saluran</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #f00"></div>
                                                        <span>Limpasan</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #6BCD1E"></div>
                                                        <span>Suplesi</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #AEAEAE"></div>
                                                        <span>TMA Bendungan</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex align-items-center legend-item-wrapper">
                                                        <div class="box me-2" style="background: #FFBEFF"></div>
                                                        <span>Air Keluar</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    import SkemaDistribusi from '../../components/SkemaDistribusi.vue'
    import Datepicker from 'vuejs-datepicker'
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import moment from 'moment';
    
    // import {BTabs} from 'bootstrap-vue';
    // import Notif from 'vue-notification' 

    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarSkema from '../../components/SidebarSkema.vue'
    
    export default{
        data(){
            return{
                name: 'Struktural',

                // Need
                is_loading: false,
                is_fullpage: true,
                token: null,
                items: [],
                q_name:'',
                q_code:'',
                per_page: 10,
                is_searched: false,
                limit: 3,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/sarpras/skema', text: 'Skema'}, 
                ],
                date_input: new Date(),
                time_input: {
                    HH:'07',
                    mm:'00'
                },
                avg: false,
                data: []
            }
        },
        mounted(){
            this.getData();

            document.body.classList.add('toggle-sidebar')
        },
        methods:{
            getData(){
                this.is_loading = true;
                this.token = localStorage.getItem('token');

                const time_input = this.time_input.HH+':'+this.time_input.mm;
                const date_input = moment(this.date_input).format("YYYY-MM-DD");
                const avg = this.avg ? 'y':'n';

                axios.get(this.$endpoints.operasi+'skema?token='+this.token+'&date='+date_input+'&time='+time_input+'&avg='+avg)
                .then(response =>{
                    this.data = response.data
                    this.is_loading = false;
                });
            },
            reload(){
                this.getData();
            },
            showSubGrafik(location){
                this.$router.push({
                    path: '/skema/subgrafik',
                    query: {
                        location,
                        date: moment(this.date_input).format("YYYY-MM-DD"),
                        time: this.time_input.HH+':'+this.time_input.mm
                    }
                })
            }
        },
        components:{
            Navbar,
            SidebarSkema,
            Loading, 
            Breadcrumb,
            SkemaDistribusi,
            Datepicker,
            VueTimepicker
        },
    }

</script>

<style scoped>
    .dashboard .filter{
        position: absolute;
        left: 0px;
        top: 15px;
    }

    .nav-tabs-bordered .nav-link.active{
        background-color: #fff;
        color: #509ee3;
        border-bottom: 2px
        solid #509ee3;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        background-color: #fff;
        border-color: #509ee3;
    }

    .btn-xs{
        border-radius: 10px;
    }

    .legend-item-wrapper>span{
        font-size: 12px;
    }

    .line, .box{
        width: 50px;
    }

    .line{
        height: 3px;
    }

    .box{
        height: 20px;
        border: 1px solid #000;
    }
</style>

<style>
    .idih{
        padding: 0.375rem 0.75rem!important;
        height: auto!important;
        font-size: 1rem!important;
    }
</style>