<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div id="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/users/index', text: 'User'}, {url: '#', text:'Batch Edit'}]"/>
                <h2><b>Batch Edit Pengguna</b></h2>
                <div class="document">
                    <form action="">
                        <div class="form-group mb-1">
                            <label for="">File CSV</label>
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <input type="file" class="form-control" @change="readFile">
                                </div>
                            </div>
                        </div>
                        <i class="d-block mb-3">Untuk arahan silahkan download file <a href="/panduan%20batch%20user.xlsx">berikut ini</a></i>
                        <div v-if="rows.length > 0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>NIK</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Organisasi</th>
                                        <th>Hak Akses</th>
                                        <th>Akses Modul</th>
                                        <th>Akses Telemeteri</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, i) in rows" :key="i">
                                        <td>{{ row.nik }}</td>
                                        <td>{{ row.email }}</td>
                                        <td>{{ row.role }}</td>
                                        <td>{{ row.organisasi }}</td>
                                        <td>{{ row.hak_akses }}</td>
                                        <td>{{ row.akses_modul }}</td>
                                        <td>{{ row.akses_telemeteri }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button class="btn btn-primary btn-sm" @click.prevent="save"><i class="fa fa-save"></i> Update</button>
                        <router-link to="/users/index" class="btn btn-sm btn-danger ms-2"><i class="fa fa-times"></i> Cancel</router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import axios from 'axios';
import * as CSV from 'csv-string'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'UserBatchEdit',
    components: {
        Navbar,
        Breadcrumb,
        Loading
    },
    data(){
        return {
            is_loading: false,
            rows: []
        }
    },
    methods: {
        readFile(ev){
            let files = ev.target.files;
            if(files.length > 0){
                let file = files[0];
                this.rows = [];

                if(file.type == 'text/csv'){
                    let reader = new FileReader();

                    reader.addEventListener('load', (e) => {
                        let data = e.target.result;
                        this.rows = CSV.parse(data, {
                            output: 'objects'
                        });
                        // this.rows.shift();

                        console.log(this.rows);
                    });

                    reader.readAsBinaryString(file);
                }else{
                    this.$swal("Error", "File yg dipilih bukan CSV. harap upload file berformat CSV", "error");
                }
            }
        },
        save(){
            let token = localStorage.getItem('token');
            let fd = this.createFormData({
                rows: JSON.stringify(this.rows)
            })

            axios.post(this.$endpoints.user + 'users/batch-update?token=' + token, fd)
            .then(response => {
                // this.rows = [];
                this.$swal("Berhasil", response.data.message, "success");
            })
            .catch(e => {
                this.$swal({
                    title: "Berhasil dengan beberapa pengecualian",
                    html: "Data berhasil diperbarui, namun terjadi kesalahan pada baris berikut. <br><br><li>" + e.response.data.join("</li><li>")+"</li>",
                    icon: "error",
                    width: "64em"
                });
            });
        }
    }
}
</script>

<style scoped>
.swal2-html-container{
    text-align: left!important;
}
</style>