<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSarpras/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/sarpras/kategori/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/sarpras/kategori/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="update" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row mt-5">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">Data Kategori</div>
                                        <form action="">
                                            <!-- ==== FORM 1 ==== -->
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Sungai / Saluran</label>
                                                        <Select2 class="vue-select2" :options="channels" v-model="channel_id" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Nama</label>
                                                        <input type="text" name="name" class="form-control" v-model="name">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Select2 from 'v-select2-component'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarSarpras from '../../../components/SidebarSarpras.vue'
    
    export default{
        data(){
          return{
            name_reference: 'Tambah Sarpras',

            // Need
            token: null,
            channels: [],
            channel_id: '',
            name: ''
            }
        },
        mounted(){
            this.token = localStorage.getItem('token');
			
            axios.get(this.$endpoints.sarpras+'segments/'+this.$route.params.id+'?token='+this.token)
			.then(response => {
				this.name = response.data.name;
				this.channel_id = response.data.channel_id;

				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error while getting data. please contact developer.');
			})

			// get channels
			axios.get(this.$endpoints.sarpras+'channels?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					data.push({
						text: response.data[i].name,
						id: response.data[i].id
					})
				}
				this.channels = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})
        },
        components:{
            Navbar,
            SidebarSarpras,
            Loading,
            Select2,
        },
        methods:{
            update(){
				let fd = this.createFormData({
					name: this.name,
					channel_id: this.channel_id,
					_method: 'PUT'
				});

				this.is_loading = true;

				axios.post(this.$endpoints.sarpras+'segments/'+this.$route.params.id+'?token='+this.token, fd)
				.then(() => {
					this.is_loading = false;
					this.$router.push({path:'/infrastructure/segment/list'});
				}).catch(() => {
					this.is_loading = false;
					alert('there was an error while updating data. please contact developer');
				})
			}
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>