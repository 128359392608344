<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarReference/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/referensi/jenis/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/referensi/jenis/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="store" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row mt-5">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">Data Lokasi</div>
                                        <form action="">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Lokasi</label>
                                                        <Select2 class="vue-select2" :options="locations" v-model="location_id" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Nama Data periodik</label>
                                                        <input v-model="name" type="text" class="form-control" id="Nama_Data" name="name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Besaran</label>
                                                        <Select2 class="vue-select2" :options="variables" v-model="variable_id" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Tipe Data</label>
                                                        <select name="type" id="type" class="form-control" v-model="type">
                                                            <option selected="selected">-- Pilih --</option>
                                                            <option value="text">Text</option>
                                                            <option value="number">Number</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarReference from '../../../components/SidebarReference.vue'
    import Select2 from 'v-select2-component';
    
    export default{
        data(){
          return{
            name_reference: 'Tambah Referensi',

            // Need
            is_fullpage: true,
            is_loading: false,
            token: null,
            locations: [],
            selected:{
                location:'',
                uom:''
            },
            variables: [],
            name: '',
            variable_id: '',
            variable_name: '',
            location_id: '',
            location_name: '',
            organization_id: '',
            organization_name: '',
            code: '',
            type: '',
            siap: '',
            siaga: '',
            awas: '',
            besaran: ''
          }
        },
        mounted(){
            this.token = localStorage.getItem('token');
            this.getLocation();
            this.getVariable();
        },
        components:{
            Navbar,
            SidebarReference,
            Loading,
            Select2
        },
        methods:{
            store()
            {
				if(this.name.length > 0)
                {
                    // Create Form
					let form_data = this.createFormData({
                        name: this.name,
                        variable_id: this.variable_id, // (Get By Uom ID)
                        variable_name: this.getVariableById(),
                        location_id: this.location_id, // (Get By Location)
                        location_name: this.getLocationById(), // (Get By Location)
                        type: this.type,
                        siap: parseFloat(this.siap),
                        siaga: parseFloat(this.siaga),
                        awas: parseFloat(this.awas),
                        besaran: this.getVariableById()
					});

					this.is_loading = true;

					axios.post(this.$endpoints.reference + 'periodik?token=' + this.token, form_data)
                        .then(() => {
                            this.is_loading = false;
                            this.$router.push({path:'/referensi/periodik/index'});
                        }).catch(() => {
                            this.is_loading = false;
                            alert('there was an error while adding data. please contact developer.');
                        });
				}else{
					alert("Name could not be blank");
				}
			},
            getLocation()
            {
                console.log('getLocations');
                axios.get(this.$endpoints.reference + 'data/lokasi?token=' + this.token)
                    .then((response) => {
                        for(var i = 0; i < response.data.data.length; i++){
                            this.locations.push({
                                id: response.data.data[i].id,
                                text: response.data.data[i].name
                            })
                        }
                    })
            },
            getVariable()
            {
                // console.log()
                axios.get(this.$endpoints.reference + 'data/variable?token=' + this.token)
                    .then((response) => {
                        for(var i = 0; i < response.data.data.length; i++){
                            this.variables.push({
                                id: response.data.data[i].id,
                                text: response.data.data[i].name
                            })
                        }
                    })
            },
            getVariableById()
            {
                var name = null;
                for(var i = 0; i < this.variables.length; i++){
                    if(this.variables[i].id == this.variable_id){
                        name = this.variables[i].text;
                    }
                }
                
                return name;
            },
            getOrganizationById(event)
            {
                // console.log(event.target.value)
                axios.get(this.$endpoints.reference + 'organizations' + '/' + event.target.value)
                    .then((response) => {
                        console.log(response.data.data.length);
                        this.organization_name = response.data.data.name;
                    })
                    
            },
            getLocationById()
            {
                var name = null;
                for(var i = 0; i < this.locations.length; i++){
                    if(this.locations[i].id == this.location_id){
                        name = this.locations[i].text;
                    }
                }
                
                return name;
            }
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>