<template> 
    <!-- chart colors: #00C875, #FF7575, #FFCB00 -->
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <!-- <div class="sidebar">
            </div> -->
            <SidebarSarpras/>

            <div class="main" id="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '#', text: 'Sarana dan Prasana'}]"/>
                <h2><b>Overview</b></h2>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="document">
                            <div class="row mb-4">
                                <div class="col-12 col-sm-7 col-lg-6 col-xl-6 text-center">
                                    <GChart
                                    type="PieChart"
                                    :data="chartData"
                                    :options="chartOptions" />
                                </div>
                                <div class="col-sm-5 col-lg-6 col-xl-6 d-none d-sm-block">
                                    <div>Sarana &amp; Prasarana</div>
                                    <h2 style="font-size: 64px;"><b>{{ total.toLocaleString() }}</b></h2>
                                </div>
                            </div>
                            <div class="row d-block d-sm-none">
                                <div class="col-12 text-center">
                                    <div>Sarana &amp; Prasarana</div>
                                    <h2><b>{{ total.toLocaleString() }}</b></h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <span class="rounded-circle" style="width: 12px; height: 12px; display: inline-block; background-color: #00C875"></span> Baik<br>
                                    <h2><b>{{ baik }}</b></h2>
                                </div>
                                <div class="col-4" style="white-space:nowrap;text-overflow: ellipsis; overflow: hidden">
                                    <span class="rounded-circle bg-warning" style="width: 12px; height: 12px; display: inline-block;"></span> Rusak Ringan<br>
                                    <h2><b>{{ rusak_ringan }}</b></h2>
                                </div>
                                <div class="col-4" style="white-space:nowrap;text-overflow: ellipsis; overflow: hidden">
                                    <span class="rounded-circle" style="width: 12px; height: 12px; display: inline-block; background-color: #FF7575"></span> Rusak Berat<br>
                                    <h2><b>{{ rusak_berat }}</b></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="document overflow-auto">
                            <h4><b>Inspeksi Terakhir</b></h4>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>Nama Sarpras</th>
                                        <th>Fungsi</th>
                                        <th>Fisik</th>
                                        <th>Petugas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="inspection in inspections" :key="inspection.id">
                                        <td>{{ inspection.inspected_at | formatDate }}</td>
                                        <td>{{ inspection.infrastructure.name }}</td>
                                        <td>{{ inspection.fungsi }}%</td>
                                        <td>{{ inspection.fisik }}%</td>
                                        <td>{{ inspection.user_name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="document">
                            <h4><b>Peta Sebaran</b></h4>
                            <GmapMap
                                :center="{lat:-6.5341128, lng:107.3428813}"
                                :zoom="12"
                                map-type-id="roadmap"
                                style="width: 100%; height: 500px"
                                ref="peta">
                                <GmapMarker
                                    v-for="(m, index) in sarpras"
                                    :key="index"
                                    :icon="{url:defineIcon(m.fungsi, m.fisik)}"
                                    :clickable="true"
                                    :position="{lat: parseFloat(m.lat), lng:parseFloat(m.lng)}"
                                    @click="goToDetailSarpras(m.id)" />
                            </GmapMap>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import { GChart } from 'vue-google-charts'
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import moment from 'moment';
import 'vue-loading-overlay/dist/vue-loading.css'
import SidebarSarpras from '../../components/SidebarSarpras.vue'

export default {
    name: 'DashboardSarpras',
    components: {
        Navbar,
        Breadcrumb,
        Loading,
        GChart,
        SidebarSarpras
    },
    data(){
        return {
            is_fullpage: true,
            is_loading: false,
            token: null,
            org_id: null,
            role: null,
            baik: 0,
            rusak_ringan: 0,
            rusak_berat: 0,
            total: 0,
            chartData: [
                ['Kondisi', 'Nilai']
            ],
            chartOptions: {
                pieHole: 0.4,
                colors: ['#00C875', '#ffcb00', '#FF7575'],
                width: 220,
                height: 220,
                chartArea:{
                    width: 300,
                    height: 300
                },
                legend: {position: 'none'}
            },
            sarpras: [],
            inspections: []
        }
    },
    mounted(){
        this.getData();
        setInterval(() => {
            this.getData();
        }, this.$refresh_duration);
    },
    methods: {
        getData(){
            this.token = localStorage.getItem('token');
            this.org_id = localStorage.getItem('org_id');
            this.role = localStorage.getItem('role');
            this.is_loading = true;
            this.chartData = [['Kondisi', 'Nilai']];
            
            axios.get(this.$endpoints.sarpras+'sarpras_dashboard/'+this.org_id+'?token='+this.token+'&role='+this.role)
            .then(response =>{
                // this.items = response.data
                this.total = response.data.total;
                this.baik = response.data.grade1;
                this.rusak_ringan = response.data.grade2;
                this.rusak_berat = response.data.grade3;
                // this.is_loading = false;
                
                this.chartData.push(['Baik', this.baik],
                    ['Rusak Ringan', this.rusak_ringan],
                    ['Rusak Berat', this.rusak_berat]
                );

                // get sarpras
                axios.get(this.$endpoints.sarpras+'infrastructures?all=1&columns=id,name,lat,lng,fisik,fungsi')
                .then((response) => {
                    this.sarpras = response.data;

                    // get sarpras
                    axios.get(this.$endpoints.sarpras+'inspections/latest?per_page=3')
                    .then((response) => {
                        this.inspections = response.data;
                        this.is_loading = false;
                    })
                    .catch((e) => {
                        this.is_loading = false;
                        console.log(e);
                        alert('there was an error. please contact developer.');
                    });
                })
                .catch((e) => {
                    this.is_loading = false;
                    console.log(e);
                    alert('there was an error. please contact developer.');
                });
            }).catch((e) => {
                console.log(e);
            });
        },
        defineIcon(fungsi, fisik){
            let value = (parseFloat(fungsi) + parseFloat(fisik)) / 2;
            if(value <= 50){
                return require('../../assets/icon/grade3.png');
            }else if(value > 50 && value < 70){
                    return require('../../assets/icon/grade2.png');
            }else{
                    return require('../../assets/icon/grade1.png');
            }
        },
        goToDetailSarpras(id){
            this.$router.push({path:'/sarpras/infrastructure/detail/'+id});
        }
    },
    filters: {
        formatDate(value){
            return moment(value).format("DD/MM/YYYY");
        }
    }
}
</script>