<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">

        <!-- History Telemteri -->
        <li class="nav-item">
            <router-link class="nav-link" to="/telemeteri" inactive-class="collapsed">
                <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Overview</span>
            </router-link>
        </li>

        <!-- Grafik Telemteri -->
        <li class="nav-item">
            <router-link class="nav-link" to="/history/telemeteri" inactive-class="collapsed">
                <img src="../assets/icon/table-icon.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>History Telemeteri</span>
            </router-link>
        </li>

        <!-- Grafik Telemteri -->
        <li class="nav-item">
            <router-link class="nav-link" to="/grafik/telemeteri" inactive-class="collapsed">
                <img src="../assets/icon/bar-graph-icon.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Grafik Telemeteri</span>
            </router-link>
        </li>

        <!-- Grafik Telemteri -->
        <li class="nav-item">
            <router-link class="nav-link" to="/telemeteri/diagnostic" inactive-class="collapsed">
                <i class="fa fa-stethoscope" style="color: #58b4f1; padding-right: 15px"></i>
                <span>Diagnostik</span>
            </router-link>
        </li>
        
        </ul>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarSpatial',
    }
</script>