<template>
    <div>
        <loading :active.sync="is_loading"
        :can-cancel="true"
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Perbandingan Data (Tabel)'}]"/>
                <h2><b>Perbandingan Data (Tabel)</b></h2>
                <!-- Container 1 -->
                <div class="document">
                    <form method="POST" autocomplete="OFF">
                        <!-- Row 1 -->
                        <div class="row mb-3" v-for="(lok, index) in lokasi" :key="index">
                            <!-- Tipe Lokasi  -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="">Pilih Tipe Lokasi</label>
                                    <Select2 class="vue-select2" v-model="lok.type_id" :options="data_types" @change="(selected) => getLocationByType(selected) "></Select2>
                                </div>
                            </div>
                            <!-- Lokasi -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="">Pilih Lokasi</label>
                                    <Select2 class="vue-select2" v-model="lok.location_id" :options="locations" @change="(selected) => getDataPeriodic(selected, lok)"></Select2>
                                </div>
                            </div>
                            <!-- Data Periodik -->
                            <div class="col-sm-4">
                                <label for="">Pilih Data Periodik</label>
                                <div class="form-group">
                                    <div class="btn-group" style="width: 100%">
                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih Data Periodic</button>
                                        <span class="sr-only">Toggle Dropdown</span>
                                        <div class="dropdown-menu" style="width: 100%">
                                            <div class="container">
                                                <div class="form-group" v-for="(periodik_data, index) in lok.periodics" :key="periodik_data.id" v-bind:index="index">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            name="periodik[]"
                                                            type="checkbox"
                                                            :value="periodik_data.id"
                                                            :id="'id_' + periodik_data.id"
                                                            v-model="lok.selected_periodics">
                                                        <label class="form-check-label" :for="'id_' + periodik_data.id">
                                                            {{ periodik_data.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-success mt-3" @click.prevent="addLocation"><i class="fa fa-plus"></i> Tambah lokasi</button>
                        <hr>

                        <!-- Row 2 -->
                        <div class="row">
                            <!-- Tanggal -->
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="">Tanggal mulai</label>
                                    <Datepicker v-model="date_start" input-class="form-control" />
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="">Tanggal akhir</label>
                                    <Datepicker v-model="date_end" input-class="form-control" />
                                </div>
                            </div>

                            <!-- Waktu & Rata - rata -->
                            <div class="col-sm-4">
                                <div class="row">
                                    <!-- Waktu -->
                                    <div class="col-sm-6">
                                        <label for="">Waktu</label>
                                        <div class="form-group">
                                            <vue-timepicker input-class="form-control" v-model="waktu" :minute-interval="5" input-width="100%" style="width: 100%"></vue-timepicker>
                                        </div>
                                    </div>
                                    <!-- Rata - rata -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Rata - rata</label>
                                            <select name="avg" v-model="avg" id="avg" class="form-select">
                                                <option value="" selected="selected">-- Pilih Rata - rata --</option>
                                                <option value="d">Harian</option>
                                                <option value="w">Mingguan</option>
                                                <option value="m">Bulanan</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- Button  -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <button type="button" v-on:click="advanceFilter" class="btn btn-primary form-control mt-4">Tampilkan</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="document" v-if="is_filtered">
                    <button class="btn btn-primary float-end mb-3" @click.prevent="downloadCsv"><i class="fa fa-download"></i> Download CSV</button>
                    <div class="clearfix"></div>
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Waktu</th>
                                <th v-for="(data, index) in data_series" :key="index"><span v-html="data[0].name"></span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(label, index) in labels" :key="index">
                                <td>{{ label }}</td>
                                <td v-for="(data, index) in data_series" :key="index">{{ getDataAtTime(data, label) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import Select2 from 'v-select2-component';

// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'

// Moment
import moment from 'moment'

import Datepicker from 'vuejs-datepicker'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

// Import Line Charts
import $ from 'jquery'

export default
{
    name:'OperationCompareDataTable',
    // Component
    components:{
        Loading,
        Select2,
        Datepicker,
        VueTimepicker,
        Navbar,
        Breadcrumb,
        OperationSidebar,
    },
    data()
    {
        //
        return{
            breadcrumb:[
                {
                    name:'Perbandingan Data',
                    url:'/seridata/grafik'
                },
                {
                    name:'Grafik'
                }
            ],
            is_fullpage: true,
            is_loading: false,
            token: null,
            parents: [],
            types : [],

            // Array Data
            data_lokasi: [],
            data_tipe_lokasi: [],
            data_periodik: [],

            // Variable
            location_count: 1,
            lokasi: [{location_id: null, type_id: null, periodics: [], selected_periodics: []}],
            locations: [],
            tipe_lokasi: [],
            periodik: [],
            waktu: {
                HH: '',
                mm: ''
            },
            avg : '',

            // Variable data
            data_types: [],

            // Date Range Picker
            is_filtered: false,
            startDate: '1990-05-30',
            endDate: '2017-09-15',
            date_start: null,
            date_end: null,
            data_series: {},
            labels: []
        }

    },

    // Fungsi yang dijalankan pertama kali
    mounted()
    {
        $('.dropdown-menu').click(function(event){
            event.stopPropagation();
        })

        // Token
        this.token = localStorage.getItem('token');
        this.data_types = [];
        this.is_loading = true;

        // API Lokasi
        axios.get(this.$endpoints.reference + 'data/type?token=' + this.token)
            .then(response => {
                for(var i=0; i<response.data.length; i++)
                {
                    this.data_types.push(response.data[i]);
                }
                this.is_loading = false;
            });
    },
    // Fungsi
    methods:
    {
        addLocation(){
            this.lokasi.push({
                location_id: null,
                type_id: null,
                periodics: [],
                selected_periodics: []
            });
        },
        getDataPeriodic(selected, lokasi)
        {
            this.is_loading = true;
            this.periodik = [];
            this.chart_loaded = false;

            // // API
            axios.get(this.$endpoints.reference + 'periodik/data/location', {
                params: {
                    token: this.token,
                    type_id: lokasi.type_id,
                    location_id: selected
                }
            })
                .then(response => {
                    // this.data_periodik = response.data.data;
                    lokasi.periodics = response.data.data;
                    this.is_loading = false;
                });

            // this.advanceFilter();

        },
        getLocationByType(selected)
        {
            this.is_loading = true;
            this.locations = [];
            this.periodik = [];
            this.chart_loaded = false;

            axios.get(this.$endpoints.reference + 'data/location/type/' + selected, {
                params: {
                    token: this.token
                }
            })
            .then(response => {
                for(var i=0; i<response.data.length; i++)
                {
                    this.locations.push(response.data[i])
                }

                this.is_loading = false;
            })
        },
        advanceFilter()
        {
            this.is_filtered = true;

            let params = {
                token: this.token,
                to_table: 1,
                locations: this.lokasi.map( lok => {
                    return {
                        location_id: lok.location_id,
                        type_id: lok.type_id,
                        periodics: lok.selected_periodics
                    };
                })
            };

            if(this.avg !== null){
                params['avg'] = this.avg;
            }

            if(this.date_start !== null && this.date_end !== null){
                params['start_date'] = moment(this.date_start).format('YYYY-MM-DD');
                params['end_date'] = moment(this.date_end).format('YYYY-MM-DD');
            }

            if(this.waktu.HH !== '' && this.waktu.mm !== ''){
                params['time'] = this.waktu.HH + ':' + this.waktu.mm;
            }

            this.is_loading = true;

            axios.get(`${this.$endpoints.operasi}compare`, {params})
            .then(response => {
                this.data_series = response.data.data;
                this.labels = response.data.labels;
            })
            .finally(() => {
                this.is_loading = false;
            })
        },
        getDataAtTime(data, time){
            const filtered = data.filter(d => {
                const waktu = d.date_input + ' ' + d.time_input;

                return waktu === time;
            });

            if(filtered.length > 0){
                return filtered[0].value;
            }

            return '-';
        },
        downloadCsv(){
            // Variable to store the final csv data
            let csv_data = [];
        
            // Get each row data
            let rows = document.getElementsByTagName('tr');
            for (let i = 0; i < rows.length; i++) {

                // Get each column data
                let cols = rows[i].querySelectorAll('td,th');

                // Stores each csv row data
                let csvrow = [];
                for (let j = 0; j < cols.length; j++) {

                    // Get the text data of each cell of
                    // a row and push it to csvrow
                    csvrow.push(cols[j].innerText.trim().replace(/(\r\n|\n|\r)/gm, ''));
                }

                // Combine each column value with comma
                csv_data.push(csvrow.join(","));
            }
            // Combine each row data with new line character
            csv_data = csv_data.join('\n');

            const CSVFile = new Blob([csv_data], { type: "text/csv" });
 
            // Create to temporary link to initiate
            // download process
            let temp_link = document.createElement('a');
        
            // Download csv file
            temp_link.download = "Perbandingan Data.csv";
            let url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;
        
            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);
        
            // Automatically click the link to trigger download 
            temp_link.click();
            document.body.removeChild(temp_link);
        }
    },
    filters: {
        date(val)
        {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    }

}
</script>

<style scoped>
.btn-group>button{
    display: flex;
    text-align: left;
}

.btn-group>button:after{
    margin-left:auto;
    align-self: center;
}
</style>