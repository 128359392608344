<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarReference/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/referensi/lokasi/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/referensi/lokasi/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="store" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row mt-5">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">Data Lokasi</div>
                                        <form action="">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <label for="">Nama</label>
                                                        <input type="text" class="form-control" name="Nama" v-model="name" required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <label for="">Kode</label>
                                                        <input type="text" class="form-control" name="Kode" v-model="code" required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2 mt-4">
                                                    <div class="form-group">
                                                        <input class="form-check-input" v-model="is_saluran" type="checkbox" id="saluran">
                                                        <label class="form-check-label" for="saluran">Lokasi Saluran</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <label for="">Divisi</label>
                                                        <Select2 class="vue-select2" :options="divisions" v-model="division_id" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <label for="">Jenis</label>
                                                        <Select2 class="vue-select2" :options="types" v-model="type_id" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <label for="">Frekuensi</label>
                                                        <Select2 class="vue-select2" :options="frequencies" v-model="frequency" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-5" v-if="is_saluran">
                                                    <div class="form-group">
                                                        <label for="">Jenis Saluran</label>
                                                    <Select2 class="vue-select2" :options="saluran_types" v-model="type" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-md-12 map-wrapper">
                                                    <i class="fa fa-map-marker"></i>
                                                    <GmapMap
                                                        :center="{lat:-6.592880630034612, lng:107.24024674765622}"
                                                        :zoom="7"
                                                        map-type-id="roadmap"
                                                        style="width: 100%; height: 400px"
                                                        ref="peta"
                                                        @dragend="getLatLng">
                                                    </GmapMap>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Select2 from 'v-select2-component'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarReference from '../../../components/SidebarReference.vue'
    
    export default{
        data(){
          return{
            name_reference: 'Tambah Referensi',

            // Need
            is_fullpage: true,
            is_loading: false,
            token: null,
            is_saluran: false,
            divisions:[],
            types: [],
            saluran_types: [{text: 'Induk', id:'induk'}, {text:'Sekunder', id:'sekunder'}],
            frequencies: [{text: 'Jam 00.00 dan 07.00', id:'Jam 00.00 dan 07.00'}, {text: 'Per Jam', id: 'Per Jam'}, {text:'Harian', id: 'Harian'}, {text:'Bulanan', id:'Bulanan'}],
            name: '',
            code: '',
            division_id: '',
            type_id: '',
            frequency: '',
            type: '',
            lat: '',
            lng: '',
          }
        },
        mounted(){
            this.token = localStorage.getItem('token');
			this.is_loading = true;

			// get divisions
			axios.get(this.$endpoints.reference+'divisions?all=1')
                .then((response) => {
                    let data = [];
                    for(var i = 0; i < response.data.length; i++){
                        data.push({
                            text: response.data[i].name,
                            id: response.data[i].id
                        })
                    }
                    this.divisions = data;
                    this.is_loading = false;
                })
                .catch(() => {
                    this.is_loading = false;
                    alert('there was an error. please contact developer.');
                })

			// get types
			axios.get(this.$endpoints.reference+'types?all=1')
                .then((response) => {
                    let data = [];
                    for(var i = 0; i < response.data.length; i++){
                        data.push({
                            text: response.data[i].name,
                            id: response.data[i].id
                        })
                    }
                    this.types = data;
                    this.is_loading = false;
                })
                .catch(() => {
                    this.is_loading = false;
                    alert('there was an error. please contact developer.');
                });
        },
        components:{
            Navbar,
            SidebarReference,
            Loading,
            Select2,
        },
        methods:{
            getLatLng(){
				this.$refs.peta.$mapPromise.then((map) => {
					let coords = map.getCenter();//({lat: 1.38, lng: 103.80})
					this.lat = coords.lat();
					this.lng = coords.lng();
				})
			},
			store(){
				if(this.name.length > 0){
					let fd = new FormData();
					fd.append('name', this.name);
					fd.append('code', this.code);
					fd.append('division_id', this.division_id);
					fd.append('type_id', this.type_id);
					fd.append('frequency', this.frequency);
					fd.append('type', this.type);
					fd.append('lat', this.lat);
					fd.append('lng', this.lng);

					this.is_loading = true;

					axios.post(this.$endpoints.reference+'locations?token='+this.token, fd)
					.then(() => {
						this.is_loading = false;
						this.$router.push({path:'/referensi/lokasi/index'});
					}).catch(() => {
						this.is_loading = false;
						alert('there was an error while saving data. please contact developer.');
					});
				}else{
					alert("Name could not be blank");
				}
			}
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>