<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSkema/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <Breadcrumb :links="links"/>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row">
                <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                        <!-- Recent Sales -->
                        <div class="col-sm-12">
                            <div class="card" style="height: 1000px">

                                <!-- ===== MENU BREADCRUMBS ===== -->
                                <iframe width="100%" height="100%" :src="iframe" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    // import {BTabs} from 'bootstrap-vue';
    // import Notif from 'vue-notification' 

    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarSkema from '../../components/SidebarSkema.vue'
    
    export default{
        data(){
            return{
                name: 'Struktural',
                // Need
                is_loading: true,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/plta', text: 'PLTA'}, 
                ],

                // iframe: 'http://202.162.210.218/',
                iframe: 'https://scdplta.jasatirta2.co.id/'
            }
        },
        mounted(){
            this.is_loading = false;
        },
        components:{
            Navbar,
            SidebarSkema,
            Loading, 
            Breadcrumb,
        },
    }

</script>

<style scoped>
    .dashboard .filter{
        position: absolute;
        left: 0px;
        top: 15px;
    }

    .nav-tabs-bordered .nav-link.active{
        background-color: #fff;
        color: #509ee3;
        border-bottom: 2px
        solid #509ee3;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        background-color: #fff;
        border-color: #509ee3;
    }

    .btn-xs{
        border-radius: 10px;
    }
</style>