<template>
  <div class="position-relative">
        <button class="btn btn-sm position-absolute" :class="edit_mode ? 'btn-success':'btn-primary'" id="btn-edit-skema" @click="() => edit_mode = !edit_mode" v-if="getRole() !== 'guest'">
            <span v-if="!edit_mode"><i class="fas fa-edit"></i> Edit</span>
            <span v-else><i class="fas fa-check"></i> Selesai</span>
        </button>
        <b-modal id="modal-edit" title="Mapping Lokasi" ref="modal_edit" :hide-footer="true">
            <div class="form-group mb-2">
                <label for="">Jenis Lokasi</label>
                <Select2 :options="types" v-model="type_id" placeholder="Pilih Jenis Lokasi" @change="(value) => fillLocation(value)"></Select2>
            </div>
            <div class="form-group mb-2">
                <label for="">Lokasi</label>
                <Select2 :options="locations" v-model="location_id" placeholder="Pilih Lokasi" @change="(value) => fillDataPeriodic(value)"></Select2>
            </div>
            <div class="form-group mb-3">
                <label for="">Data Periodik</label>
                <Select2 :options="data_periodics" v-model="data_id" placeholder="Pilih Data periodik"></Select2>
            </div>
            <hr>
            <div class="form-group">
                <a href="" class="btn btn-primary me-2" @click.prevent="updateMapping"><i class="fas fa-save"></i> Simpan</a>
                <a href="" class="btn btn-danger" @click.prevent="$bvModal.hide('modal-edit')"><i class="fas fa-times"></i> Batal</a>
            </div>
        </b-modal>
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        id="svg2"
        version="1.1"
        inkscape:version="0.48.4 r9939"
        sodipodi:docname="skema.svg"
        viewBox="0 0 1040 600"
        width="100%"
        height="1000%">
        <!-- <style
            type="text/css"
            id="style3">
                @import url(http://fonts.googleapis.com/css?family=Open+Sans:400italic,700,400);
                tspan{font-size: 10px; font-family: 'Open Sans'}
                .small-font{font-size: 9px}
            </style> -->
        <defs
            id="defs4" />
        <sodipodi:namedview
            id="base"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageopacity="0.0"
            inkscape:pageshadow="2"
            inkscape:zoom="1.344207"
            inkscape:cx="117.69783"
            inkscape:cy="432.63538"
            inkscape:document-units="px"
            inkscape:current-layer="layer1"
            showgrid="false"
            inkscape:window-width="1366"
            inkscape:window-height="716"
            inkscape:window-x="0"
            inkscape:window-y="30"
            inkscape:window-maximized="1"
            showguides="true"
            inkscape:guide-bbox="true" />
        <metadata
            id="metadata7">
            <rdf:RDF>
            <cc:Work
                rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                <dc:title />
            </cc:Work>
            </rdf:RDF>
        </metadata>
        <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            id="layer1"
            transform="translate(-52,-308.2677)">
            <path
            style="fill:#ff0000;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 941.3098,363.70872 0,403.2117"
            id="path3384"
            inkscape:connector-curvature="0" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-9-7"
            width="44.635979"
            height="21.702089"
            x="504.24814"
            y="782.6358" />
            <rect
            style="fill:#ff37ff;fill-opacity:1;stroke:#000000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5-1-4-6-1"
            width="44.635979"
            height="21.702089"
            x="387.46725"
            y="849.284" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 853.47717,517.66032 7.43933,7.8113"
            id="path4424-9"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
            d="m 705.62048,210.13655 7.43933,7.8113"
            id="path4424"
            inkscape:connector-curvature="0"
            transform="translate(0,308.2677)" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 777.57821,364.14235 0,401.71504"
            id="path3807-1-4"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 1034.4243,518.09145 -12.9475,0"
            id="path4558"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 495.08741,443.22122 0,-56.13752"
            id="path4241"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 381.26566,574.75719 -18.97029,14.87866"
            id="path4195"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 273.53555,517.76013 11.53096,-13.94874"
            id="path4142"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:6, 2;stroke-dashoffset:0"
            d="M 112.8356,128.6276 58.390448,110.72883"
            id="path4080"
            inkscape:connector-curvature="0"
            transform="translate(0,308.2677)" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
            d="m 115.20278,135.2031 -6.97004,6.97003"
            id="path4076"
            inkscape:connector-curvature="0"
            transform="translate(0,308.2677)" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 641.88352,363.46632 0,186.44004 -87.50782,82.9281 0,120.2411"
            id="path3935"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 554.3757,363.77573 0,180.43174 87.50782,80.39323 0,63.21571"
            id="path3927"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 57.578695,364.4483 0,360.7156"
            id="path3803"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 108.93403,366.4686 0,336.97003"
            id="path3807"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 203.60242,363.5199 0,401.71504"
            id="path3807-1"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 284.82241,418.73512 0,310.09119"
            id="path3807-8"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 205.02036,417.23808 80.8122,26.71025"
            id="path3807-3"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 361.4336,416.97419 76.02171,-38.08014"
            id="path3807-3-2"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 361.41126,414.84942 0,386.13746"
            id="path3807-8-4"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 437.91888,363.94322 0,517.56649"
            id="path3807-8-4-3"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:2.9244945;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 360.64476,562.64125 -30.30457,0 0,290.60446"
            id="path3923"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 439.66534,591.42597 56.81233,0"
            id="path3925"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 716.09795,486.81104 61.36458,-38.27988"
            id="path3807-3-2-2"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 713.89534,486.00176 0,234.96"
            id="path3807-1-4-3"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="M 862.16735,454.6882 926.2623,421.81405"
            id="path3807-3-2-2-5"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 861.718,453.17164 0,234.96"
            id="path3807-1-4-3-0"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 1033.943,365.55992 0,401.71504"
            id="path3807-1-4-6-4"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 56.568543,364.36708 979.550357,0"
            id="path2995"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(48.921727,268.55167)"
            @click="showSubGrafik('bekasi')" />
            <path
            style="fill:none;stroke:#062961;stroke-width:1.94344258;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 200.05409,439.78852 -83.69552,0"
            id="path4056"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none; cursor:pointer"
            id="path4054-9"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(-36.751015,267.99641)"
            @click="showSubGrafik('pab')" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 203.13223,436.45002 -10.076,-32.80061"
            id="path4082"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;"
            id="path4054-3"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(129.47764,242.85752)" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 180.11136,519.20743 c 6.54039,-0.006 11.26736,-0.13134 18.53491,-0.0672 1.76555,0.0156 1.83609,3.92517 5.12885,3.79922 3.29374,-0.12599 2.88385,-4.10362 3.94483,-4.09549 47.28645,0.36219 104.86322,-0.76636 144.16038,-0.76053 4.22342,6.3e-4 2.98697,8.92943 9.29925,8.92943 6.45678,0 4.96706,-8.92532 9.29927,-8.92424 58.63444,0.0146 120.03807,0.0455 177.6549,0.0435 0,0 0.42314,-9.63717 6.31249,-9.46872 5.47576,0.15662 6.31248,9.46872 6.31248,9.46872 l 74.56006,0 c 0,0 -0.45857,-8.9272 7.06737,-8.9272 6.30474,0 5.95146,8.9272 5.95146,8.9272 18.79761,-0.0449 37.69498,-0.0468 56.53926,-0.0256 4.89969,0.006 1.42792,-9.47314 8.92756,-9.47314 6.82154,0 4.89621,9.50063 9.4846,9.50063 44.10289,0 87.63884,0.25278 128.63288,0.26112 3.77117,0 3.07631,-9.17232 9.99476,-9.46872 6.13918,-0.26302 4.91198,9.2057 8.94268,9.2057 50.4273,0 103.62015,0 151.94125,0"
            id="path4122"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="csssssscsccscssscssc" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-0"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(129.63032,346.44824)"
            @click="showSubGrafik('cikarang')">
            <path
                style="fill:none;stroke:#ff0000;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
                d="m 927.26614,362.8001 0,401.71504"
                id="path3807-1-4-6"
                inkscape:connector-curvature="0" />
            </path>
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-7"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 a 4.8729486,4.8729486 0 1 1 -9.7459,0 4.8729486,4.8729486 0 1 1 9.7459,0 z"
            transform="translate(786.20246,346.95222)"
            @click="showSubGrafik('salamdarma')" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(283.0665,346.95222)"
            @click="showSubGrafik('curug')" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 380.8937,517.84632 0,57.76609"
            id="path4191"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-5"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(206.44141,417.25389)"
            @click="showSubGrafik('cibeet')" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-7"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(282.32257,279.25431)"
            @click="showSubGrafik('walahar')" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 441.15227,141.88072 106.75439,0 c 0,0 1.11955,-8.18326 6.32343,-8.18326 5.76,0 6.69539,8.18326 6.69539,8.18326 l 32.36109,0"
            id="path4233"
            inkscape:connector-curvature="0"
            transform="translate(0,308.2677)"
            sodipodi:nodetypes="ccscc" />
            <rect
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;stroke-dashoffset:0"
            id="rect4235"
            width="5.4329524"
            height="9.0599604"
            x="492.40543"
            y="444.27786" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 498.75164,449.97206 23.59558,-40.86875"
            id="path4243"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
            d="m 499.92298,140.02088 23.61987,-23.61987"
            id="path4245"
            inkscape:connector-curvature="0"
            transform="translate(0,308.2677)" />
            <path
            style="fill:none;stroke:#062961;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 499.28358,449.20832 9.8181,-38.01019"
            id="path4249"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;"
            id="path4054-3-4-7-9"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(367.87487,234.9903)" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 586.54925,571.52165 0,-17.80674"
            id="path4306"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-7-9-5"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(431.28515,401.4353)"
            @click="showSubGrafik('barugbug')" />
            <rect
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;stroke-dashoffset:0"
            id="rect4235-0"
            width="5.4329524"
            height="9.0599604"
            x="584.88483"
            y="545.09851" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 621.27975,517.67974 -9.97024,10.08967"
            id="path4355"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;"
            id="path4054-3-4-7-9-5-8"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(453.42716,360.34301)" />
            <path
            style="fill:none;stroke:#062961;stroke-width:1.29334152;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 590.19318,544.87031 14.35455,-15.58727"
            id="path4375"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 712.31585,486.60113 -31.48373,0"
            id="path4399"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-7-9-5-8-1"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(559.43761,316.07899)"
            @click="showSubGrafik('cijengkol')" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2.16932225;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 855.59862,455.16997 -21.30721,0"
            id="path4399-9"
            inkscape:connector-curvature="0" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-7-9-5-8-1-6"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(706.06808,284.08988)"
            @click="showSubGrafik('gadung')" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 590.59097,573.07334 47.17229,0"
            id="path4462"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 782.61752,601.16682 18.67447,0 0,-76.94887 c 0,0 5.8914,-2.41102 5.8914,-6.44399 0,-4.16448 -5.8914,-6.18097 -5.8914,-6.18097 l 0,-70.37177"
            id="path4511"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccscc" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 800.75425,536.84291 -10.19807,-11.57288 c 0,0 6.13745,-2.75245 6.13745,-7.47021 0,-4.35145 -6.13745,-6.32343 -6.13745,-6.32343 l 0,-63.00821"
            id="path4517"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="ccscc" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 784.94231,517.19538 0,-28.40894 6.17494,-6.48115"
            id="path4519"
            inkscape:connector-curvature="0" />
            <rect
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;stroke-dashoffset:0"
            id="rect4235-0-1"
            width="5.4329524"
            height="9.0599604"
            x="990.44714"
            y="511.27136" />
            <g
            id="g4553"
            transform="translate(2,0)">
            <path
                inkscape:connector-curvature="0"
                id="path4541"
                d="m 991.83794,511.06695 0,-80.3614"
                style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4549"
                d="M 990.80766,510.03668 972.5289,441.81933"
                style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4551"
                d="m 991.83794,510.03668 24.72656,-65.93756"
                style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <path
            style="fill:#1961cf;fill-opacity:1;stroke:none"
            d="m 394.53006,655.53013 43.60595,33.14052 43.60597,-33.14052 z"
            id="path4566"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccc" />
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="410.83731"
            y="665.26184"
            id="text4593"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4595"
                x="410.83731"
                y="665.26184"
                style="font-size:8px">Ir. H. Djuanda</tspan></text>
            <path
            style="fill:#1961cf;fill-opacity:1;stroke:none"
            d="m 394.84844,724.96742 43.60595,33.14052 43.60597,-33.14052 z"
            id="path4566-67"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccc" />
            <path
            style="fill:#1961cf;fill-opacity:1;stroke:none"
            d="m 394.58542,791.51149 43.60595,33.14052 43.60597,-33.14052 z"
            id="path4566-1"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccc" />
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="423.09509"
            y="736.96216"
            id="text4622"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4624"
                x="423.09509"
                y="736.96216"
                class="small-font">Cirata</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="415.77997"
            y="804.45416"
            id="text4626"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4628"
                x="415.77997"
                y="804.45416"
                class="small-font">Saguling</tspan></text>
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630"
            width="44.635979"
            height="21.702089"
            x="146.18283"
            y="376.49905" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1"
            width="44.635979"
            height="21.702089"
            x="137.25565"
            y="414.37561" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1"
            width="44.635979"
            height="21.702089"
            x="123.49287"
            y="460.1275" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-2"
            width="44.635979"
            height="21.702089"
            x="123.49289"
            y="519.79321" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-2-9"
            width="44.635979"
            height="21.702089"
            x="123.49288"
            y="578.46686" />
            <rect
            style="fill:#dc7974;fill-opacity:1;stroke:#890000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-2-9-5"
            width="44.635979"
            height="21.702089"
            x="123.86485"
            y="631.92749" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-7"
            width="44.635979"
            height="21.702089"
            x="229.13138"
            y="486.90909" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-3"
            width="44.635979"
            height="21.702089"
            x="385.72928"
            y="489.51285" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0"
            width="44.635979"
            height="21.702089"
            x="447.10376"
            y="489.65302" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-4"
            width="44.635979"
            height="21.702089"
            x="443.75604"
            y="421.86346" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2"
            width="44.635979"
            height="21.702089"
            x="672.14349"
            y="446.36475" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-49"
            width="44.635979"
            height="21.702089"
            x="654.86798"
            y="561.55469" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-9"
            width="44.635979"
            height="21.702089"
            x="654.86798"
            y="598.68317" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12987947;stroke-opacity:1"
            id="rect4630-1-1-0-0"
            width="44.9272"
            height="21.702089"
            x="808.65515"
            y="556.83881" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12987947;stroke-opacity:1"
            id="rect4630-1-1-0-0-5"
            width="44.9272"
            height="21.702089"
            x="820.78448"
            y="415.05276" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-5"
            width="44.635979"
            height="21.702089"
            x="969.87317"
            y="534.89276" />
            <rect
            style="fill:#6bcd1e;fill-opacity:1;stroke:#116f00;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0"
            width="44.635979"
            height="21.702089"
            x="210.34708"
            y="522.70947" />
            <rect
            style="fill:#6bcd1e;fill-opacity:1;stroke:#116f00;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-6"
            width="44.635979"
            height="21.702089"
            x="295.3414"
            y="526.70947" />
            <rect
            style="fill:#6bcd1e;fill-opacity:1;stroke:#116f00;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-0"
            width="44.635979"
            height="21.702089"
            x="386.84515"
            y="569.11371" />
            <rect
            style="fill:#6bcd1e;fill-opacity:1;stroke:#116f00;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-01"
            width="44.635979"
            height="21.702089"
            x="809.39911"
            y="478.35385" />
            <rect
            style="fill:#6bcd1e;fill-opacity:1;stroke:#116f00;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-7"
            width="44.635979"
            height="21.702089"
            x="873.37738"
            y="534.94128" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4"
            width="44.635979"
            height="21.702089"
            x="182.1311"
            y="778.29236" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-5"
            width="44.635979"
            height="21.702089"
            x="308.73218"
            y="861.58728" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8"
            width="44.635979"
            height="21.702089"
            x="265.58411"
            y="744.77893" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-0"
            width="44.635979"
            height="21.702089"
            x="495.13052"
            y="580.27264" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-9"
            width="44.635979"
            height="21.702089"
            x="691.48029"
            y="725.29108" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-1"
            width="44.635979"
            height="21.702089"
            x="754.25098"
            y="769.54962" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-7"
            width="44.635979"
            height="21.702089"
            x="839.85181"
            y="695.85175" />
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-6"
            width="44.635979"
            height="21.702089"
            x="904.34222"
            y="772.94043" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6"
            width="44.635979"
            height="21.702089"
            x="265.58408"
            y="775.4635" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none; cursor:pointer"
            id="path4054-3-4-5-6"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(175.94016,682.34215)"
            @click="showSubGrafik('cipamingkis')" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-8"
            width="44.635979"
            height="21.702089"
            x="385.40585"
            y="418.32706" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-1"
            width="44.635979"
            height="21.702089"
            x="494.38657"
            y="546.79572" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-3"
            width="44.635979"
            height="21.702089"
            x="655.23999"
            y="529.06458" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-4"
            width="44.635979"
            height="21.702089"
            x="719.38324"
            y="559.07062" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-7-9-5-8-1-6-4"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(622.29995,430.27271)"
            @click="showSubGrafik('macan')" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-4-7"
            width="44.635979"
            height="21.702089"
            x="866.65552"
            y="469.79861" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-4-3"
            width="44.635979"
            height="21.702089"
            x="873.46765"
            y="378.90662" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-4-33"
            width="44.635979"
            height="21.702089"
            x="721.26947"
            y="408.58484" />
            <path
            sodipodi:type="arc"
            style="fill:#1959b1;fill-opacity:1;stroke:#062961;stroke-width:2;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;cursor:pointer"
            id="path4054-3-4-7-9-5-8-1-6-4-3"
            sodipodi:cx="155.11003"
            sodipodi:cy="171.26607"
            sodipodi:rx="4.8729486"
            sodipodi:ry="4.8729486"
            d="m 159.98298,171.26607 c 0,2.69125 -2.18169,4.87295 -4.87295,4.87295 -2.69125,0 -4.87295,-2.1817 -4.87295,-4.87295 0,-2.69126 2.1817,-4.87295 4.87295,-4.87295 2.69126,0 4.87295,2.18169 4.87295,4.87295 z"
            transform="translate(399.12006,543.40995)"
            @click="showSubGrafik('pundong')" />
            <rect
            style="fill:#ff0000;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-8-6-1-0"
            width="44.635979"
            height="21.702089"
            x="569.47711"
            y="665.28833" />
            <rect
            style="fill:#aeaeae;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-4-6"
            width="44.635979"
            height="21.702089"
            x="449.01395"
            y="686.13208" />
            <rect
            style="fill:#aeaeae;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-9"
            width="44.635979"
            height="21.702089"
            x="448.48788"
            y="757.67358" />
            <rect
            style="fill:#aeaeae;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-7"
            width="44.635979"
            height="21.702089"
            x="447.96182"
            y="828.1629" />
            <rect
            style="fill:#ffbeff;fill-opacity:1;stroke:#000000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5"
            width="44.635979"
            height="21.702089"
            x="451.06409"
            y="621.8736" />
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 550.13376,714.76957 -26.5421,0"
            id="path4457"
            inkscape:connector-curvature="0" />
            <rect
            style="fill:#b5cd83;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5-5"
            width="44.635979"
            height="21.702089"
            x="531.60223"
            y="751.78815" />
            <path
            style="fill:none;stroke:#f0000f;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.94117647"
            d="m 116.7809,142.30463 0,219.88476 12.62496,0"
            id="path4477"
            inkscape:connector-curvature="0"
            transform="translate(0,308.2677)" />
            <g
            id="g4524"
            transform="matrix(1,0,0,2.053971,-16.307243,-677.87149)">
            <path
                inkscape:connector-curvature="0"
                id="path4501"
                d="m 538.4251,679.45835 0,29.37232"
                style="fill:none;stroke:#000000;stroke-width:0.48937953px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
            <path
                inkscape:connector-curvature="0"
                id="path4514"
                d="m 541.61291,703.15415 -3.11813,5.86111 -3.42994,-5.65546"
                style="fill:none;stroke:#000000;stroke-width:0.39305219px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
            </g>
            <g
            transform="matrix(0,1,-0.80023381,0,1112.8071,24.887355)"
            id="g4524-5">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3"
                d="m 538.4251,679.45835 0,29.37232"
                style="fill:none;stroke:#000000;stroke-width:0.48937953px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3"
                d="m 541.61291,703.15415 -3.11813,5.86111 -3.42994,-5.65546"
                style="fill:none;stroke:#000000;stroke-width:0.39305219px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
            </g>
            <path
            style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 590.8288,565.4806 52.88831,0"
            id="path4501-3-9"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 633.49587,562.29279 10.55361,3.11813 -10.18332,3.42994"
            id="path4514-3-2"
            inkscape:connector-curvature="0" />
            <g
            id="g4610"
            transform="matrix(1,0,0,1.3581551,0,-206.61793)">
            <g
                id="g4578-3"
                transform="matrix(0.39987398,0,0,1,394.00732,36.82277)">
                <path
                style="fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
                d="m 590.8288,561.4806 52.88831,0"
                id="path4501-3-9-4"
                inkscape:connector-curvature="0" />
                <path
                style="fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
                d="m 633.49587,558.29279 10.55361,3.11813 -10.18332,3.42994"
                id="path4514-3-2-7"
                inkscape:connector-curvature="0" />
            </g>
            <path
                inkscape:connector-curvature="0"
                id="path4608"
                d="m 630.39327,598.32385 0,-21.42865"
                style="fill:none;stroke:#000000;stroke-width:0.31912285;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            transform="matrix(0.36024356,0,0,1,417.44024,-17.31089)"
            id="g4578-9">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9"
                d="m 590.8288,561.4806 52.88831,0"
                style="fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4"
                d="m 633.49587,558.29279 10.55361,3.11813 -10.18332,3.42994"
                style="fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            id="g4668">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8"
                d="m 689.97234,481.68717 0,-10.49368"
                style="fill:none;stroke:#000000;stroke-width:0.3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6"
                d="m 687.47886,473.22151 2.43898,-2.09397 2.68288,2.0205"
                style="fill:none;stroke:#000000;stroke-width:0.3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            id="g4698"
            transform="matrix(0.64314146,0,0,1,264.52488,0)">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8-4"
                d="m 746.21122,463.12742 0,-30.08909"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6-5"
                d="m 741.25977,438.85338 4.84323,-6.00416 5.32755,5.79349"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            transform="translate(153.20245,-29.517302)"
            id="g4668-5">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8-8"
                d="m 689.97234,481.68717 0,-10.49368"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6-3"
                d="m 687.47886,473.22151 2.43898,-2.09397 2.68288,2.0205"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            transform="matrix(0.69412125,0,0,1,373.6065,-29.504431)"
            id="g4698-5">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8-4-0"
                d="m 746.21122,463.12742 0,-30.08909"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6-5-9"
                d="m 741.25977,438.85338 4.84323,-6.00416 5.32755,5.79349"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            transform="matrix(0,-1,1,0,442.72073,1173.4125)"
            id="g4668-5-3">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8-8-8"
                d="m 689.97234,481.68717 0,-10.49368"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6-3-0"
                d="m 687.47886,473.22151 2.43898,-2.09397 2.68288,2.0205"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            transform="matrix(-1,0,0,-1,1583.1314,1002.8089)"
            id="g4668-5-6">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8-8-5"
                d="m 689.97234,481.68717 0,-10.49368"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6-3-9"
                d="m 687.47886,473.22151 2.43898,-2.09397 2.68288,2.0205"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <g
            transform="matrix(-1,0,0,-1,1675.3791,1002.8089)"
            id="g4668-5-6-5">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-8-8-5-7"
                d="m 689.97234,481.68717 0,-10.49368"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-6-3-9-1"
                d="m 687.47886,473.22151 2.43898,-2.09397 2.68288,2.0205"
                style="fill:none;stroke:#000000;stroke-width:0.30000001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <path
            style="fill:none;stroke:#000000;stroke-width:0.3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 774.04066,571.07774 -7.21284,0"
            id="path4501-3-9-9-8-8-8-9"
            inkscape:connector-curvature="0" />
            <path
            style="fill:none;stroke:#000000;stroke-width:0.3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 768.22178,573.04055 -1.43929,-1.91991 1.38879,-2.11189"
            id="path4514-3-2-4-6-3-0-2"
            inkscape:connector-curvature="0" />
            <g
            transform="matrix(0.25627191,-0.25318004,0.7028024,0.71138512,257.24024,345.7249)"
            id="g4578-9-8">
            <path
                inkscape:connector-curvature="0"
                id="path4501-3-9-9-7"
                d="m 590.8288,561.4806 52.88831,0"
                style="fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-3-2-4-4"
                d="m 633.49587,558.29279 10.55361,3.11813 -10.18332,3.42994"
                style="fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none" />
            </g>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="163.93707"
            y="392.5629"
            id="text4883"
            sodipodi:linespacing="125%"
            transform="matrix(0.97580254,0.2186536,-0.2186536,0.97580254,0,0)"><tspan
                sodipodi:role="line"
                id="tspan4885"
                x="163.93707"
                y="392.5629"
                class="small-font">PAB</tspan></text>
            <text
            xml:space="preserve"
            style="font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic;font-stretch:normal;font-variant:normal;text-anchor:start;text-align:start;writing-mode:lr"
            x="127.3017"
            y="408.48914"
            id="text4887"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4889"
                x="127.3017"
                y="408.48914"
                class="small-font"
                style="fill:#64a0de;fill-opacity:1;-inkscape-font-specification:Sans Italic;font-family:Sans;font-weight:normal;font-style:italic;font-stretch:normal;font-variant:normal;text-anchor:start;text-align:start;writing-mode:lr;line-height:125%">Bekasi Utara</tspan></text>
            <text
            xml:space="preserve"
            style="font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="162.7547"
            y="451.09836"
            id="text4891"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4893"
                x="162.7547"
                y="451.09836"
                class="small-font">Bekasi</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#228e00;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="126.24962"
            y="492.65552"
            id="text4895"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                x="126.24962"
                y="492.65552"
                id="tspan4899"
                class="small-font">Buaran</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#228e00;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="126.66626"
            y="552.20746"
            id="text4903"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                x="126.66626"
                y="552.20746"
                id="tspan4907"
                class="small-font">Pulogadung</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#228e00;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="125.82249"
            y="609.96246"
            id="text4911"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4913"
                x="125.82249"
                y="609.96246"
                class="small-font">Pejompongan</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:7px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="328.86215"
            y="319.39621"
            id="text4915"
            sodipodi:linespacing="125%"
            transform="matrix(0.94350263,0.33136504,-0.33136504,0.94350263,0,0)"><tspan
                sodipodi:role="line"
                id="tspan4917"
                x="328.86215"
                y="319.39621"
                style="font-size:7px;font-family:Open Sans">Cikarang - Bekasi - Laut</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#dc7974;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="131.51001"
            y="672.56128"
            id="text4919"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4921"
                x="131.51001"
                y="672.56128"
                class="small-font">TMA B. Gergaji</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="291.42621"
            y="511.06696"
            id="text4923"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4925"
                x="291.42621"
                y="511.06696"
                class="small-font">Karang</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="290.90018"
            y="404.28079"
            id="text4965"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4967"
                x="290.90018"
                y="404.28079"
                class="small-font">Sarengseng</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:125%;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="332.45734"
            y="592.07715"
            id="text4969"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4971"
                x="332.45734"
                y="592.07715"
                class="small-font">Beet</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="405.44348"
            y="534.58478"
            id="text3401"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan3403"
                x="405.44348"
                y="534.58478"
                class="small-font">Curug</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="393.54056"
            y="145.22839"
            id="text4173"
            sodipodi:linespacing="125%"
            transform="translate(0,308.2677)"><tspan
                sodipodi:role="line"
                id="tspan4175"
                x="393.54056"
                y="145.22839" /></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="386.10123"
            y="453.49609"
            id="text4177"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4179"
                x="386.10123"
                y="453.49609"
                class="small-font">Walahar</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="258.14474"
            y="810.58392"
            id="text4181"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4183"
                x="258.14474"
                y="810.58392"
                class="small-font">Cipamingkis</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:8px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="383.3573"
            y="561.92706"
            id="text4185"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                x="383.3573"
                y="561.92706"
                id="tspan4193"
                class="small-font">Suplesi Cibeet</tspan></text>
            <text
            xml:space="preserve"
            style="font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="502.15482"
            y="395.46933"
            id="text4195"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4197"
                x="502.15482"
                y="395.46933"
                class="small-font">Ranggon</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="568.36487"
            y="529.37726"
            id="text4199"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4201"
                x="568.36487"
                y="529.37726"
                class="small-font">Rejag</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:8px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="582.77991"
            y="613.62494"
            id="text4203"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4205"
                x="582.77991"
                y="613.62494"
                class="small-font">Barugbug</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="653.91711"
            y="592.37976"
            id="text4207"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4209"
                x="653.91711"
                y="592.37976"
                class="small-font">Sungapan</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="653.17322"
            y="633.52789"
            id="text4211"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4213"
                x="653.17322"
                y="633.52789"
                class="small-font">Benggala</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="664.84436"
            y="497.85175"
            id="text4215"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4217"
                x="664.84436"
                y="497.85175"
                class="small-font">Jengkol</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="733.70123"
            y="605.99146"
            id="text4219"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4221"
                x="733.70123"
                y="605.99146"
                class="small-font">Macan</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="870.12134"
            y="459.95969"
            id="text4223"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4225"
                x="870.12134"
                y="459.95969"
                class="small-font">Gadung</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="910.15894"
            y="508.54715"
            id="text4227"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4229"
                x="910.15894"
                y="508.54715"
                class="small-font"
                style="font-size:9px;font-family:Open Sans">Salamdarma</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="51.331379"
            y="740.65424"
            id="text4231"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4233"
                x="51.331379"
                y="740.65424"
                class="small-font">Ciliwung</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="94.479492"
            y="408.58069"
            id="text4235"
            sodipodi:linespacing="125%"
            transform="translate(0,308.2677)"><tspan
                sodipodi:role="line"
                id="tspan4237"
                x="94.479492"
                y="408.58069"
                class="small-font">Cipinang</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="186.73807"
            y="773.94794"
            id="text4239"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4241"
                x="186.73807"
                y="773.94794">Bekasi</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="265.31464"
            y="740.25006"
            id="text4243"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4245"
                x="265.31464"
                y="740.25006"
                class="small-font">Cikarang</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="345.52469"
            y="838.56805"
            id="text4247"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4249"
                x="345.52469"
                y="838.56805"
                class="small-font">Cibeet</tspan></text>
            <rect
            style="fill:#eaee11;fill-opacity:1;stroke:#000000;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-4-5-6"
            width="44.635979"
            height="21.702089"
            x="342.95312"
            y="805.41492" />
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="449.5188"
            y="583.31781"
            id="text4280"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4282"
                x="449.5188"
                y="583.31781"
                class="small-font">Cikao</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:8px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="556.73138"
            y="637.99146"
            id="text4284"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4286"
                x="556.73138"
                y="637.99146"
                class="small-font">Ciherang</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:8px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="599.51294"
            y="632.43329"
            id="text4288"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4290"
                x="599.51294"
                y="632.43329"
                class="small-font">Cilamaya</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="691.62592"
            y="759.48438"
            id="text4292"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4294"
                x="691.62592"
                y="759.48438"
                class="small-font">Cijengkol</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="759.5556"
            y="803.65674"
            id="text4296"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4298"
                x="759.5556"
                y="803.65674"
                class="small-font">Ciasem</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="840.41254"
            y="730.98309"
            id="text4300"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4302"
                x="840.41254"
                y="730.98309"
                class="small-font">Cigadung</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="900.90289"
            y="806.12036"
            id="text4304"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4306"
                x="900.90289"
                y="806.12036"
                class="small-font">Cipunegara</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="994.63849"
            y="777.85089"
            id="text4308"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4310"
                x="994.63849"
                y="777.85089"
                class="small-font">Cilalanang</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#64a0de;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="560.88507"
            y="742.39325"
            id="text4211-7"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4213-0"
                x="560.88507"
                y="742.39325"
                class="small-font"
                style="font-size:9px;font-family:Open Sans">Pundong</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="211.78912"
            y="554.11029"
            id="text4345"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4347"
                x="211.78912"
                y="554.11029"
                class="small-font">B.Tb.44b</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="295.98105"
            y="558.02618"
            id="text4345-3"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4347-3"
                x="295.98105"
                y="558.02618"
                class="small-font">B.Tb.34b</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="388.56485"
            y="528.12122"
            id="text4372"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4374"
                x="388.56485"
                y="528.12122"
                class="small-font">STB</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="464.95813"
            y="218.87776"
            id="text4376"
            sodipodi:linespacing="125%"
            transform="translate(0,308.2677)"><tspan
                sodipodi:role="line"
                id="tspan4378"
                x="464.95813"
                y="218.87776"
                class="small-font">STT</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="565.62091"
            y="461.95969"
            id="text4380"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4382"
                x="565.62091"
                y="461.95969"
                class="small-font">STUT</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="467.93387"
            y="418.11618"
            id="text4384"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4386"
                x="467.93387"
                y="418.11618"
                class="small-font">STUB</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="447.84769"
            y="151.9238"
            id="text4388"
            sodipodi:linespacing="125%"
            transform="translate(0,308.2677)"><tspan
                sodipodi:role="line"
                id="tspan4390"
                x="447.84769"
                y="151.9238"
                class="small-font">STU</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="451.56738"
            y="616.41742"
            id="text4392"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4394"
                x="451.56738"
                y="616.41742"
                class="small-font">AKTotal</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="496.66693"
            y="611.72205"
            id="text4396"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4398"
                x="496.66693"
                y="611.72205"
                class="small-font">Cipaisan</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="456.77487"
            y="681.88354"
            id="text4400"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4402"
                x="456.77487"
                y="681.88354"
                class="small-font">TMA H. Juanda</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="454.54309"
            y="754.04504"
            id="text4404"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4406"
                x="454.54309"
                y="754.04504"
                class="small-font">TMA Cirata</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="453.05521"
            y="822.48688"
            id="text4408"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4410"
                x="453.05521"
                y="822.48688"
                class="small-font">TMA Saguling</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="813.11884"
            y="512.26678"
            id="text4412"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4414"
                x="813.11884"
                y="512.26678"
                class="small-font">B.Tt42</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="875.28027"
            y="565.82996"
            id="text4416"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4418"
                x="875.28027"
                y="565.82996"
                class="small-font">B.Tt53</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans"
            x="961.11292"
            y="513.01074"
            id="text4420"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4422"
                x="961.11292"
                y="513.01074"
                class="small-font">Bugis</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="150.50629"
            y="391.74966"
            id="text4455"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457"
                x="150.50629"
                y="391.74966" @click="showEditModal(0)">{{ data.location_0 ? data.location_0.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="139.35614"
            y="428.12283"
            id="text4455-0"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-2"
                x="139.35614"
                y="428.12283" @click="showEditModal(1)">{{ data.location_1 ? data.location_1.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="125.96534"
            y="474.99063"
            id="text4455-6"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-9"
                x="125.96534"
                y="474.99063" @click="showEditModal(2)">{{ data.location_2 ? data.location_2.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="125.73354"
            y="533.76135"
            id="text4455-65"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-7"
                x="125.73354"
                y="533.76135" @click="showEditModal(3)">{{ data.location_3 ? data.location_3.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="125.96534"
            y="591.04419"
            id="text4455-1"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-5"
                x="125.96534"
                y="591.04419" @click="showEditModal(4)">{{ data.location_4 ? data.location_4.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="128.19714"
            y="645.35126"
            id="text4455-9"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-6"
                x="128.19714"
                y="645.35126" @click="showEditModal(5)">{{ data.location_5 ? data.location_5.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="232.34776"
            y="501.77222"
            id="text4455-3"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-0"
                x="232.34776"
                y="501.77222" @click="showEditModal(6)">{{ data.location_6 ? data.location_6.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="214.49338"
            y="537.48102"
            id="text4455-68"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-22"
                x="214.49338"
                y="537.48102" @click="showEditModal(7)">{{ data.location_7 ? data.location_7.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="298.5578"
            y="541.71277"
            id="text4455-2"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-55"
                x="298.5578"
                y="541.71277" @click="showEditModal(8)">{{ data.location_8 ? data.location_8.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="390.47662"
            y="583.32452"
            id="text4455-687"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-79"
                x="390.47662"
                y="583.32452" @click="showEditModal(9)">{{ data.location_9 ? data.location_9.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="186.22392"
            y="791.90607"
            id="text4455-66"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-01"
                x="186.22392"
                y="791.90607" @click="showEditModal(10)">{{ data.location_10 ? data.location_10.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="269.31262"
            y="758.94122"
            id="text4455-09"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-4"
                x="269.31262"
                y="758.94122" @click="showEditModal(11)">{{ data.location_11 ? data.location_11.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="268.80048"
            y="790.41821"
            id="text4455-02"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-29"
                x="268.80048"
                y="790.41821" @click="showEditModal(12)">{{ data.location_12 ? data.location_12.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="312.69254"
            y="875.33453"
            id="text4455-33"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-90"
                x="312.69254"
                y="875.33453" @click="showEditModal(13)">{{ data.location_13 ? data.location_13.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="346.58459"
            y="819.16217"
            id="text4455-10"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-8"
                x="346.58459"
                y="819.16217" @click="showEditModal(14)">{{ data.location_14 ? data.location_14.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="389.31763"
            y="433.09857"
            id="text4455-36"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-84"
                x="389.31763"
                y="433.09857" @click="showEditModal(15)">{{ data.location_15 ? data.location_15.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="389.31763"
            y="503.26007"
            id="text4455-7"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-1"
                x="389.31763"
                y="503.26007" @click="showEditModal(16)">{{ data.location_16 ? data.location_16.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="499.41971"
            y="561.28687"
            id="text4455-4"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-3"
                x="499.41971"
                y="561.28687" @click="showEditModal(17)">{{ data.location_17 ? data.location_17.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="511.41971"
            y="595.50775"
            id="text4455-76"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-41"
                x="511.41971"
                y="595.50775" @click="showEditModal(18)">{{ data.location_18 ? data.location_18.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="453.29587"
            y="699.65839"
            id="text4455-40"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-74"
                x="453.29587"
                y="699.65839" @click="showEditModal(19)">{{ data.location_19 ? data.location_19.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="452.55194"
            y="772.56384"
            id="text4455-021"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-02"
                x="452.55194"
                y="772.56384" @click="showEditModal(20)">{{ data.location_20 ? data.location_20.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="452.55194"
            y="842.49353"
            id="text4455-12"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-87"
                x="452.55194"
                y="842.49353" @click="showEditModal(21)">{{ data.location_21 ? data.location_21.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="455.52768"
            y="636.93622"
            id="text4455-49"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-68"
                x="455.52768"
                y="636.93622" @click="showEditModal(22)">{{ data.location_22 ? data.location_22.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="451.06409"
            y="504.51614"
            id="text4455-99"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-64"
                x="451.06409"
                y="504.51614" @click="showEditModal(23)">{{ data.location_23 ? data.location_23.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="448.50342"
            y="435.56216"
            id="text4455-05"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-42"
                x="448.50342"
                y="435.56216" @click="showEditModal(24)">{{ data.location_24 ? data.location_24.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="535.87244"
            y="765.12451"
            id="text4455-8"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-13"
                x="535.87244"
                y="765.12451" @click="showEditModal(25)">{{ data.location_25 ? data.location_25.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="572.32513"
            y="680.31616"
            id="text4455-5"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-641"
                x="572.32513"
                y="680.31616" @click="showEditModal(26)">{{ data.location_26 ? data.location_26.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="659.59711"
            y="543.94458"
            id="text4455-67"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-82"
                x="659.59711"
                y="543.94458" @click="showEditModal(27)">{{ data.location_27 ? data.location_27.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="659.3653"
            y="576.16553"
            id="text4455-60"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-06"
                x="659.3653"
                y="576.16553" @click="showEditModal(28)">{{ data.location_28 ? data.location_28.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="659.59711"
            y="614.10608"
            id="text4455-53"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-96"
                x="659.59711"
                y="614.10608" @click="showEditModal(29)">{{ data.location_29 ? data.location_29.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="722.59961"
            y="573.42157"
            id="text4455-094"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-18"
                x="722.59961"
                y="573.42157" @click="showEditModal(30)">{{ data.location_30 ? data.location_30.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="695.34357"
            y="739.03833"
            id="text4455-70"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-133"
                x="695.34357"
                y="739.03833" @click="showEditModal(31)">{{ data.location_31 ? data.location_31.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="759.05237"
            y="784.46674"
            id="text4455-69"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-44"
                x="759.05237"
                y="784.46674" @click="showEditModal(32)">{{ data.location_32 ? data.location_32.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="843.86072"
            y="709.32953"
            id="text4455-56"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-443"
                x="843.86072"
                y="709.32953" @click="showEditModal(33)">{{ data.location_33 ? data.location_33.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="907.83893"
            y="787.4425"
            id="text4455-538"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-78"
                x="907.83893"
                y="787.4425" @click="showEditModal(34)">{{ data.location_34 ? data.location_34.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="813.12769"
            y="570.44586"
            id="text4455-27"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-879"
                x="813.12769"
                y="570.44586" @click="showEditModal(35)">{{ data.location_35 ? data.location_35.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="675.50006"
            y="461.36804"
            id="text4455-98"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-23"
                x="675.50006"
                y="461.36804" @click="showEditModal(36)">{{ data.location_36 ? data.location_36.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="726.31927"
            y="423.65924"
            id="text4455-82"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-05"
                x="726.31927"
                y="423.65924" @click="showEditModal(37)">{{ data.location_37 ? data.location_37.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="813.72601"
            y="492.84503"
            id="text4455-31"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-50"
                x="813.72601"
                y="492.84503" @click="showEditModal(38)">{{ data.location_38 ? data.location_38.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="824.51843"
            y="430.12283"
            id="text4455-34"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-32"
                x="824.51843"
                y="430.12283" @click="showEditModal(39)">{{ data.location_39 ? data.location_39.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="878.59375"
            y="393.15799"
            id="text4455-18"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-33"
                x="878.59375"
                y="393.15799" @click="showEditModal(40)">{{ data.location_40 ? data.location_40.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="871.38623"
            y="484.66174"
            id="text4455-50"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-19"
                x="871.38623"
                y="484.66174" @click="showEditModal(41)">{{ data.location_41 ? data.location_41.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="877.33771"
            y="548.64001"
            id="text4455-73"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-331"
                x="877.33771"
                y="548.64001" @click="showEditModal(42)">{{ data.location_42 ? data.location_42.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="974.04901"
            y="548.64001"
            id="text4455-316"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-53"
                x="974.04901"
                y="548.64001" @click="showEditModal(43)">{{ data.location_43 ? data.location_43.value:'&#9940;' }}</tspan></text>
            <rect
            style="fill:#ff37ff;fill-opacity:1;stroke:#000000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5-1"
            width="44.635979"
            height="21.702089"
            x="367.2226"
            y="672.23999" />
            <rect
            style="fill:#ffbeff;fill-opacity:1;stroke:#000000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5-1-4"
            width="44.635979"
            height="21.702089"
            x="367.2226"
            y="696.32617" />
            <rect
            style="fill:#ff37ff;fill-opacity:1;stroke:#000000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5-1-8"
            width="44.635979"
            height="21.702089"
            x="368.10126"
            y="741.66302" />
            <rect
            style="fill:#ffbeff;fill-opacity:1;stroke:#000000;stroke-width:1.12621152000000002;stroke-opacity:1"
            id="rect4630-1-1-0-4-6-5-1-4-6"
            width="44.635979"
            height="21.702089"
            x="368.10126"
            y="765.74921" />
            <text
            xml:space="preserve"
            style="font-size:9px;font-style:italic;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#ff0000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Italic"
            x="304.90131"
            y="894.22247"
            id="text4247-4"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4249-3"
                x="304.90131"
                y="894.22247"
                class="small-font">Cipamingkis</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="371.78125"
            y="685.98724"
            id="text4455-33-6"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-90-5"
                x="371.78125"
                y="685.98724" @click="showEditModal(44)">{{ data.location_44 ? data.location_44.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="372.71933"
            y="710.07343"
            id="text4455-33-2"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-90-3"
                x="372.71933"
                y="710.07343" @click="showEditModal(45)">{{ data.location_45 ? data.location_45.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="373.46326"
            y="755.45337"
            id="text4455-33-7"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-90-8"
                x="373.46326"
                y="755.45337" @click="showEditModal(46)">{{ data.location_46 ? data.location_46.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans Bold"
            :class="edit_mode ? 'doing-edit':''"
            x="373.69504"
            y="779.25922"
            id="text4455-33-68"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4457-90-89"
                x="373.69504"
                y="779.25922" @click="showEditModal(47)">{{ data.location_47 ? data.location_47.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="497.43262"
            y="345.62579"
            id="text4637"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4639"
                x="497.43262"
                y="345.62579"
                style="font-size:10px">Laut Jawa <tspan v-if="edit_mode">(Mode Edit)</tspan></tspan></text>
            <rect
            style="fill:#6bcd1e;fill-opacity:1;stroke:#116f00;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-0-2-0-0-4"
            width="44.635979"
            height="21.702089"
            x="577.29211"
            y="483.41791" />
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            x="588.07904"
            y="478.64636"
            id="text4167"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4169"
                style="font-size:8px;line-height:125%;font-family:Open Sans"
                x="588.07904"
                y="478.64636">B.Tt.21</tspan></text>
            <g
            id="g4524-3"
            transform="matrix(0,1,-0.48752799,0,658.53344,244.90937)"
            style="stroke-width:1.43218863;stroke-miterlimit:4;stroke-dasharray:none">
            <path
                inkscape:connector-curvature="0"
                id="path4501-9"
                d="m 538.4251,679.45835 0,29.37232"
                style="fill:none;stroke:#000000;stroke-width:1.43218863;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-1"
                d="m 541.61291,703.15415 -3.11813,5.86111 -3.42994,-5.65546"
                style="fill:none;stroke:#000000;stroke-width:1.43218863;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none" />
            </g>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans"
            :class="edit_mode ? 'doing-edit':''"
            x="401.30334"
            y="865.16809"
            id="text3385"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan3387"
                x="401.30334"
                y="865.16809" @click="showEditModal(48)">{{ data.location_48 ? data.location_48.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-weight:bold;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans;font-stretch:normal;font-variant:normal"
            :class="edit_mode ? 'doing-edit':''"
            x="519.49707"
            y="796.67773"
            id="text3389"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan3391"
                x="519.49707"
                y="796.67773" @click="showEditModal(49)">{{ data.location_49 ? data.location_49.value:'&#9940;' }}</tspan></text>
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Sans;-inkscape-font-specification:Sans"
            :class="edit_mode ? 'doing-edit':''"
            x="594.03052"
            y="497.897"
            id="text3393"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan3395"
                x="594.03052"
                y="497.897" @click="showEditModal(50)">{{ data.location_50 ? data.location_50.value:'&#9940;' }}</tspan></text>
            <path
            style="fill:none;stroke:#062961;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 179.78354,439.43188 1.33325,78.91376"
            id="path4596"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cc" />
            <rect
            style="fill:#64a0de;fill-opacity:1;stroke:#062961;stroke-width:1.12621152;stroke-opacity:1"
            id="rect4630-1-1-6"
            width="44.635979"
            height="21.702089"
            x="153.9231"
            y="694.51453" />
            <g
            id="g4524-7"
            transform="matrix(1,0,0,0.8016862,-343.96117,122.54988)">
            <path
                inkscape:connector-curvature="0"
                id="path4501-2"
                d="m 538.4251,679.45835 0,29.37232"
                style="fill:none;stroke:#000000;stroke-width:0.48937953px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
            <path
                inkscape:connector-curvature="0"
                id="path4514-8"
                d="m 541.61291,703.15415 -3.11813,5.86111 -3.42994,-5.65546"
                style="fill:none;stroke:#000000;stroke-width:0.39305219px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
            </g>
            <path
            style="fill:none;stroke:#000000;stroke-width:0.435;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
            d="m 194.46635,668.01794 0,-166.95719"
            id="path3862"
            inkscape:connector-curvature="0" />
            <text
            xml:space="preserve"
            style="font-size:10px;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;line-height:125%;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;font-family:Open Sans;-inkscape-font-specification:Open Sans"
            :class="edit_mode ? 'doing-edit':''"
            x="170.437"
            y="708.8985"
            id="text4632"
            sodipodi:linespacing="125%"><tspan
                sodipodi:role="line"
                id="tspan4634"
                style="font-size:10px;font-family:Open Sans;font-weight:bold;-inkscape-font-specification:Open Sans Bold"
                x="170.437"
                y="708.8985" @click="showEditModal(51)">{{ data.location_51 ? data.location_51.value:'&#9940;' }}</tspan></text>
        </g>
        </svg>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import Select2 from 'v-select2-component'
import axios from 'axios'


export default {
    name: 'SkemaDistribusi',
    props:{
        data: {}
    },
    components: {
        BModal,
        Select2
    },
    methods: {
        showSubGrafik(location){
            this.$emit('subgrafik_changed', location);

        },
        showEditModal(id){
            this.current_id = id;
            this.type_id = null;
            this.location_id = null;
            this.data_id = null;
            this.locations = [];
            this.data_periodics = [];

            if(this.edit_mode){
                this.$refs['modal_edit'].show();
            }
        },
        fillLocation(value){
            const types = this.types.filter(type => type.id == value);
            if(types.length > 0){
                this.locations = types[0].locations.map(v => {
                    return {
                        id: v.id,
                        text: v.name,
                        data_periodics: v.periodik
                    }
                });
            }

        },
        fillDataPeriodic(value){
            const locations = this.locations.filter(location => location.id == value);
            if(locations.length > 0){
                this.data_periodics = locations[0].data_periodics.map(v => {
                    return {
                        id: v.id,
                        text: v.name
                    }
                });
            }
        },
        updateMapping(){
            this.is_loading = true;
            const token = localStorage.getItem('token');

            const data = this.createFormData({
                key: `location_${this.current_id}`,
                data: JSON.stringify({
                    type_id: this.type_id,
                    location_id: this.location_id,
                    data_id: this.data_id
                })
            })

            axios.post(`${this.$endpoints.operasi}skema/mapping?token=${token}`, data)
            .finally(() => {
                this.$refs['modal_edit'].hide();
                this.$emit('updated');
            })
        }
    },
    data(){
        return {
            showing_subgrafik: false,
            current_subgrafik: '',
            edit_mode: false,
            current_id: null,
            types: [],
            type_id: null,
            locations: [],
            location_id: null,
            data_periodics: [],
            data_id: null
        }
    },
    mounted(){
        axios.get(`${this.$endpoints.reference}types?mapping=1`)
        .then(response => {
            this.types = response.data.map(type => {
                return {
                    id: type.id,
                    text: type.name,
                    locations: type.locations
                }
            });
        })
        .catch(e => console.log(e));
    }
}
</script>

<style>
    @import url(http://fonts.googleapis.com/css?family=Open+Sans:400italic,700,400);
    tspan{
        font-family: 'Open Sans';
        font-size: 10px!important;
    }

    #btn-edit-skema{
        top: 25px;
        right: 80px;
    }

    .doing-edit{
        fill:#fff!important;
        cursor: pointer
    }

    .select2-container{
        width: 100%!important;
    }
</style>