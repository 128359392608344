<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true">
        </loading>

        <!-- Navbar -->
        <Navbar/>

        <!-- Sidebar -->
        <SidebarSpatial/>
        <main class="main" id="main">
            <Breadcrumb :links="links"/>
            <div class="row">
                <div class="col-md-8">
                    <div class="position-relative">
                        <GmapMap
                            :center="{lat:this.lat, lng:this.lng}"
                            :zoom="9"
                            map-type-id="roadmap"
                            style="width: 100%; height: 400px"
                            ref="peta">
                            <GmapMarker
                                v-if="marker_lat && marker_lng"
                                :position="{lat: parseFloat(marker_lat), lng:parseFloat(marker_lng)}"
                            >
                            </GmapMarker>
                        </GmapMap>
                        <div class="position-absolute" style="left: 50%; transform: translateX(-50%); top: 50%">
                            <img :src="require('../../assets/marker/marker_black.png')" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Name</label>
                                <input type="text" v-model="name" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Address</label>
                                <input type="text" v-model="address" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Koreksi</label>
                                <input type="number" v-model="correction" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Level Siap</label>
                                <input type="number" v-model="level_siap" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Level Siaga</label>
                                <input type="number" v-model="level_siaga" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Level Awas</label>
                                <input type="number" v-model="level_awas" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Wilayah</label>
                                <select class="form-select" v-model="wilayah">
                                    <option v-for="(zone, i) in zones" :key="i" :value="zone">{{ zone }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Nomor Urut</label>
                                <input type="number" min="0" v-model="sort" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Formula Debit</label>
                                <input type="text" v-model="q_formula" class="form-control">
                            </div>
                            <div class="alert alert-info" style="font-size:13px">
                                + : tambah<br/>
                                - : kurang<br/>
                                * : kali<br/>
                                / : bagi<br/>
                                Exp: 10^6<br/>
                                ${tma} : untuk mengambil nilai TMA terkoreksi<br/>
                                <br>
                                contoh: 
                                <div class="bg-white rounded p-2">
                                    <code>20.043 . H2 - 26644 . H + 9E+06</code>
                                </div>
                                menjadi: 
                                <div class="bg-white rounded p-2">
                                    <code>20.043*${tma}*${tma}-26644*${tma}+9*exp</code>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary me-2" @click="update"><i class="fa fa-save"></i> Save</button>
            <router-link to="/telemeteri" class="btn btn-danger"><i class="fa fa-times"></i> Cancel</router-link>
        </main>
    </div>
</template>
<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarSpatial from '../../components/SidebarTelemeteri.vue'
import { zones } from '../../constants';

export default {
    components: {
        Loading,
        Breadcrumb,
        SidebarSpatial,
        Navbar
    },
    methods: {
        update(){
            this.is_loading = true;
            axios.post(this.$endpoints.telemeteri + 'stations/edit/' + this.$route.params.id, {
                name: this.name,
                address: this.address,
                correction: this.correction,
                level_siap: this.level_siap,
                level_siaga: this.level_siaga,
                level_awas: this.level_awas,
                q_formula: this.q_formula,
                lat: this.marker_lat,
                lng: this.marker_lng,
                sort: this.sort,
                wilayah: this.wilayah
            }).then((response) => {
                console.log(response);
                this.is_loading = false;
            })
        }
    },
    mounted(){
        axios.get(this.$endpoints.telemeteri + 'stations/edit/' + this.$route.params.id)
        .then((response) => {
            console.log(response);
            this.is_loading = false;

            this.name = response.data.name;
            this.address = response.data.address;
            this.correction = response.data.correction;
            this.level_siap = response.data.level_siap;
            this.level_siaga = response.data.level_siaga;
            this.level_awas = response.data.level_awas;
            this.q_formula = response.data.q_formula;
            this.marker_lat = response.data.lat;
            this.marker_lng = response.data.lng;
            this.sort = response.data.sort;
            this.wilayah = response.data.wilayah;
        });
    },
    data(){
        return {
            is_loading: true,
            lat: -6.5341128, 
            lng: 107.3428813,
            links: [
                {url: '/dashboard', text:'Home'}, 
                {url: '/telemeteri', text: 'Telemeteri'},
                {url: '#', text: 'Edit'}
            ],
            name: '',
            marker_lat: false,
            marker_lng: false,
            address: '',
            correction: '',
            level_awas: '',
            level_siap: '',
            level_siaga: '',
            q_formula: '',
            sort: null,
            wilayah: null,
            zones
        }
    }
}
</script>