<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSarpras/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/sarpras/infrastructure/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/sarpras/infrastructure/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="update" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <!-- END OF BREADCOMP -->

        <section class="p-0">
            <div class="row">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">Data Sarpras</div>
                                        <form action="">
                                            <!-- ==== FORM 1 ==== -->
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Organisasi</label>
                                                        <Select2 class="vue-select2" :options="organizations" @change="changeOrg" v-model="org_id" />
                                                    </div>
                                                    <div class="form-group mt-3">
                                                        <label for="">Sungai/Saluran</label>
                                                        <Select2 class="vue-select2" :options="channels" @change="changeChannel" v-model="channel_id" />
                                                    </div>
                                                    <div class="form-group mt-3">
                                                        <label for="">Ruas</label>
                                                        <Select2 class="vue-select2" :options="segments" v-model="segment_id" />
                                                    </div>
                                                    <div class="form-group mt-3">
                                                        <label for="">Jenis/Sub-Jenis</label>
                                                        <Select2 class="vue-select2" :options="types" v-model="type_id" />
                                                    </div>
                                                    <div class="form-group mt-3">
                                                        <label for="">Nama</label>
                                                        <input type="text" name="name" class="form-control" v-model="name">
                                                    </div>
                                                    <div class="form-group mt-3">
                                                        <label for="">Kode</label>
                                                        <input type="text" name="code" class="form-control" v-model="code">
                                                    </div>
                                                    <div class="form-group mt-3 mb-3">
                                                        <label for="">Panjang (m)</label>
                                                        <div class="input-group">
                                                            <input type="text" name="length" class="form-control" v-model="length">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text">m</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <!-- ===== ROW 1 ===== -->
                                                    <div class="row">
                                                        <div class="col-md-12 map-wrapper">
                                                            <i class="fa fa-map-marker"></i>
                                                            <GmapMap
                                                                :center="{lat:-6.592880630034612, lng:107.24024674765622}"
                                                                :zoom="7"
                                                                map-type-id="roadmap"
                                                                style="width: 100%; height: 500px"
                                                                ref="peta"
                                                                @idle="getLatLng">
                                                            </GmapMap>
                                                        </div>
                                                    </div>
                                                    <!-- ===== ROW 2 ===== -->
                                                    <div class="row mt-3">
                                                        <div class="col-md-6">
                                                            <label for="">Latitude</label>
                                                            <input type="text" name="code" class="form-control" v-model="lat">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Longitude</label>
                                                            <input type="text" name="code" class="form-control" v-model="lng">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Select2 from 'v-select2-component'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarSarpras from '../../../components/SidebarSarpras.vue'
    
    export default{
        data(){
          return{
                name_reference: 'Tambah Sarpras',
                is_loading: false,
                // Need
                token: null,
                organizations: [],
                channels: [],
                segments: [],
                types: [],
                type_id: null,
                channel_id: null,
                segment_id: null,
                org_id: '',
                org_name: '',
                name: '',
                code: '',
                lat: '',
                lng: '',
                length: 0
            }
        },
        mounted(){
            this.token = localStorage.getItem('token');
			this.is_loading = true;

			// get TYPES
			axios.get(this.$endpoints.sarpras+'supertypes?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					let children = [];
					for(var j = 0; j < response.data[i].types.length; j++){
						children.push({
							text: response.data[i].types[j].name,
							id: response.data[i].types[j].id
						});
					}

					data.push({
						text: response.data[i].name,
						children: children
					});
				}
				this.types = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error while populating UOM. please contact developer.');
			})

			// get CHANNEL
			axios.get(this.$endpoints.sarpras+'channels?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					data.push({
						text: response.data[i].name,
						id: response.data[i].id,
						segments: response.data[i].segments
					});
				}
				this.channels = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})

			// get segments
			axios.get(this.$endpoints.sarpras+'segments?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					data.push({
						text: response.data[i].name,
						id: response.data[i].id
					});
				}
				this.segments = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})

			// get organizations
			axios.get(this.$endpoints.reference+'organizations?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data[0].length; i++){
					var text = response.data[0][i].name;
					switch(response.data[0][i].indent){
						case 1:
							text = "- "+text;
							break;

						case 2:
							text = "-- "+text;
							break;

						case 3:
							text = "--- "+text;
							break;

						case 4:
							text = "---- "+text;
							break;
					}
					data.push({
						text,
						id: response.data[0][i].id
					})
				}
				this.organizations = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})

			axios.get(this.$endpoints.sarpras+'infrastructures/'+this.$route.params.id+'?token='+this.token)
			.then(response => {
				this.channel_id = response.data.channel_id;
				this.segment_id = response.data.segment_id;
				this.type_id = response.data.type_id;
				this.org_id = response.data.ref_organization_id;
				this.org_name = response.data.ref_organization_name;
				this.name = response.data.name;
				this.code = response.data.code;
				this.lat = this.marker_lat = response.data.lat;
				this.lng = this.marker_lng = response.data.lng;
				this.length = response.data.length;

				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error while getting data. please contact developer.');
			})
        },
        components:{
            Navbar,
            SidebarSarpras,
            Loading,
            Select2,
        },
        methods:{
            changeOrg(id){
				for(var i = 0; i < this.organizations.length; i++){
					if(this.organizations[i].id == id){
						this.org_name = this.organizations[i].text;

						break;
					}
				}
			},
			changeChannel(id){
				for(var j = 0; j < this.channels.length; j++){
					if(this.channels[j].id == id){
						let segments = [];
						for(var a = 0; a < this.channels[j].segments.length; a++){
							segments.push({
								text: this.channels[j].segments[a].name,
								id: this.channels[j].segments[a].id,
							});
						}

						this.segments = segments;
						break;
					}
				}
			},
			changeSection(id){
				for(var i = 0; i < this.sections.length; i++){
					if(this.sections[i].id == id){
						this.section_name = this.sections[i].text;

						break;
					}
				}
			},
			getLatLng(){
				this.$refs.peta.$mapPromise.then((map) => {
					let coords = map.getCenter();//({lat: 1.38, lng: 103.80})
					this.lat = coords.lat();
					this.lng = coords.lng();
				})
			},
			update(){
				let fd = this.createFormData({
					type_id: this.type_id,
					channel_id: this.channel_id,
					segment_id: this.segment_id,
					ref_organization_id: this.org_id,
					ref_organization_name: this.org_name,
					lat: this.lat,
					lng: this.lng,
					name: this.name,
					code: this.code,
					length: this.length,
					_method: 'PUT'
				});

				this.is_loading = true;

				axios.post(this.$endpoints.sarpras+'infrastructures/'+this.$route.params.id+'?token='+this.token, fd)
				.then(() => {
					this.is_loading = false;
					this.$router.push({path:'/infrastructure/sarpras/list'});
				}).catch(() => {
					this.is_loading = false;
					alert('there was an error while updating data. please contact developer');
				})
			}
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>