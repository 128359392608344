<template> 
    <!-- chart colors: #00C875, #FF7575, #FFCB00 -->
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <!-- <div class="sidebar">
            </div> -->
            <SidebarSarpras/>

            <div class="main" id="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '#', text: 'Sarana dan Prasana'}]"/>
                <h2><b>Overview</b></h2>
                <ul class="nav nav-tabs" style="border-bottom: 0!important">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#peta">Peta Sebaran</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" data-bs-toggle="tab" data-bs-target="#list-bangunan">List (Bangunan)</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" data-bs-toggle="tab" data-bs-target="#list-tanah">List (Tanah)</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="peta" role="tabpanel">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="document" style="position: relative">
                                    <div style="position: absolute; z-index: 99; background: #fff; padding: 10px 20px; border: 1px solid #ddd; bottom: 30px; left: 30px">
                                        <h4>Legenda</h4>
                                        <ul style="list-style-type: none; padding: 0">
                                            <li><img :src="require('../../assets/icon/grade3.png')" /> Kelompok bangunan</li>
                                            <li><img :src="require('../../assets/icon/grade1.png')" /> Kelompok tanah</li>
                                        </ul>
                                    </div>
                                    <GmapMap
                                        :center="{lat:-6.5341128, lng:107.3428813}"
                                        :zoom="12"
                                        map-type-id="roadmap"
                                        style="width: 100%; height: 700px"
                                        ref="peta">
                                        <GmapMarker
                                            v-for="(m, index) in kelompok_bangunan"
                                            :key="'b_'+index"
                                            :icon="{url: require('../../assets/icon/grade3.png')}"
                                            :clickable="true"
                                            :position="{lat: parseFloat(m.latitude), lng:parseFloat(m.longitude)}"
                                            :animation="selected_sarpras == m ? 1:0"
                                            @click="selected_sarpras = m; selected_kelompok = 'bangunan'" />

                                        <GmapMarker
                                            v-for="(m, index) in kelompok_tanah"
                                            :key="'t_'+index"
                                            :icon="{url: require('../../assets/icon/grade1.png')}"
                                            :clickable="true"
                                            :position="{lat: parseFloat(m.latitude_2), lng:parseFloat(m.longitude_2)}"
                                            :animation="selected_sarpras == m ? 1:0"
                                            @click="selected_sarpras = m; selected_kelompok = 'tanah'" />
                                    </GmapMap>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                    <div class="card-body">
                                        <h4>Detail Sarana dan Prasarana</h4>
                                        <template v-if="selected_sarpras === null">
                                            Silahkan klik pada salah satu marker dalam peta disamping
                                        </template>
                                        <template v-else>
                                            <table class="table" v-if="selected_kelompok === 'bangunan'">
                                                <tbody>
                                                    <tr>
                                                        <td>Nama objek</td>
                                                        <td>: {{ selected_sarpras.nama_objek }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis bangunan</td>
                                                        <td>: {{ selected_sarpras.jenis_bangunan }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>kelompok bangunan</td>
                                                        <td>: {{ selected_sarpras.kelompok_bangunan }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Unit kerja</td>
                                                        <td>: {{ selected_sarpras.unit_kerja }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Seksi</td>
                                                        <td>: {{ selected_sarpras.seksi }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Provinsi</td>
                                                        <td>: {{ selected_sarpras.provinsi }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kota/Kabupaten</td>
                                                        <td>: {{ selected_sarpras.kota_kab }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kecamatan</td>
                                                        <td>: {{ selected_sarpras.kecamatan }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kelurahan</td>
                                                        <td>: {{ selected_sarpras.desa_kel }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table" v-else>
                                                <tbody>
                                                    <tr>
                                                        <td>Nama objek</td>
                                                        <td>: {{ selected_sarpras.nama_objek }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis tanah</td>
                                                        <td>: {{ selected_sarpras.jenis_tanah }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis Aset</td>
                                                        <td>: {{ selected_sarpras.jenis_aset }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Unit kerja</td>
                                                        <td>: {{ selected_sarpras.unit_kerja }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Seksi</td>
                                                        <td>: {{ selected_sarpras.seksi }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Provinsi</td>
                                                        <td>: {{ selected_sarpras.provinsi }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kota/Kabupaten</td>
                                                        <td>: {{ selected_sarpras.kota_kab }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kecamatan</td>
                                                        <td>: {{ selected_sarpras.kecamatan }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kelurahan</td>
                                                        <td>: {{ selected_sarpras.desa_kel }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="list-bangunan" role="tabpanel">
                        <div class="document">
                            <a href="" class="btn btn-sm btn-primary mb-3" @click.prevent="downloadCsv(kelompok_bangunan, 'kelompok-bangunan.csv')"><i class="fa fa-download"></i> Download CSV</a>
                            <br>
                            <dataset
                            v-slot="{ds}"
                            :ds-data="kelompok_bangunan"
                            :ds-sortby="sortByKB"
                            :ds-search-in="['nama_objek', 'jenis_bangunan', 'kelompok_bangunan']">
                                <div class="row mb-3" :data-page-count="ds.dsPagecount">
                                    <div class="col-md-6 mb-md-0">
                                        <dataset-show />
                                    </div>
                                    <div class="col-md-6">
                                        <dataset-search ds-search-placeholder="Search..." />
                                    </div>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Nama objek</th>
                                            <th>Jenis</th>
                                            <th>Kelompok</th>
                                            <th>Unit Kerja</th>
                                            <th>Seksi</th>
                                            <th>Provinsi</th>
                                            <th>Kota/Kab.</th>
                                            <th>Kec.</th>
                                            <th>Kel.</th>
                                        </tr>
                                    </thead>
                                    <dataset-item tag="tbody">
                                        <template #default="{ row }">
                                            <tr>
                                                <td>{{ row.nama_objek }}</td>
                                                <td>{{ row.jenis_bangunan }}</td>
                                                <td>{{ row.kelompok_bangunan }}</td>
                                                <td>{{ row.unit_kerja }}</td>
                                                <td>{{ row.seksi }}</td>
                                                <td>{{ row.provinsi }}</td>
                                                <td>{{ row.kota_kab }}</td>
                                                <td>{{ row.kecamatan }}</td>
                                                <td>{{ row.desa_kel }}</td>
                                            </tr>
                                        </template>
                                    </dataset-item>
                                </table>
                                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                                    <dataset-info class="mb-2 mb-md-0" />
                                    <dataset-pager />
                                </div>
                            </dataset>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="list-tanah" role="tabpanel">
                        <div class="document">
                            <a href="" class="btn btn-sm btn-primary mb-3" @click.prevent="downloadCsv(kelompok_tanah, 'kelompok-tanah.csv')"><i class="fa fa-download"></i> Download CSV</a>
                            <br>
                            <dataset
                            v-slot="{ds}"
                            :ds-data="kelompok_tanah"
                            :ds-sortby="sortByKT"
                            :ds-search-in="['nama_objek', 'jenis_tanah', 'jenis_aset']">
                                <div class="row mb-3" :data-page-count="ds.dsPagecount">
                                    <div class="col-md-6 mb-md-0">
                                        <dataset-show />
                                    </div>
                                    <div class="col-md-6">
                                        <dataset-search ds-search-placeholder="Search..." />
                                    </div>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Nama objek</th>
                                            <th>Jenis</th>
                                            <th>Jenis asset</th>
                                            <th>Unit Kerja</th>
                                            <th>Seksi</th>
                                            <th>Provinsi</th>
                                            <th>Kota/Kab.</th>
                                            <th>Kec.</th>
                                            <th>Kel.</th>
                                        </tr>
                                    </thead>
                                    <dataset-item tag="tbody">
                                        <template #default="{ row }">
                                            <tr>
                                                <td>{{ row.nama_objek }}</td>
                                                <td>{{ row.jenis_tanah }}</td>
                                                <td>{{ row.jenis_aset }}</td>
                                                <td>{{ row.unit_kerja }}</td>
                                                <td>{{ row.seksi }}</td>
                                                <td>{{ row.provinsi }}</td>
                                                <td>{{ row.kota_kab }}</td>
                                                <td>{{ row.kecamatan }}</td>
                                                <td>{{ row.desa_kel }}</td>
                                            </tr>
                                        </template>
                                    </dataset-item>
                                </table>
                                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                                    <dataset-info class="mb-2 mb-md-0" />
                                    <dataset-pager />
                                </div>
                            </dataset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import 'vue-loading-overlay/dist/vue-loading.css'
import SidebarSarpras from '../../components/SidebarSarpras.vue'
import axios from 'axios'
import sarpras_items from '@/sarpras.json'

import { 
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset'

export default {
    name: 'DashboardSarpras',
    components: {
        Navbar,
        Breadcrumb,
        Loading,
        SidebarSarpras,
        Dataset,
        DatasetItem,
        DatasetInfo,
        DatasetPager,
        DatasetSearch,
        DatasetShow
    },
    data(){
        return {
            is_fullpage: true,
            is_loading: false,
            token: null,
            org_id: null,
            role: null,
            baik: 0,
            rusak_ringan: 0,
            rusak_berat: 0,
            total: 0,
            sarpras: [],
            inspections: [],
            kelompok_bangunan: [],
            kelompok_tanah: [],
            invalids: {bangunan: [], tanah: []},
            selected_sarpras: null,
            selected_kelompok: '',
            sarpras_items
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        getData(){
            this.is_loading = true;

            axios.post('https://api.jasatirta2.co.id/get-data-sarpras-sda', {}, {
                headers: {
                    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2IiwidXNlcm5hbWUiOiJzaXNkYXBqdCIsImtleSI6IiQyeSQxMCRMLnh5aERUY1docG5mZk5YdTJDTFJla3dIRHBiVkkudGdYNDNLMFA3TXQ2MnlESFN2VzhNUyJ9.4_UQDjpth22-LA-rV-ZsbPAujZHVOI3BqdvcnlwBqqg'
                }
            })
            .then(response => {
                this.kelompok_bangunan = response.data.data.kelompok_bangunan;
                this.kelompok_tanah = response.data.data.kelompok_tanah;

                this.kelompok_bangunan.forEach(obj => {
                    if(obj.latitude.length == 0 || obj.latitude === null){
                        this.invalids.bangunan.push(obj);
                    }
                });

                this.kelompok_tanah.forEach(obj => {
                    if((obj.latitude_1 == "0" || obj.latitude_1 === null) && (obj.latitude_2 == "0" || obj.latitude_2 === null)){
                        this.invalids.tanah.push(obj);
                    }
                });
            })
            .catch(e => {
                console.log(e)
                alert("terjadi kesalahan saat fetch API")
            })
            .finally(() => {
                this.is_loading = false;
            })

            // this.kelompok_bangunan = sarpras_items.data.kelompok_bangunan;
            // this.kelompok_tanah = sarpras_items.data.kelompok_tanah;

            // this.kelompok_bangunan.forEach(obj => {
            //     if(obj.latitude.length == 0 || obj.latitude === null){
            //         this.invalids.bangunan.push(obj);
            //     }
            // });

            // this.kelompok_tanah.forEach(obj => {
            //     if((obj.latitude_1 == "0" || obj.latitude_1 === null) && (obj.latitude_2 == "0" || obj.latitude_2 === null)){
            //         this.invalids.tanah.push(obj);
            //     }
            // });

            console.log(this.invalids);
        },
        defineIcon(fungsi, fisik){
            let value = (parseFloat(fungsi) + parseFloat(fisik)) / 2;
            if(value <= 50){
                return require('../../assets/icon/grade3.png');
            }else if(value > 50 && value < 70){
                    return require('../../assets/icon/grade2.png');
            }else{
                    return require('../../assets/icon/grade1.png');
            }
        },
        click(event, i) {
            let toset
            const sortEl = this.cols[i]

            if (!event.shiftKey) {
                this.cols.forEach((o) => {
                    if (o.field !== sortEl.field) {
                        o.sort = ''
                    }
                })
            }

            if (!sortEl.sort) {
                toset = 'asc'
            }
            
            if (sortEl.sort === 'desc') {
                toset = event.shiftKey ? '' : 'asc'
            }

            if (sortEl.sort === 'asc') {
                toset = 'desc'
            }
            
            sortEl.sort = toset
        },
        downloadCsv(data, filename){
            const csvRows = [];
    
            // Get headers from the first object keys
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(',')); // join headers with commas

            // Loop through the array and create rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"'); // escape double quotes
                    return `"${escaped}"`; // wrap values in double quotes
                });
                csvRows.push(values.join(',')); // join values with commas
            }

            const csvString = csvRows.join("\n");
            this.sendCsvToClient(csvString, filename)
        },
        sendCsvToClient(csvString, filename){
            const blob = new Blob([csvString], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    },
    filters: {
        formatDate(value){
            return moment(value).format("DD/MM/YYYY");
        }
    },
    computed: {
        sortByKB() {
            return this.kelompok_bangunan.reduce((acc, o) => {
                if (o.sort) {
                o.sort === 'asc' ? acc.push(o.field) : acc.push('-' + o.field)
                }

                return acc
            }, [])
        },sortByKT() {
            return this.kelompok_tanah.reduce((acc, o) => {
                if (o.sort) {
                o.sort === 'asc' ? acc.push(o.field) : acc.push('-' + o.field)
                }

                return acc
            }, [])
        }
    }
}
</script>
<style>
.form-inline{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline label{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}
</style>