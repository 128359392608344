<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <!-- Navbar -->
        <Navbar />

        <!-- Sidebar -->
        <SidebarScada />

        <main id="main" class="main">
            <!-- Breadcomp -->
            <Breadcrumb :links="links" />
            <!-- END OF BREADCOMP -->
            <div class="text-center" style="position:sticky; top: 100px; z-index: 99999;">
                <button class="btn btn-success" @click="getData"><i class="fa fa-sync"></i> reload data</button>
            </div>

            <section class="section dashboard" style="overflow-y: visible; padding-top: 20px">
                <div class="row">
                    <!-- Left side columns -->
                    <div class="col-lg-12">
                        <div class="row mb-4">
                            <!-- Recent Sales -->
                            <div class="col-md-4 col-sm-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="mb-3 border-bottom pb-3"><b>Pumping Station & DVC</b></h4>
                                        <table class="w-100">
                                            <tr>
                                                <th>Level Limpasan:</th>
                                                <th></th>
                                                <td>m</td>
                                            </tr>
                                            <tr>
                                                <th>Turbidity:</th>
                                                <th></th>
                                                <td>NTU</td>
                                            </tr>
                                            <tr>
                                                <th>Temperature:</th>
                                                <th></th>
                                                <td><sup>o</sup>C</td>
                                            </tr>
                                            <tr>
                                                <th>Flowrate IPA 1:</th>
                                                <th>{{ dvc.flowrate_ipasatu | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Flowrate IPA 2:</th>
                                                <th>{{ dvc.flowrate_ipadua | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Total Flow IPA 1 & 2:</th>
                                                <th></th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure IPA 1:</th>
                                                <th>{{ dvc.pressure_ipasatu | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure IPA 2:</th>
                                                <th>{{ dvc.pressure_ipadua | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="mb-3 border-bottom pb-3"><b>Surge Tower</b></h4>
                                        <table class="w-100">
                                            <tr>
                                                <th>Flowrate IPA 1:</th>
                                                <th>{{ surge_tower.flow_ipasatu | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Flowrate IPA 2:</th>
                                                <th>{{ surge_tower.flow_ipadua | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Total Flow IPA 1 & 2:</th>
                                                <th>{{ (parseFloat(surge_tower.flow_ipasatu) + parseFloat(surge_tower.flow_ipadua)) | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>TMA:</th>
                                                <th>{{ surge_tower.tma | pembulatan }}</th>
                                                <td>mdpl</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Upstream 1:</th>
                                                <th>{{ surge_tower.pressure_upstream_satu | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Upstream 2:</th>
                                                <th>{{ surge_tower.pressure_upstream_dua | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Downstream 1:</th>
                                                <th>{{ surge_tower.pressure_downstream_satu | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Downstream 2:</th>
                                                <th>{{ surge_tower.pressure_downstream_dua | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="mb-3 border-bottom pb-3"><b>IVC</b></h4>
                                        <table class="w-100">
                                            <tr>
                                                <th>Pressure Inlet 1:</th>
                                                <th>{{ ivc.pressure_inlet_satu | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Inlet 2:</th>
                                                <th>{{ ivc.pressure_Inlet_dua | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Outlet 1:</th>
                                                <th>{{ ivc.pressure_Outlet_satu | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Pressure Outlet 2:</th>
                                                <th>{{ ivc.pressure_Outlet_dua | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="mb-3 border-bottom pb-3"><b>Pulogadung</b></h4>
                                        <table class="w-100">
                                            <tr>
                                                <th>Flow Open Channel Intake:</th>
                                                <th>{{ pulogadung.open_channel_inlet | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>TMA:</th>
                                                <th>{{ pulogadung.level_tma | pembulatan }}</th>
                                                <td>mdpl</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="mb-3 border-bottom pb-3"><b>Buaran Intake</b></h4>
                                        <table class="w-100">
                                            <tr>
                                                <th>TMA:</th>
                                                <th>{{ buaran.level_intake | pembulatan }}</th>
                                                <td>mdpl</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="mb-3 border-bottom pb-3"><b>Buaran Outlet</b></h4>
                                        <table class="w-100">
                                            <tr>
                                                <th>Flow Buaran 1:</th>
                                                <th>{{ buaran.flow_buaran1 | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Flow Buaran 2:</th>
                                                <th>{{ buaran.flow_buaran2 | pembulatan }}</th>
                                                <td>m3/detik</td>
                                            </tr>
                                            <tr>
                                                <th>Totalizer 1:</th>
                                                <th>{{ buaran.totalizer_buaran1 | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Totalizer 2:</th>
                                                <th>{{ buaran.totalizer_buaran2 | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                            <tr>
                                                <th>Total Flow B1 & B2:</th>
                                                <th>{{ (parseFloat(buaran.flow_buaran1) + parseFloat(buaran.flow_buaran2)) | pembulatan }}</th>
                                                <td>Bar</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <br>
                                <SkemaScada :data="{ivc,dvc,surge_tower,pulogadung,buaran,atr,report_satu}"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
  
<script>
    // import
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarScada from '../../components/SidebarScada.vue'
    import SkemaScada from '../../components/SkemaScada.vue'
    import axios from 'axios';
    import moment from 'moment';

    export default{
        name: 'Geomoss',
        data(){
            return{
                // Need
                is_loading: false,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/scada', text: 'Scada'}, 
                ],
                ivc: {},
                dvc: {},
                surge_tower: {},
                pulogadung: {},
                buaran: {},
                report_satu: {},
                atr: {},
            }
        },
        mounted(){
            this.getData()
        },
        methods: {
            getData(){
                this.is_loading = true;

                axios.get(`${this.$endpoints.scada}/dashboard`)
                .then(response => {
                    
                    this.ivc = response.data.ivc;
                    this.dvc = response.data.dvc;
                    this.surge_tower = response.data.surge_tower;
                    this.pulogadung = response.data.pulogadung;
                    this.buaran = response.data.buaran;
                    this.atr = response.data.atr;
                    this.report_satu = response.data.report_satu;
                })
                .catch(e => console.log(e))
                .finally(() => {
                    this.is_loading = false;
                })
            }
        },
        filters: {
            date(value){
                return moment(value).format("DD MMM YYYY HH:mm")
            },
            pembulatan(value){
                if(value === null || isNaN(value)) return '#null';

                return parseFloat(value).toFixed(2);
            }
        },
        components:{
            Navbar,
            SidebarScada,
            Loading, 
            Breadcrumb,
            SkemaScada
        },
    }
</script>