<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true">
        </loading>
        <!-- Navbar -->
        <Navbar/>

        <!-- Sidebar -->
        <SidebarSpatial/>

        <main id="main" class="main">
            <!-- Breadcomp -->
            <Breadcrumb :links="links"/>
            <!-- END OF BREADCOMP -->

            <section class="section pt-0">
                <div class="row">
                    <!-- Left side columns -->
                    <div class="col-lg-12">
                        <div class="row">
                            <!-- Recent Sales -->
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="position-relative">
                                            <h4><b>Peta</b></h4>
                                            <GmapMap
                                                :center="{lat:this.lat, lng:this.lng}"
                                                :zoom="9"
                                                map-type-id="terrain"
                                                style="width: 100%; height: 800px"
                                                ref="peta">
                                            </GmapMap>
                                            <div class="card position-absolute" id="spatial-map-legend">
                                                <div class="card-header pb-2" ref="header">
                                                    Map Legend
                                                </div>
                                                <div class="card-body expanded" ref="body" :key="random">
                                                    <draggable style="height: 400px; overflow:auto" @end="setZindex">
                                                        <div v-for="(shp, j) in spatials" :key="shp.id" class="p-2 list-item" style="border-bottom: 1px solid #aaa;" :data-id="shp.id" :data-position="j">
                                                            <div class="d-flex">
                                                                <b>{{ shp.name }}</b>
                                                                <div class="d-flex flex-grow-1 justify-content-end align-items-center">
                                                                    <a href="" class="me-2 text-danger" @click.prevent="deleteLayer(shp.id)" v-if="getRole() !== 'guest'"><i class="bi bi-trash"></i></a>
                                                                    <a href="" class="me-2 text-dark" @click.prevent="toggleLabel($event, shp.id)"><i class="bi bi-eye-slash-fill"></i></a>
                                                                    <input type="checkbox" @click="toggleLayer($event, shp.id)">
                                                                </div>
                                                            </div>
                                                            <ul class="list-unstyled">
                                                                <li v-for="(icon, i) in shp.icons" :key="i">
                                                                    <div v-html="icon" class="d-flex align-items-center"></div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </draggable>
                                                    <div class="form-group">
                                                        <a href="" class="btn btn-primary w-100" @click.prevent="showAddSpasialModal()" v-if="getRole() !== 'guest'">Tambah data spasial</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <b-modal title="Tambah layer KML" ref="add_shp_modal" :hide-footer="true" :hide-header-close="true">
            <form action="">
                <div class="form-group mb-3">
                    <label for="">Nama</label>
                    <input type="name" class="form-control" v-model="layer_name">
                </div>
                <div class="form-group mb-4">
                    <label for="">Shapefile (KML, KMZ)</label>
                    <input type="file" class="form-control" @change="holdFile($event)">
                </div>
                <div class="form-group">
                    <button type="button" class="btn btn-danger me-2" @click="$refs.add_shp_modal.hide()"><i class="bi bi-x"></i> Cancel</button>
                    <button type="button" class="btn btn-primary" @click="addShapefile()"><i class="bi bi-hdd"></i> Save</button>
                </div>
            </form>
        </b-modal>
        <div id="kml-search" class="position-relative">
            <input class="map-search" type="text" placeholder="Spatial Search" @keyup="search()" v-model="q">
            <div id="search-result" v-if="search_results.length > 0">
                <ul>
                    <li v-for="result in search_results" :key="result.id">
                        <a href="" @click.prevent="centerAndZoomTo(result.lat, result.lng)">{{ result.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <input type="text" class="map-search" id="spatial-search" placeholder="Address Search"> -->
    </div>
</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    import { BModal } from 'bootstrap-vue'
    // import {BTabs} from 'bootstrap-vue';
    // import Notif from 'vue-notification' 

    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarSpatial from '../../components/SidebarSpatial.vue'
    import { gmapApi } from 'vue2-google-maps'
    import 'bootstrap-icons/font/bootstrap-icons.css';
    import draggable from 'vuedraggable'
    // import '../../maplabel.js'
    
    export default{
        data(){
            return{
                // Need
                is_loading: true,
                is_fullpage: true,
                token: null,
                lat: -6.5341128, 
                lng: 107.3428813,
                limit: 3,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/spasial', text: 'Spasial'}, 
                ],
                map: null,
                spatials: [],
                layers: {},
                markers: {},
                layer_name: '',
                shapefile: null,
                q: '',
                search_results: [],
                tmp_marker: null,
                random: Math.random()
            }
        },
        mounted(){
            this.is_loading = true;
            this.token = localStorage.getItem('token');

            axios.get(this.$endpoints.spatial+'spatial?token='+this.token)
            .then((response) =>{
                this.$nextTick(() => {
                    this.$refs.peta.$mapPromise.then((map) => {
                        this.map = map;

                        response.data.forEach((kml) => {
                            let layer =  new this.google.maps.KmlLayer({
                                url: "http://spasial.jasatirta2.id/storage/" + encodeURI(kml.filename),
                                map: null,
                                //suppressInfoWindows: true,
                                preserveViewport: true,
                                zIndex: kml.zindex
                            });

                            this.layers['shp-' + kml.id] = layer;
                        });

                        // KML SEARCH
                        let kml_in = document.getElementById('kml-search');
                        map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(kml_in);

                        // ADDRESS SEARCH
                        // let input = /** @type {HTMLInputElement} */(document.getElementById('spatial-search'));
                        // map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(input);
                        // let searchBox = new this.google.maps.places.SearchBox(
                        // /** @type {HTMLInputElement} */(input));

                        // this.google.maps.event.addListener(searchBox, 'places_changed', function() {
                        //     var places = searchBox.getPlaces();

                        //     if (places.length == 0) {
                        //         return;
                        //     }
                        //     for (var i = 0, marker; marker = markers[i]; i++) {
                        //         marker.setMap(null);
                        //     }

                        //     // For each place, get the icon, place name, and location.
                        //     markers = [];
                        //     var bounds = new this.google.maps.LatLngBounds();
                        //     for (var i = 0, place; place = places[i]; i++) {
                        //         var image = {
                        //             url: place.icon,
                        //             size: new google.maps.Size(71, 71),
                        //             origin: new google.maps.Point(0, 0),
                        //             anchor: new google.maps.Point(17, 34),
                        //             scaledSize: new google.maps.Size(25, 25)
                        //         };

                        //         // Create a marker for each place.
                        //         var marker = new google.maps.Marker({
                        //             map: spatial_map.map,
                        //             icon: image,
                        //             title: place.name,
                        //             position: place.geometry.location
                        //         });

                        //         markers.push(marker);

                        //         bounds.extend(place.geometry.location);
                        //     }

                        //     spatial_map.map.fitBounds(bounds);
                        //     spatial_map.map.setZoom(10);
                        // });
                    });
                });

                response.data.forEach((v) => {
                    this.markers['shp-' + v.id] = [];
                });

                this.spatials = response.data
                this.is_loading = false;
            });

            // toggle legend box
            this.$refs.header.addEventListener('click', () => {
                let _body = this.$refs.body;

                if(_body.classList.contains('expanded')){
                    _body.classList.remove('expanded');
                    _body.classList.add('folded');
                }else{
                    _body.classList.remove('folded');
                    _body.classList.add('expanded');
                }
            });
                
            document.body.classList.add('toggle-sidebar')
        },
        methods: {
            showAddSpasialModal(){
                this.$refs.add_shp_modal.show();
            },
            toggleLayer(event, id){
                if(event.target.checked){
                    this.layers['shp-' + id].setMap(this.map);
                }else{
                    this.layers['shp-' + id].setMap(null);
                }
            },
            toggleLabel(e, id){
                if(e.target.parentNode.classList.contains('label-visible')){
                    e.target.parentNode.classList.remove('label-visible');
                    e.target.classList.add('bi-eye-slash-fill');
                    e.target.classList.remove('bi-eye-fill');

                    this.markers['shp-' + id].forEach(marker => {
                        marker.setMap(null);
                    });
                }else{
                    e.target.parentNode.classList.add('label-visible');
                    e.target.classList.remove('bi-eye-slash-fill');
                    e.target.classList.add('bi-eye-fill');

                    if(this.markers['shp-' + id].length > 0){
                        this.markers['shp-' + id].forEach(marker => {
                            marker.setMap(this.map);
                        });
                    }else{
                        axios.post(this.$endpoints.spatial + 'spatial/show_label/' + id + '/y')
                        .then(response => {
                            for(let key in response.data){
                                let coordinates = response.data[key].split(',');
                                let lat = parseFloat(coordinates[1].trim()),
                                    lon = parseFloat(coordinates[0].trim());

                                let marker = new this.google.maps.Marker({
                                    position: new this.google.maps.LatLng(lat, lon),
                                    map: this.map,
                                    icon: require('../../assets/blank.png'),
                                    label: {
                                        text: key,
                                        className: 'label-marker'
                                    }
                                });

                                this.markers['shp-' + id].push(marker);
                            }
                        })
                        .catch(e => console.log(e));
                    }   
                }
            },
            deleteLayer(id){
                if(confirm("Hapus Layer?")){
                    this.layers['shp-' + id].setMap(null);
                    this.is_loading = true;

                    axios.post(`${this.$endpoints.spatial}spatial/delete/${id}`)
                    .then(response => {
                        this.spatials = this.spatials.filter(v => v.id != id);
                        this.is_loading = false;

                        this.$swal(response.data.message);
                    })
                    .catch(e => console.log(e));
                }
            },
            addShapefile(){
                let formData = new FormData();
                formData.append('name', this.layer_name);
                formData.append('shapefile', this.shapefile);

                axios.post(`${this.$endpoints.spatial}spatial/upload`, formData, {
                    headers: {
                        "Content-type":"multipart/form-data"
                    }
                }).then(response => {
                    this.$refs.add_shp_modal.hide();
                    this.$swal(response.data.message);

                    // add SHP to the map and legend
                    const shp = response.data.shp;

                    const layer =  new this.google.maps.KmlLayer({
                        url: "http://spasial.jasatirta2.id/storage/" + encodeURI(shp.filename),
                        map: null,
                        preserveViewport: true
                    });

                    this.spatials.push(shp);
                    this.layers['shp-' + shp.id] = layer;

                })
                .catch(e => console.log(e));
            },
            holdFile(event){
                this.shapefile = event.target.files[0];
            },
            search(){
                if(this.q.length > 2){
                    axios.get(`${this.$endpoints.spatial}spatial/search?q=${this.q}`)
                    .then(response => {
                        // console.log(response);
                        this.search_results = response.data;
                    })
                    .catch(e => console.log(e));
                }else{
                    this.search_results = [];
                    this.deletePrevMarker();
                }
            },
            centerAndZoomTo(lat, lng){
                var latLng = new this.google.maps.LatLng(parseFloat(lng), parseFloat(lat));
                
                this.deletePrevMarker();
                this.tmp_marker = new this.google.maps.Marker({
                    position: latLng,
                    map: this.map
                });

                this.map.setCenter(latLng);
                this.map.setZoom(16);
                
            },
            deletePrevMarker(){
                // delete previous selected marker
                if(this.tmp_marker){
                    this.tmp_marker.setMap(null);
                }
            },
            setZindex(){
                let items = document.querySelectorAll('.list-item'),
                    total = items.length,
                    index = {};

                for(let i = 0; i < total; i++){
                    let {id} = items[i].dataset,
                        zIndex = Math.abs(i - total);

                    index[id] = zIndex;

                    this.layers[`shp-${id}`].setZIndex(zIndex);
                }

                this.is_loading = true;

                axios.post(`${this.$endpoints.spatial}spatial/set_z_index?token=${this.token}`, {
                    index
                })
                .then(() => {
                    this.is_loading = false;
                });
            }
        },
        computed: {
            google: gmapApi
        },
        components:{
            Navbar,
            SidebarSpatial,
            Loading, 
            Breadcrumb,
            BModal,
            draggable
        },
    }

</script>

<style>
    .card-header{
        cursor: pointer;
        font-size: 14px;
    }

    #spatial-map-legend{
        bottom: -5px;
        right: 60px;
        font-size: 12px;
    }

    #spatials{
        height: 400px;
        overflow: auto;
    }

    #spatials li a{
        font-size: 14px;
    }

    .spatial-symbol-poly {
        height: 10px;
        width: 10px;
    }

    .spatial-symbol-line {
        height: 2px;
        width: 10px;
    }

    .odd{
        background: #ddd;
    }

    .expanded{
        height: 490px;
        transition: height .3s;
    }

    .folded{
        height: 0;
        padding-top: 0!important;
        padding-bottom: 0!important;
        transition: height .3s;
    }

    .label-marker{
        color: #fff!important;
        margin-top: 20px!important;
        text-shadow:
            -1px -1px 0 #000,  
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
    }

    .map-search{
        background-color: #fff;
        padding: 3px 10px;
        width: 400px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 300;
        text-overflow: ellipsis;
        margin-top: 10px;
    }

    #search-result{
        max-height: 300px;
        overflow: auto;
        background: #fff;
        width: 400px;
    }

    #search-result ul{
        list-style-type: none;
        padding: 10px 0 0 0;
    }

    #search-result ul>li{
        padding: 5px 20px;
    }

    #search-result a{
        font-size: 14px;
    }
</style>