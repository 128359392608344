<template>
    <ol class="breadcrumb">
        <li v-for="(link, index) in links" v-bind:key="index" class="breadcrumb-item">
            <router-link :to="link.url" v-if="link.url != '#'">{{ link.text }}</router-link>
            <span v-if="link.url == '#'">{{ link.text }}</span>
        </li>
    </ol>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: {
        links:{
            type: Array,
            default: () => []
        }
    }
}
</script>