<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarReference/>

    <main id="main" class="main">
      <!-- Breadcomp -->
      <div class="pagetitle">
        <nav style="--bs-breadcrumb-divider: '>';">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item active">{{ name }}</li>
          </ol>
        </nav>
        <h1><strong>{{ name }}</strong></h1>
      </div>
      <!-- END OF BREADCOMP -->

      <section class="section dashboard">
        <div class="row">
          <!-- Left side columns -->
            <div class="col-lg-12">
                <div class="row">
                    <!-- Recent Sales -->
                    <div class="col-12">
                        <div class="card recent-sales">
                            <!-- ===== MENU BREADCRUMBS ===== -->
                            <div class="container-fluid">
                                <div class="row mt-3">
                                    <div class="col-sm-6">
                                        <BreadCrumbs/>
                                    </div>
                                </div>
                                <!-- ===== END OF  MENU ===== -->

                                <!-- ===== Filter ===== -->
                                <form action="" autocomplete="off" @submit="filter">
                                    <div class="row mt-3">
                                        <!-- ===== SEARCH ===== -->
                                        <div :class="getRole() === 'guest' ? 'col-sm-12':'col-sm-8'">
                                            <div class="form-group">
                                                <input type="text" class="form-control" v-model="q_name" placeholder="Cari Data">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <router-link class="btn btn-info form-control" to="/referensi/petugas/create" v-if="getRole() !== 'guest'">
                                                    <i class="fa fa-plus"></i>
                                                    Tambah
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="card-body mt-2">
                              <table class="table table-borderless datatable">
                                    <thead>
                                        <tr>
                                            <th width="3%">ID</th>
                                            <th>Nomor</th>
                                            <th>Nama</th>
                                            <th>Jenis</th>
                                            <th width="10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in items.data" :key="item.id" v-bind:index="index">
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.number }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.type }}</td>
                                            <td>
                                                <router-link :to="{path: '/referensi/petugas/edit/'+item.id}" class="btn btn-xs btn btn-outline-primary me-2" v-if="getRole() !== 'guest'">
                                                  <i class="fa fa-pen"></i>
                                                </router-link>
                                                <a href="#" class="btn btn-xs btn btn-outline-danger" v-on:click="destroy(item.id, index)" v-if="getRole() !== 'guest'"><i class="fa fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="float-end">
                                  <pagination :data="items" @pagination-change-page="getResults"></pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import BreadCrumbs from '../TabsStruktural.vue'
    // import Notif from 'vue-notification' 

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarReference from '../../../components/SidebarReference.vue'
    
    export default{
        data(){
          return{
            name: 'Struktural',

            // Need
            is_loading: false,
            is_fullpage: true,
            token: null,
            items: [],
            q_name:'',
            q_code:'',
            per_page: 10,
            is_searched: false,
            limit: 3,
          }
        },
        mounted(){
          this.is_loading = true;
          this.token = localStorage.getItem('token')

            axios.get(this.$endpoints.reference+'officers?token='+this.token)
                .then(response =>{
                    this.items = response.data
                    this.is_loading = false;
                });
        },
        methods:{
            destroy(id, index){
				if(confirm('anda hendak menghapus data. anda yakin?')){
					let fd = new FormData();
					fd.append('_method', 'DELETE');

					this.is_loading = true;

					axios.post(this.$endpoints.reference+'officers/'+id+'?token='+this.token, fd)
                        .then(() => {
                            this.items.data.splice(index, 1);
                            this.is_loading = false;
                        }).catch(() => {
                            alert("error while deleting officer. please contact developer");
                            this.is_loading = false;
                        });
				}
			},
            getResults(page = 1){
				this.is_loading = true;
				axios.get(this.$endpoints.reference+'officers?per_page='+this.per_page+'&page='+page+'&name='+this.q_name+'&token='+this.token)
                    .then(response =>{
                        this.items = response.data
                        this.is_loading = false;
                    }).catch(() => {
                        alert("error while loading page. please contact developer");
                        this.is_loading = false;
                    });
			},
			filter(event){
				this.is_searched = true;
				this.is_loading = true;

				axios.get(this.$endpoints.reference+'officers?per_page='+this.per_page+'&name='+this.q_name+'&code='+this.q_code+'&token='+this.token)
                    .then(response =>{
                        this.items = response.data
                        this.is_loading = false;
                    }).catch(() => {
                        alert("error while filtering page. please contact developer");
                        this.is_loading = false;
                    });

				event.preventDefault();
			},
			clear(){
				this.is_searched = false;
				this.q_name = '';
				this.q_code = '';

				this.getResults();
			}
        },
        components:{
          Navbar,
          SidebarReference,
          Loading,
          BreadCrumbs
        }
    }

</script>

<style scoped>
  .dashboard .filter{
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .nav-tabs-bordered .nav-link.active{
    background-color: #fff;
    color: #509ee3;
    border-bottom: 2px
    solid #509ee3;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #000000;
    background-color: #fff;
    border-color: #509ee3;
  }

  .btn-xs{
    border-radius: 10px;
  }


</style>