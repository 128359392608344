<template>
  <ul class="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified">
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/besaran/index" aria-selected="true">Besaran</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/satuan/index" aria-selected="false">Satuan</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/jenis/index" aria-selected="false">Jenis Lokasi</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/periodik/index" aria-selected="false">Periodik</router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'BreadCrumbs',
    }
</script>

<style scoped>
.dashboard .filter{
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .nav-tabs-bordered .nav-link.active{
    background-color: #fff;
    color: #509ee3;
    border-bottom: 2px
    solid #509ee3;
  }
</style>
