<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSarpras/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/sarpras/komponen/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/sarpras/komponen/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="store" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row mt-5">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">Data Komponen</div>
                                        <form action="">
                                            <!-- ==== FORM 1 ==== -->
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Sarpras</label>
                                                        <Select2 class="vue-select2" :options="sarpras" v-model="infrastructure_id" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Tipe</label>
                                                        <Select2 class="vue-select2" :options="types" v-model="type" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- ==== FORM 2 ==== -->
                                            <div class="row mt-3">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Nama</label>
                                                        <input type="text" name="name" class="form-control" v-model="name">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- ==== FORM 3 ==== -->
                                            <div class="row mt-3">
                                                <div class="col-sm-6">
                                                    <label for="">Keterangan</label>
                                                    <quill-editor
                                                        ref="myQuillEditor"
                                                        v-model="description"
                                                        :options="editorOption"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Select2 from 'v-select2-component';
	import { quillEditor } from 'vue-quill-editor'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarSarpras from '../../../components/SidebarSarpras.vue'

    // CSS
    import 'vue-loading-overlay/dist/vue-loading.css';
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
    
    export default{
        data(){
          return{
            name_reference: 'Tambah Sarpras',

            // Need
            token: null,
            sarpras: [],
            types:[
                {text:'Sipil', id: 'sipil'}, 
                {text:'Mekanikal dan Elektrikal', id: 'me'}
            ],
            infrastructure_id: null,
            name: '',
            type: '',
            description: '',
            editorOption: {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'color': [] }, { 'background': [] }],
                    ]
                },
            }
          }
        },
        mounted(){
            this.token = localStorage.getItem('token');
			// get types
			axios.get(this.$endpoints.sarpras+'infrastructures?all=1&columns=id,name')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					data.push({
						text: response.data[i].name,
						id: response.data[i].id
					})
				}
				this.sarpras = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})
        },
        components:{
            Navbar,
            SidebarSarpras,
            Loading,
            Select2,
			quillEditor
        },
        methods:{
            store(){
				if(this.name.length > 0 && this.infrastructure_id.length > 0){
					let fd = this.createFormData({
						infrastructure_id: this.infrastructure_id,
						name: this.name,
						type: this.type,
						description: this.description
					});

					this.is_loading = true;

					axios.post(this.$endpoints.sarpras+'components?token='+this.token, fd)
					.then(() => {
						this.is_loading = false;
						this.$router.push({path:'/sarpras/komponen/index'});
					}).catch(() => {
						this.is_loading = false;
						alert('there was an error while adding data. please contact developer.');
					});
				}else{
					alert("Jenis dan nama tidak boleh kosong.");
				}
			}
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>