<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <SidebarSarpras />
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/sarpras/infrastructure/index', text: 'Infrastructure'}, {url: '#', text:'Detail'}]"/>
                <h2><b>Detail Sarpras</b></h2>
                <div class="row">
                    <div class="col-md-9 col-lg-8">
                        <div class="document">
                            <div class="row">
                                <div class="col-6" :style="{color: checkConditionText(item.fungsi)}">
                                    <div class="kartu px-4 py-2 rounded" :style="{backgroundColor: checkCondition(item.fungsi)}">
                                        <div class="kepala">Fungsi</div>
                                        <h2><b>{{ item.fungsi }}%</b></h2>
                                    </div>
                                </div>
                                <div class="col-6" :style="{color: checkConditionText(item.fisik)}">
                                    <div class="kartu px-4 py-2 rounded" :style="{backgroundColor: checkCondition(item.fisik)}">
                                        <div class="kepala">Fisik</div>
                                        <h2><b>{{ item.fisik }}%</b></h2>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-tabs content-class="mt-3" nav-class="flat-tab mt-3">
                                        <b-tab title="Perbaikan" active>
                                            <table class="table table-hover">
                                                <tbody>
                                                    <tr>
                                                        <th width="25%">Nama</th>
                                                        <td>{{ item.name }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Jenis</th>
                                                        <td>{{ item.type ? item.type.supertype.name:'' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sub-Jenis</th>
                                                        <td>{{ item.type ? item.type.name:'' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Tanggal perbaikan terakhir</th>
                                                        <td>{{ translateDate(item.repaired_date) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Diperbaiki oleh (terakhir)</th>
                                                        <td>{{ item.repaired_by }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Uraian perbaikan terakhir</th>
                                                        <td>{{ item.description }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Rekomendasi Terakhir</th>
                                                        <td>{{ item.recommendation }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-tab>
                                        <b-tab title="Komponen Fisik">
                                            <div v-if="item.komponen_fisik.length > 0">
                                                <ul>
                                                    <li v-for="komponen in item.komponen_fisik" :key="komponen.id">
                                                        <a href="#" :title="komponen.description !== null ? komponen.description.replace(/<p>/g, '').replace(/<\/p>/g, '<br>'):''"  v-b-tooltip.hover.html>{{ komponen.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="item.komponen_fisik.length == 0">
                                                <div class="alert alert-danger">Belum adan komponen fisik</div>
                                                <router-link to="/sarpras/komponen/create" class="btn btn-primary" v-if="getRole() !== 'guest'">Tambah komponen</router-link>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Komponen Fungsi">
                                            <div v-if="item.komponen_fungsi.length > 0">
                                                <ul>
                                                    <li v-for="komponen in item.komponen_fungsi" :key="komponen.id">
                                                        <a href="#" :title="komponen.description !== null ? komponen.description.replace(/<p>/g, '').replace(/<\/p>/g, '<br>'):''"  v-b-tooltip.hover.html>{{ komponen.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="item.komponen_fungsi.length == 0">
                                                <div class="alert alert-danger">Belum adan komponen fungsi</div>
                                                <router-link to="/sarpras/komponen/create" class="btn btn-primary" v-if="getRole() !== 'guest'">Tambah komponen</router-link>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Lokasi">
                                            <div v-if="item.lat != null && item.lng != null">
                                                <GmapMap
                                                    :center="{lat:parseFloat(item.lat), lng:parseFloat(item.lng)}"
                                                    :zoom="9"
                                                    map-type-id="roadmap"
                                                    style="width: 100%; height: 500px"
                                                    ref="peta">
                                                    <GmapMarker
                                                        :position="{lat: parseFloat(item.lat), lng:parseFloat(item.lng)}"
                                                        :clickable="true"
                                                        :icon="{url:defineIcon(item.fungsi, item.fisik)}"
                                                        :title="item.name" />
                                                </GmapMap>
                                            </div>
                                            <div v-else>
                                                <div class="alert alert-danger">Koordinat belum ditentukan.</div>
                                                <GmapMap
                                                    :center="{lat:-6.5341128, lng:107.3428813}"
                                                    :zoom="12"
                                                    map-type-id="roadmap"
                                                    style="width: 100%; height: 500px"
                                                    ref="peta">
                                                </GmapMap>
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-4">
                        <div class="document">
                            <h4><b>Gambar Teknik</b></h4>
                            <div v-if="item.images.length > 0">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr v-for="image in item.images" :key="image.id">
                                            <td><a :href="$endpoints.sarpras+'storage/'+image.filename" target="_blank">{{ image.filename.replace(/^[a-zA-Z0-9]{16}_/g, '') }}</a></td>
                                            <td width="5%">
                                                <a href="" class="btn btn-sm btn-danger" @click.prevent="deleteImage(image.id)"><i class="fa fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <div class="alert alert-danger">
                                    Belum ada gambar teknik
                                </div>
                            </div>
                            <hr>
                            <b class="mb-1 d-block">Tambah gambar teknik</b>
                            <input type="file" class="form-control" @change="toggleUploadBtn" ref="drawing">
                            <div v-if="uploadBtnVisible" class="mt-2">
                                <button class="btn btn-primary me-2" @click="uploadImage"><i class="fa fa-upload"></i> Upload</button>
                                <button class="btn btn-danger" @click="resetUpload"><i class="fa fa-upload"></i> Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="document">
                            <h4>
                                <b>Riwayat pemeriksaan</b>
                                <div class="float-end" v-if="getRole() !== 'guest'">
                                    <router-link :to="{path:'/sarpras/inspections/add/'+id}" class="btn btn-sm btn-primary"><i class="fa fa-plus"></i> <span class="d-none d-md-inline">Tambah riwayat pemeriksaan</span></router-link>
                                </div>
                            </h4>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>Uraian Pemeriksaan</th>
                                        <th>Fungsi</th>
                                        <th>Fisik</th>
                                        <th>Rekomendasi</th>
                                        <th>Petugas</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="inspection in item.inspections" :key="inspection.id">
                                        <td>{{ inspection.inspected_at | formatDate }}</td>
                                        <td>{{ inspection.description }}</td>
                                        <td>{{ inspection.fungsi }}%</td>
                                        <td>{{ inspection.fisik }}%</td>
                                        <td>{{ inspection.recommendation }}</td>
                                        <td>{{ inspection.user_name }}</td>
                                        <td>
                                            <router-link :to="{path:'/sarpras/inspections/edit/'+id+'/'+inspection.id}" class="btn btn-sm btn-primary me-1" v-if="getRole() !== 'guest'">Edit</router-link>
                                            <a href="#" class="btn btn-sm btn-danger" @click="deleteInspection($event, inspection.id)" v-if="getRole() !== 'guest'">Delete</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../../components/Navbar.vue'
import Breadcrumb from '../../../components/Breadcrumb.vue'
import SidebarSarpras from '../../../components/SidebarSarpras.vue'
import axios from 'axios';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import { BTabs } from 'bootstrap-vue'
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'InfrastructureDetail',
    components: {
        Navbar,
        Breadcrumb,
        SidebarSarpras,
        Loading,
        BTabs
    },
    filters: {
        formatDate(value){
            return moment(value).format("DD/MM/YYYY");
        }
    },
    methods: {
        defineIcon(fungsi, fisik){
            let value = (parseFloat(fungsi) + parseFloat(fisik)) / 2;

            if(value <= 50){
                return require('../../../assets/icon/grade3.png');
            }else if(value > 50 && value < 70){
                    return require('../../../assets/icon/grade2.png');
            }else{
                    return require('../../../assets/icon/grade1.png');
            }
        },
        checkCondition(value){
            if(value <= 50){
                return '#FF7575';
            }else if(value > 50 && value < 70){
                return '#FFCB00';
            }else{
                return '#00C875';
            }
        },
        checkConditionText(value){
            if(value <= 50){
                return '#984444';
            }else if(value > 50 && value < 70){
                return '#987900';
            }else{
                return '#036b40';
            }
        },
        deleteInspection(event, id){
            event.preventDefault();

            if(confirm('Delete this inspection?')){
                var fd = this.createFormData({
                    _method: 'delete'
                });

                this.is_loading = true;

                axios.post(this.$endpoints.sarpras+'inspections/'+id+'?token='+this.token, fd)
                .then(() =>{
                    var idx = null;
                    for(let i = 0; i < this.item.inspections.length; i++){
                        if(id == this.item.inspections[i].id){
                            idx = i;
                            break;
                        }
                    }

                    if(idx !== null){
                        this.item.inspections.splice(idx, 1);
                    }

                    this.is_loading = false;
                }).catch(() => {
                    alert('something went wrong');
                    this.is_loading = false;
                });
            }
        },
        translateDate(date){
            if(date){
                return moment(date).format("DD MMM YYYY")
            }

            return '';
        },
        showHideContent(id){
            switch(id){
                case 'history':
                    this.toggleHistory = !this.toggleHistory;
                    break;

                case 'image':
                    this.toggleImage = !this.toggleImage;
                    break;

                case 'component':
                    this.toggleComponent = !this.toggleComponent;
                    break;
            }
        },
        toggleUploadBtn(e){
            if(e.target.files.length > 0){
                this.uploadBtnVisible = true;
            }else{
                this.uploadBtnVisible = true;
            }
        },
        uploadImage(){
            this.is_loading = true;

            let file = this.$refs['drawing'].files[0];
            let fd = new FormData();
            fd.append('drawing', file);
            fd.append('id', this.$route.params.id);

            axios.post(`${this.$endpoints.sarpras}upload_technical_image`, fd)
            .then(response => {
                var that = this;
                this.item = response.data
                this.is_loading = false;
                this.resetUpload();

                for(var i = 0; i < response.data.inspections.length; i++){
                    if(response.data.inspections[i].images.length > 0){
                        this.slides.push(response.data.inspections[i].images.map(function(v){
                            return that.$endpoints.sarpras+'/storage/'+v.filename;
                        }));

                        this.slides = this.slides.flat();
                    }
                }
            })
        },
        resetUpload(){
            this.$refs['drawing'].value = '';
            this.uploadBtnVisible = false;
        },
        deleteImage(id){
            this.is_loading = true;
            
            axios.post(`${this.$endpoints.sarpras}infrastructures/delete_image/${id}`)
            .then(() => {
                this.item.images = this.item.images.filter(image => image.id != id);
                this.is_loading = false;
            });
        }
    },
    data(){
        return {
            is_loading: false,
            is_fullpage: true,
            token: null,
            item: {
                images: [],
                inspections: [],
                komponen_fisik: [],
                komponen_fungsi: [],
                lat: null,
                lng:null
            },
            slides: [],
            toggleHistory: false,
            toggleImage: false,
            toggleComponent: false,
            id: this.$route.params.id,
            uploadBtnVisible: false
        };
    },
    mounted(){
        this.is_loading = true;
        this.token = localStorage.getItem('token');

        axios.get(this.$endpoints.sarpras+'infrastructures/'+this.$route.params.id+'?token='+this.token+'&per_page='+this.per_page)
        .then(response =>{
            var that = this;
            this.item = response.data
            this.is_loading = false;

            for(var i = 0; i < response.data.inspections.length; i++){
                if(response.data.inspections[i].images.length > 0){
                    this.slides.push(response.data.inspections[i].images.map(function(v){
                        return that.$endpoints.sarpras+'/storage/'+v.filename;
                    }));

                    this.slides = this.slides.flat();

                    console.log(this.slides);
                }
            }
        });
    }
}
</script>

<style>

</style>