<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSarpras/>

    <main id="main" class="main">
      <!-- Breadcomp -->
      <Breadcrumb :links="links"/>
      <!-- END OF BREADCOMP -->

      <section class="section dashboard">
        <div class="row">
          <!-- Left side columns -->
          <div class="col-lg-12">
            <div class="row">
              <!-- Recent Sales -->
              <div class="col-sm-12">
                <!-- ===== Filter ===== -->
				<h3><strong>Sarpras Item</strong></h3>
                <div class="card recent-sales mt-5">

                  <!-- ===== MENU BREADCRUMBS ===== -->
					<div class="container-fluid">
						<!-- ===== END OF  MENU ===== -->

						<!-- ==== FILTER ==== -->
                        <div class="row mt-3 d-none d-lg-flex">
                            <div class="col-lg-2">
                                <button class="btn btn-outline-secondary" style="width: 100%" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">
                                    <img src="../../../assets/icon/icon-filter.png" style="width: 20px" alt=""> Filter
                                </button>
                            </div>
                            <div :class="getRole() === 'guest' ? 'col-lg-8':'col-lg-6'">
                                <form action="" autocomplete="off" @submit="filter" class="mb-3">
                                    <!-- Is Search -->
                                    <!-- <div v-if="is_searched" class="form-group">
                                        <div class="row">
                                            <div class="col-md-11">
                                                <input type="text" class="form-control" v-model="q_name" placeholder="Search...">
                                            </div>
                                            <div class="col-md-1">
                                                <a href="#" class="btn w-100 btn-danger" @click="clear"><i class="fa fa-times"></i> clear</a>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- Name -->
                                    <!-- <div v-else class="form-group">
                                    </div> -->
                                    <input type="text" name="name" class="form-control" v-model="q_name" placeholder="Search...">
                                </form>
                            </div>
                            <div class="col-lg-2">
                                <button type="button" @click="exportToCsv" style="width: 100%" class="btn btn-outline-secondary"><i class="fa fa-download"></i> Export CSV</button>
                            </div>
                            <div class="col-lg-2">
                                <router-link class="btn btn-primary" style="width: 100%" to="/sarpras/infrastructure/create" v-if="getRole() !== 'guest'">
                                    <i class="fa fa-plus"></i>
                                    Tambah
                                </router-link>
                            </div>
                        </div>

                        <div class="row mt-3 d-flex d-lg-none">
                            <div class="col-12">
                                <form action="" autocomplete="off" @submit="filter" class="mb-3">
                                    <input type="text" name="name" class="form-control" v-model="q_name" placeholder="Search...">
                                </form>
                            </div>
                            <div class="col-4 col-lg-2">
                                <button class="btn btn-outline-secondary" style="width: 100%" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">
                                    <img src="../../../assets/icon/icon-filter.png" style="width: 20px" alt="">
                                </button>
                            </div>
                            <div class="col-4 col-lg-2">
                                <button type="button" @click="exportToCsv" style="width: 100%" class="btn btn-outline-secondary"><i class="fa fa-download"></i></button>
                            </div>
                            <div class="col-4 col-lg-2">
                                <router-link class="btn btn-primary" style="width: 100%" to="/sarpras/infrastructure/create">
                                    <i class="fa fa-plus"></i>
                                </router-link>
                            </div>
                        </div>
                        
                        <div class="col-sm-12">
                            <div class="collapse multi-collapse" id="multiCollapseExample2">
                                <div id="filternya-cuk" class="jumbotron">
                                    <b>Total terdapat {{ this.total_entries }} Data Sarpras</b>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-3">
                                            Tampilkan Status Kerusakan
                                        </div>
                                        <div class="col-md-9">
											<button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="this.is_allstats ? 'btn-info':'btn-secondary'" @click="allStats">Semua</button>
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="stats_id.indexOf(stat.id) > -1  ? 'btn-info':'btn-secondary'" v-for="stat in stats" :key="stat.id" @click="changeStats(stat.id);">{{ stat.text }}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-3">
                                            Tampilkan Lokasi Sarpras
                                        </div>
                                        <div class="col-md-9">
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="this.is_allchannel ? 'btn-info':'btn-secondary'" @click="allChannel">Semua</button>
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="channel_id.indexOf(channel.id) > -1  ? 'btn-info':'btn-secondary'" v-for="channel in channels" :key="channel.id" @click="changeChannel(channel.id);">{{ channel.text }}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-3">Tampilkan Ruas Sarpras</div>
                                        <div class="col-md-9">
                                            <Select2 class="vue-select2" :options="segments" v-model="segment_id"  @change="advanceFilter();" :settings="{width: '100%'}" />
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-3">Tampilkan Jenis Sarpras</div>
                                        <div class="col-md-9">
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="this.is_alltype ? 'btn-info':'btn-secondary'" @click="allType">Semua</button>
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="type_id.indexOf(type.id) > -1 ? 'btn-info':'btn-secondary'" v-for="type in types" :key="type.id" @click="changeType(type.id);">{{ type.text }}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-3">Tampilkan Sub-Jenis Sarpras</div>
                                        <div class="col-md-9">
                                            <Select2 class="vue-select2" :options="subtypes" v-model="sub_type_id" @change="advanceFilter();" :settings="{width: '100%'}" />
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-3">
                                            Tampilkan Sarpras di Unit Kerja
                                        </div>
                                        <div class="col-md-9">
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="this.is_allorg ? 'btn-info':'btn-secondary'" @click="changeParentOrg(null)">Semua</button>
                                            <button class="btn btn-sm mr-2" style="margin-right: 5px;" :class="unit_id.indexOf(unit.id) > -1 ? 'btn-info':'btn-secondary'" v-for="unit in units" :key="unit.id" @click="changeParentOrg(unit.id)">{{ unit.text }}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-3">Tampilkan Sarpras di Seksi</div>
                                        <div class="col-md-9">
                                            <Select2 class="vue-select2" :options="sections" v-model="section_id" @change="advanceFilter" :settings="{width: '100%'}" />
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                </div>

                                <!-- <div class="float-end">
                                    <button type="button" @click="exportToCsv" class="btn btn-success"><i class="fa fa-download"></i> Export CSV</button>
                                </div> -->
                            </div>
                        </div>
					</div>

                  <div class="card-body mt-2 overflow-auto">
                    <table class="table table-borderless datatable">
                      <thead>
                        <tr>
                            <th width="3%">ID</th>
                            <th>Jenis/Sub</th>
                            <th>Org</th>
                            <th>Jenis Lokasi</th>
                            <th>Lokasi</th>
                            <th>Nama</th>
                            <th>Panjang (m)</th>
                            <th width="8%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in items.data" :key="item.id" v-bind:index="index" @click="goToDetailSarpras($event, item.id)" style="cursor: pointer">
                                <td>{{ item.id }}</td>
                                <td>{{ item.type.supertype.name+' / '+item.type.name }}</td>
                                <td>{{ item.ref_organization_name }}</td>
                                <td>{{ item.channel.name }}</td>
                                <td>{{ item.segment.name }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.length }}</td>
                            <td>
                            <router-link :to="{path: '/sarpras/infrastructure/edit/'+item.id}" class="btn btn-sm btn btn-outline-primary me-2" v-if="getRole() !== 'guest'">
                                <i class="fa fa-pen"></i>
                            </router-link>
                            <a href="#" class="btn btn-sm btn btn-outline-danger" v-on:click="destroy(item.id, index)" v-if="getRole() !== 'guest'">
                                <i class="fa fa-trash"></i>
                            </a>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="float-end">
                      <pagination :data="items" :limit="limit" @pagination-change-page="getResults"></pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../../components/Breadcrumb.vue'
    import Select2 from 'v-select2-component'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarSarpras from '../../../components/SidebarSarpras.vue'
    
    export default{
        data(){
            return{
                name: 'Struktural',

                // Need
                is_loading: false,
				is_fullpage: true,
				token: null,
				items: {},
				total_entries: 0,
				channels: [],
				stats: [
					{
						'id': 'rusak berat',
						'text': 'Rusak Berat'
					},
					{
						'id': 'rusak ringan',
						'text': 'Rusak Ringan'
					},
					{
						'id': 'baik',
						'text': 'Baik'
					},
				],
				segments: [],
				orgs: [],
				types: [],
				subtypes: [],
				units: [],
				sections:[],
				q_name:'',
				is_searched: false,
				is_advance_searched: false,
				advance_filter: false,
				limit: 3,
				unit_id: [],
				section_id: null,
				type_id: [],
				sub_type_id: null,
				channel_id: [],
				stats_id: [],
				stat_id: '',
				segment_id: null,
                per_page: 10,
                is_allchannel: false,
				is_allstats: false,
                is_alltype: false,
                is_allorg: false,

                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/sarpras/komponen', text: 'Sarpras List'}, 
                ],
            }
        },
        mounted(){
            this.is_loading = true;
            this.token = localStorage.getItem('token');

            // this.is_loading = true;
			this.token = localStorage.getItem('token');

			axios.get(this.$endpoints.sarpras+'infrastructures?token='+this.token+'&per_page='+this.per_page)
			.then(response =>{
				this.items = response.data
				this.total_entries = response.data.total;
				this.is_loading = false;
			});

			// get organizations
			axios.get(this.$endpoints.sarpras+'infrastructures/get_parent_orgs')
			.then((response) => {
				// let data = [];
				for(var i = 0; i < response.data.length; i++){
                    if(response.data[i].id === null) continue;
					this.units.push({
						text: response.data[i].ref_organization_parent_name,
						id: response.data[i].id
					});
				}
				// this.orgs = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})

			// get TYPES
			axios.get(this.$endpoints.sarpras+'supertypes?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					let children = [];
					for(var j = 0; j < response.data[i].types.length; j++){
						children.push({
							text: response.data[i].types[j].name,
							id: response.data[i].types[j].id
						});
					}

					data.push({
						text: response.data[i].name,
						id: response.data[i].id,
						children: children
					});
				}
				this.types = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error while populating UOM. please contact developer.');
			});

			// get CHANNEL
			axios.get(this.$endpoints.sarpras+'channels?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					data.push({
						text: response.data[i].name,
						id: response.data[i].id,
						segments: response.data[i].segments
					});
				}
				this.channels = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})
        },
        components:{
            Navbar,
            SidebarSarpras,
            Loading, 
            Breadcrumb,
            Select2
        },
        methods:{
            destroy(id, index){
				let fd = new FormData();
				fd.append('_method', 'DELETE');

				this.is_loading = true;

				axios.post(this.$endpoints.sarpras+'infrastructures/'+id+'?token='+this.token, fd)
				.then(() => {
					this.items.data.splice(index, 1);
					this.is_loading = false;
				}).catch(() => {
					alert("error while deleting data. please contact developer");
					this.is_loading = false;
				});
			},
			getResults(page = 1){
				this.is_loading = true;

				var q = [];

				if(this.unit_id !== null){
					q.push("unit_id="+this.unit_id);
				}

				if(this.section_id !== null){
					q.push("section_id="+this.section_id);
				}

				if(this.type_id !== null){
					q.push("type_id="+this.type_id);
				}

				if(this.sub_type_id !== null){
					q.push("sub_type_id="+this.sub_type_id);
				}

				if(this.channel_id !== null){
					q.push("channel_id="+this.channel_id);
				}

				if(this.segment_id !== null){
					q.push("segment_id="+this.segment_id);
				}

				q.push("advance_filter=1");

				axios.get(this.$endpoints.sarpras+'infrastructures?per_page='+this.per_page+'&page='+page+'&name='+this.q_name+'&token='+this.token+"&"+q.join('&'))
				.then(response =>{
					this.items = response.data;
                    this.total_entries = response.data.total;
					this.is_loading = false;
				}).catch(() => {
					alert("error while loading page. please contact developer");
					this.is_loading = false;
				});
			},
			filter(event){
				this.is_searched = true;
				this.is_loading = true;

				var q = [];
				q.push("advance_filter=1");
				q.push("per_page=" + this.per_page);

				if(this.q_name){
					q.push("name="+this.q_name);
				}

				axios.get(this.$endpoints.sarpras+'infrastructures?token='+this.token+'&'+q.join('&'))
				.then(response =>{
                    // console.log(response.data);
					this.items = response.data;
                    this.total_entries = response.data.total;
					this.is_loading = false;
				}).catch(() => {
					alert("error while filtering page. please contact developer");
					this.is_loading = false;
				});

				event.preventDefault();
			},
			advanceFilter(event){
                this.is_advance_searched = true;
                this.q_name = "";

				var q = [];

				if(this.unit_id.length > 0){
					q.push("unit_id="+this.unit_id);
				}

				if(this.section_id !== null){
					q.push("section_id="+this.section_id);
				}

				if(this.type_id.length > 0){
					q.push("type_id="+this.type_id);
				}

				if(this.sub_type_id !== null){
					q.push("sub_type_id="+this.sub_type_id);
				}

				if(this.channel_id.length > 0){
					q.push("channel_id="+this.channel_id);
				}

				if(this.segment_id !== null){
					q.push("segment_id="+this.segment_id);
				}

				if(this.stat_id !== null){
					q.push("status="+this.stat_id);
				}

				if(q.length > 0){
					this.is_loading = true;
					axios.get(this.$endpoints.sarpras+'infrastructures?token='+this.token+'&advance_filter=1&per_page='+this.per_page+'&'+q.join('&'))
					.then(response =>{
						this.items = response.data;
						this.total_entries = response.data.total;
						this.is_loading = false;
					}).catch(() => {
						alert("error while filtering page. please contact developer");
						this.is_loading = false;
					});
				}else{
					this.items = {};
				}

				event.preventDefault();
			},
			changeChannel(id){
				var segments = [];
				this.setSearchCriteria('channel_id', id);
                this.setSearchCriteria('segment_id', null);
                
                this.is_allchannel = false;

				for(var i = 0; i < this.channel_id.length; i++){
					for(var j = 0; j < this.channels.length; j++){
						if(this.channels[j].id == this.channel_id[i]){
							for(var a = 0; a < this.channels[j].segments.length; a++){
								segments.push({
									text: this.channels[j].segments[a].name,
									id: this.channels[j].segments[a].id,
								});
							}
							break;
						}
					}
				}

				this.segments = segments;

				this.advanceFilter();
			},
			allChannel(){
                this.is_allchannel = !this.is_allchannel;
                if(this.is_allchannel){
                    this.channel_id = this.channels.map(function(v){
                        return v.id;
                    });
                }else{
                    this.channel_id = [];
                }

                this.advanceFilter();

			},
			allStats(){
                this.is_allstats = !this.is_allstats;
                if(this.is_allstats){
                    this.stats_id = this.stats.map(function(v){
                        return v.id;
                    });
                }else{
                    this.stats_id = [];
                }

                this.advanceFilter();

			},
			changeStats(id){

				// this.setSearchCriteria('stats', id);
				this.stats_id = id
				this.stat_id = id
                this.is_alltype = false;
				// alert(id)

				this.advanceFilter();
			},
			changeType(id){
				this.setSearchCriteria('type_id', id);
                this.setSearchCriteria('sub_type_id', null);
                
                this.is_alltype = false;

				var subtypes = [];
				for(var i = 0; i < this.type_id.length; i++){
					for(var j = 0; j < this.types.length; j++){
						if(this.types[j].id == this.type_id[i]){
							subtypes.push(this.types[j].children);
						}
					}
				}

				this.subtypes = subtypes.flat();

				this.advanceFilter();
			},
			allType(){
                this.is_alltype = !this.is_alltype;

                if(this.is_alltype){
                    this.type_id = this.types.map(function(v){
                        return v.id;
                    });
                }else{
                    this.type_id = [];
                }

				var subtypes = [];
				for(var i = 0; i < this.type_id.length; i++){
					for(var j = 0; j < this.types.length; j++){
						if(this.types[j].id == this.type_id[i]){
							subtypes.push(this.types[j].children);
						}
					}
				}

				this.subtypes = subtypes.flat();
			},
			changeParentOrg(id){
                this.sections = [];
                
                if(id == null){
                    this.is_allorg = !this.is_allorg;
                    this.unit_id = this.units.map(function(v){
                        return v.id;
                    });

                }else{
                    this.is_allorg = false;
                    this.setSearchCriteria('unit_id', id);
                }

				if(this.unit_id.length > 0){
					this.is_loading = true;

					for(var i = 0; i < this.unit_id.length; i++){
						axios.get(this.$endpoints.sarpras+'infrastructures/get_orgs/'+this.unit_id[i])
						.then((response) => {
							for(var i = 0; i < response.data.length; i++){
								this.sections.push(response.data[i]);
							}
							this.is_loading = false;
						})
						.catch(() => {
							this.is_loading = false;
							alert('there was an error. please contact developer.');
						})
					}
				}
				
				this.advanceFilter();
			},
			setSearchCriteria(key, value){
				if(Array.isArray(this[key])){
					var idx = this[key].indexOf(value)
					if(idx > -1){
						this[key].splice(idx, 1);
					}else{
						this[key].push(value);
					}
				}else{
					this[key] = value;
				}
			},
			clear(){
				this.is_searched = false;
				this.q_name = '';

				this.getResults();
			},
			clearAdvance(){
				this.is_advance_searched = false;
				this.org_id = null;
				this.type_id = null;
				this.channel_id = null;
				this.segment_id = null;
			},
			setPerPage(pp){
				this.per_page = pp;
				if(this.is_searched || this.is_advance_searched){
					this.getResults();
				}
            },
            exportToCsv(){
                this.is_advance_searched = true;

				var q = [];

				if(this.unit_id.length > 0){
					q.push("unit_id="+this.unit_id);
				}

				if(this.section_id !== null){
					q.push("section_id="+this.section_id);
				}

				if(this.type_id.length > 0){
					q.push("type_id="+this.type_id);
				}

				if(this.sub_type_id !== null){
					q.push("sub_type_id="+this.sub_type_id);
				}

				if(this.channel_id.length > 0){
					q.push("channel_id="+this.channel_id);
				}

				if(this.segment_id !== null){
					q.push("segment_id="+this.segment_id);
				}

				if(q.length > 0){
                    let url = this.$endpoints.sarpras+'infrastructures/csv/?token='+this.token+'&advance_filter=1&'+q.join('&');
                    console.log(url);
                    window.location = url;
				}else{
					this.items = {};
				}
            },
            goToDetailSarpras(e, id){
                if(e.target.nodeName == 'TD'){
                    this.$router.push({path:'/sarpras/infrastructure/detail/'+id});
                }
            }
        }
    }

</script>

<style scoped>
  .dashboard .filter{
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .nav-tabs-bordered .nav-link.active{
    background-color: #fff;
    color: #509ee3;
    border-bottom: 2px
    solid #509ee3;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #000000;
    background-color: #fff;
    border-color: #509ee3;
  }

  .btn-xs{
    border-radius: 10px;
  }


</style>