<template>
    <ul class="sidebar-nav" id="sidebar-nav">
        <li class="nav-item">
            <router-link class="nav-link" to="/cctv">
                <img src="../assets/icon/overview.png" alt="">
                Overview
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'CCTVSidebar',
    data(){
        return {
            collapsed: false
        }
    }
}
</script>