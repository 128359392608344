<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
                <router-link class="nav-link" to="/scada/dashboard" inactive-class="collapsed">
                    <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                    <span>Overview</span>
                </router-link>
            </li>
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#menu-0" data-bs-toggle="collapse" href="#" aria-expanded="false">
                    <img src="../assets/icon-navbar/icon-spatial.png" alt="" style="width: 30px; padding-right: 5px;">
                    <span>Seri Data</span>
                    <i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="menu-0" class="nav-content collapse" data-bs-parent="#sidebar-nav" style="">
                    <li>
                        <router-link :to="{name: 'LocationScadaTable'}">
                            <i class="bi bi-circle"></i><span>Tabel</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'LocationScadaGraphic'}">
                            <i class="bi bi-circle"></i><span>Grafik</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarScada',
        data(){
            return{
                collapsed_0: false,
                collapsed_1: false,
                collapsed_2: false,
                collapsed_3: false,
                collapsed_4: false,
                collapsed_5: false,
            }
        },
    }
</script>