<template>
    <div>
        <Navbar />
        <div id="main" class="main">
            <div class="alert alert-danger">
                Anda tidak punya hak untuk mengakses halaman ini.
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'

export default {
    name: 'Unauthorized',
    components: {
        Navbar
    },
    mounted(){
        document.body.classList.add('toggle-sidebar')
    }
}
</script>