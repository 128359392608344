<template>
    <div id="login-page">
        <header id="header">
            <div class="container d-flex align-items-center">
                <h4 class="logo-landing me-auto">
                    <router-link to="/login">
                        <img src="../../assets/pjt-logo-3.png" alt="" style="width: 200px;">
                    </router-link>
                </h4>
                <nav id="navbar" class="navbar" ref="navbar">
                    <ul>
                        <li><router-link to="/login"><a>Login</a></router-link></li>
                        <li><router-link to="/bantuan"><a>Bantuan</a></router-link></li>
                        <li><a href="../../assets/apps/app-release-v.1.0.1.apk" class="getstarted" download>Download APK</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle" ref="menu_toggler" @click="toggleMenu"></i>
                </nav>
            </div>
        </header>
        <section id="hero">
            <img src="../../assets/sisda-pjt.png" alt="bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <img src="../../assets/responsive.png" class="img-fluid" alt="responsive">
                    </div>
                    <div class="col-lg-7" style="margin-top: 110px;">
                        <h3 class="text-start text-light">
                            <b>Selamat datang di SISDA SUPER APP!</b>
                        </h3>
                        <p class="text-start">Aplikasi induk untuk seluruh aktivitas pengelolaan sumber daya air Perum Jasa Tirta II</p>
                    </div>
                </div>
            </div>
        </section>
        <main id="main">
            <section id="about">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4><a href="#">Video Tata Cara Download Aplikasi SISDA</a></h4>
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/c1YvFGemXp4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <strong>Copyright</strong> Jasa Tirta 2 
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4><a href="#">Video Tata Cara Input Data Pada Aplikasi SISDA</a></h4>
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/G9-9oAUgmAo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <strong>Copyright</strong> Jasa Tirta 2
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-lg-12">
                            <p>
                                Untuk Bantuan dapat mengirimkan email ke <a href="mailto:sisda@jasatirta2.co.id">sisda.jasatirta2.co.id</a> 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
    import axios from 'axios';
    import '../../assets/style/landing/style.css';
    // import '../../assets/style/landing/animate.css/animate.min.css';


    export default{
        name: 'Bantuan',
        data(){
            return{
                is_loading: false,
                is_fullpage: true,
                username:'',
                password:'',
                error: false,
                // url: 
            };
        },
        mounted(){

        },
        beforeCreate: function() {
            document.body.className = 'home';
        },
        methods:{
            doLogin: function(event){
                let fd = new FormData();

                // Request Form
                fd.append('username', this.username);
                fd.append('password', this.password);

                // Loading
                this.is_loading = true;

                // API 
                axios.post(this.$endpoints.user+'login', fd,{
                    headers:{
                        'Content-type':'multipart/form-data'
                    }
                })
                .then(response => {
                    this.is_loading = false;

                    localStorage.setItem('name', response.data.name);
                    localStorage.setItem('id', response.data.id);
                    localStorage.setItem('role', response.data.role);
                    localStorage.setItem('org_id', response.data.ref_org_id);
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('module_access', response.data.module_access);
                    // this.$router.push({path:'/dashboard'});
                })
                .catch(e => {
                    console.log(e)
                    this.error = true
                    this.is_loading = false;
                });

                event.preventDefault();
            },
            toggleMenu(){
                this.$refs.navbar.classList.toggle('navbar-mobile');
                this.$refs.menu_toggler.classList.toggle('bi-list')
                this.$refs.menu_toggler.classList.toggle('bi-x')
            },
            scrollToSection(selector){
                document.querySelector(selector).scrollIntoView();
            },
            showLoginModal(){
                this.$refs.login_modal.show();
            },
        },
        components:{
            // Loading,
        }
    }
</script>

<style scoped>
    @import '../../assets/style/landing/style.css';
    /* @import '../../assets/style/landing/animate.css/animate.min.css'; */

    #main{
        margin-left: 0!important;
    }

    .btn-primary{
        background-color: #509ee3;
    }

    .card{
        opacity: 1!important;
        border-radius: 20px;
    }

    .form-control{
        border-radius: 10px;
    }

    /* .logo img{
        filter: invert(1);
    } */

    .form-floating > label{
        left: 8px;
    }
    
</style>