<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div class="main" id="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Import Data'}]"/>
                <h2><b>Import Data</b></h2>
                <form action="" ref="form_upload">
                    <label class="mb-2"><i>contoh file excel yg benar dapat anda lihat <a href="/contoh_import.csv">disini</a>. seluruh kolom harap menggunakan jenis data (format) text.</i></label>
                    <div class="form-group mb-3">
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <input type="file" class="form-control" @change="readFile">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <b-modal id="data_modal" ref="data_modal" size="xl" title="Preview Data" :hide-footer="true">
                <div class="data-wrapper mb-3">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Lokasi</th>
                                <th>Nama Data</th>
                                <th>Besaran</th>
                                <th>Value</th>
                                <th>Tanggal</th>
                                <th>Jam</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rows" :key="i">
                                <td>{{ row[0] }}</td>
                                <td>{{ row[1] }}</td>
                                <td>{{ row[2] }}</td>
                                <td>{{ row[3] }}</td>
                                <td>{{ row[4] }}</td>
                                <td>{{ row[5] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary me-2" @click="save"><i class="fa fa-save"></i> Import</button>
                    <button class="btn btn-danger" @click="$bvModal.hide('data_modal')"><i class="fa fa-times"></i> Batal</button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import Loading from 'vue-loading-overlay';
import * as CSV from 'csv-string'
import { BModal } from 'bootstrap-vue'
import axios from 'axios'

export default {
    name: "ImportSeriData",
    components: {
        Loading,
        Navbar,
        Breadcrumb,
        OperationSidebar,
        BModal
    },
    methods:{
        readFile(ev){
            let files = ev.target.files;
            if(files.length > 0){
                let file = files[0];

                if(file.type == 'text/csv'){
                    let reader = new FileReader();

                    reader.addEventListener('load', (e) => {
                        let data = e.target.result;
                        this.rows = CSV.parse(data);
                        this.rows.shift();

                        this.$refs['data_modal'].show();
                    });

                    reader.readAsBinaryString(file);
                }else{
                    this.$swal("Error", "File yg dipilih bukan CSV. harap upload file berformat CSV", "error");
                }
            }
        },
        save(){
            const user_id = localStorage.getItem('id');
            const user_name = localStorage.getItem('name');
            const token = localStorage.getItem('token');

            let fd = this.createFormData({
                rows: JSON.stringify(this.rows),
                user_id,
                user_name
            })

            axios.post(this.$endpoints.operasi + 'import?token=' + token, fd)
            .then(response => {
                this.$refs['form_upload'].reset();
                this.$refs['data_modal'].show();
                this.$swal("Berhasil", response.data.message, "success");
            })
            .catch(e => {
                this.$refs['form_upload'].reset();
                this.$refs['data_modal'].hide();
                this.$swal({
                    title: "Berhasil dengan beberapa pengecualian",
                    html: "Data berhasil dimasukan, namun terjadi kesalahan pada baris berikut. <br><br>" + e.response.data.join("<br>"),
                    icon: "error",
                    width: "64em"
                });
            });
        }
    },
    data(){
        return {
            is_loading: false,
            rows: []
        }
    }
}
</script>

<style scoped>
    .data-wrapper{
        height: 300px;
        overflow: auto
    }
</style>