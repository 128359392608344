<template>
  <ul class="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified">
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/organisasi/index" aria-selected="true">Organisasi</router-link>
        </li>
        <!-- <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/seksi/index" aria-selected="false">Seksi</router-link>
        </li> -->
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/lokasi/index" aria-selected="false">Lokasi</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/operator/index" aria-selected="false">Operator</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/referensi/petugas/index" aria-selected="false">Petugas</router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'BreadCrumbs',
    }
</script>

<style scoped>
.dashboard .filter{
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .nav-tabs-bordered .nav-link.active{
    background-color: #fff;
    color: #509ee3;
    border-bottom: 2px
    solid #509ee3;
  }
</style>
