<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '#', text: 'Operasi'}]"/>
                <h2><b>{{ current_location }}</b></h2>
                <div class="row mb-3" style="justiy-content: stretch">
                    <div class="col-10">
                        <video id="videoPlayer" autoplay controls muted playsinline style="width: 100%;"></video>
                    </div>
                    <div class="col-2">
                        <div class="card" v-for="(location, index) in locations" :key="index" @click="changeCameraLocation(index)">
                            <div class="card-header">
                                {{ location.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/CCTVSidebar.vue'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'Cctv',
    components: {
        Navbar,
        Breadcrumb,
        OperationSidebar,
        Loading
    },
    methods: {
        changeCameraLocation(location){
            this.current_location = this.locations[location].name;
            this.current_url = this.locations[location].url

            this.startPlay();
        },
        async startPlay(){
            this.mediaStream = new MediaStream();
            
            document.querySelector('#videoPlayer').srcObject = this.mediaStream;

            this.webrtc = new RTCPeerConnection({
                iceServers: [{
                    urls: ["stun:stun.l.google.com:19302"]
                }],
                sdpSemantics: "unified-plan"
            });
            this.webrtc.onnegotiationneeded = this.handleNegotiationNeeded;
            this.webrtc.onsignalingstatechange = this.signalingstatechange;

            this.webrtc.ontrack = this.ontrack
            let offer = await this.webrtc.createOffer({
                offerToReceiveAudio:true,
                offerToReceiveVideo:true
            });

            await this.webrtc.setLocalDescription(offer);
        },
        async signalingstatechange (){
            switch (this.webrtc.signalingState){
                case 'have-local-offer': {
                    let data = new FormData();
                    data.append('data', btoa(this.webrtc.localDescription.sdp));

                    axios.post(this.current_url, data, {
                        auth: {
                            username: 'cctv',
                            password: 'sisitipi2023'
                        },
                        headers: {
                            'Content-type':'application/x-www-form-urlencoded'
                        }
                    })
                    .then(response => {
                        this.webrtc.setRemoteDescription(new RTCSessionDescription({
                            type: 'answer',
                            sdp: atob(response.data)
                        }))
                    }).catch((error) => {
                        if(error.response.status == 500){
                            this.$swal('Error!', 'CCTV Offline');
                        }
                    });
                }
                break;

                default:
                    console.log(`unhandled signalingState is ${this.webrtc.signalingState}`);
                break;
            }
        },
        async handleNegotiationNeeded() {
            let offer = await this.webrtc.createOffer();
            await this.webrtc.setLocalDescription(offer);

            let data = new FormData();
            data.append('data', btoa(this.webrtc.localDescription.sdp));

            axios.post(this.current_url, data, {
                auth: {
                    username: 'cctv',
                    password: 'sisitipi2023'
                },
                headers: {
                    'Content-type':'application/x-www-form-urlencoded'
                }
            })
            .then(response => {
                this.webrtc.setRemoteDescription(new RTCSessionDescription({
                    type: 'answer',
                    sdp: atob(response.data)
                }))
            }).catch((error) => {
                if(error.response.status == 500){
                    this.$swal('Error!', 'CCTV Offline');
                }
            });
        },
        ontrack (event){
            console.log(event.streams.length + ' track is delivered');
            this.mediaStream.addTrack(event.track);
        }
    },
    data(){
        return {
            is_loading: false,
            current_location: "Area Waduk Ir. H. Djuanda",
            current_cctv: "https://g3.ipcamlive.com/player/player.php?alias=6348f45faf5a3",
            locations: [],
            mediaStream: null,
            webrtc: null,
            webrtcSendChannel: null
        };
    },
    mounted(){
        const rtspServer = 'https://totolom.com';
        axios.get(`${rtspServer}/streams`, {
            auth: {
                username: 'cctv',
                password: 'sisitipi2023'
            }
        })
        .then(response => {
            for(let uuid in response.data.payload){
                this.locations.push({
                    name: response.data.payload[uuid].name,
                    url: `${rtspServer}/stream/${uuid}/channel/0/webrtc?uuid=${uuid}&channel=0`
                });
            }
        }).catch(error => {
            if(error.toString().match(/Network/) !== null){
                this.$swal('Error!', 'RTSP server down. please check the droplet');
            }
        })
    }
}
</script>

<style scoped>
    .row > .col-md-2 {
        display:flex;
        flex: 0 0 25%;
        max-width: 25%
    }

    .scrolling-wrapper-flexbox {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        .card {
            flex: 0 0 auto;
        }
    }
</style>