<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <div class="container-fluid">
        <div class="sidebar">
            <OperationSidebar />
        </div>
        <main id="main" class="main">
            <!-- Breadcomp -->
            <Breadcrumb :links="links"/>
            <!-- END OF BREADCOMP -->

            <section class="section dashboard">
                <div class="row">
                    <!-- Left side columns -->
                    <div class="col-lg-12">
                        <div class="row">
                            <!-- Recent Sales -->
                            <div class="col-sm-12">
                                <div class="card" style="height: 1000px">

                                    <!-- ===== MENU BREADCRUMBS ===== -->
                                    <iframe width="100%" height="100%" :src="iframe" frameborder="0" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
  </div>

</template>

<script>

    // import
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    // Component
    import Navbar from '../../components/Navbar.vue'
    import OperationSidebar from '../../components/OperationSidebar.vue'
    
    export default{
        data(){
            return{
                name: 'Struktural',
                // Need
                is_loading: true,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/operation/produksi-listrik', text: 'Produksi Listrik'}, 
                ],
                iframe: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSWZInUcGTHy8J7M49UgDyO6yk-KYkFyxB2eqPe8SglXULap8th4O2Ola5bnJlPcinia-jstEPD-Xkk/pubchart?oid=1820199499&amp;format=interactive'
            }
        },
        mounted(){
            this.is_loading = false;
        },
        components:{
            Navbar,
            OperationSidebar,
            Loading, 
            Breadcrumb,
        },
    }

</script>