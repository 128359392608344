<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Seri Data (Tabel)'}]"/>
                <h2><b>Seri Data (Tabel)</b></h2>
                <div class="document">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Tipe Lokasi</label>
                                <Select2 class="vue-select2" :options="locations_type" v-model="location_type_id" @change="getLocationsByTypeId()" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Lokasi</label>
                                <Select2 class="vue-select2" :options="locations" v-model="location_id" />
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-3">
                            <!-- <date-range-picker
                                ref="picker"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                                :showDropdowns="true"
                                :autoApply="true"
                                :singleDatePicker="true"
                                v-model="date_start"
                                @update="getInputData(1)">
                                <template v-slot:input="picker" style="width: 100%!important;">
                                    {{ picker.startDate | date }}
                                </template>
                            </date-range-picker> -->
                            <label for="">Tanggal mulai</label>
                            <Datepicker v-model="date_start" input-class="form-control" />
                        </div>
                        <div class="col-md-3">
                            <label for="">Tanggal akhir</label>
                            <Datepicker v-model="date_end" input-class="form-control" />
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-inline-block me-3" v-if="!kualitasAirSelected">
                                        <label for="">Waktu</label><br>
                                        <vue-timepicker v-model="time_start" input-class="form-control" minute-interval="60" close-on-complete ref="ts"></vue-timepicker>
                                        s/d
                                        <vue-timepicker v-model="time_end" input-class="form-control" minute-interval="60" close-on-complete ref="te"></vue-timepicker>
                                    </div>
                                    <br v-if="kualitasAirSelected">
                                    <button class="btn btn-primary d-inline-block" @click="getInputData">Tampilkan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="document" style="overflow-x: auto">
                    <h4>
                        <b>Tabel Data</b>
                        <a href="" class="download btn btn-sm btn-primary float-end" @click.prevent="download"><img src="../../assets/icon/download.png" height="18" alt="download" class="me-2">Export Data</a>
                    </h4>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th v-for="data in periodic_data" v-bind:key="data.id">{{ data.text }}</th>
                            </tr>
                        </thead>
                        <tbody v-for="(ip, i) in input_data" v-bind:key="i">
                            <tr v-for="(dates, j) in ip" v-bind:key="j">
                                <td v-for="(value, k) in dates" v-bind:key="k">{{ value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import Select2 from 'v-select2-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import 'vue2-timepicker/dist/VueTimepicker.css'
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker'
import exportCSVFile from '@/to_csv.js'

export default {
    name: 'OperationSeriDataTable',
    components: {
        Navbar,
        Breadcrumb,
        OperationSidebar,
        Select2,
        Loading,
        Datepicker,
        VueTimepicker
    },
    data(){
        return {
            is_loading: false,
            token: null,
            limit: 3,
            locations_type: [],
            locations: [],
            periodic_data: [],
            input_data: [],
            selected:{
                location:''
            },
            date_start: null,
            date_end: null,
            time_start: null,
            time_end: null,
            location_type_id: '',
            location_id: ''
        }
    },
    methods: {
        init(){
            this.is_loading = true;
            this.token = localStorage.getItem('token');

            // Function Get locations
            this.getTypes();
            this.getLocations();
        },
        getLocations()
        {
            // console.log(this.$route.params.type);
            axios.get(this.$endpoints.reference + 'lokasi/operasi' + '/' + this.type)
            .then((response) => {
                this.locations = [];
                for(var i = 0; i < response.data.data.length; i++){
                    this.locations.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name,
                        type_id: response.data.data[i].type_id,
                        type_name: response.data.data[i].type_name
                    })
                }

                this.is_loading = false;
            })
            .catch( () => {
                console.log('error');
            })
        },
        getTypes()
        {
            this.locations_type = [];
            axios.get(this.$endpoints.reference + 'data/type')
            .then((response) => {
                this.locations_type = response.data;
                this.is_loading = false;
            })
            .catch( (e) => {
                console.log('error', e);
            })
        },
        getLocationsByTypeId()
        {
            this.is_loading = true;
            this.time_start = null;
            this.time_end = null;

            // reset time picker everytime "TYPE" is changed
            this.$refs.ts.hour = '';
            this.$refs.ts.minute = '';
            this.$refs.te.hour = '';
            this.$refs.te.minute = '';


            axios.get(this.$endpoints.reference + 'lokasi/type/' + this.location_type_id)
            .then((response) => {
                this.locations = [];
                for(var i = 0; i < response.data.length; i++){
                    this.locations.push(response.data[i]);
                }

                this.is_loading = false;
            }).catch((e) => {
                console.log('error', e);
                this.is_loading = false;
            })
        },
        getInputData()
        {
            this.is_loading = true;
            this.periodic_data = [];
            this.input_data = [];

            axios.get(this.$endpoints.reference + 'periodik/data/location/' + this.location_id)
            .then((response) => {
                let ids = response.data.map(a => a.id);
                let dates = this.getDatesArray();
                
                this.periodic_data = response.data;
                this.periodic_data.unshift({id: 0, text: 'Tanggal'});

                console.log(this.time_start, this.time_end);
                
                // use anon function
                // everytime request finish, execute this function one more time
                // so that the date will be sorted out
                var that = this;
                (function loopThis(i){
                    let query = [];
                    query.push('type_id=' + that.location_type_id);
                    query.push('periodic_id=' + JSON.stringify(ids));
                    query.push('date=' + moment(dates[i]).format('YYYY-MM-DD'));
                    if(that.time_start){
                        query.push('time_start=' + that.time_start.HH+':'+that.time_start.mm+':00');
                    }

                    if(that.time_end){
                        query.push('time_end=' + that.time_end.HH+':'+that.time_end.mm+':00');
                    }

                    axios.get(that.$endpoints.operasi + 'seridata_tabel?' + query.join('&'))
                    .then((response) => {
                        that.input_data.push(response.data);

                        i++;
                        if(i < dates.length){
                            loopThis(i);
                        }else{
                            that.is_loading = false;
                        }
                    }).catch((e) => {
                        console.log('error',e );
                        that.is_loading = false;
                    });
                })(0);
            }).catch((e) => {
                console.log('error',e );
                this.is_loading = false;
            });
        },
        getDatesArray(){
            var dateArray = [];
            var currentDate = moment(this.date_start);
            var stopDate = moment(this.date_end);
            while (currentDate <= stopDate) {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'days');
            }
            return dateArray;
        },
        download(){
            this.is_loading = true;

            let headers = {};
            let data = [];
            let location = this.locations.filter(v => v.id == this.location_id);
            let location_name = '';

            if(location.length > 0){
                location_name = location[0].text;
            }

            for(let i in this.periodic_data){
                headers[this.periodic_data[i].text.toLowerCase().replace(/[^a-zA-Z0-9]+/g,'_')] = this.periodic_data[i].text;
            }

            for(let i in this.input_data){
                for(let j in this.input_data[i]){
                    let k = 0;
                    let _data = {};
                    for(let _k in headers){
                        _data[_k] = this.input_data[i][j][k];

                        k++;
                    }

                    data.push(_data);
                }
            }

            this.is_loading = false;

            exportCSVFile(headers, data, "Seri Data - " + location_name);
        }
    },
    filters:{
        date(value){
            return moment(value).format("DD MMMM YYYY")
        }
    },
    computed: {
        kualitasAirSelected(){
            const type = this.locations_type.filter(location_type => location_type.id == this.location_type_id);

            if(type.length > 0){
                if(type[0].text == 'KUALITAS AIR'){
                    return true;
                }
            }

            return false;
        }
    },
    mounted(){
        this.init();
    }
}
</script>

<style>
.vue-daterange-picker{width: 100%}
[contenteditable]{
    border: 1px solid #ccc;
}
</style>