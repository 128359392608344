<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarTelemeteri/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <Breadcrumb :links="links"/>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row" id="filternya">
                <div class="col-sm-12">
                    <div class="document">
                        <!-- Form -->
                        <form action="#" method="POST">
                            <!-- Row 1 -->
                            <div class="row">
                                <!-- Jenis Lokasi -->
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="">Lokasi Telemeteri</label>
                                        <Select2 class="vue-select2" v-model="telemetering_id" :options="telemeterings"></Select2>
                                    </div>
                                </div>

                                <!-- Lokasi -->
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label for="">Tanggal mulai</label>
                                        <Datepicker v-model="startDate" input-class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label for="">Tanggal akhir</label>
                                        <Datepicker v-model="endDate" input-class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <br>    
                                    <button class="btn btn-primary" @click.prevent="advanceFilter">Tampilkan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="row" id="datanya">
                <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title">
                                Tabel Periodik
                                <div class="float-end" id="buttons">
                                    <button class="btn btn-outline-dark me-2" @click.prevent="print()"><i class="fa fa-print"></i> print</button>
                                    <button class="btn btn-outline-dark" @click.prevent="download()"><i class="fa fa-file-excel"></i> download CSV</button>
                                </div>
                            </div>
                            <table class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th width="10%">Tanggal</th>
                                        <th width="7%">Waktu</th>
                                        <th v-if="current_selected_group == 'awlr'">Level (m)</th>
                                        <th v-if="current_selected_group == 'arr'">Curah Hujan (mm)</th>
                                        <template v-if="current_selected_group == 'wqs'">
                                            <th>PH</th>
                                            <th>Nitrat</th>
                                            <th>Condv. ACT</th>
                                            <th>Condv. SPCV</th>
                                            <th>Salinitas</th>
                                            <th>TDS Terlarut</th>
                                            <th>Resistivitas</th>
                                            <th>Densitas</th>
                                            <th>Kons. RDO</th>
                                            <th>satRDO</th>
                                            <th>ORP</th>
                                            <th>Suhu Insitu 1</th>
                                            <th>Nitrat mV</th>
                                            <th>Kekeruhan</th>
                                            <th>TDS Tergencat</th>
                                            <th>Amonium</th>
                                            <th>Amonium mV</th>
                                            <th>Amonia</th>
                                            <th>Total Amonia</th>
                                            <th>Suhu Insitu 2</th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(klimatologi, index) in klimatologis.data" :key="klimatologi.id" v-bind:index="index">
                                        <td>{{ klimatologi.timestamps | date }}</td>
                                        <td>{{ klimatologi.timestamps | time }}</td>
                                        <td v-if="current_selected_group != 'wqs'">{{ klimatologi.value }}</td>
                                        <template v-if="current_selected_group == 'wqs'">
                                            <td>{{ klimatologi.ph | nullsafe }}</td>
                                            <td>{{ klimatologi.nitrat | nullsafe }}</td>
                                            <td>{{ klimatologi.cndv_act | nullsafe }}</td>
                                            <td>{{ klimatologi.cndv_spc | nullsafe }}</td>
                                            <td>{{ klimatologi.salinity | nullsafe }}</td>
                                            <td>{{ klimatologi.tds | nullsafe }}</td>
                                            <td>{{ klimatologi.resistivity | nullsafe }}</td>
                                            <td>{{ klimatologi.density | nullsafe }}</td>
                                            <td>{{ klimatologi.kons_rdo | nullsafe }}</td>
                                            <td>{{ klimatologi.sat_rdo | nullsafe }}</td>
                                            <td>{{ klimatologi.orp | nullsafe }}</td>
                                            <td>{{ klimatologi.suhu_insitu_1 | nullsafe }}</td>
                                            <td>{{ klimatologi.nitrat_mv | nullsafe }}</td>
                                            <td>{{ klimatologi.kekeruhan | nullsafe }}</td>
                                            <td>{{ klimatologi.tds_tergencat | nullsafe }}</td>
                                            <td>{{ klimatologi.amonium | nullsafe }}</td>
                                            <td>{{ klimatologi.amonium_mv | nullsafe }}</td>
                                            <td>{{ klimatologi.amonia | nullsafe }}</td>
                                            <td>{{ klimatologi.amonia_total | nullsafe }}</td>
                                            <td>{{ klimatologi.suhu_insitu_2 | nullsafe }}</td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="float-end">      
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="per_page"
                                    @change="getResults"
                                    pills>
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    import moment from 'moment'
    import { BPagination } from 'bootstrap-vue'
    import Select2 from 'v-select2-component'
    import Datepicker from 'vuejs-datepicker'
    // import DateRangePicker from 'vue2-daterange-picker'
    // import VueTimepicker from 'vue2-timepicker'

    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarTelemeteri from '../../components/SidebarTelemeteri.vue'
    import exportCSVFile from '@/to_csv.js'
    
    export default{
        components:{
            Navbar,
            SidebarTelemeteri,
            Loading, 
            Breadcrumb,
            BPagination,
            Select2,
            Datepicker,
        },
        data(){
            return{
                // Need
                is_loading: false,
				is_fullpage: true,

                // Date Range Picker
                startDate: new Date(),
                endDate: new Date(),
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/history/telemeteri', text: 'History Telemeteri'}, 
                ],

                // Varible Filter
                currentPage: 1,
                per_page: 30,
                rows:0,
                
				q_name:'',

                // Telemetering ID
                telemeterings: [],
                telemetering_id: null,

                // klimatologi
                klimatologis: [],

                current_selected_group: 'awlr'
            }
        },
        mounted(){
            // this.is_loading = true;
            this.token = localStorage.getItem('token');

            axios.get(this.$endpoints.telemeteri + 'stations?token=' + this.token)
            .then(response => {
                let telemeterings = [];

                for(let k in response.data){
                    telemeterings.push({
                        text: k,
                        children: response.data[k].map(data => {
                            return {
                                id: data.id,
                                text: data.name
                            }
                        })
                    })
                }

                this.telemeterings = telemeterings;

                // console.log(response.data);
            })
            .catch(() => {
                alert('there was an error. please contact developer.');
            });
        },
        methods:{       
			advanceFilter()
            {
				let q = [];

                // Type
                if(this.telemetering_id !== null){
                    q.push("monitoring_id="+this.telemetering_id);

                    // record current telemetering group (AWLR|ARR|WQS|AWS)
                    for(let i in this.telemeterings){
                        for(let j in this.telemeterings[i].children){
                            if(this.telemeterings[i].children[j].id == this.telemetering_id){
                                this.current_selected_group = this.telemeterings[i].text;
                            }
                        }
                    }
                }

                q.push("startDate=" + moment(this.startDate).format('YYYY-MM-DD'));
                q.push("endDate=" + moment(this.endDate).format('YYYY-MM-DD'));
                
				if(this.telemetering_id == null){
                    alert('silahkan pilih lokasi pengamatan dan tanggal pengamatan');
				}else{
					this.is_loading = true;
					axios.get(this.$endpoints.telemeteri+'data/history?token='+this.token+'&per_page='+this.per_page+'&'+q.join('&'))
                    .then(response =>{
                        this.klimatologis = response.data
                        this.rows = response.data.total;
                        this.is_loading = false;
                    }).catch(() => {
                        alert("error while filtering page. please contact developer");
                        this.is_loading = false;
                    });
				}

				// event.preventDefault();
			},
            getResults(page = 1)
            {
				let q = [];

                if(this.telemetering_id !== null){
                    q.push("monitoring_id="+this.telemetering_id);
                }

                q.push("startDate=" + moment(this.startDate).format('YYYY-MM-DD'));
                q.push("endDate=" + moment(this.endDate).format('YYYY-MM-DD'));

                if(this.telemetering_id == null){
                    alert('silahkan pilih lokasi pengamatan dan tanggal pengamatan');
                }else{
                    this.is_loading = true;
                    axios.get(this.$endpoints.telemeteri+'data/history?token='+this.token+'&per_page='+this.per_page+'&'+q.join('&')+'&page=' + page)
                    .then(response =>{
                        this.klimatologis = response.data;
                        this.currentPage = response.data.current_page;
                        this.rows = response.data.total;
                        this.is_loading = false;
                        console.log(response);
                    }).catch(() => {
                        alert("error while filtering page. please contact developer");
                        this.is_loading = false;
                    });
                }

			},
            print(){ 
                window.print();
            },
            download(){
                const fileTitle = 'history telemeteri';
                let headers = {};
                let data = [];
                let q = [];

                this.is_loading = true;

                switch(this.current_selected_group){
                    case "awlr":
                        headers = {
                            date: 'Tanggal',
                            time: 'Waktu',
                            value: 'Level (m)'
                        };
                        break;

                    case "arr":
                        headers = {
                            date: 'Tanggal',
                            time: 'Waktu',
                            value: 'Curah Hujan (mm)'
                        };
                        break;

                    case "wqs":
                        headers = {
                            date: 'Tanggal',
                            time: 'Waktu',
                            ph:'PH',
                            nitrat:'Nitrat',
                            cndv_act:'Condv. ACT',
                            cndv_spc:'Condv. SPCV',
                            salinity:'Salinitas',
                            tds:'TDS Terlarut',
                            resistivity:'Resistivitas',
                            density:'Densitas',
                            kons_rdo:'Kons. RDO',
                            sat_rdo:'satRDO',
                            orp:'ORP',
                            suhu_insitu_1:'Suhu Insitu 1',
                            nitrat_mv:'Nitrat mV',
                            kekeruhan:'Kekeruhan',
                            tds_tergencat:'TDS Tergencat',
                            amonium:'Amonium',
                            amonium_mv:'Amonium mV',
                            amonia:'Amonia',
                            amonia_total:'Total Amonia',
                            suhu_insitu_2:'Suhu Insitu 2'
                        }
                }

                if(this.telemetering_id !== null){
                    q.push("monitoring_id="+this.telemetering_id);
                }

                q.push("startDate=" + moment(this.startDate).format('YYYY-MM-DD'));
                q.push("endDate=" + moment(this.endDate).format('YYYY-MM-DD'));

                axios.get(this.$endpoints.telemeteri+'data/history?token='+this.token+'&to_csv=1&'+q.join('&'))
                .then(response =>{
                    switch(this.current_selected_group){
                        case "awlr":
                        case "arr":
                            response.data.forEach(row => {
                                data.push({
                                    date: moment(row.timestamps).format("YYYY-MM-DD"),
                                    time: moment(row.timestamps).format("HH:mm"),
                                    value: row.value
                                });
                            });
                            break;

                        case "wqs":
                            response.data.forEach(row => {
                                data.push({
                                    date: moment(row.timestamps).format("YYYY-MM-DD"),
                                    time: moment(row.timestamps).format("HH:mm"),
                                    ph: row.ph,
                                    nitrat: row.nitrat,
                                    cndv_act: row.cndv_act,
                                    cndv_spc: row.cndv_spc,
                                    salinity: row.salinity,
                                    tds: row.tds,
                                    resistivity: row.resistivity,
                                    density: row.density,
                                    kons_rdo: row.kons_rdo,
                                    sat_rdo: row.sat_rdo,
                                    orp: row.orp,
                                    suhu_insitu_1: row.suhu_insitu_1,
                                    nitrat_mv: row.nitrat_mv,
                                    kekeruhan: row.kekeruhan,
                                    tds_tergencat: row.tds_tergencat,
                                    amonium: row.amonium,
                                    amonium_mv: row.amonium_mv,
                                    amonia: row.amonia,
                                    amonia_total: row.amonia_total,
                                    suhu_insitu_2: row.suhu_insitu_2
                                });
                            });
                            break;
                    }

                    this.is_loading = false;

                    exportCSVFile(headers, data, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
                }).catch(() => {
                    alert("error while filtering page. please contact developer");
                    this.is_loading = false;
                });
            }
        },
        filters: 
        {
            date(val) 
            {
                return val ? moment(val).format("YYYY-MM-DD") : "";
            },
            time(val){
                return val ? moment(val).format("HH:mm") : "";
            },
            nullsafe(val){
                return val ?? '-';
            }
        }
    }

</script>

<style scoped>
    .dashboard .filter{
        position: absolute;
        left: 0px;
        top: 15px;
    }

    .nav-tabs-bordered .nav-link.active{
        background-color: #fff;
        color: #509ee3;
        border-bottom: 2px
        solid #509ee3;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        background-color: #fff;
        border-color: #509ee3;
    }

    .btn-xs{
        border-radius: 10px;
    }
</style>

<style>
    @media print{
        #filternya,
        #sidebar,
        #buttons,
        #header,
        .breadcrumb,
        .pagination{
            display: none!important;
        }

        .section.dashboard{
            padding: 0!important
        }

        #datanya th,
        #datanya td{
            font-size: 8pt;
        }
    }
</style>