<template>
    <ul class="sidebar-nav" id="sidebar-nav">
        <li class="nav-item">
            <router-link class="nav-link" to="/operation/dashboard">
                <img src="../assets/icon/overview.png" alt="">
                Overview
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/operation/input_data" class="nav-link">
                <img src="../assets/icon/legalitas.png" alt="">
                Pembaruan Data
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/operation/manage_data" class="nav-link">
                <img src="../assets/icon/drawer.png" alt="">
                Manajemen Data
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/operation/import" class="nav-link">
                <img src="../assets/icon/drawer.png" alt="">
                Import Data
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/operation/simulasi-tma" class="nav-link">
                <img src="../assets/icon/drawer.png" alt="">
                Simulasi TMA dan Produksi Listrik
            </router-link>
        </li>
        <li class="nav-item">
            <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#" aria-expanded="false">
                <img src="../assets/icon/chart-gray.png" alt=""> <span>Seri Data</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="forms-nav" class="nav-content collapse" data-bs-parent="#sidebar-nav" style="">
                <li>
                    <router-link to="/operation/seri_data/tabel">
                        <i class="bi bi-circle"></i><span>Tabel</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/operation/seri_data/grafik">
                        <i class="bi bi-circle"></i><span>Grafik</span>
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="nav-item">
            <a class="nav-link collapsed"  data-bs-target="#forms-nav2" data-bs-toggle="collapse" href="#" aria-expanded="false">
                <img src="../assets/icon/compare.png" alt=""> <span>Perbandingan Data</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="forms-nav2" class="nav-content collapse" data-bs-parent="#sidebar-nav" style="">
                <li>
                    <router-link :to="{name: 'OperationCompareDataTable'}">
                        <i class="bi bi-circle"></i><span>Tabel</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'OperationCompareDataGrafik'}">
                        <i class="bi bi-circle"></i><span>Grafik</span>
                    </router-link>
                </li>
            </ul>
        </li>
        <!-- <li class="nav-item">
            <router-link to="/cctv" class="nav-link">
                <img src="../assets/icon/drawer.png" alt="">
                CCTV
            </router-link>
        </li> -->
    </ul>
</template>

<script>
export default {
    name: 'OperationSidebar',
    data(){
        return {
            collapsed: false
        }
    }
}
</script>