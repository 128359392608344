<template>
    <div id="app">
        <!-- ======= Navbar ======= -->
        <!-- <Navbar /> -->

        <!-- ======= Sidebar ======= -->
        <!-- <Sidebar />         -->

        <!-- ====== Content ====== -->
        <router-view :key="$route.fullPath"/>

        <!-- End #main -->

        <!-- ======= Footer ======= -->
        <!-- <Footer /> -->
    </div>
</template>

<script>
    // import Navbar from './components/Navbar.vue'
    // import Sidebar from './components/Sidebar.vue'
    // import Footer from './components/Footer.vue'

    export default {
        components:{
            // Navbar,
            // Sidebar,
            // Footer
        }
    }
</script>