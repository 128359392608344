<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <div class="container-fluid">
        <div class="sidebar">
            <OperationSidebar />
        </div>
        <main id="main" class="main">
            <!-- Breadcomp -->
            <Breadcrumb :links="links"/>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center mb-4">
                                <h4><b>Simulasi TMA</b></h4>
                            </div>
                            <line-chart :chart-data="latest.data_tma" :options="chart_options_tma"></line-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center mb-4">
                                <h4><b>Produksi Listrik</b></h4>
                            </div>
                            <bar-chart :chart-data="latest.data_listrik" :options="chart_options"></bar-chart>
                            <table class="table table-bordered mt-4">
                                <thead>
                                    <th></th>
                                    <th class="text-center" v-for="(month, i) in produki_listrik_bars" :key="i">
                                        <!-- <div class="d-flex justify-content-center">
                                            <peity type="bar" :options="{ 'fill': generateProduksiListrikColorBar(i)}" :data="generateProduksiListrikTableBar(i)"></peity>
                                        </div> -->
                                        {{ month }}
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="dataset in latest.data_listrik.datasets" :key="dataset.label">
                                        <th><div style="display:inline-block; width: 50px;height:15px;margin-right:10px" :style="`background: ${dataset.borderColor}`"></div>{{ dataset.label}}</th>
                                        <td v-for="(d, i) in dataset.data" :key="i">{{ d }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="float-start"><b>History Upload</b></h4>
                            <div class="float-end">
                                <a href="" class="btn btn-primary" @click.prevent="$bvModal.show('upload_modal')" v-if="getRole() !== 'guest'"><i class="fa fa-upload"></i> Upload</a>
                            </div>
                        </div>
                        <div class="card-body">
                            <input type="text" class="form-control mb-3" placeholder="Cari..." v-model="q">
                            <table class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th width="5%">ID</th>
                                        <th>Filename</th>
                                        <th>Tanggal Upload</th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in data.data" :key="row.id" :class="row.id == current_id ? 'table-primary':''">
                                        <td>{{ row.id }}</td>
                                        <td>{{ row.filename }}</td>
                                        <td>{{ row.created_at }}</td>
                                        <td>
                                            <a href="" class="btn btn-sm btn-danger me-2" @click.prevent="destroy(row.id)" v-if="getRole() !== 'guest'"><i class="fa fa-trash"></i></a>
                                            <a href="" class="btn btn-sm btn-primary" @click.prevent="playIt(row.id)"><i class="fa fa-play"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="per_page"
                                @change="getResults"
                                pills>
                            </b-pagination>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal id="upload_modal" ref="upload_modal" size="xl" title="Import Data Simulasi" :hide-footer="true">
                <div class="mb-3">
                    <form action="" ref="form_upload">
                        <label class="mb-2"><i>contoh file excel yg benar dapat anda lihat <a href="/Template Simulasi SISDA.xlsx">disini</a>.</i></label>
                        <div class="form-group mb-3">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <input type="file" class="form-control" ref="file_input">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary me-2" @click="importData"><i class="fa fa-save"></i> Import</button>
                    <button class="btn btn-danger" @click="$bvModal.hide('upload_modal')"><i class="fa fa-times"></i> Batal</button>
                </div>
            </b-modal>
        </main>
    </div>
  </div>

</template>

<script>

    // import
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    import { BPagination, BModal } from 'bootstrap-vue'
    import BarChart from '../../components/chart/BarChart.vue'
    import LineChart from '../../components/chart/LineChart.vue'
    import Navbar from '../../components/Navbar.vue'
    import OperationSidebar from '../../components/OperationSidebar.vue'
    // import Peity from 'vue-peity';
    import axios from 'axios';
    
    export default{
        data(){
            return{
                name: 'Struktural',
                // Need
                is_loading: false,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/operation/simulasi-tma', text: 'Simulasi TMA dan Produksi Listrik'}, 
                ],
                current_id: null,
                currentPage: 1,
                per_page: 10,
                rows:0,
                chart_options_tma: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        labels: {
                            boxWidth: 12
                        }
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            scaleLabel: {
                                display: true,
                                labelString: '+ mdpl'
                            }
                        }]
                    }
                },
                chart_options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        labels: {
                            boxWidth: 12
                        }
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            scaleLabel: {
                                display: true,
                                labelString: 'GWh'
                            }
                        }, {
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            scaleLabel: {
                                display: true,
                                labelString: 'GWh'
                            }
                        }]
                    }
                },
                data: [],
                latest: {
                    data_tma: {
                        datasets: [],
                        labels: []
                    },
                    data_listrik: {
                        datasets: [],
                        labels: []
                    }
                },
                q: '',
                produki_listrik_bars:[
                    'JAN',
                    'FEB',
                    'MAR',
                    'APR',
                    'MEI',
                    'JUN',
                    'JUL',
                    'AUG',
                    'SEP',
                    'OKT',
                    'NOV',
                    'DES',
                ]
            }
        },
        watch:{
            q(){
                this.getData();
            }
        },
        mounted(){
            this.getData();
        },
        methods: {
            getData(){
                this.is_loading = true;
                const token = localStorage.getItem('token');

                let url = this.$endpoints.operasi + 'simulations?token=' + token;
                if(this.q.length > 0){
                    url += '&q=' + this.q;
                }
                
                axios.get(url)
                .then(response => {
                    this.is_loading = false;

                    this.data = response.data.rows;
                    this.currentPage = response.data.rows.current_page;
                    this.rows = response.data.rows.total;
                    this.current_id = response.data.latest.id;
                    this.latest.data_tma = JSON.parse(response.data.latest.data_tma);
                    this.latest.data_listrik = JSON.parse(response.data.latest.data_listrik);
                })
            },
            getResults(page = 1){
                const token = localStorage.getItem('token');
                
                axios.get(this.$endpoints.operasi + 'simulations?token=' + token + '&page=' + page)
                .then(response => {
                    this.is_loading = false;

                    this.data = response.data.rows;
                    this.currentPage = response.data.rows.current_page;
                    this.rows = response.data.rows.total;
                })
            },
            playIt(id){
                this.current_id = id;
                this.is_loading = true;
                const token = localStorage.getItem('token');
                
                axios.get(this.$endpoints.operasi + 'simulations/'+ id +'?token=' + token)
                .then(response => {
                    this.is_loading = false;

                    this.latest.data_tma = JSON.parse(response.data.data_tma);
                    this.latest.data_listrik = JSON.parse(response.data.data_listrik);
                })
            },
            destroy(id){
                if(confirm('delete data?')){
                    this.is_loading = true;
                    const token = localStorage.getItem('token');
                    const fd = this.createFormData({
                        '_method': 'DELETE'
                    });

                    axios.post(this.$endpoints.operasi + 'simulations/' + id + '?token=' + token, fd)
                    .then(() => {
                        this.$swal('Berhasil', 'Berhasil menghapus data', 'success');
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                    })
                }
            },
            generateProduksiListrikTableBar(month){
                const date = new Date();
                const currentMonth = date.getMonth();

                if(month < currentMonth){
                    return this.latest.data_listrik.datasets[0]?.data[month] + ',' + this.latest.data_listrik.datasets[1]?.data[month];
                }

                return this.latest.data_listrik.datasets[0]?.data[month] + ',' + this.latest.data_listrik.datasets[2]?.data[month];
            },
            generateProduksiListrikColorBar(month){
                const date = new Date();
                const currentMonth = date.getMonth();

                if(month < currentMonth){
                    return ['#cc00ff', '#4257f5'];
                }

                return ['#cc00ff', '#fc7e3a'];
            },
            importData(){
                this.is_loading = true;
                const fd = new FormData();
                fd.append('file', this.$refs['file_input'].files[0]);

                axios.post(this.$endpoints.operasi + 'simulations', fd)
                .then(response => {
                    if(response.data.error == 0){
                        this.$swal('Berhasil', response.data.message, 'success');
                        
                        this.getData();
                        this.$refs['form_upload'].reset();
                        this.$bvModal.hide('upload_modal');
                        this.is_loading = false;
                    }
                });
            }
        },
        components:{
            Navbar,
            OperationSidebar,
            Loading, 
            Breadcrumb,
            BarChart,
            LineChart,
            BPagination,
            BModal,
            // Peity
        },
    }

</script>