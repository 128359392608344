import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'

import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap'
import './assets/bootstrap/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/bootstrap-icons/bootstrap-icons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/style/main.css'
import './assets/style/override.css'
import './assets/style/custom.css'
import VueMasonry from 'vue-masonry-css'
import VShowSlide from 'v-show-slide'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import Toasted from 'vue-toasted'
import 'sweetalert2/dist/sweetalert2.min.css'
import './assets/js/main.js'
import './assets/js/vendor/bootstrap/js/bootstrap.bundle.js'/*  */
import axios from 'axios';

// window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false
Vue.prototype.$endpoints = {
	user: (process.env.NODE_ENV === 'development') ? 'https://user.jasatirta2.id/':'https://user.jasatirta2.id/',
	reference: (process.env.NODE_ENV === 'development') ? 'https://reference.jasatirta2.id/':'https://reference.jasatirta2.id/',
	sarpras: (process.env.NODE_ENV === 'development') ? 'https://sarpras.jasatirta2.id/':'https://sarpras.jasatirta2.id/',
	operasi: (process.env.NODE_ENV === 'development') ? 'https://operation.jasatirta2.id/':'https://operation.jasatirta2.id/',
	telemeteri: (process.env.NODE_ENV === 'development') ? 'https://telemetri.jasatirta2.id/':'https://telemetri.jasatirta2.id/',
	spatial: (process.env.NODE_ENV === 'development') ? 'https://spasial.jasatirta2.id/api/':'https://spasial.jasatirta2.id/api/',
	geomoss: (process.env.NODE_ENV === 'development') ? 'https://bendungan.jasatirta2.id/api':'https://bendungan.jasatirta2.id/api',
	scada: (process.env.NODE_ENV === 'development') ? 'https://scada.jasatirta2.id/api':'https://scada.jasatirta2.id/api'
};

Vue.prototype.$refresh_duration = 60000; // auto fetch data every 1 minute

Vue.use(VueGoogleMaps, {
	load:{
		key: 'AIzaSyDdIMFgCkeCb45CXklYYp9j_5jZrTHi6ow',
		libraries: 'places'
	}
});
Vue.use(VueMasonry);
Vue.use(VShowSlide);
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Toasted)
Vue.use(Vuex)

Vue.mixin({
	methods:{
		logout: function(){
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('org_id');
            localStorage.removeItem('name');
            localStorage.removeItem('id');
            localStorage.removeItem('isAuthenticated');
			this.$router.push({path: '/login'});
		},
		createFormData: function(data){
			let fd = new FormData();

			for(var key in data){
				if(data[key] !== null){
					fd.append(key, data[key]);
				}
			}

			return fd;
		},
		doLogin: function(event){
			let fd = new FormData();
			let route_type = localStorage.getItem('router_type')

			// Request Form
			fd.append('username', this.username);
			fd.append('password', this.password);

			// Loading
			this.is_loading = true;

			// API 
			axios.post(this.$endpoints.user+'login', fd,{
				headers:{'Content-type':'multipart/form-data'}
			}).then(response => {
				localStorage.setItem('name', response.data.name);
				localStorage.setItem('id', response.data.id);
				localStorage.setItem('role', response.data.role);
				localStorage.setItem('org_id', response.data.ref_org_id);
				localStorage.setItem('module_access', response.data.module_access);

				// Patokan
				localStorage.setItem('token', response.data.access_token);

				this.$router.push({path:route_type});
			}).catch(() => {
                this.$swal('User atau password yang anda masukan salah');
			});

			event.preventDefault();
        },
        getRole: () => {
            return localStorage.getItem('role')
        }
	},
})

const store = new Vuex.Store({
	state:{
		authenticated: false,
	},
	mutations:{
		setAuthentication(state, status){
			state.authenticated = status
		}
	}
})

new Vue({
	router,
	store: store,
	render: h => h(App),
}).$mount('#app')
