<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <SidebarSarpras />
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/sarpras/dashboard', text: 'Sarpras'}, {url:'#', text: 'Tambah Riwayat Pemeriksaan'}]"/>
                <h2><b>Tambah Riwayat Pemeriksaan</b></h2>
                <div class="document">
                    <form>
                        <div class="form-group mb-3">
                            <div class="d-inline-block me-5">
                                <div class="cool-radio"><input type="radio" name="is_inspection" value="t" v-model="is_inspection" id="rutin"><label for="rutin">Inspeksi Rutin</label></div>
                            </div>
                            <div class="d-inline-block">
                                <div class="cool-radio"><input type="radio" name="is_inspection" value="f" v-model="is_inspection" id="perbaikan"><label for="perbaikan">Perbaikan</label></div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="">Sarpras</label>
                            <Select2 class="vue-select2" :options="sarpras" v-model="infrastructure_id" @change="changeSelectedSarpras($event)" />
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-2">
                                <label for="">Tanggal</label>
                                    <date-range-picker
                                        ref="picker"
                                        opens="right"
                                        v-model="inspected_at"
                                        min-date="0000-00-00"
                                        format="YYYY-MM-DD"
                                        :append-to-body="true"
                                        :showWeekNumbers="true"
                                        :showDropdowns="true"
                                        :singleDatePicker="true"
                                        style="width: 100%"
                                    >
                                        <template #input="picker" style="width: 100%;">
                                            {{ picker.startDate | date }}
                                        </template>
                                    </date-range-picker>
                            </div>
                            <div class="col-md-2">
                                <label for="">Waktu</label>
                                <vue-timepicker input-class="form-control" input-width="100%" style="width: 100%" format="HH:mm" v-model="inspected_at_time"></vue-timepicker>
                            </div>
                        </div>
                        <br>
                        <div class="card">
                            <div class="card-header">Kondisi sarpras</div>
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">Fisik</label>
                                            <div class="input-group">
                                                <input type="text" v-model="sarpras_fisik" class="form-control">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="basic-addon1">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">Fungsi</label>
                                            <div class="input-group">
                                                <input type="text" v-model="sarpras_fungsi" class="form-control">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="basic-addon1">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Rekomendasi</label>
                                            <input type="text" v-model="sarpras_rekomendasi" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Keterangan</label>
                                            <input type="text" v-model="sarpras_deskripsi" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="is_inspection == false">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Diperbaiki oleh</label>
                                            <input type="text" v-model="repaired_by" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="card">
                            <div class="card-header">Komponen Sipil</div>
                            <div class="card-body">
                                <div class="row" v-for="komponen in item.komponen_fungsi" :key="komponen.id">
                                    <div class="col-md-12">
                                        <div class="well">
                                            <h4>{{ komponen.name }}</h4>
                                            <div class="row mb-3">
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="">Nilai</label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" :ref="'value_'+komponen.id">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" id="basic-addon1">%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="">Rekomendasi</label>
                                                <input type="text" class="form-control" :ref="'rekomendasi_'+komponen.id">
                                            </div>
                                            <div class="form-group">
                                                <label for="">Keterangan</label>
                                                <input type="text" class="form-control" :ref="'deskripsi_'+komponen.id">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="card">
                            <div class="card-header">Komponen Mekanikal dan Elektrikal</div>
                            <div class="card-body">
                                <div class="row" v-for="komponen in item.komponen_fisik" :key="komponen.id">
                                    <div class="col-md-12">
                                        <div class="well">
                                            <h4>{{ komponen.name }}</h4>
                                            <div class="row mb-3">
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="">Nilai</label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" :ref="'value_'+komponen.id">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" id="basic-addon1">%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="">Rekomendasi</label>
                                                <input type="text" class="form-control" :ref="'rekomendasi_'+komponen.id">
                                            </div>
                                            <div class="form-group">
                                                <label for="">Keterangan</label>
                                                <input type="text" class="form-control" :ref="'deskripsi_'+komponen.id">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="form-group">
                            <button class="btn btn-primary me-1" type="button" v-on:click="store"><i class="fa fa-save"></i> Save</button>
                            <router-link :to="'/sarpras/infrastructure/detail/' + $route.params.id" class="btn btn-danger"><i class="fa fa-times"></i> Cancel</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from 'axios'
    import Loading from 'vue-loading-overlay';
    import Navbar from '../../../components/Navbar.vue'
    import Breadcrumb from '../../../components/Breadcrumb.vue'
    import SidebarSarpras from '../../../components/SidebarSarpras.vue'
    import Select2 from 'v-select2-component';
    import DateRangePicker from 'vue2-daterange-picker'
    import VueTimepicker from 'vue2-timepicker'
    import moment from 'moment'
    import 'vue-loading-overlay/dist/vue-loading.css';

	export default{
		name:'InspectionAdd',
		data(){
			return{
				breadcrumb: [{name: 'Inspeksi Sarpras', url: '/infrastructure/sarpras/show/'+this.$route.params.id}, {name: 'Tambah'}],
				is_fullpage: true,
				is_loading: false,
                token: null,
                username: null,
                userid: null,
                sarpras: [],
                item: {
                    images: [],
                    inspections: [],
                    komponen_fisik: [],
                    komponen_fungsi: []
                },
				infrastructure_id: null,
				name: '',
				type: '',
				description: '',
				editorOption: {
					modules: {
						toolbar: [
							[{ header: [1, 2, false] }],
							['bold', 'italic', 'underline'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }],
							[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
							[{ 'indent': '-1'}, { 'indent': '+1' }],
							[{ 'color': [] }, { 'background': [] }],
						]
					},
                },
                startDate: '1990-05-30',
                endDate: '2017-09-15',
                locale: {
                    direction: 'ltr', 
                    format: 'DD-MM-YYYY', 
                    separator: ' - ', 
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: moment.weekdaysMin(), 
                    monthNames: moment.monthsShort(), 
                    firstDay: 1 
                },
                inspected_at:{
                    startDate: new Date()
                },
                inspected_at_time: {
                    HH:moment(new Date()).format("HH"),
                    mm: moment(new Date()).format("mm")
                },
                is_inspection: 't',
                sarpras_fisik: 0,
                sarpras_fungsi: 0,
                sarpras_rekomendasi: null,
                sarpras_deskripsi: null,
                repaired_by: null
			}
		},
		mounted(){
            this.token = localStorage.getItem('token');
            this.username = localStorage.getItem('name');
            this.userid = localStorage.getItem('id');
            this.is_loading = true;
            this.infrastructure_id = this.$route.params.id;

			// get sarpras
			axios.get(this.$endpoints.sarpras+'infrastructures?all=1&columns=id,name')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					data.push({
						text: response.data[i].name,
						id: response.data[i].id
					})
				}
				this.sarpras = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
            })
            
            // get current sarpras
            this.getSelectedSarpas(this.$route.params.id);
		},
		components:{
            SidebarSarpras,
			Loading,
            Select2,
            DateRangePicker,
            VueTimepicker,
            Navbar,
            Breadcrumb
		},
		methods:{
            getSelectedSarpas(id){
                axios.get(this.$endpoints.sarpras+'infrastructures/'+id+'?token='+this.token+'&per_page='+this.per_page)
                .then(response =>{
                    var that = this;
                    this.item = response.data

                    for(var i = 0; i < response.data.inspections.length; i++){
                        if(response.data.inspections[i].images.length > 0){
                            this.slides.push(response.data.inspections[i].images.map(function(v){
                                return that.$endpoints.sarpras+'/storage/'+v.filename;
                            }));

                            this.slides = this.slides.flat();

                            console.log(this.slides);
                        }
                    }
                });
            },
            changeSelectedSarpras(val){
                this.getSelectedSarpas(val);
            },
			store(){
                var components = {};
                var inspected_at = moment(this.inspected_at.startDate).format("YYYY-MM-DD"),
                    inspected_at_time = this.inspected_at_time;

                for(let i = 0; i < this.item.komponen_fungsi.length; i++){
                    let id = this.item.komponen_fungsi[i].id;
                    components[id] = {
                        value: this.$refs['value_'+id][0].value,
                        recommendation: this.$refs['rekomendasi_'+id][0].value,
                        description: this.$refs['deskripsi_'+id][0].value,
                    };
                }
                
                for(let i = 0; i < this.item.komponen_fisik.length; i++){
                    let id = this.item.komponen_fisik[i].id;
                    components[id] = {
                        value: this.$refs['value_'+id][0].value,
                        recommendation: this.$refs['rekomendasi_'+id][0].value,
                        description: this.$refs['deskripsi_'+id][0].value,
                    };
                }

                let data = this.createFormData({
                    id: this.$route.params.id,
                    user_id: this.userid,
                    user_name: this.username,
                    fisik: this.sarpras_fisik,
                    fungsi: this.sarpras_fungsi,
                    recommendation: this.sarpras_rekomendasi,
                    description: this.sarpras_deskripsi,
                    inspected_at: inspected_at+" "+inspected_at_time.HH+":"+inspected_at_time.mm,
                    is_inspection: this.is_inspection,
                    repaired_by: this.repaired_by,
                    components: JSON.stringify(components)
                });
                
                axios.post(this.$endpoints.sarpras+'inspections/storefromweb?token='+this.token, data)
                .then(() => {
                    this.is_loading = false;
                    this.$router.push({path:'/sarpras/infrastructure/detail/'+this.$route.params.id});
                }).catch(() => {
                    this.is_loading = false;
                    alert('there was an error while adding data. please contact developer.');
                });
			}
        },
        filters: {
            date(val) 
            {
                return val ? moment(val).format("YYYY-MM-DD") : "";
            }
        }
	}
</script>

<style scoped>
    .cool-radio{
        position: relative;
        padding-left: 25px;
    }

    .cool-radio input{
        display: none;
    }

    .cool-radio input+label:before{
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        border-radius: 100%;
        background: #e6e6e6;
        border: 1px solid #aaa;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 3px;
    }

    .cool-radio input+label:after{
        content: '';
        background: transparent;
        position: absolute;
        left: 3px;
        top: 6px
    }

    .cool-radio input:checked+label:after{
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        background: #509ee3;
    }
</style>