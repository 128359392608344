<template>
    <div id="login-page">
        <header id="header">
            <div class="container d-flex align-items-center">
                <h4 class="logo-landing me-auto">
                    <router-link to="/login">
                        <img src="../../assets/pjt-logo-3.png" alt="" style="width: 200px;">
                    </router-link>
                </h4>
                <nav id="navbar" class="navbar" ref="navbar">
                    <ul>
                        <li><a href="" @click.prevent="showLoginModal">Login</a></li>
                        <li><router-link to="/bantuan"><a>Bantuan</a></router-link></li>
                        <li><a href="app-release-v.1.0.4.apk" class="getstarted" download>Download APK</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle" ref="menu_toggler" @click="toggleMenu"></i>
                </nav>
            </div>
        </header>
        <section id="hero">
            <img src="../../assets/sisda-pjt.png" alt="bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <img src="../../assets/responsive.png" class="img-fluid" alt="responsive">
                    </div>
                    <div class="col-lg-7" style="margin-top: 110px;">
                        <h3 class="text-start text-light">
                            <b>Selamat datang di SISDA SUPER APP!</b>
                        </h3>
                        <p class="text-start">Aplikasi induk untuk seluruh aktivitas pengelolaan sumber daya air Perum Jasa Tirta II</p>
                    </div>
                </div>
            </div>
        </section>
        <main id="main">
            <section id="about">
                <div class="container">
                    <div class="row mb-4">
                        <div class="col-lg-12">
                            <p>
                                SISDA atau Sistem Informasi Sumber Daya Air merupakan sebuah sistem Informasi yang melingkupi segala hal yang terkait dengan pengelolaan sumber daya terintegrasi di lingkungan Perum Jasa Tirta II. SISDA dikembangkan sebagai aplikasi Induk yang menaungi aplikasi dan fungsi utama pengelolaan air lainnya yang lebih spesifik termasuk di dalamnya implementasi teknologi 4.0 dalam pengelolaan sumber daya air.
                            </p>
                            <p>Aplikasi-aplikasi yang dihimpun oleh SISDA antara lain: </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/operation/dashboard')" to="/operation/dashboard" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-operasi.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Operasi</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi pemantauan kegiatan pengelolaan air secara operasional seperti kondisi hidrologi.
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/sarpras/dashboard')" to="/sarpras/dashboard" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-sarpras.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Sarpras</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi pemantauan sarana dan prasarana infrastruktur pengelolaan sumber daya air berikut pemeliharaannya
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/spatial')" to="/spatial" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-map.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Spasial</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi yang menampilkan data spasial yang menggambarkan data-data teknis, dalam bentuk lapisan berbagai macam data pengelolaan sumber daya air.
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/skema')" to="/skema" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-skema.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Skema Distribusi Air</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi yang memvisualisasi distribusi air yang berada di bawah pengelolaan Perum Jasa Tirta II beserta besaran-besaran tertentu di titik-titik yang menjadi pusat perhatian pemantauan utama. 
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <a :href="'http://pm.jasatirta2.id/token_login?token='+this.token" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-monitoring.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Monitoring</b>
                                            </h4>
                                            <div>
                                                Aplikasi pemantauan pekerjaan terkait proyek pengelolaan sumber daya air Perum Jasa Tirta II.
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/telemeteri')" to="/telemeteri" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-telemeteri.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Telemeteri</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi pemantauan kondisi hidrologi terkait dengan peringatan dini banjir, peringatan dini suplai air baku, dan peringatan dini suplai air untuk irigasi yang dilakukan oleh perangkat telemetri automatis
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/cctv')" to="/cctv" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-cctv.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>CCTV</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi yang dapat melakukan pemantauan CCTV secara Realtime. 
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/plta')" to="/plta" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-plta.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>PLTA</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi yang dapat menampilkan informasi berupa inforasi sekitar PLTA. 
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/bendungan')" to="/bendungan" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-bendungan.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Bendungan</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi yang dapat melakukan monitoring terkait bendungan yang aktif. 
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-4 d-table-cell">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="text-center">
                                        <router-link @click.native="showModal('/bmkg')" to="/bmkg" style="text-decoration: none; color: inherit">
                                            <img src="../../assets/logo-bmkg.png" width="80" class="mb-3" alt=""><br>
                                            <h4>
                                                <b>Cuaca BMKG</b>
                                            </h4>
                                            <div class="mt-3">
                                                Aplikasi yang dapat menampilkan kondisi cuaca yang ada di indonesia secara realtime. 
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <b-modal id="modal-1" title="Login" ref="login_modal" :hide-footer="true">
            <form class="row g-3 needs-validation" novalidate v-on:submit="doLogin" autocomplete="off">
                <div class="form-floating">
                    <input type="text" class="form-control" placeholder="Username / NIK" v-model="username">
                    <label for="">Username / NIK</label>
                </div>
                <div class="form-floating">
                    <input type="password" class="form-control" placeholder="Password" v-model="password">
                    <label for="">Password</label>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary w-100" type="submit">Masuk</button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    // import axios from 'axios';
    // import Loading from 'vue-loading-overlay';
    import {BModal} from 'bootstrap-vue'
    import '../../assets/style/landing/style.css';

    export default{
        name: 'Login',
        data(){
            return{
                is_loading: false,
                is_fullpage: true,
                username:'',
                password:'',
                error: false,
                token: null,
                publicPath: process.env.BASE_URL
            };
        },
        mounted(){
            this.token = localStorage.getItem('token') != null
        },
        beforeCreate: function() {
            document.body.className = 'home';
        },
        methods:{
            toggleMenu(){
                this.$refs.navbar.classList.toggle('navbar-mobile');
                this.$refs.menu_toggler.classList.toggle('bi-list')
                this.$refs.menu_toggler.classList.toggle('bi-x')
            },
            scrollToSection(selector){
                document.querySelector(selector).scrollIntoView();
            },
            showLoginModal(){
                this.$refs.login_modal.show();
                localStorage.setItem('router_type', '/dashboard');
            },
            clickedDownload(){
                const link = document.createElement('a');
                link.href = 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png';
                link.setAttribute('download', 'file.png'); //or any other extension
                document.body.appendChild(link);
                link.click();
            },
            showModal(type){
                if(this.token != null){
                    this.$refs.login_modal.show();
                }

                localStorage.setItem('router_type', type);
            }
        },
        components:{
            BModal
        }
    }
</script>

<style scoped>
    @import '../../assets/style/landing/style.css';

    #main{
        margin-left: 0!important;
    }

    .btn-primary{
        background-color: #509ee3;
    }

    .card{
        opacity: 1!important;
        border-radius: 20px;
    }

    .form-control{
        border-radius: 10px;
    }

    /* .logo img{
        filter: invert(1);
    } */

    .form-floating > label{
        left: 8px;
    }
    
</style>