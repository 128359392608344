<template>
  <ul class="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified">
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/sarpras/jenis/index" aria-selected="true">Jenis</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/sarpras/subjenis/index" aria-selected="false">Subjenis</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/sarpras/kategori/index" aria-selected="false">Kategori</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/sarpras/lokasi/index" aria-selected="false">Lokasi</router-link>
        </li>
        <li class="nav-item flex-fill">
            <router-link class="nav-link w-100" active-class="active" to="/sarpras/komponen/index" aria-selected="false">Komponen</router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'TabsSarpras',
    }
</script>

<style scoped>
.dashboard .filter{
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .nav-tabs-bordered .nav-link.active{
    background-color: #fff;
    color: #509ee3;
    border-bottom: 2px
    solid #509ee3;
  }
</style>
