<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">

        <li class="nav-item">
            <router-link class="nav-link" :to="route">
                <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Overview</span>
            </router-link>
        </li>
        
        </ul>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarSpatial',
        data(){
            return{
                route: ''
            }
        },
        mounted(){
            this.route = this.$route.path
            document.body.classList.remove('toggle-sidebar')
        }
    }
</script>