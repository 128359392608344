<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarSpatial/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <Breadcrumb :links="links"/>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div v-if="is_loading == false" class="d-inline" style="margin-bottom: 40px;">
                <h5>
                    <strong>{{ this.location_name }}</strong>
                    <router-link :to="'/telemeteri/edit/' + defaultLocation.id" class="btn btn-primary btn-xs ms-2 py-1" v-if="getRole() !== 'guest'"><small><i class="fa fa-edit"></i> Edit</small></router-link> 
                </h5>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body" style="border-top: 10px solid #509ee3">
                            <b>Normal</b>
                            <span style="display: block; font-size: 64px; font-weight:bold">{{ normal_n }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body" style="border-top: 10px solid #fff200">
                            <b>Siap</b>
                            <span style="display: block; font-size: 64px; font-weight:bold">{{ siap_n }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body" style="border-top: 10px solid #f26c4f">
                            <b>Siaga</b>
                            <span style="display: block; font-size: 64px; font-weight:bold">{{ siaga_n }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body" style="border-top: 10px solid #e93a3a">
                            <b>Awas</b>
                            <span style="display: block; font-size: 64px; font-weight:bold">{{ awas_n }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ========== AWLR DATA ========== -->
            <div v-if="defaultLocation._group == 'awlr' || defaultLocation._group == 'aws'" id="awlr" style="margin-top: 10px;">
                <!-- ===== ROW 1 ===== -->
                <div class="row">
                    <!-- ===== MAPS ===== -->
                    <div class="col-12 col-lg-8">
                        <div class="card">
                            <div class="card-body" style="height: 920px; position: relative;">
                                <div class="row mb-2">
                                    <div class="col-md-4 offset-md-8">
                                        <Select2 class="vue-select2" :options="types" v-model="type_id" @change="toggleMarker()" :settings="{width: '100%'}"></Select2>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <GmapMap
                                    :center="{lat:this.lat, lng:this.lng}"
                                    :zoom="9"
                                    map-type-id="roadmap"
                                    style="width: 100%; height: calc(100% - 50px)"
                                    ref="peta">
                                        <GmapMarker
                                            v-for="marker in filtered_locations"
                                            :key="marker.id"
                                            :icon="{url:defineIcon()}"
                                            :clickable="true"
                                            :position="{lat: parseFloat(marker.lat), lng:parseFloat(marker.lng)}"
                                            :title="defineTitle(marker)"
                                            @click="changeLocation(marker.id)"
                                        >
                                        </GmapMarker>
                                </GmapMap>
                                <MapLegend></MapLegend>
                            </div>
                        </div>
                    </div>

                    <!-- =====  ===== -->
                    <div class="col-12 col-lg-4">
                        <!-- Row 1 -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div v-if="image">
                                        <b-carousel
                                                id="carousel-1"
                                                v-model="slide"
                                                :interval="4000"
                                                controls
                                                indicators
                                                background="#ababab"
                                                img-width="100%"
                                                img-height="100%"
                                                style="text-shadow: 1px 1px 2px #333;"
                                                @sliding-start="onSlideStart"
                                                @sliding-end="onSlideEnd"
                                            >
                                        
                                            <!-- Slides with custom text -->
                                            <b-carousel-slide v-for="picture in image"  :key="picture.id"   
                                                :caption="picture.ts"
                                                :img-src="(defaultLocation.source == 'kenda' ? 'https://sisda.jasatirta2.co.id/kenda':'https://telemetri.jasatirta2.id/storage')+'/' + picture.picture">
                                            </b-carousel-slide>
                                        </b-carousel>
                                    </div>
                                    <div v-else>
                                        <b-carousel
                                                id="carousel-1"
                                                v-model="slide"
                                                :interval="4000"
                                                controls
                                                indicators
                                                background="#ababab"
                                                img-width="100%"
                                                img-height="100%"
                                                style="text-shadow: 1px 1px 2px #333;"
                                                @sliding-start="onSlideStart"
                                                @sliding-end="onSlideEnd"
                                            >
                                        
                                            <!-- Slides with custom text -->
                                            <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
                                                <p>
                                                   Belum ada gambar tangkapan CCTV yang tersedia.
                                                </p>
                                            </b-carousel-slide>
                                        </b-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Row 2 -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body" style="height: 450px; overflow: scroll;">
                                        <p class="card-title">Lokasi Pengukuran</p>
                                        <form action="">
                                            <div class="form-group"><input type="text" class="form-control" placeholder="cari lokasi" v-model="q_location"></div>
                                        </form>
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Nama</th>
                                                    <th scope="col">Level Siap</th>
                                                    <th scope="col">Level Siaga</th>
                                                    <th scope="col">Level Awas</th>
                                                    <th scope="col">Jenis</th>
                                                    <th scope="col">Sumber</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(locations, wilayah) in filtered_group_locations" :key="wilayah">
                                                <tr>
                                                    <th colspan="7">{{ wilayah == 'null' ? '#belum dikelompokan#':wilayah }}</th>
                                                </tr>
                                            
                                                <tr v-for="(location, index) in locations" :key="location.id" v-on:click="changeLocation(location.id)" v-bind:index="index" style="cursor: pointer;">
                                                    <td scope="row">{{ location.id }}</td>
                                                    <td scope="row">{{ location.name }}</td>
                                                    <td>{{ location.level_siap}}</td>
                                                    <td>{{ location.level_siaga}}</td>
                                                    <td>{{ location.level_awas}}</td>
                                                    <td>{{ location._group}}</td>
                                                    <td>{{ location.source}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ===== ROW 1 ===== -->
                <!-- Row 3 -->
                <div class="row mt-4">
                    <!-- Blok 1 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Per 10 menit</div>
                            <div class="card-body">
                                <line-chart :chart-data="charts_1" :options="chart_options"></line-chart>
                            </div>
                        </div>
                    </div>

                    <!-- Blok 2 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Per 1 jam</div>
                            <div class="card-body">
                                <line-chart :chart-data="charts_2" :options="chart_options"></line-chart>
                            </div>
                        </div>
                    </div>

                    <!-- Blo 3 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Terbaru</div>
                            <div class="card-body" style="overflow: auto;">
                                <table class="table table-hover">
                                    <thead color="light" style="cursor: pointer;">
                                        <tr style="cursor: pointer;">
                                            <th scope="col">Waktu Pencatatan</th>
                                            <th scope="col">Level (m)</th>
                                            <th scope="col" v-if="defaultLocation.q_formula">Debit (m3/detik)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, index) in lokasi_pengukuran" :key="row.id" v-on:click="changeInputData(row.id)" v-bind:index="index" style="cursor: pointer;">
                                            <td scope="row">{{ row.timestamps }}</td>
                                            <td scope="row">{{ row.value }}</td>
                                            <td scope="row" v-if="defaultLocation.q_formula">{{ row.debit }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ========== END OF AWLR DATA ========== -->

            <!-- ========== ARR DATA ========== -->
            <div v-else-if="defaultLocation._group == 'arr'" id="arr" style="margin-top: 10px;">
                <!-- ===== ROW 1 ===== -->
                <div class="row">
                    <!-- ===== MAPS ===== -->
                    <div class="col-12 col-lg-8">
                        <div class="card">
                            <div class="card-body" style="height: 830px; position: relative;">
                                <div class="row mb-2">
                                    <div class="col-md-4 offset-md-8">
                                        <Select2 class="vue-select2" :options="types" v-model="type_id" @change="toggleMarker()" :settings="{width: '100%'}"></Select2>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <GmapMap
                                    :center="{lat:this.lat, lng:this.lng}"
                                    :zoom="9"
                                    map-type-id="roadmap"
                                    style="width: 100%; height: 100%"
                                    ref="peta">
                                        <GmapMarker
                                            v-for="marker in filtered_locations"
                                            :key="marker.id"
                                            :icon="{url:defineIcon()}"
                                            :clickable="true"
                                            :title="defineTitle(marker)"
                                            :position="{lat: parseFloat(marker.lat), lng:parseFloat(marker.lng)}"
                                            @click="changeLocation(marker.id)"
                                        >
                                        </GmapMarker>
                                </GmapMap>
                                <MapLegend></MapLegend>
                            </div>
                        </div>
                    </div>

                    <!-- =====  ===== -->
                    <div class="col-12 col-lg-4">
                        <!-- Row 1 -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card" style="height: 350px;">
                                    <div class="card-body" style="overflow: auto;">
                                        <table class="table table-hover">
                                            <thead color="light" style="cursor: pointer;">
                                                <tr style="cursor: pointer;">
                                                    <th scope="col">Waktu Pencatatan</th>
                                                    <th scope="col">Curah Hujan (mm)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row, index) in lokasi_pengukuran" :key="row.id" v-on:click="changeInputData(row.id)" v-bind:index="index" style="cursor: pointer;">
                                                    <td scope="row">{{ row.timestamps }}</td>
                                                    <td scope="row">{{ row.value }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Row 2 -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body" style="height: 450px; overflow: scroll;">
                                        <p class="card-title">Lokasi Pengukuran</p>
                                        <form action="">
                                            <div class="form-group"><input type="text" class="form-control" placeholder="cari lokasi" v-model="q_location"></div>
                                        </form>
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Nama</th>
                                                    <th scope="col">Level Siap</th>
                                                    <th scope="col">Level Siaga</th>
                                                    <th scope="col">Level Awas</th>
                                                    <th scope="col">Jenis</th>
                                                    <th scope="col">Sumber</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(locations, wilayah) in filtered_group_locations" :key="wilayah">
                                                <tr>
                                                    <th colspan="7">{{ wilayah == 'null' ? '#belum dikelompokan#':wilayah }}</th>
                                                </tr>
                                            
                                                <tr v-for="(location, index) in locations" :key="location.id" v-on:click="changeLocation(location.id)" v-bind:index="index" style="cursor: pointer;">
                                                    <td scope="row">{{ location.id }}</td>
                                                    <td scope="row">{{ location.name }}</td>
                                                    <td>{{ location.level_siap}}</td>
                                                    <td>{{ location.level_siaga}}</td>
                                                    <td>{{ location.level_awas}}</td>
                                                    <td>{{ location._group}}</td>
                                                    <td>{{ location.source}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ===== ROW 1 ===== -->
                <!-- Row 3 -->
                <div class="row mt-4">
                    <!-- Blok 1 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Harian</div>
                            <div class="card-body">
                                <bar-chart :chart-data="charts_1" :options="chart_options"></bar-chart>
                            </div>
                        </div>
                    </div>

                    <!-- Blok 2 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Mingguan</div>
                            <div class="card-body">
                                <bar-chart :chart-data="charts_2" :options="chart_options"></bar-chart>
                            </div>
                        </div>
                    </div>

                    <!-- Blok 3 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Bulanan</div>
                            <div class="card-body">
                                <bar-chart :chart-data="charts_3" :options="chart_options"></bar-chart>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- ========== END OF ARR DATA ========== -->

            <!-- ========== WQS DATA ========== -->
            <div v-else-if="defaultLocation._group == 'wqs'" id="wqs" style="margin-top: 10px;">
                <!-- ===== ROW 1 ===== -->
                <div class="row">
                    <!-- ===== MAPS ===== -->
                    <div class="col-8 col-lg-8">
                        <div class="card">
                            <div class="card-body" style="height: 830px; position: relative;">
                                <div class="row mb-2">
                                    <div class="col-md-4 offset-md-8">
                                        <Select2 class="vue-select2" :options="types" v-model="type_id" @change="toggleMarker()" :settings="{width: '100%'}"></Select2>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <GmapMap
                                    :center="{lat:this.lat, lng:this.lng}"
                                    :zoom="9"
                                    map-type-id="roadmap"
                                    style="width: 100%; height: 100%"
                                    ref="peta">
                                        <GmapMarker
                                            v-for="marker in filtered_locations"
                                            :key="marker.id"
                                            :icon="{url:defineIcon()}"
                                            :clickable="true"
                                            :title="defineTitle(marker)"
                                            :position="{lat: parseFloat(marker.lat), lng:parseFloat(marker.lng)}"
                                            @click="changeLocation(marker.id)"
                                        >
                                        </GmapMarker>
                                </GmapMap>
                                <MapLegend></MapLegend>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-lg-4">
                        <div class="card">
                            <div class="card-body" style="height: 830px; overflow: scroll;">
                                <p class="card-title">Lokasi Pengukuran</p>
                                <form action="">
                                    <div class="form-group"><input type="text" class="form-control" placeholder="cari lokasi" v-model="q_location"></div>
                                </form>
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Nama</th>
                                            <th scope="col">Level Siap</th>
                                            <th scope="col">Level Siaga</th>
                                            <th scope="col">Level Awas</th>
                                            <th scope="col">Jenis</th>
                                            <th scope="col">Sumber</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(locations, wilayah) in filtered_group_locations" :key="wilayah">
                                        <tr>
                                            <th colspan="7">{{ wilayah == 'null' ? '#belum dikelompokan#':wilayah }}</th>
                                        </tr>
                                    
                                        <tr v-for="(location, index) in locations" :key="location.id" v-on:click="changeLocation(location.id)" v-bind:index="index" style="cursor: pointer;">
                                            <td scope="row">{{ location.id }}</td>
                                            <td scope="row">{{ location.name }}</td>
                                            <td>{{ location.level_siap}}</td>
                                            <td>{{ location.level_siaga}}</td>
                                            <td>{{ location.level_awas}}</td>
                                            <td>{{ location._group}}</td>
                                            <td>{{ location.source}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ===== ROW 2 ===== -->
                <div class="row">
                    <!-- ===== MAPS ===== -->
                    <div class="col-12 col-lg-12">
                        <div class="card" style="height: 350px;">
                            <div class="card-body" style="overflow: auto;">
                                <table class="table table-hover">
                                    <thead color="light" style="cursor: pointer;">
                                        <tr style="cursor: pointer;">
                                            <th scope="col">Waktu pencatatan</th>
                                            <th scope="col">PH</th>
                                            <th scope="col">Nitrat</th>
                                            <th scope="col">Condv. ACT</th>
                                            <th scope="col">Condv. SPCV</th>
                                            <th scope="col">Salinitas</th>
                                            <th scope="col">TDS Terlarut</th>
                                            <th scope="col">Resistivitas</th>
                                            <th scope="col">Densitas</th>
                                            <th scope="col">Kons. RDO</th>
                                            <th scope="col">satRDO</th>
                                            <th scope="col">ORP</th>
                                            <th scope="col">Suhu Insitu 1</th>
                                            <th scope="col">Nitrat mV</th>
                                            <th scope="col">Kekeruhan</th>
                                            <th scope="col">TDS Tergencat</th>
                                            <th scope="col">Amonium</th>
                                            <th scope="col">Amonium mV</th>
                                            <th scope="col">Amonia</th>
                                            <th scope="col">Total Amonia</th>
                                            <th scope="col">Suhu Insitu 2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, index) in lokasi_pengukuran" :key="row.id" v-on:click="changeInputData(row.id)" v-bind:index="index" style="cursor: pointer;">
                                            <td scope="row">{{ row.timestamps }}</td>
                                            <td scope="row">{{ row.ph }}</td>
                                            <td scope="row">{{ row.nitrat }}</td>
                                            <td scope="row">{{ row.cndv_act }}</td>
                                            <td scope="row">{{ row.cndv_spc }}</td>
                                            <td scope="row">{{ row.salinity }}</td>
                                            <td scope="row">{{ row.tds }}</td>
                                            <td scope="row">{{ row.resistivity }}</td>
                                            <td scope="row">{{ row.density }}</td>
                                            <td scope="row">{{ row.kons_rdo }}</td>
                                            <td scope="row">{{ row.sat_rdo }}</td>
                                            <td scope="row">{{ row.orp }}</td>
                                            <td scope="row">{{ row.suhu_insitu_1 }}</td>
                                            <td scope="row">{{ row.nitrat_mv }}</td>
                                            <td scope="row">{{ row.kekeruhan }}</td>
                                            <td scope="row">{{ row.tds_tergencat }}</td>
                                            <td scope="row">{{ row.amonium }}</td>
                                            <td scope="row">{{ row.amonium_mv }}</td>
                                            <td scope="row">{{ row.amonia }}</td>
                                            <td scope="row">{{ row.amonia_total }}</td>
                                            <td scope="row">{{ row.suhu_insitu_2 }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ===== ROW 1 ===== -->
                <div class="row">
                    <!-- Dropdown -->
                    <div class="col-sm-2">
                        <div class="btn-group" style="width: 100%">
                            <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih Data</button>
                            <span class="sr-only">Toggle Dropdown</span>
                            <div class="dropdown-menu" style="width: 100%">
                                <div class="container">
                                    <div class="form-group" v-for="(wqs, index) in wqs_variable" :key="wqs.id" v-bind:index="index">
                                        <div class="form-check">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                :value="wqs" 
                                                :id="wqs"
                                                :checked="default_wqs.indexOf(wqs) > -1 ? 'checked':''"
                                                ref="checkbox_periodic"
                                                @click="setVariable()">
                                            <label class="form-check-label" :for="wqs">
                                                {{ wqs }}
                                            </label>
                                        </div>
                                    </div>
                                    <button class="btn btn-info" @click="changeGraph()" style="width: 100%">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Row 3 -->
                <div class="row mt-4">
                    <!-- Blok 1 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Harian</div>
                            <div class="card-body">
                                <line-chart :chart-data="charts_1" :options="chart_options"></line-chart>
                            </div>
                        </div>
                    </div>

                    <!-- Blok 2 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Mingguan</div>
                            <div class="card-body">
                                <line-chart :chart-data="charts_2" :options="chart_options"></line-chart>
                            </div>
                        </div>
                    </div>

                    <!-- Blok 3 -->
                    <div class="col-md-4">
                        <div class="card" style="height: 500px;">
                            <div class="card-header">Data Grafik Bulanan</div>
                            <div class="card-body">
                                <line-chart :chart-data="charts_3" :options="chart_options"></line-chart>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- ========== END OF WQS DATA ========== -->
            

        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    import LineChart from '../../components/chart/LineChart.vue'
    import BarChart from '../../components/chart/BarChart.vue'
    import MapLegend from '../../components/MapLegend.vue'

    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarSpatial from '../../components/SidebarTelemeteri.vue'
    // import GmapCluster from 'vue2-google-maps/src/components/cluster'
    import $ from 'jquery'
    import moment from 'moment'
    import Select2 from 'v-select2-component';
    
    export default{
        components:{
            Navbar,
            SidebarSpatial,
            Loading, 
            Breadcrumb,
            LineChart,
            BarChart,
            // GmapCluster,
            MapLegend,
            Select2
            // SweetAlert
        },
        data(){
            return{
                name: 'Struktural',

                // Variable
				is_fullpage: true,
				is_loading: false,
                is_chart_harian: false,
                is_chart_mingguan: false,
                is_chart_bulanan: false,
				token: null,

                // Datatable
                markers: [],
                lat: -6.5341128, 
                lng: 107.3428813,
                labels: [], 
                usedInt: [],

                // Item Component
                // itemComponent: Item,
                items: [],
                location_name: '',
                location_id: '',
                locations: [],
                filtered_locations: [],

                // Slide
                slide: 0,
                sliding: null,
                image: [],
                // chart_options: {},
                chart_options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                maxRotation: 0,
                                fontSize: 10
                            }
                        }],
                        yAxes: [{
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                        }]
                    },
                    legend: {
                        labels: {
                            boxWidth: 12
                        }
                    }
                },

                // Chart
                charts_1: {
                    labels: [],
                    datasets: []
                },
                charts_2: {
                    labels: [],
                    datasets: []
                },
                charts_3: {
                    labels: [],
                    datasets: []
                },

                // AwlrData
                lokasi_pengukuran: [],
                defaultLocation: [],
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/telemeteri', text: 'Telemeteri'}, 
                ],
                selected_marker_id: 3,

                wqs_daily: [],
                wqs_weekly: [],
                wqs_monthly: [],

                // default_wqs: [16,17,18,19],
                default_wqs: ['ph','tds','kekeruhan','orp'],
                wqs_variable: [
                    'nitrat',
                    'cndv_act',
                    'cndv_spc',
                    'salinity',
                    'resistivity',
                    'density',
                    'kons_rdo',
                    'sat_rdo',
                    'suhu_insitu_1',
                    'nitrat_mv',
                    'tds_tergencat',
                    'amonium',
                    'amonium_mv',
                    'amonia',
                    'amonia_total',
                    'suhu_insitu_2',
                    'ph',
                    'tds',
                    'kekeruhan',
                    'orp',
                ],

                colors: [
                    "#c0f", 
                    "#4257f5", 
                    "#fc7e3a", 
                    "#03ff39", 
                    "#03fff7", 
                    "#648265", 
                    "#eb1f00", 
                    "#42f5b3", 
                    "#d42a6b", 
                    "#a39e00",
                    "#ff87db",
                    "#fa698b",
                    "#8776de",
                    "#619fcf",
                    "#30cf9f",
                    "#86e397",
                    "#81db12",
                    "#db8b12",
                    "#a12506",
                    "#1c034d",
                    "#000000",
                    "#0e0887",
                    "#0f5d61",
                    "#0f5237",
                    "#13520f",
                    "#818712",
                    "#872512",
                    "#875012",
                    "#5e0711",
                    "#4f2f32",
                    "#00ffd5",
                    "#d9ff00",
                    "#2f8700",
                ],
                // search_locations: [],
                q_location: '',
                types: [
                    {text: 'SEMUA', id: 'all'},
                    {text: 'Tinggi Muka Air (AWLR)', id: 'awlr'},
                    {text: 'Curah Hujan (ARR)', id: 'arr'},
                    {text: 'Kualitas Air (WQS)', id: 'wqs'},
                    {text: 'Cuaca (AWS)', id: 'aws'}
                ],
                grouped_locations: {},
                type_id: 'all',
                normal_n: 0,
                siap_n: 0,
                siaga_n: 0,
                awas_n: 0
            }
        },
        // updated(){
        //     this.zoomToSelected();
        // },
        mounted(){
            this.is_loading = true;
            this.token = localStorage.getItem('token');
            document.body.classList.add('toggle-sidebar')

            $('.dropdown-menu').click(function(event){
                event.stopPropagation();
            })

            // init data
            axios.get(this.$endpoints.telemeteri + 'stations/data?token=' + this.token)
            .then((response) => {
                // this.image = response.data.image
                this.charts_1 = response.data.latest_data;
                this.charts_2 = response.data.hourly;
                this.locations = response.data.locations;
                // this.search_locations = response.data.locations;
                this.defaultLocation = response.data.default_location;
                this.location_name = response.data.default_location.name;
                this.lokasi_pengukuran = response.data.awlrData;

                for(let i = 0; i < response.data.default_location.data.length; i++){
                    if(response.data.default_location.data[i].picture !== null){
                        this.image.push({
                            id: response.data.default_location.data[i].id,
                            picture: response.data.default_location.data[i].picture,
                            ts: response.data.default_location.data[i].timestamps
                        });
                    }
                }

                // filter marker hitam jangan ditampilkan. lokasi yang sudah lama tidak update
                // this.locations = this.locations.filter(location => {
                //     let latest_input_ts = moment(location.latest_input_ts);
                //     let today = moment();
                //     let diff = today.diff(latest_input_ts, 'hours');

                //     return (diff < 24 && location.latest_input_ts != null);
                // });

                this.locations.forEach(location => {
                    if(!(location.wilayah in this.grouped_locations)){
                        this.grouped_locations[location.wilayah] = [];
                    }

                    this.grouped_locations[location.wilayah].push(location);
                });
                
                

                this.filtered_locations = this.locations;

                for(let i = 0; i < this.locations.length; i++){
                    if(this.locations[i].level_siap && this.locations[i].level_siaga && this.locations[i].level_awas){
                        if(parseFloat(this.locations[i].value) >= parseFloat(this.locations[i].level_siap) && parseFloat(this.locations[i].value) < parseFloat(this.locations[i].level_siaga)){
                            this.siap_n++;
                        }else if(parseFloat(this.locations[i].value) >= parseFloat(this.locations[i].level_siaga) && parseFloat(this.locations[i].value) < parseFloat(this.locations[i].level_awas)){
                            this.siaga_n++;
                        }else if(parseFloat(this.locations[i].value) >= parseFloat(this.locations[i].level_awas)){
                            this.awas_n++;
                        }else{
                            this.normal_n++;
                        }
                    }else{
                        this.normal_n++;
                    }
                }

                // set zoom to selected location

                this.zoomToSelected();
                this.is_loading = false;
            })
            .catch((e) => {
                console.log(e);
            })

        },
        computed: {
            filtered_group_locations(){
                if(this.q_location.length === 0){
                    return this.grouped_locations;
                }

                let locations = {};

                for(let wilayah in this.grouped_locations){
                    this.grouped_locations[wilayah].forEach(loc => {
                        if(loc.name.toLowerCase().indexOf(this.q_location.toLowerCase()) > -1){
                            if(!(wilayah in locations)){
                                locations[wilayah] = [];
                            }

                            locations[wilayah].push(loc); 
                        }
                    })
                }

                return locations;
            }
        },
        methods:{
            defineIcon()
            {
                return require('../../assets/marker/icon-awlr-biru.png');
            },
            zoomToSelected(){
                this.$nextTick(() => {
                    this.$refs.peta.$mapPromise.then((map) => {
                        map.panTo({lat: parseFloat(this.defaultLocation.lat), lng: parseFloat(this.defaultLocation.lng)});
                        map.setZoom(12);
                    });
                });
            },
            defineTitle(marker){
                let latest_input_ts = moment(marker.latest_input_ts);
                let today = moment();
                let diff = today.diff(latest_input_ts, 'hours');
                let title = 'sisda-marker-';
                // title += marker._group;
                // title += '-';
                title += marker.name;
                title += (marker.id == this.selected_marker_id ? '-active':'');

                if(diff > 24 || marker.latest_input_ts == null){
                    title += '-idle';
                }else{
                    if(marker.level_siap && marker.level_siaga && marker.level_awas){
                        if(parseFloat(marker.value) >= parseFloat(marker.level_siap) && parseFloat(marker.value) < parseFloat(marker.level_siaga)){
                            title += '-l1';
                        }else if(parseFloat(marker.value) >= parseFloat(marker.level_siaga) && parseFloat(marker.value) < parseFloat(marker.level_awas)){
                            title += '-l2';
                        }else if(parseFloat(marker.value) >= parseFloat(marker.level_awas)){
                            title += '-l3';
                        }
                    }
                }

                return title;
            },
            setVariable(){
                let variable = [];
                this.$refs.checkbox_periodic.forEach((cb) => {
                    if(cb.checked){
                        variable.push(cb.value);
                    }
                });

                this.default_wqs = variable
            },
            changeLocation(id)
            {
                this.is_loading = true;
                this.image = []
                this.selected_marker_id = id;

                axios.get(this.$endpoints.telemeteri + 'stations/' + id)
                .then((response) => {
                    this.defaultLocation = response.data.location;
                    this.location_name = response.data.location.name;

                    if(response.data.location._group == 'awlr' || response.data.location._group == 'aws'){
                        this.lokasi_pengukuran = response.data.awlrData;
                        this.defaultLocation = response.data.location;
                        this.location_name = response.data.location.name;

                        for(let i = 0; i < response.data.location.data.length; i++){
                            if(response.data.location.data[i].picture !== null){
                                this.image.push({
                                    id: response.data.location.data[i].id,
                                    picture: response.data.location.data[i].picture,
                                    ts: response.data.location.data[i].timestamps
                                });
                            }
                        }

                        this.charts_1 = response.data.latest_data;
                        this.charts_2 = response.data.hourly;

                    }else if(response.data.location._group == 'arr'){
                        this.lokasi_pengukuran = response.data.arrData;
                        this.defaultLocation = response.data.location;
                        this.location_name = response.data.location.name;
                        this.charts_1 = response.data.daily;
                        this.charts_2 = response.data.weekly;
                        this.charts_3 = response.data.monthly;
                    }else{
                        this.wqs_daily = response.data.daily;
                        this.wqs_weekly = response.data.weekly;
                        this.wqs_monthly = response.data.monthly;
                        this.charts_1 = {
                            labels: response.data.daily.labels,
                            datasets: response.data.daily.datasets.filter((v) => this.default_wqs.indexOf(v.label2) > -1)
                        };
                        this.charts_2 = {
                            labels: response.data.weekly.labels,
                            datasets: response.data.weekly.datasets.filter((v) => this.default_wqs.indexOf(v.label2) > -1)
                        };
                        this.charts_3 = {
                            labels: response.data.monthly.labels,
                            datasets: response.data.monthly.datasets.filter((v) => this.default_wqs.indexOf(v.label2) > -1)
                        };
                    }

                    // change chart option if necessary
                    if(response.data.location.q_formula != null){
                        this.chart_options = {
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 6,
                                        maxRotation: 0,
                                        fontSize: 10
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    position: "left",
                                    id: "y-axis-1",
                                    scaleLabel: {
                                        display: true,
                                        labelString: "meter"
                                    }
                                }, {
                                    display: true,
                                    position: "right",
                                    id: "y-axis-2",
                                    scaleLabel: {
                                        display: true,
                                        labelString: "m3/detik"
                                    }
                                }]
                            },
                            legend: {
                                labels: {
                                    boxWidth: 12
                                }
                            }
                        }
                    }else{
                        this.chart_options = {
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 6,
                                        maxRotation: 0,
                                        fontSize: 10
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    position: "left",
                                    id: "y-axis-1",
                                }]
                            },
                            legend: {
                                labels: {
                                    boxWidth: 12
                                }
                            }
                        }
                    }

                    this.zoomToSelected();
                    this.is_loading = false;
                });

            },
            changeGraph()
            {

                this.charts_1 = {
                    labels: this.wqs_daily.labels,
                    datasets: this.wqs_daily.datasets.filter((v) => this.default_wqs.indexOf(v.label2) > -1)
                };
                this.charts_2 = {
                    labels: this.wqs_weekly.labels,
                    datasets: this.wqs_weekly.datasets.filter((v) => this.default_wqs.indexOf(v.label2) > -1)
                };
                this.charts_3 = {
                    labels: this.wqs_monthly.labels,
                    datasets: this.wqs_monthly.datasets.filter((v) => this.default_wqs.indexOf(v.label2) > -1)
                };
            },
            toggleMarker(){
                if(this.type_id != 'all'){
                    this.filtered_locations = this.locations.filter((location) => (location.id == this.selected_marker_id || location._group == this.type_id));
                    this.locations = this.locations.filter((location) => location._group == this.type_id);
                }else{
                    this.filtered_locations = this.locations;
                    // this.locations = this.locations;
                }
            },
            getFixed(number)
            {
                return parseFloat(number).toFixed(2)
            },
            onSlideStart() 
            {
                this.sliding = true
            },
            onSlideEnd() 
            {
                this.sliding = false
            },
            findLocations(){
                // this.search_locations = this.locations.filter((loc) => loc.name.toLowerCase().indexOf(this.q_location.toLowerCase()) > -1);
            }
        }
    }

</script>

<style>
    @keyframes pulsate {
        from {
            transform: scale(0.25);
            opacity: 1.0;
        }
        95% {
            transform: scale(1.3);
            opacity: 0;
        }
        to {
            transform: scale(0.3);
            opacity: 0;
        }
    }
    
    #spatial-legend{
        position: absolute;
        bottom: 10px;
        right: 80px;
        background: rgba(255, 255, 255, .8)
    }

    #inner-legend .list-group-item{
        background: transparent;
        padding: 5px 0;
    }

    .dashboard .filter{
        position: absolute;
        left: 0px;
        top: 15px;
    }

    .unselected{
        opacity: .5;
    }

    .selected{
        opacity: 1;
    }

    .nav-tabs-bordered .nav-link.active{
        background-color: #fff;
        color: #509ee3;
        border-bottom: 2px
        solid #509ee3;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        background-color: #fff;
        border-color: #509ee3;
    }

    .btn-xs{
        border-radius: 10px;
    }

    div[title*="sisda-marker-"] img{
        display: none;
        height: 0!important;
        width: 0!important;
    }

    /* MARKER */
    div[title*="sisda-marker-"]{
        background: #00bff3;
        width: 16px;
        height: 16px;
        border-radius: 100%;
    }

    div[title^="sisda-marker-"][title$='-l1']{
        background: #fff200;
    }

    div[title^="sisda-marker-"][title$='-l2']{
        background: #f26c4f;
    }

    div[title^="sisda-marker-"][title$='-l3']{
        background: #e93a3a;
    }

    div[title^="sisda-marker-"][title$='-idle']{
        background: rgb(68, 68, 68);
    }

    div[title*="-active"]{
        background: #0c0;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 0!important;
        overflow: visible!important;
    }

    div[title*="-active"]:after{
        content: '';
        display: block;
        animation: pulsate 1.5s ease-in-out infinite;
        background: transparent;
        
        -moz-border-radius:51px;
        -webkit-border-radius:51px;
        border-radius:51px;
        
        -moz-box-shadow:inset 0 0 5px #0f6, inset 0 0 5px #0f6, inset 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6;
        -webkit-box-shadow:inset 0 0 5px #0f6, inset 0 0 5px #0f6, inset 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6;
        box-shadow:inset 0 0 5px #0f6, inset 0 0 5px #0f6, inset 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6;
        
        height:51px!important;
        margin:-18px 0 0 -18px;
        width:51px!important;
    }
    
</style>