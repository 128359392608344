<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarTelemeteri/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <Breadcrumb :links="links"/>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row">
				<div class="col-md-12">
                    <div class="document">
                        <h4><b>Telemeteri</b></h4>
                        <form method="POST" autocomplete="OFF">
                            <!-- Row 1 -->
                            <div class="row">
                                <!-- Lokasi -->
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="">Pilih Lokasi</label>
                                        <Select2 class="vue-select2" v-model="telemetering_id" :options="telemeterings"></Select2>
                                    </div>
                                </div>
                                <!-- Tanggal -->
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label for="">Tanggal mulai</label>
                                        <Datepicker v-model="startDate" input-class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label for="">Tanggal akhir</label>
                                        <Datepicker v-model="endDate" input-class="form-control" />
                                    </div>
                                </div>
                                <!-- Button -->
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <button type="button" v-on:click="advanceFilter" class="btn btn-primary form-control mt-4">Tampilkan</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
            
            <div class="row mt-4">
                <div class="col-md-12">
                    <div class="card" style="height: 600px">
                        <div class="card-header">{{ this.label }}</div>
                        <div class="card-body">
                            <div v-if="station._group == 'arr'">
                                <bar-chart :chart-data="charts" :height="500" :options="chart_options"></bar-chart>
                            </div>
                            <div v-else>
                                <line-chart :chart-data="charts" :height="500" :options="chart_options"></line-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    import moment from 'moment'
    import Select2 from 'v-select2-component'
    import Datepicker from 'vuejs-datepicker'
    import $ from 'jquery'
    import LineChart from '../../components/chart/LineChart.vue'
    import BarChart from '../../components/chart/BarChart.vue'

    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarTelemeteri from '../../components/SidebarTelemeteri.vue'
    
    export default{
        components:{
            Navbar,
            SidebarTelemeteri,
            Loading, 
            Breadcrumb,
            Select2,
            Datepicker,
            LineChart,
            BarChart
        },
        data(){
            return{
                name: 'Struktural',

                // Need
                is_loading: false,
				is_fullpage: true,
                is_searched: false,
				is_advance_searched: false,
                startDate: new Date(),
                endDate: new Date(),
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/grafik/telemeteri', text: 'Grafik Telemeteri'}, 
                ],

                // Telemeteri
                telemeterings: [],
                telemetering_id: null,
                station: {},
                chart_options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                maxRotation: 0,
                                fontSize: 10
                            }
                        }],
                        yAxes: [{
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            scaleLabel: {
                                display: true,
                                labelString: "meter"
                            }
                        }, {
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            scaleLabel: {
                                display: true,
                                labelString: "m3/detik"
                            }
                        }]
                    }
                },
                label: 'Grafik',
                charts:{
                    labels: [],
                    datasets: []
                },
            }
        },
        mounted(){
            $('.dropdown-menu').click(function(event){
                event.stopPropagation();
            })

            // Token
			this.token = localStorage.getItem('token');
            this.data_types = [];
            this.is_loading = true;

            // API Lokasi

            axios.get(this.$endpoints.telemeteri + 'stations/data?token=' + this.token)
            .then((response) => {
                // this.image = response.data.image
                this.telemeterings = response.data.locations.map(location => {
                    return {
                        id: location.id,
                        text: location.name
                    }
                });
                this.is_loading = false;
            })
            .catch((e) => {
                console.log(e);
            })

            // axios.get(this.$endpoints.operasi + 'api/externaltelemetering?token=' + this.token)
            //     .then((response) => {
            //         for(var i=0; i<response.data.length; i++)
            //         {
            //             this.telemeterings.push(response.data[i]);
            //             this.is_loading = false;
            //         }
            //     }).catch(() => {
            //         alert('there was an error. please contact developer.');
            //     })
        },
        methods:{
            dateFormat (classes, date) 
            {
                if (!classes.disabled) {
                    classes.disabled = date.getTime() < new Date()
                }
                
                return classes
            },
            advanceFilter(){
                this.is_loading = true;

                axios.get(this.$endpoints.telemeteri + 'data/graphic/' + this.telemetering_id + '?date_start=' + moment(this.startDate).format('YYYY-MM-DD') + '&date_end=' + moment(this.endDate).format('YYYY-MM-DD'))
                .then(response => {
                    this.is_loading = false;
                    this.station = response.data.station;

                    if(response.data.station.q_formula != null){
                        this.chart_options = {
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 6,
                                        maxRotation: 0,
                                        fontSize: 10
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    position: "left",
                                    id: "y-axis-1",
                                    scaleLabel: {
                                        display: true,
                                        labelString: "meter"
                                    }
                                }, {
                                    display: true,
                                    position: "right",
                                    id: "y-axis-2",
                                    scaleLabel: {
                                        display: true,
                                        labelString: "m3/detik"
                                    }
                                }]
                            },
                            legend: {
                                labels: {
                                    boxWidth: 12
                                }
                            }
                        }
                    }else{
                        this.chart_options = {
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 6,
                                        maxRotation: 0,
                                        fontSize: 10
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    position: "left",
                                    id: "y-axis-1",
                                    scaleLabel: {
                                        display: true,
                                        labelString: "meter"
                                    }
                                }]
                            },
                            legend: {
                                labels: {
                                    boxWidth: 12
                                }
                            }
                        }
                    }

                    this.charts = response.data.chart_data;
                });
            }
        },
        filters: 
        {
            date(val) 
            {
                return val ? moment(val).format("YYYY-MM-DD") : "";
            }
        },
        watch: {
            limit (newVal) {
                this.limit = parseInt(newVal);
                if (this.limit < 0) {
                    this.limit = 0;
                }
            }
        },
    }

</script>

<style scoped>
    .dashboard .filter{
        position: absolute;
        left: 0px;
        top: 15px;
    }

    .nav-tabs-bordered .nav-link.active{
        background-color: #fff;
        color: #509ee3;
        border-bottom: 2px
        solid #509ee3;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        background-color: #fff;
        border-color: #509ee3;
    }

    .btn-xs{
        border-radius: 10px;
    }
</style>