<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true"></loading>
        <!-- Navbar -->
        <Navbar />

        <!-- Sidebar -->
        <SidebarScada />
        <div class="main" id="main">
            <Breadcrumb :links="links" />
            <section class="section">
                <div class="document">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Lokasi</label>
                                <Select2 class="vue-select2" :options="scada_location_select2" v-model="location" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Tanggal mulai</label>
                                <Datepicker v-model="date_start" input-class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Tanggal akhir</label>
                                <Datepicker v-model="date_end" input-class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-primary mt-4" @click="getData">Tampilkan</button>
                        </div>
                    </div>
                </div>
                <div class="document">
                    <template v-if="selected_location !== null">
                        <h4 class="border-bottom pb-3 mb-4">
                            <b>Grafik <span v-if="selected_location !== null">{{ scada_locations[selected_location] }}</span></b>
                        </h4>
                        <line-chart :options="chart_options" :chart-data="chart_data"></line-chart>
                    </template>
                    <template v-else>
                        <h4 class="text-center">Silahkan pilih wilayah terlebih dahulu</h4>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </template>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
// import
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarScada from '../../components/SidebarScada.vue'
import Datepicker from 'vuejs-datepicker'
import axios from 'axios';
import moment from 'moment';
import Select2 from 'v-select2-component';
import LineChart from '@/components/chart/LineChart'
import { scada_locations, translate_scada_columns, scada_chart_options } from '../../constants'

export default {
    name: 'LocationScadaGraphic',
    components:{
        Navbar,
        SidebarScada,
        Loading,
        Breadcrumb,
        Datepicker,
        Select2,
        LineChart
    },
    data(){
        return {
            is_loading: false,
            links: [
                {url: '/dashboard', text:'Home'},
                {url: '/scada', text: 'Scada'},
                {url: '#', text: 'Seri Data'},
            ],
            scada_locations,
            per_page: 50,
            translate_scada_columns,
            date_start: null,
            date_end: null,
            location: null,
            selected_location: null, // wilayah terpilih setelah menekan tombol Tampilkan
            chart_data: {
                labels: [],
                datasets: []
            },
            chart_options: {

            }
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        getData(){
            this.is_loading = true;
            this.selected_location = this.location;

            let ds, es;

            if(this.date_start !== null){
                ds = moment(this.date_start).format("YYYY-MM-DD");
            }

            if(this.date_end !== null){
                es = moment(this.date_end).format("YYYY-MM-DD");
            }

            axios.get(`${this.$endpoints.scada}/data/${this.location}`, {
                params: {
                    start_date: ds,
                    end_date: es,
                    to_graph: 1
                }
            })
                .then(response => {
                    switch(this.selected_location){
                        case "0":
                            this.generateBuaran(response.data);
                            break;

                        case "1":
                            this.generatePulogadung(response.data);
                            break;

                        case "2":
                            this.generateAtr(response.data);
                            break;

                        case "3":
                            this.generateDvc(response.data);
                            break;

                        case "4":
                            this.generateIvc(response.data);
                            break;

                        case "5":
                            this.generateSurgeTower(response.data);
                            break;
                    }
                })
                .finally(() => {
                    this.is_loading = false;
                })
        },
        generateBuaran(rows){
            // setup options
            this.chart_options = scada_chart_options.buaran;

            // setup data
            let data = {
                labels: [],
                datasets: []
            };

            rows.forEach(row => {
                data.labels.push(moment(row.LocalTimeCol).format("YYYY-MM-DD HH:mm"))
            });

            const columns = [
                {label: 'TMA (mdpl)', column: 'level_intake', borderColor: '#c023cc', axis: 'y-axis-1'},
                {label: 'Flow Buaran 1 (m3/detik)', column: 'flow_buaran1', borderColor: '#06c400', axis: 'y-axis-2'},
                {label: 'Flow Buaran 2 (m3/detik)', column: 'flow_buaran2', borderColor: '#ff6816', axis: 'y-axis-2'},
                {label: 'Totalizer Buaran 1 (Bar)', column: 'totalizer_buaran1', borderColor: '#374aff', axis: 'y-axis-3'},
                {label: 'Totalizer Buaran 2 (Bar)', column: 'totalizer_buaran2', borderColor: '#cc0037', axis: 'y-axis-3'},
            ];

            columns.forEach(col => {
                data.datasets.push({
                    label: col.label,
                    data: rows.map(row => parseFloat(row[col.column])),
                    lineTension: 0,
                    spanGaps: true,
                    type: 'line',
                    borderColor: col.borderColor,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    yAxisID: col.axis
                });
            });

            this.chart_data = data;

        },
        generatePulogadung(rows){
            // setup options
            this.chart_options = scada_chart_options.pulogadung;

            // setup data
            let data = {
                labels: [],
                datasets: []
            };

            rows.forEach(row => {
                data.labels.push(moment(row.LocalTimeCol).format("YYYY-MM-DD HH:mm"))
            });

            const columns = [
                {label: 'TMA (mdpl)', column: 'level_tma', borderColor: '#c023cc', axis: 'y-axis-1'},
                {label: 'Flow Open Channel Intake (m3/detik)', column: 'open_channel_inlet', borderColor: '#06c400', axis: 'y-axis-2'},
            ];

            columns.forEach(col => {
                data.datasets.push({
                    label: col.label,
                    data: rows.map(row => parseFloat(row[col.column])),
                    lineTension: 0,
                    spanGaps: true,
                    type: 'line',
                    borderColor: col.borderColor,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    yAxisID: col.axis
                });
            });

            this.chart_data = data;

        },
        generateAtr(rows){
            // setup options
            this.chart_options = scada_chart_options.atr;

            // setup data
            let data = {
                labels: [],
                datasets: []
            };

            rows.forEach(row => {
                data.labels.push(moment(row.LocalTimeCol).format("YYYY-MM-DD HH:mm"))
            });

            const columns = [
                {label: 'Downstream (mdpl)', column: 'atr_downstream', borderColor: '#c023cc', axis: 'y-axis-1'},
                {label: 'Limpasan (m)', column: 'atr_limpasan', borderColor: '#06c400', axis: 'y-axis-2'},
                {label: 'Upstream Left (mdpl)', column: 'atr_upstream_left', borderColor: '#ff6816', axis: 'y-axis-1'},
                {label: 'Upstream Right (mdpl)', column: 'atr_upstream_right', borderColor: '#374aff', axis: 'y-axis-1'},
                {label: 'Temperatur (oC)', column: 'temperatur', borderColor: '#cc0037', axis: 'y-axis-3'},
                {label: 'Turbidity (NTU)', column: 'turbidity', borderColor: '#e0b500', axis: 'y-axis-4'},
            ];

            columns.forEach(col => {
                data.datasets.push({
                    label: col.label,
                    data: rows.map(row => parseFloat(row[col.column])),
                    lineTension: 0,
                    spanGaps: true,
                    type: 'line',
                    borderColor: col.borderColor,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    yAxisID: col.axis
                });
            });

            this.chart_data = data;

        },
        generateDvc(rows){
            // setup options
            this.chart_options = scada_chart_options.dvc;

            // setup data
            let data = {
                labels: [],
                datasets: []
            };

            rows.forEach(row => {
                data.labels.push(moment(row.LocalTimeCol).format("YYYY-MM-DD HH:mm"))
            });

            const columns = [
                {label: 'Flowrate IPA 1 (m3/detik)', column: 'flowrate_ipasatu', borderColor: '#c023cc', axis: 'y-axis-1'},
                {label: 'Flowrate IPA 2 (m3/detik)', column: 'flowrate_ipadua', borderColor: '#06c400', axis: 'y-axis-1'},
                {label: 'Pressure IPA 1 (Bar)', column: 'pressure_ipasatu', borderColor: '#ff6816', axis: 'y-axis-2'},
                {label: 'Pressure IPA 2 (Bar)', column: 'pressure_ipadua', borderColor: '#374aff', axis: 'y-axis-2'}
            ];

            columns.forEach(col => {
                data.datasets.push({
                    label: col.label,
                    data: rows.map(row => parseFloat(row[col.column])),
                    lineTension: 0,
                    spanGaps: true,
                    type: 'line',
                    borderColor: col.borderColor,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    yAxisID: col.axis
                });
            });

            this.chart_data = data;

        },
        generateIvc(rows){
            // setup options
            this.chart_options = scada_chart_options.ivc;

            // setup data
            let data = {
                labels: [],
                datasets: []
            };

            rows.forEach(row => {
                data.labels.push(moment(row.LocalTimeCol).format("YYYY-MM-DD HH:mm"))
            });

            const columns = [
                {label: 'Pressure Inlet 1 (Bar)', column: 'pressure_inlet_satu', borderColor: '#c023cc', axis: 'y-axis-1'},
                {label: 'Pressure Inlet 2 (Bar)', column: 'pressure_Inlet_dua', borderColor: '#06c400', axis: 'y-axis-1'},
                {label: 'Pressure Outlet 1 (Bar)', column: 'pressure_Outlet_satu', borderColor: '#ff6816', axis: 'y-axis-1'},
                {label: 'Pressure Outlet 2 (Bar)', column: 'pressure_Outlet_dua', borderColor: '#374aff', axis: 'y-axis-1'}
            ];

            columns.forEach(col => {
                data.datasets.push({
                    label: col.label,
                    data: rows.map(row => parseFloat(row[col.column])),
                    lineTension: 0,
                    spanGaps: true,
                    type: 'line',
                    borderColor: col.borderColor,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    yAxisID: col.axis
                });
            });

            this.chart_data = data;

        },
        generateSurgeTower(rows){
            // setup options
            this.chart_options = scada_chart_options.surge_tower;

            // setup data
            let data = {
                labels: [],
                datasets: []
            };

            rows.forEach(row => {
                data.labels.push(moment(row.LocalTimeCol).format("YYYY-MM-DD HH:mm"))
            });

            const columns = [
                {label: 'Flowrate IPA 1 (m3/detik)', column: 'flow_ipasatu', borderColor: '#c023cc', axis: 'y-axis-2'},
                {label: 'Flowrate IPA 2 (m3/detik)', column: 'flow_ipadua', borderColor: '#06c400', axis: 'y-axis-2'},
                {label: 'Pressure Downstream 1 (Bar)', column: 'pressure_downstream_satu', borderColor: '#ff6816', axis: 'y-axis-3'},
                {label: 'Pressure Downstream 2 (Bar)', column: 'pressure_downstream_dua', borderColor: '#374aff', axis: 'y-axis-3'},
                {label: 'Pressure Upstream 1 (Bar)', column: 'pressure_upstream_satu', borderColor: '#cc0037', axis: 'y-axis-3'},
                {label: 'Pressure Upstream 2 (Bar)', column: 'pressure_upstream_dua', borderColor: '#e0b500', axis: 'y-axis-3'},
                {label: 'TMA (mdpl)', column: 'tma', borderColor: '#ff6f08', axis: 'y-axis-1'}
            ];

            columns.forEach(col => {
                data.datasets.push({
                    label: col.label,
                    data: rows.map(row => parseFloat(row[col.column])),
                    lineTension: 0,
                    spanGaps: true,
                    type: 'line',
                    borderColor: col.borderColor,
                    borderWidth: 2,
                    backgroundColor: 'transparent',
                    yAxisID: col.axis
                });
            });

            this.chart_data = data;

        },
    },
    filters: {
        formatDate(val){
            moment.locale('id');
            return moment(val).format("DD MMM YYYY HH:mm:ss")
        },
        pembulatan(value){
            if(value === null || isNaN(value)) return '#null';

            return parseFloat(value).toFixed(2);
        }
    },
    computed: {
        scada_location_select2(){
            return scada_locations.map((location, i) => {
                return {
                    id: i,
                    text: location
                };
            })
        }
    }
}
</script>
<style>
.select2-selection,
.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 38px!important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 38px!important;
}
</style>