<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <!-- Navbar -->
        <Navbar />

        <!-- Sidebar -->
        <SidebarBendungan />

        <main id="main" class="main">
            <!-- Breadcomp -->
            <Breadcrumb :links="links" />
            <!-- END OF BREADCOMP -->

            <section class="section dashboard" style="overflow-y: visible">
                <div class="row">
                    <!-- Left side columns -->
                    <div class="col-lg-12">
                        <div class="row">
                            <!-- Recent Sales -->
                            <div class="col-sm-12">
                                <div class="card" style="min-height: 500px">
                                    <div class="card-body">
                                        <div class="row">
                                            <!-- <div class="col-md-2">
                                                <ul class="list-unstyled p-2">
                                                    <li v-for="device in devices" :key="device.id"><input type="checkbox" class="form-check-input me-2" :id="`device-${ device.id }`"><label :for="`device-${ device.id }`">{{ device.name }}</label></li>
                                                </ul>
                                            </div> -->
                                            <div class="col-md-12">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item">
                                                        <a class="nav-link" :class="current_tab == 'report' ? 'active':''" href="#" @click.prevent="current_tab = 'report'">Report</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" :class="current_tab == 'longitudinal_displacement' ? 'active':''" href="#" @click.prevent="current_tab = 'longitudinal_displacement'">Longitudinal Displacement</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" :class="current_tab == 'transverse_displacement' ? 'active':''" href="#" @click.prevent="current_tab = 'transverse_displacement'">Transverse Displacement</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" :class="current_tab == 'height_displacement' ? 'active':''" href="#" @click.prevent="current_tab = 'height_displacement'">Height Displacement</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="tab-content">
                                                <div class="tab-pane" :class="current_tab == 'report' ? 'active':''">
                                                    <div class="py-3">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal mulai</label>
                                                                    <Datepicker v-model="date_start" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal akhir</label>
                                                                    <Datepicker v-model="date_end" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Point</label>
                                                                    <div class="btn-group" style="width: 100%">
                                                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih point</button>
                                                                        <span class="sr-only">Toggle Dropdown</span>
                                                                        <div class="dropdown-menu" style="width: 100%; height: 400px; overflow: auto">
                                                                            <div class="container">
                                                                                <div class="form-group" v-for="(device, index) in devices" :key="device.id" v-bind:index="index">
                                                                                    <div class="form-check">
                                                                                        <input @click="setSelectedId" class="form-check-input" type="checkbox" :value="device.id" :id="`device-${device.id}`" ref="selected_ids" checked v-if="selected_id.indexOf(device.id) > -1">
                                                                                        <input @click="setSelectedId" class="form-check-input" type="checkbox" :value="device.id" :id="`device-${device.id}`" ref="selected_ids" v-else>
                                                                                        <label class="form-check-label" :for="`device-${device.id}`">
                                                                                            {{ device.name }}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <br>
                                                                <button class="btn btn-primary" @click="filter"><i class="fa fa-filter"></i> Filter</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-success mb-3" @click="downloadCsv"><i class="fa fa-download"></i> Download CSV</button>
                                                    <br>
                                                    <div class="w-100 overflow-auto">
                                                        <table class="table table-bordered" id="sticky-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th width="150">Point</th>
                                                                    <th width="250">Timestamp</th>
                                                                    <th>Easting</th>
                                                                    <th>Northing</th>
                                                                    <th>Height</th>
                                                                    <th>Easting Diff</th>
                                                                    <th>Northing Diff</th>
                                                                    <th>Height Diff</th>
                                                                    <th>Longitudinal Displacement</th>
                                                                    <th>Transverse Displacement</th>
                                                                    <th>Height Displacement</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="row in report.data" :key="row.id">
                                                                    <td>{{ row.id }}</td>
                                                                    <td>{{ row.device.name }}</td>
                                                                    <td>{{ row.epoch_local | date }}</td>
                                                                    <td>{{ row.easting }}</td>
                                                                    <td>{{ row.northing }}</td>
                                                                    <td>{{ row.height }}</td>
                                                                    <td>{{ row.easting_diff }}</td>
                                                                    <td>{{ row.northing_diff }}</td>
                                                                    <td>{{ row.height_diff }}</td>
                                                                    <td>{{ row.longitudinal_displacement }}</td>
                                                                    <td>{{ row.transverse_displacement }}</td>
                                                                    <td>{{ row.height_displacement }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <pagination :data="report" :limit="3" @pagination-change-page="getReport"></pagination>
                                                </div>
                                                <div class="tab-pane" :class="current_tab == 'longitudinal_displacement' ? 'active':''">
                                                    <div class="py-3">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal mulai</label>
                                                                    <Datepicker v-model="date_start_chart1" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal akhir</label>
                                                                    <Datepicker v-model="date_end_chart1" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Point</label>
                                                                    <div class="btn-group" style="width: 100%">
                                                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih point</button>
                                                                        <span class="sr-only">Toggle Dropdown</span>
                                                                        <div class="dropdown-menu" style="width: 100%; height: 400px; overflow: auto">
                                                                            <div class="container">
                                                                                <div class="form-group" v-for="(device, index) in devices" :key="device.id" v-bind:index="index">
                                                                                    <div class="form-check">
                                                                                        <input @click="setSelectedIdChart1" class="form-check-input" type="checkbox" :value="device.id" :id="`c1-device-${device.id}`" ref="selected_ids_chart1" checked v-if="selected_id_chart1.indexOf(device.id) > -1">
                                                                                        <input @click="setSelectedIdChart1" class="form-check-input" type="checkbox" :value="device.id" :id="`c1-device-${device.id}`" ref="selected_ids_chart1" v-else>
                                                                                        <label class="form-check-label" :for="`c1-device-${device.id}`">
                                                                                            {{ device.name }}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <br>
                                                                <button class="btn btn-primary" @click="filter"><i class="fa fa-filter"></i> Filter</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <template v-if="chart1_generated">
                                                        <apexchart type="line" height="500" :options="chartOptions" :series="series_chart1"></apexchart>
                                                    </template>
                                                    <div class="alert alert-info" v-else>Silahkan tentukan dahulu filter</div>
                                                </div>
                                                <div class="tab-pane" :class="current_tab == 'transverse_displacement' ? 'active':''">
                                                    <div class="py-3">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal mulai</label>
                                                                    <Datepicker v-model="date_start_chart2" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal akhir</label>
                                                                    <Datepicker v-model="date_end_chart2" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Point</label>
                                                                    <div class="btn-group" style="width: 100%">
                                                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih point</button>
                                                                        <span class="sr-only">Toggle Dropdown</span>
                                                                        <div class="dropdown-menu" style="width: 100%; height: 400px; overflow: auto">
                                                                            <div class="container">
                                                                                <div class="form-group" v-for="(device, index) in devices" :key="device.id" v-bind:index="index">
                                                                                    <div class="form-check">
                                                                                        <input @click="setSelectedIdChart2" class="form-check-input" type="checkbox" :value="device.id" :id="`c2-device-${device.id}`" ref="selected_ids_chart2" checked v-if="selected_id_chart2.indexOf(device.id) > -1">
                                                                                        <input @click="setSelectedIdChart2" class="form-check-input" type="checkbox" :value="device.id" :id="`c2-device-${device.id}`" ref="selected_ids_chart2" v-else>
                                                                                        <label class="form-check-label" :for="`c2-device-${device.id}`">
                                                                                            {{ device.name }}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <br>
                                                                <button class="btn btn-primary" @click="filter"><i class="fa fa-filter"></i> Filter</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <template v-if="chart2_generated">
                                                        <apexchart type="line" height="500" :options="chartOptions" :series="series_chart2"></apexchart>
                                                    </template>
                                                    <div class="alert alert-info" v-else>Silahkan tentukan dahulu filter</div>
                                                </div>
                                                <div class="tab-pane" :class="current_tab == 'height_displacement' ? 'active':''">
                                                    <div class="py-3">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal mulai</label>
                                                                    <Datepicker v-model="date_start_chart3" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Tanggal akhir</label>
                                                                    <Datepicker v-model="date_end_chart3" input-class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label for="">Point</label>
                                                                    <div class="btn-group" style="width: 100%">
                                                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih point</button>
                                                                        <span class="sr-only">Toggle Dropdown</span>
                                                                        <div class="dropdown-menu" style="width: 100%; height: 400px; overflow: auto">
                                                                            <div class="container">
                                                                                <div class="form-group" v-for="(device, index) in devices" :key="device.id" v-bind:index="index">
                                                                                    <div class="form-check">
                                                                                        <input @click="setSelectedIdChart3" class="form-check-input" type="checkbox" :value="device.id" :id="`c3-device-${device.id}`" ref="selected_ids_chart3" checked v-if="selected_id_chart3.indexOf(device.id) > -1">
                                                                                        <input @click="setSelectedIdChart3" class="form-check-input" type="checkbox" :value="device.id" :id="`c3-device-${device.id}`" ref="selected_ids_chart3" v-else>
                                                                                        <label class="form-check-label" :for="`c3-device-${device.id}`">
                                                                                            {{ device.name }}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <br>
                                                                <button class="btn btn-primary" @click="filter"><i class="fa fa-filter"></i> Filter</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <template v-if="chart3_generated">
                                                        <apexchart type="line" height="500" :options="chartOptions" :series="series_chart3"></apexchart>
                                                    </template>
                                                    <div class="alert alert-info" v-else>Silahkan tentukan dahulu filter</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
  
<script>
    // import
    import Loading from 'vue-loading-overlay'
    import Breadcrumb from '../../components/Breadcrumb.vue'
    // Component
    import Navbar from '../../components/Navbar.vue'
    import SidebarBendungan from '../../components/SidebarBendungan.vue'
    import axios from 'axios';
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import $ from 'jquery'
    import VueApexCharts from 'vue-apexcharts'

    export default{
        name: 'Geomoss',
        data(){
            return{
                // Need
                is_loading: false,
                links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/bendungan', text: 'Bendungan'}, 
                ],
                date_start: moment().subtract(1,'w').toDate(),
                date_end: new Date(),
                date_start_chart1: moment().subtract(1,'w').toDate(),
                date_end_chart1: new Date(),
                date_start_chart2: moment().subtract(1,'w').toDate(),
                date_end_chart2: new Date(),
                date_start_chart3: moment().subtract(1,'w').toDate(),
                date_end_chart3: new Date(),
                selected_id: [],
                selected_id_chart1: [],
                selected_id_chart2: [],
                selected_id_chart3: [],
                current_tab: 'report',
                devices: [],
                report: [],
                chart1_generated: false,
                chart2_generated: false,
                chart3_generated: false,
                chartOptions: {
                    chart: {
                        id: 'vuechart-example',
                        toolbar: {
                            show: false
                        }
                    },
                    yaxis: {
                        decimalsInFloat: 7
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'top'
                    },
                    markers: {
                        size: 6
                    },
                    stroke: {
                        width: 1
                    }
                },
                series_chart1: [],
                series_chart2: [],
                series_chart3: [],
            }
        },
        mounted(){
            $('.dropdown-menu').on('click', function(event){
                event.stopPropagation();
            })
            this.getDevices();
            this.getReport(1)
        },
        methods: {
            getReport(page){
                this.is_loading = true;

                axios.get(this.$endpoints.geomoss + '/devices/report', {
                    params: {
                        page,
                        date_start: moment(this.date_start).format('YYYY-MM-DD'),
                        date_end: moment(this.date_end).format('YYYY-MM-DD'),
                        selected_id: this.selected_id
                    }
                })
                .then(response => {
                    this.report = response.data;
                })
                .catch(e => {
                    alert(JSON.stringify(e));
                })
                .finally(() => {
                    this.is_loading = false;
                })
            },
            getDevices(){
                this.is_loading = true;
                axios.get(this.$endpoints.geomoss + '/devices', {
                    params: {
                        per_page: 100
                    }
                })
                .then(response => {
                    this.devices = response.data.data;
                    // this.selected_id = response.data.data.map(dev => dev.id);
                })
                .catch(e => {
                    alert(JSON.stringify(e));
                })
                .finally(() => {
                    this.is_loading = false;
                })
            },
            getGraph(type, date_start, date_end, selected_id){
                this.is_loading = true;

                // this.chart1_generated = (type == 'longitudinal_displacement');
                // this.chart2_generated = (type == 'transverse_displacement');
                // this.chart3_generated = (type == 'height_displacement');

                axios.get(this.$endpoints.geomoss + '/devices/graph', {
                    params: {
                        date_start: moment(date_start).format('YYYY-MM-DD'),
                        date_end: moment(date_end).format('YYYY-MM-DD'),
                        selected_id,
                        type
                    }
                })
                .then(response => {
                    // this.chartOptions.xaxis.categories = [];
                    
                    // response.data.forEach(point => {
                    //     point.data.forEach(data => {
                    //         if(this.chartOptions.xaxis.categories.indexOf(data.x) == -1){
                    //             this.chartOptions.xaxis.categories.push(data.x);
                    //         }
                    //     })
                    // });

                    switch(type){
                        case "longitudinal_displacement":
                            this.series_chart1 = response.data;
                            break;

                        case "transverse_displacement":
                            this.series_chart2 = response.data;
                            break;

                        case "height_displacement":
                            this.series_chart3 = response.data;
                            break;
                    }
                })
                .catch(e => {
                    alert(JSON.stringify(e));
                })
                .finally(() => {
                    this.is_loading = false;
                })
            },
            downloadCsv(){
                this.is_loading = true;

                axios.get(this.$endpoints.geomoss + '/devices/report', {
                    params: {
                        to_csv: 1,
                        date_start: moment(this.date_start).format('YYYY-MM-DD'),
                        date_end: moment(this.date_end).format('YYYY-MM-DD'),
                        selected_id: this.selected_id
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const date_suffix = moment().format("DD_MMM_YYYY_HHmmss");
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `geomoss_report_${date_suffix}.csv`); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch(e => {
                    alert(JSON.stringify(e));
                })
                .finally(() => {
                    this.is_loading = false;
                })
            },
            setSelectedId(){
                const inputs = this.$refs['selected_ids'];

                let id = [];
                inputs.forEach((cb) => {
                    if(cb.checked){
                        id.push(parseInt(cb.value));
                    }
                });

                this.selected_id = id;
            },
            setSelectedIdChart1(){
                const inputs = this.$refs['selected_ids_chart1'];

                let id = [];
                inputs.forEach((cb) => {
                    if(cb.checked){
                        id.push(parseInt(cb.value));
                    }
                });

                this.selected_id_chart1 = id;
            },
            setSelectedIdChart2(){
                const inputs = this.$refs['selected_ids_chart2'];

                let id = [];
                inputs.forEach((cb) => {
                    if(cb.checked){
                        id.push(parseInt(cb.value));
                    }
                });

                this.selected_id_chart2 = id;
            },
            setSelectedIdChart3(){
                const inputs = this.$refs['selected_ids_chart3'];

                let id = [];
                inputs.forEach((cb) => {
                    if(cb.checked){
                        id.push(parseInt(cb.value));
                    }
                });

                this.selected_id_chart3 = id;
            },
            filter(){
                switch(this.current_tab){
                    case "report":
                        this.getReport(1);
                        break;

                    case "longitudinal_displacement":
                        if(this.selected_id_chart1.length == 0){
                            alert('Silahkan pilih point terlebih dahulu');
                            return ;
                        }
                        this.getGraph('longitudinal_displacement', this.date_start_chart1, this.date_end_chart1, this.selected_id_chart1);
                        this.chart1_generated = true;
                        break;

                    case "transverse_displacement":
                        if(this.selected_id_chart2.length == 0){
                            alert('Silahkan pilih point terlebih dahulu');
                            return ;
                        }
                        this.getGraph('transverse_displacement', this.date_start_chart2, this.date_end_chart2, this.selected_id_chart2);
                        this.chart2_generated = true;
                        break;

                    case "height_displacement":
                        if(this.selected_id_chart3.length == 0){
                            alert('Silahkan pilih point terlebih dahulu');
                            return ;
                        }
                        this.getGraph('height_displacement', this.date_start_chart3, this.date_end_chart3, this.selected_id_chart3);
                        this.chart3_generated = true;
                        break;
                }
            }
        },
        filters: {
            date(value){
                return moment(value).format("DD MMM YYYY HH:mm")
            }
        },
        components:{
            Navbar,
            SidebarBendungan,
            Loading, 
            Breadcrumb,
            Datepicker,
            'apexchart': VueApexCharts
        },
    }
</script>