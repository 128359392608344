<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Seri Data (Grafik)'}]"/>
                <h2><b>Seri Data (Grafik)</b></h2>
                <!-- Container 1 -->
                <div class="document">
                    <h4>
                        <b>Seri Data</b>
                    </h4>
                    <form method="POST" autocomplete="OFF">
                        <!-- Row 1 -->
                        <div class="row">
                            <!-- Tipe Lokasi  -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="">Pilih Tipe Lokasi</label>
                                    <Select2 class="vue-select2" v-model="tipe_lokasi" :options="data_types" @change="getLocationByType"></Select2>
                                </div>
                            </div>
                            <!-- Lokasi -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="">Pilih Lokasi</label>
                                    <Select2 class="vue-select2" v-model="lokasi" :options="locations" @change="getDataPeriodic"></Select2>
                                </div>
                            </div>
                            <!-- Data Periodik -->
                            <div class="col-sm-4">
                                <label for="">Pilih Data Periodik</label>
                                <div class="form-group">
                                    <div class="btn-group" style="width: 100%">
                                        <button type="button" class="btn btn-secondary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pilih Data Periodic</button>
                                        <span class="sr-only">Toggle Dropdown</span>
                                        <div class="dropdown-menu" style="width: 100%">
                                            <div class="container">
                                                <div class="form-group" v-for="(periodik_data, index) in data_periodik" :key="periodik_data.id" v-bind:index="index">
                                                    <div class="form-check">
                                                        <input 
                                                            class="form-check-input" 
                                                            name="periodik[]" 
                                                            type="checkbox" 
                                                            :value="periodik_data.id" 
                                                            :id="periodik_data.name"
                                                            ref="checkbox_periodic"
                                                            @click="setPeriodic()">
                                                        <label class="form-check-label" :for="periodik_data.name">
                                                            {{ periodik_data.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Row 2 -->
                        <div class="row">
                            <!-- Tanggal -->
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="">Tanggal mulai</label>
                                    <Datepicker v-model="date_start" input-class="form-control" />
                                    <!-- <date-range-picker
                                        ref="picker"
                                        opens="right"
                                        v-model="dateRange"
                                        format="YYYY-MM-DD"
                                        :append-to-body="true"
                                        :showWeekNumbers="true"
                                        :showDropdowns="true"
                                        style="width: 100%" >
                                        <template #input="picker" style="width: 100%;">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker> -->
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="">Tanggal akhir</label>
                                    <Datepicker v-model="date_end" input-class="form-control" />
                                    <!-- <date-range-picker
                                        ref="picker"
                                        opens="right"
                                        v-model="dateRange"
                                        format="YYYY-MM-DD"
                                        :append-to-body="true"
                                        :showWeekNumbers="true"
                                        :showDropdowns="true"
                                        style="width: 100%" >
                                        <template #input="picker" style="width: 100%;">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker> -->
                                </div>
                            </div>

                            <!-- Waktu & Rata - rata -->
                            <div class="col-sm-4">
                                <div class="row">
                                    <!-- Waktu -->
                                    <div class="col-sm-6">
                                        <label for="">Waktu</label>
                                        <div class="form-group">
                                            <vue-timepicker input-class="form-control" v-model="waktu" :minute-interval="5" input-width="100%" style="width: 100%"></vue-timepicker>
                                        </div>
                                    </div>
                                    <!-- Rata - rata -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Rata - rata</label>
                                            <select name="avg" v-model="avg" id="avg" class="form-select">
                                                <option value="" selected="selected">-- Pilih Rata - rata --</option>
                                                <option value="d">Harian</option>
                                                <option value="w">Mingguan</option>
                                                <option value="m">Bulanan</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- Button  -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <button type="button" v-on:click="advanceFilter" class="btn btn-primary form-control mt-4">Tampilkan</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="document" v-if="chart_curah_hujan_loaded">
                    <line-chart :options="chart_curah_hujan_options" :chart-data="charts_data"></line-chart>
                    <hr />
                    <table class="mt-4 table table-bordered">
                        <thead>
                            <tr>
                                <th width="10%"></th>
                                <th v-for="(periodic, i) in charts_data.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Rata-Rata</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.avg }}</td>
                            </tr>
                            <tr>
                                <td>Maksimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.max }}</td>
                            </tr>
                            <tr>
                                <td>Minimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.min }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="document" v-if="chart_produksi_listrik_loaded">
                    <line-chart :options="chart_produksi_listrik_options[0]" :chart-data="charts_data"></line-chart>
                    <hr />
                    <table class="mt-4 table table-bordered">
                        <thead>
                            <tr>
                                <th width="10%"></th>
                                <th v-for="(periodic, i) in charts_data.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Rata-Rata</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.avg }}</td>
                            </tr>
                            <tr>
                                <td>Maksimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.max }}</td>
                            </tr>
                            <tr>
                                <td>Minimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.min }}</td>
                            </tr>
                            <tr>
                                <td>Jumlah Kumulatif</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.cumulative.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="charts_data_unit.datasets.length > 0">
                        <hr>
                        <line-chart :options="chart_produksi_listrik_options[1]" :chart-data="charts_data_unit"></line-chart>
                        <hr />
                        <table class="mt-4 table table-bordered">
                            <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th v-for="(periodic, i) in charts_data_unit.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Rata-Rata</td>
                                    <td v-for="(periodic, i) in charts_data_unit.datasets" :key="i">-</td>
                                </tr>
                                <tr>
                                    <td>Maksimum</td>
                                    <td v-for="(periodic, i) in charts_data_unit.datasets" :key="i"> {{ periodic.max }}</td>
                                </tr>
                                <tr>
                                    <td>Minimum</td>
                                    <td v-for="(periodic, i) in charts_data_unit.datasets" :key="i"> {{ periodic.min }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>

                <div class="document" v-if="chart_waduk_loaded">
                    <line-chart :options="chart_waduk_options[0]" :chart-data="charts_data"></line-chart>
                    <hr />
                    <table class="mt-4 table table-bordered">
                        <thead>
                            <tr>
                                <th width="10%"></th>
                                <th v-for="(periodic, i) in charts_data.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Rata-Rata</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.avg }}</td>
                            </tr>
                            <tr>
                                <td>Maksimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.max }}</td>
                            </tr>
                            <tr>
                                <td>Minimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.min }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="charts_data_listrik.datasets.length > 0">
                        <hr>
                        <line-chart :options="chart_waduk_options[1]" :chart-data="charts_data_listrik"></line-chart>
                        <hr />
                        <table class="mt-4 table table-bordered">
                            <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th v-for="(periodic, i) in charts_data_listrik.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Rata-Rata</td>
                                    <td v-for="(periodic, i) in charts_data_listrik.datasets" :key="i"> {{ periodic.avg }}</td>
                                </tr>
                                <tr>
                                    <td>Maksimum</td>
                                    <td v-for="(periodic, i) in charts_data_listrik.datasets" :key="i"> {{ periodic.max }}</td>
                                </tr>
                                <tr>
                                    <td>Minimum</td>
                                    <td v-for="(periodic, i) in charts_data_listrik.datasets" :key="i"> {{ periodic.min }}</td>
                                </tr>
                                <tr>
                                    <td>Jumlah Kumulatif</td>
                                    <td v-for="(periodic, i) in charts_data_listrik.datasets" :key="i"> {{ periodic.min }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-if="charts_data_volume.datasets.length > 0">
                        <hr>
                        <line-chart :options="chart_waduk_options[2]" :chart-data="charts_data_volume"></line-chart>
                        <hr />
                        <table class="mt-4 table table-bordered">
                            <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th v-for="(periodic, i) in charts_data_volume.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Rata-Rata</td>
                                    <td v-for="(periodic, i) in charts_data_volume.datasets" :key="i"> {{ periodic.avg }}</td>
                                </tr>
                                <tr>
                                    <td>Maksimum</td>
                                    <td v-for="(periodic, i) in charts_data_volume.datasets" :key="i"> {{ periodic.max }}</td>
                                </tr>
                                <tr>
                                    <td>Minimum</td>
                                    <td v-for="(periodic, i) in charts_data_volume.datasets" :key="i"> {{ periodic.min }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>

                <div class="document" v-if="chart_kualitas_air_loaded">
                    <line-chart :options="chart_kualitas_air_options[0]" :chart-data="charts_data"></line-chart>
                    <hr />
                    <table class="mt-4 table table-bordered">
                        <thead>
                            <tr>
                                <th width="10%"></th>
                                <th v-for="(periodic, i) in charts_data.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Rata-Rata</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.avg }}</td>
                            </tr>
                            <tr>
                                <td>Maksimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.max }}</td>
                            </tr>
                            <tr>
                                <td>Minimum</td>
                                <td v-for="(periodic, i) in charts_data.datasets" :key="i"> {{ periodic.min }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="charts_data_suhu.datasets.length > 0">
                        <hr>
                        <line-chart :options="chart_kualitas_air_options[1]" :chart-data="charts_data_suhu"></line-chart>
                        <hr />
                        <table class="mt-4 table table-bordered">
                            <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th v-for="(periodic, i) in charts_data_suhu.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Rata-Rata</td>
                                    <td v-for="(periodic, i) in charts_data_suhu.datasets" :key="i"> {{ periodic.avg }}</td>
                                </tr>
                                <tr>
                                    <td>Maksimum</td>
                                    <td v-for="(periodic, i) in charts_data_suhu.datasets" :key="i"> {{ periodic.max }}</td>
                                </tr>
                                <tr>
                                    <td>Minimum</td>
                                    <td v-for="(periodic, i) in charts_data_suhu.datasets" :key="i"> {{ periodic.min }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-if="charts_data_kekeruhan.datasets.length > 0">
                        <hr>
                        <line-chart :options="chart_kualitas_air_options[2]" :chart-data="charts_data_kekeruhan"></line-chart>
                        <hr />
                        <table class="mt-4 table table-bordered">
                            <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th v-for="(periodic, i) in charts_data_kekeruhan.datasets" :key="i">{{ periodic.periodic + ' (' + periodic.uom + ')' }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Rata-Rata</td>
                                    <td v-for="(periodic, i) in charts_data_kekeruhan.datasets" :key="i"> {{ periodic.avg }}</td>
                                </tr>
                                <tr>
                                    <td>Maksimum</td>
                                    <td v-for="(periodic, i) in charts_data_kekeruhan.datasets" :key="i"> {{ periodic.max }}</td>
                                </tr>
                                <tr>
                                    <td>Minimum</td>
                                    <td v-for="(periodic, i) in charts_data_kekeruhan.datasets" :key="i"> {{ periodic.min }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>

                <!-- Container 2 -->
                <!-- <div v-for="chart in charts" :key="chart.name" class="document">
                    <h4><b>{{ chart.name }}</b></h4>
                    <line-chart :chart-data="chart" :options="chart_options"></line-chart>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import Select2 from 'v-select2-component';

// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'

// Moment
import moment from 'moment'

// Date range picker
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import Datepicker from 'vuejs-datepicker'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

// Import Line Charts
import LineChart from '@/components/chart/LineChart'
import $ from 'jquery'

export default
{
    name:'OperationSeriDataGrafik',
    data()
    {
        // 
        return{
            breadcrumb:[
                {
                    name:'Seri Data', 
                    url:'/seridata/grafik'
                }, 
                {
                    name:'Grafik'
                }
            ],
            is_fullpage: true,
            is_loading: false,
            token: null,
            parents: [],
            types : [],

            // Array Data
            data_lokasi: [],
            data_tipe_lokasi: [],
            data_periodik: [],

            // Variable 
            lokasi: '',
            locations: [],
            tipe_lokasi: '',
            periodik: [],
            waktu: {
                HH: '',
                mm: ''
            },
            avg : '',

            // Variable data
            data_types: [],

            // Date Range Picker
            startDate: '1990-05-30',
            endDate: '2017-09-15',
            locale: {
                direction: 'ltr', 
                format: 'DD-MM-YYYY', 
                separator: ' - ', 
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(), 
                monthNames: moment.monthsShort(), 
                firstDay: 1 
            },
            date_start: null,
            date_end: null,
            chart_curah_hujan_loaded: false,
            chart_kualitas_air_loaded: false,
            chart_produksi_listrik_loaded: false,
            chart_waduk_loaded: false,
            charts_data: {
                labels: [],
                datasets: []
            },
            charts_data_unit: {
                labels: [],
                datasets: []
            },
            charts_data_listrik: {
                labels: [],
                datasets: []
            },
            charts_data_volume: {
                labels: [],
                datasets: []
            },
            charts_data_suhu: {
                labels: [],
                datasets: []
            },
            charts_data_kekeruhan: {
                labels: [],
                datasets: []
            },
            chart_curah_hujan_options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "mm"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },
            chart_kualitas_air_options: [{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "mg/l"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            }, {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "oC"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            }, {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "detik"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            }],
            chart_produksi_listrik_options: [{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "MW"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    },{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                            display: true,
                            labelString: "kWh"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            }, {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "Unit"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            }],
            chart_waduk_options: [{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "m3/detik"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    },{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                            display: true,
                            labelString: "meter"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "kWh"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    },{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                            display: true,
                            labelString: "MW"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "Juta m3"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    },{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                            display: true,
                            labelString: "m3"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            }],
        }
        
    },

    // Fungsi yang dijalankan pertama kali
    mounted()
    {
        $('.dropdown-menu').click(function(event){
            event.stopPropagation();
        })

        // Token
        this.token = localStorage.getItem('token');
        this.data_types = [];
        this.is_loading = true;

        // API Lokasi
        axios.get(this.$endpoints.reference + 'data/type?token=' + this.token)
            .then(response => {
                for(var i=0; i<response.data.length; i++)
                {
                    this.data_types.push(response.data[i]);
                }
                this.is_loading = false;
            });
    },

    // Component
    components:{
        Loading,
        Select2,
        Datepicker,
        VueTimepicker,
        LineChart,
        Navbar,
        Breadcrumb,
        OperationSidebar,
    },

    // Fungsi
    methods:
    {
        dateFormat (classes, date) 
        {
            if (!classes.disabled) {
                classes.disabled = date.getTime() < new Date()
            }
            
            return classes
        },
        getDataPeriodic()
        {
            this.is_loading = true;
            this.periodik = [];
            this.chart_loaded = false;

            var q = [];

            // Tipe Lokasi
            if(this.tipe_lokasi !== null){
                q.push("type_id="+this.tipe_lokasi);
            }

            // Lokasi
            if(this.lokasi !== null){
                q.push("location_id="+this.lokasi);
            }

            // // API
            axios.get(this.$endpoints.reference + 'periodik/data/location?token=' + this.token + '&' + q.join('&'))
                .then(response => {
                    this.data_periodik = response.data.data;
                    this.is_loading = false;
                });

            // this.advanceFilter();

        },
        getLocationByType()
        {
            this.is_loading = true;
            this.locations = [];
            this.periodik = [];
            this.chart_loaded = false;

            axios.get(this.$endpoints.reference + 'data/location/type/' + this.tipe_lokasi + '?token=' + this.token)
            .then(response => {
                for(var i=0; i<response.data.length; i++)
                {
                    this.locations.push(response.data[i])
                }

                this.is_loading = false;
            })
        },
        setPeriodic(){
            let periodik = [];
            this.$refs.checkbox_periodic.forEach((cb) => {
                if(cb.checked){
                    periodik.push(parseInt(cb.value));
                }
            });

            this.periodik = periodik;
        },
        advanceFilter()
        {
            // var start = this.dateRange.startDate,
            //     end = this.dateRange.endDate,
            //     currentDate = new Date(start),
            //     between = [];
                
            // while(currentDate <= end) 
            // {
            //     between.push(moment(currentDate).format('YYYY-MM-DD'));
            //     currentDate.setDate(currentDate.getDate() + 1);
            // }

            var q = [];

            // Rata - rata
            if(this.rata_rata !== null){
                q.push("avg="+this.avg);
            }

            // Date
            if(this.date_start !== null && this.date_end !== null){
                q.push("start_date=" + moment(this.date_start).format('YYYY-MM-DD'));
                q.push("end_date=" + moment(this.date_end).format('YYYY-MM-DD'));
            }

            // Periodik
            if(this.periodik.length > 0){
                q.push("periodic_id="+this.periodik.join(','));
            }

            // Waktu
            if(this.waktu.HH != '' && this.waktu.mm != ''){
                q.push("time=" + this.waktu.HH + ':' + this.waktu.mm );
            }

            if(this.tipe_lokasi != ''){
                q.push(`type=${this.type_name}`);
            }
            
            this.chart_loaded = false;

            if(q.length > 0 && this.periodik.length > 0){
                this.charts = [];
                this.is_loading = true;
                this.chart_curah_hujan_loaded = false;
                this.chart_kualitas_air_loaded = false;
                this.chart_produksi_listrik_loaded = false;
                this.chart_waduk_loaded = false;


                axios.get(this.$endpoints.operasi+'seridata?token='+this.token+'&filter=1&'+q.join('&'))
                .then(response =>{
                    let cd = response.data;
                    this.is_loading = false;

                    switch(this.type_name){
                        case "BENDUNG":
                        case "INTAKE AIR BAKU":
                        case "SUNGAI":
                        case "SITU":
                        case "BANGUNAN BAGI UTAMA":
                        case "WADUK":
                            this.chart_waduk_loaded = true;
                            this.charts_data = Object.assign({}, cd);
                            this.charts_data.datasets = Object.assign([], cd.datasets.filter(ds => (ds.uom.toLowerCase() == 'meter' || ds.uom.toLowerCase() == 'm3/detik') ));
                            this.charts_data_listrik = Object.assign({}, cd);
                            this.charts_data_listrik.datasets = Object.assign([], cd.datasets.filter(ds => (ds.uom.toLowerCase() == 'kwh' || ds.uom.toLowerCase() == 'mw') )); 
                            this.charts_data_volume = Object.assign({}, cd);
                            this.charts_data_volume.datasets = Object.assign([], cd.datasets.filter(ds => (ds.uom.toLowerCase() == 'juta m3' || ds.uom.toLowerCase() == 'm3') )); 
                            break;

                        case "CURAH HUJAN":
                            this.chart_curah_hujan_loaded = true;
                            this.charts_data = cd;
                            break;

                        case "PRODUKSI LISTRIK":
                            this.chart_produksi_listrik_loaded = true;
                            this.charts_data = Object.assign({}, cd);
                            this.charts_data.datasets = Object.assign([], cd.datasets.filter(ds => ds.uom.toLowerCase() != 'unit'));
                            this.charts_data_unit = Object.assign({}, cd);
                            this.charts_data_unit.datasets = Object.assign([], cd.datasets.filter(ds => ds.uom.toLowerCase() == 'unit')); 
                            break;

                        case "KUALITAS AIR":
                            this.chart_kualitas_air_loaded = true;
                            this.charts_data = Object.assign({}, cd);
                            this.charts_data.datasets = Object.assign([], cd.datasets.filter(ds => ds.uom.toLowerCase() == 'mg/l' ));
                            this.charts_data_suhu = Object.assign({}, cd);
                            this.charts_data_suhu.datasets = Object.assign([], cd.datasets.filter(ds => ds.uom.toLowerCase() == 'oc' )); 
                            this.charts_data_kekeruhan = Object.assign({}, cd);
                            this.charts_data_kekeruhan.datasets = Object.assign([], cd.datasets.filter(ds => ds.uom.toLowerCase() == 'detik' || ds.uom.toLowerCase() == 'skala ntu' )); 
                            break;

                        
                    }

                }).catch((e) => {
                    console.log(e);
                    alert("error while filtering page. please contact developer");
                    this.is_loading = false;
                });

            }else{
                alert('silahkan pilih data periodik terlebih dahulu');
            }
        },
        
    },
    computed: {
        type_name(){
            if(this.tipe_lokasi != ''){
                let type = this.data_types.filter(type => type.id == this.tipe_lokasi);
                if(type.length > 0){
                    return type[0].text;
                }
            }

            return '';
        }
    },
    filters: {
        date(val) 
        {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    }

}
</script>

<style scoped>
.vue-daterange-picker{width: 100%}
[contenteditable]{
    border: 1px solid #ccc;
}

.btn-group>button{
    display: flex;
    text-align: left;
}

.btn-group>button:after{
    margin-left:auto;
    align-self: center;
}
</style>