<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Pembaruan Data'}]"/>
                <h2><b>Manajemen Data</b></h2>
                <div class="document">
                    <div class="row">
                        <div class="col-md-12">
                            <b-tabs nav-class="flat-tab">
                                <b-tab title="Hidrologi" active @click="setType('HIDROLOGI')"></b-tab>
                                <b-tab title="Klimatologi" @click="setType('KLIMATOLOGI')"></b-tab>
                                <b-tab title="Kualitas Air" @click="setType('KUALITAS AIR')"></b-tab>
                                <b-tab title="Produksi Listrik" @click="setType('PRODUKSI LISTRIK')"></b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="">Tipe lokasi</label>
                                <Select2 class="vue-select2" :options="locations_type" v-model="location_type_id" @change="getLocationsByTypeId()" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="">Lokasi</label>
                                <Select2 class="vue-select2" :options="locations" v-model="location_id" @change="periodic_id = ''; getPeriodicDataByLocationId();" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="">Data periodik</label>
                                <Select2 class="vue-select2" :options="periodic_data" v-model="periodic_id" />
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-2">
                            <label for="">Tanggal mulai</label>
                            <Datepicker v-model="date_start" input-class="form-control" />
                        </div>
                        <div class="col-md-2">
                            <label for="">Tanggal akhir</label>
                            <Datepicker v-model="date_end" input-class="form-control" />
                        </div>
                        <div class="col-md-8">
                            <br>
                            <button class="btn btn-primary" @click="getInputData(1)">Tampilkan</button>
                        </div>
                        <!-- <div class="col-sm-4">
                            <date-range-picker
                                ref="picker"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                                :showDropdowns="true"
                                :autoApply="true"
                                v-model="dateRange"
                                @update="getInputData(1)">  
                            <template v-slot:input="picker" style="width: 100%!important;">
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                            </template>
                        </date-range-picker>
                        </div> -->
                    </div>
                </div>
                <div class="document">
                    <!-- Table -->
                    <h4>
                        <b>Data {{ type | capitalize }}</b>
                        <div class="float-end">
                            <a href="" class="btn btn-sm btn-outline-danger text-black me-2" v-if="is_any_checkbox_checked" @click.prevent="batchDestroy"><img :src="require('@/assets/icon/trash.png')" alt="delete" /> <span class="d-none d-md-inline">hapus</span></a>
                            <a href="" class="btn btn-sm btn-outline-primary text-black border" @click.prevent="downloadCsv"><img :src="require('@/assets/icon/color-download.png')" alt="delete" /> <span class="d-none d-md-inline">export csv</span></a>
                        </div>
                    </h4>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-12 overflow-auto">
                            <hr>
                            per page
                            <select v-model="perPage" @change="getInputData">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>
                            <table class="table table-hover" id="table-hidrologi" ref="tabel_data">
                                <thead>
                                    <tr>
                                        <th width="2%"><p-check color="success" v-model="check" @change="toggleAllCheckBox"></p-check></th>
                                        <th @click="sortMePls($event, 'date_input')" class="sort-desc">Waktu <span><i class="fa fa-sort-down"></i></span></th>
                                        <th @click="sortMePls($event, 'location_name')">Lokasi <span></span></th>
                                        <th @click="sortMePls($event, 'periodic_name')">Nama Data <span></span></th>
                                        <th @click="sortMePls($event, 'variable_name')">Besaran <span></span></th>
                                        <th @click="sortMePls($event, 'value')">Nilai <span></span></th>
                                        <th @click="sortMePls($event, 'uom_name')">Satuan <span></span></th>
                                        <th @click="sortMePls($event, 'lapangan_officer_name')">Petugas <span></span></th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(hidrologi, index) in hidrologis" :key="hidrologi.id" v-bind:index="index">
                                        <td><p-check color="success" ref="cb" @change="toggleCheckbox" :data-id="hidrologi.id"></p-check></td>
                                        <td>
                                            {{ hidrologi.date_input }}<br>
                                            <small class="text-muted">{{ hidrologi.time_input ? hidrologi.time_input.toString().replace(/\:00$/g, ''):'' }}</small>
                                        </td>
                                        <td>{{ hidrologi.location_name }}</td>
                                        <td>{{ hidrologi.periodic_name }}</td>
                                        <td>{{ hidrologi.variable_name }}</td>
                                        <td :ref="'value_' + hidrologi.id" class="editable">{{ hidrologi.value }}</td>
                                        <td>{{ hidrologi.uom_name }}</td>
                                        <td>{{ hidrologi.lapangan_officer_name }}</td>
                                        <th>
                                            <template v-if="role !== 'guest'">
                                                <a href="" class="edit me-1" @click.prevent="setEditable(hidrologi.id)">
                                                    <img :src="require('@/assets/icon/color-edit.png')" alt="edit" />
                                                </a>
                                                <a href="" class="delete" @click.prevent="destroy(hidrologi.id, index)">
                                                    <img :src="require('@/assets/icon/color-trash.png')" alt="delete" />
                                                </a>
                                            </template>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            @change="getInputData"
                            pills></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import Select2 from 'v-select2-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {BTabs} from 'bootstrap-vue';
import $ from 'jquery';
import moment from 'moment';
import 'vue2-timepicker/dist/VueTimepicker.css'
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Datepicker from 'vuejs-datepicker';
import { BPagination } from 'bootstrap-vue'
import PrettyCheck from 'pretty-checkbox-vue/check';
import 'pretty-checkbox/dist/pretty-checkbox.min.css'

export default {
    name: 'OperationManageData',
    components: {
        Navbar,
        Breadcrumb,
        OperationSidebar,
        Select2,
        Loading,
        BTabs,
        BPagination,
        'p-check': PrettyCheck,
        Datepicker
    },
    data(){
        return {
            check: false,
            is_any_checkbox_checked: false,
            is_loading: false,
            token: null,
            items: {},
            name: '',
            q_name:'',
            q_code: '',
            is_searched: false,
            limit: 3,
            locations_type: [],
            locations: [],
            selected:{
                location:''
            },
            hidrologis : [],
            records: [],
            field_officer: [],
            entry_officer:[],
            periodic_data: [],
            currentPage: 1,
            rows: 0,
            perPage: 10,
            order: 'date_input',
            sort: 'DESC',

            // Variable input
            date_start: new Date(),
            date_end: new Date(),
            location_type_id: '',
            location_id: '',
            periodic_id: '',
            petugas_input_data_id: '',
            petugas_lapangan_id: '',
            date_input: '',
            time_input: '',
            role: '',
            type: 'HIDROLOGI'
        }
    },
    methods: {
        init(){
            this.hidrologis = [];
            this.is_loading = true;
            this.token = localStorage.getItem('token');

            // Function Get locations
            this.getTypes();
            this.getLocations();
            this.getInputData(1);


            // Only Number
            $('.only-number').keypress(function (e){
                var charCode = (e.which) ? e.which : e.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    return false;
                }
            });
        },
        destroy(id, index)
        {
            if(confirm('anda hendak menghapus data. anda yakin?'))
            {
                let form_data = new FormData();
                form_data.append('_method', 'DELETE');

                this.is_loading = true;

                axios.post(this.$endpoints.operasi + 'inputdata/' + id + '?token=' + this.token, form_data)
                .then(() => {
                    this.hidrologis.splice(index, 1);
                    this.is_loading = false;
                }).catch((e) => {
                    alert("error while deleting operator. please contact developer");
                    console.log(e)
                    this.is_loading = false;
                });
            }
        },
        batchDestroy()
        {
            if(confirm('anda hendak menghapus data terpilih. anda yakin?'))
            {
                this.is_loading = true;

                let ids = [];
                this.$refs.cb.forEach((cb) => {
                    cb.$el.childNodes.forEach((node) => {
                        if(node.nodeName == 'INPUT' && node.checked){
                            ids.push(cb.$attrs['data-id']);
                        }
                    });
                });

                let that = this;

                (function _destroy(i){
                    let form_data = new FormData();
                    form_data.append('_method', 'DELETE');

                    axios.post(that.$endpoints.operasi + 'inputdata/' + ids[i] + '?token=' + that.token, form_data)
                    .then(() => {
                        i++;
                        if(i < ids.length){
                            _destroy(i);
                        }else{
                            that.getInputData(1);
                            // this.is_loading = false;
                        }
                    }).catch((e) => {
                        console.log(e)
                    });
                })(0);
            }
        },
        getResults(page = 1)
        {
            this.is_loading = true;
            axios.get(this.$endpoints.reference+'operators?page='+page+'&name='+this.q_name+'&code='+this.q_code+'&token='+this.token)
                .then(response =>{
                    this.items = response.data
                    this.is_loading = false;
                }).catch(() => {
                    alert("error while loading page. please contact developer");
                    this.is_loading = false;
                });
        },
        filter(event)
        {
            this.is_searched = true;
            this.is_loading = true;

            axios.get(this.$endpoints.reference+'operators?name='+this.q_name+'&code='+this.q_code+'&token='+this.token)
                .then(response =>{
                    this.items = response.data
                    this.is_loading = false;
                }).catch(() => {
                    alert("error while filtering page. please contact developer");
                    this.is_loading = false;
                });

            event.preventDefault();
        },
        clear()
        {
            this.is_searched = false;
            this.q_name = '';
            this.q_code = '';
            this.getResults();
        },
        getLocations()
        {
            // console.log(this.$route.params.type);
            axios.get(this.$endpoints.reference + 'lokasi/operasi' + '/' + this.type)
            .then((response) => {
                this.locations = [];
                for(var i = 0; i < response.data.data.length; i++){
                    this.locations.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name,
                        type_id: response.data.data[i].type_id,
                        type_name: response.data.data[i].type_name
                    })
                }

                this.is_loading = false;
            })
            .catch( () => {
                console.log('error');
            })
        },
        getTypes()
        {
            this.locations_type = [];
            axios.get(this.$endpoints.reference + 'types/by_desc/' + this.type)
            .then((response) => {
                for(var i = 0; i < response.data.length; i++){
                    this.locations_type.push({
                        id: response.data[i].id,
                        text: response.data[i].name
                    })
                }

                console.log(this.locations_type);

                this.is_loading = false;
            })
            .catch( (e) => {
                console.log('error', e);
            })
        },
        getLocationsByTypeId()
        {
            this.is_loading = true;
            axios.get(this.$endpoints.reference + 'lokasi/type/' + this.location_type_id)
            .then((response) => {
                this.locations = [];
                for(var i = 0; i < response.data.length; i++){
                    this.locations.push(response.data[i]);
                }

                this.is_loading = false;
                this.getInputData(1);
            }).catch((e) => {
                console.log('error', e);
                this.is_loading = false;
            })
        },
        getPeriodicDataByLocationId(){
            this.periodic_data = [];

            axios.get(this.$endpoints.reference + 'periodik/data/location/' + this.location_id)
            .then((response) => {
                // this.is_loading = true;
                if(response.data.length > 0)
                {
                    this.periodic_data = response.data;
                    this.periodic_data.unshift({
                        text: 'SEMUA',
                        id: -9999
                    });
                }else{
                    this.periodic_data = [];
                }

                this.is_loading = false;
            }).catch((e) => {
                console.log('error',e );
                this.is_loading = false;
            });
        },
        getInputData(page)
        {
            this.is_loading = true;

            let query = [];
            query.push('type_id=' + this.location_type_id);
            query.push('location_id=' + this.location_id);
            if(this.periodic_id > 0){
                query.push('periodic_id=' + this.periodic_id);
            }
            query.push('start_date=' + moment(this.date_start).format('YYYY-MM-DD'));
            query.push('end_date=' + moment(this.date_end).format('YYYY-MM-DD'));
            query.push('page=' + page);
            query.push('per_page=' + this.perPage);
            query.push('order=' + this.order);
            query.push('sort=' + this.sort);

            axios.get(this.$endpoints.operasi + 'manage?' + query.join('&'))
            .then((response) => {
                this.hidrologis = response.data.data;
                this.currentPage = response.data.current_page;
                this.rows = response.data.total;

                this.is_loading = false;
            }).catch((e) => {
                console.log('error',e );
                this.is_loading = false;
            });
        },
        downloadCsv(){
            this.is_loading = true;

            let query = [];
            query.push('type_id=' + this.location_type_id);
            query.push('location_id=' + this.location_id);
            
            if(this.periodic_id > 0){
                query.push('periodic_id=' + this.periodic_id);
            }

            query.push('start_date=' + moment(this.date_start).format('YYYY-MM-DD'));
            query.push('end_date=' + moment(this.date_end).format('YYYY-MM-DD'));
            query.push('to_csv=1');
            query.push('order=' + this.order);
            query.push('sort=' + this.sort);

            axios.get(this.$endpoints.operasi + 'manage?' + query.join('&'),{
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'manage_data.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.is_loading = false;
            })
            .catch(() => alert('file not found'));
        },
        store(){
            var data = [];
            this.$refs.inputfield.forEach((v) => {
                data.push({
                    id: v.dataset.id,
                    type: v.dataset.type ?? 'number',
                    value: v.value,
                    name: v.dataset.name
                });
            });

            var fd = this.createFormData({
                location_id: this.location_id,
                location_name: this.getLocationName(),
                user_id: localStorage.getItem('id'),
                user_name: localStorage.getItem('name'),
                role: this.role,
                lapangan_officer_id: this.petugas_lapangan_id,
                lapangan_officer_name: this.getLapanganOfficerName(),
                input_officer_id: this.petugas_input_data_id,
                input_officer_name: this.getInputOfficerName(),
                date_input: moment(this.date_input).format('YYYY-MM-DD'),
                time_input: this.time_input,
                type_id: this.getTypeId(),
                type_name: this.getTypeName(),
                data: JSON.stringify(data)
            });
            
            this.is_loading = true;
            axios.post(this.$endpoints.operasi + 'batchsave?token=' + this.token, fd)
            .then(() =>{
                this.is_loading = false;
                this.$refs.inputfield.forEach((v) => {
                    v.value = '';
                });

                this.$swal('Pembaharuan data berhasil dilakukan');
            }).catch((error)=>{
                this.is_loading = false;
                console.log(error)
            })
        },
        getLocationName(){
            var name = null;
            for(var i = 0; i < this.locations.length; i++){
                if(this.locations[i].id == this.location_id){
                    name = this.locations[i].text;
                }
            }

            return name;
        },
        getLapanganOfficerName(){
            var  name = null;
            for(var i = 0; i < this.field_officer.length; i++){
                if(this.field_officer[i].id == this.petugas_lapangan_id){
                        name = this.field_officer[i].text;
                }
            }

            return  name;
        },
        getInputOfficerName(){
            var  name = null;
            for(var i = 0; i < this.entry_officer.length; i++){
                if(this.entry_officer[i].id == this.petugas_input_data_id){
                        name = this.entry_officer[i].text;
                }
            }

            return  name;
        },
        getTypeId(){
            var id = null;
            for(var i = 0; i < this.locations.length; i++){
                if(this.locations[i].id == this.location_id){
                    id = this.locations[i].type_id;
                }
            }

            return id;
        },
        getTypeName(){
            var name = null;
            for(var i = 0; i < this.locations.length; i++){
                if(this.locations[i].id == this.location_id){
                    name = this.locations[i].type_name;
                }
            }

            return name;
        },
        customFormatter(date) 
        {
            return moment(date).format('DD/MM/YYYY');
        },
        setType(type){
            this.type = type;
            // when changing tab, these values need to reset
            this.location_id = '';
            this.periodic_id = '';
            //
            this.getTypes();
            this.getLocations();
            // this.getInputData(1);
        },
        setEditable(id){
            document.querySelectorAll('.editable').forEach((td) => {
                let child = td.childNodes[0];
                if(child.nodeName == 'INPUT'){
                    td.innerHTML = child.value;
                }
            });

            
            let value = this.$refs['value_' + id][0].textContent;
            this.$refs['value_' + id][0].innerHTML = '<input type="text" value="'+value+'" style="width: 80px" @keypress="saveOnEnter"/>';
            this.$refs['value_' + id][0].childNodes[0].focus();
            this.$refs['value_' + id][0].childNodes[0].addEventListener('keydown', e => {
                console.log(e.keyCode);
                if(e.keyCode == 13){
                    this.is_loading = true;
                    let fd = this.createFormData({
                        value: e.target.value,
                        _method: 'PUT'
                    })
                    axios.post(this.$endpoints.operasi + 'inputdata/' + id, fd).then((response) => {
                        this.$refs['value_' + id][0].innerHTML = response.data.value;
                        this.is_loading = false;
                    }).catch(e => {
                        console.log(e);
                        this.is_loading = false;
                    });
                }else if(e.keyCode == 27){
                    this.$refs['value_' + id][0].innerHTML = value;
                }
            })
        },
        toggleCheckbox(){
            this.is_any_checkbox_checked = false;
            this.$refs.cb.forEach((cb) => {
                cb.$el.childNodes.forEach((node) => {
                    if(node.nodeName == 'INPUT' && node.checked){
                        this.is_any_checkbox_checked = true;
                        return;
                    }
                });
            });

            // console.log(this.is_any_checkbox_checked);
        },
        toggleAllCheckBox(){
            console.log(this.check);
            this.$refs.cb.forEach((cb) => {
                cb.$el.childNodes.forEach((node) => {
                    if(node.nodeName == 'INPUT'){
                        if(this.check && !node.checked){
                            node.click();
                        }else if(!this.check && node.checked){
                            // if(node.checked){
                                node.click();
                            // }
                        }
                    }
                });
                // console.log(cb.$el, cb.checked); 
            });
        },
        sortMePls(e, order){
            this.$refs['tabel_data'].querySelectorAll('th>span').forEach(span => {
                span.innerHTML = '';
            });

            this.order = order;

            e.target.querySelectorAll('span').forEach(span => {
                if(e.target.classList.contains('sort-desc')){
                    span.innerHTML = '<i class="fa fa-sort-up"></i>';
                    e.target.classList.remove('sort-desc');
                    e.target.classList.add('sort-asc');
                    this.sort = 'ASC';
                }else{
                    span.innerHTML = '<i class="fa fa-sort-down"></i>';
                    e.target.classList.remove('sort-asc');
                    e.target.classList.add('sort-desc');
                    this.sort = 'DESC';
                }
            });

            this.getInputData(1);

        }
    },
    filters:{
        capitalize(text){
            let result = [];
            let chunks = text.split(' ');
            for(let i = 0; i < chunks.length; i++){
                result.push(chunks[i].charAt(0).toUpperCase() + chunks[i].slice(1).toLowerCase());
            }

            return result.join(' ');
        },
        date(value){
            return moment(value).format("DD MMMM YYYY")
        }
    },
    mounted(){
        this.init();
        this.role = localStorage.getItem('role');

        axios.get(this.$endpoints.reference+'officers?token='+this.token)
        .then(response =>{
            // this.officers = response.data
            for(var i in response.data.data){
                if(response.data.data[i].type == 'LAPANGAN'){
                    this.field_officer.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name
                    });
                }else if(response.data.data[i].type == 'INPUT'){
                    this.entry_officer.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name
                    });
                }
            }
            this.is_loading = false;
        });
    }
}
</script>

<style>
.vue-daterange-picker{width: 100%}
[contenteditable]{
    border: 1px solid #ccc;
}
</style>