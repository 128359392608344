<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/operation/dashboard', text: 'Operasi'}, {url:'#', text: 'Pembaruan Data'}]"/>
                <h2><b>Pembaruan Data</b></h2>
                <div class="document">
                    <div class="row">
                        <div class="col-md-12">
                            <b-tabs nav-class="flat-tab">
                                <b-tab title="Hidrlogi" active @click="setType('HIDROLOGI')"></b-tab>
                                <b-tab title="Klimatologi" @click="setType('KLIMATOLOGI')"></b-tab>
                                <b-tab title="Kualitas Air" @click="setType('KUALITAS AIR')"></b-tab>
                                <b-tab title="Produksi Listrik" @click="setType('PRODUKSI LISTRIK')"></b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="">Lokasi</label>
                                <Select2 class="vue-select2" :options="locations" v-model="location_id" @change="getLocationsById()" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="">Petugas lapangan</label>
                                <Select2 class="vue-select2" :settings="orgFSelectSettings" v-model="petugas_lapangan_id" id="fo" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="">Petugas input</label>
                                <Select2 class="vue-select2" :settings="orgISelectSettings" v-model="petugas_input_data_id" id="io" />
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            <label for="">Waktu pengamatan</label>
                            <datepicker v-model="date_input" name="date_input" placeholder="Select date" input-class="form-control" :format="customFormatter"></datepicker>
                        </div>
                        <div class="col-sm-4">
                            <label for="">Jam pengamatan</label>
                            <br>
                            <vue-timepicker v-model="time_input" input-class="form-control"></vue-timepicker>
                        </div>
                    </div>
                </div>
                <div class="document">
                    <!-- Table -->
                    <h4>
                        <b>Data {{ type | capitalize }}</b>
                        <button type="button" v-on:click="store" class="btn btn-xs btn-primary mr-1 float-end"><i class="fa fa-save"></i> <span class="d-none d-md-inline">Simpan Batch Input</span></button>
                    </h4>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-12">
                            <table class="table table-hover" id="table-hidrologi">
                                <thead>
                                    <tr>
                                        <th>Nama Data</th>
                                        <th width="20%">Besaran</th>
                                        <th width="8%">Nilai</th>
                                        <th width="10%">Satuan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(hidrologi, index) in hidrologis" :key="hidrologi.id" v-bind:index="index">
                                        <td>{{ hidrologi.name }}</td>
                                        <td>{{ hidrologi.variable_name }}</td>
                                        <td>
                                            <input type="text" name="records" id="records" class="only-number" ref="inputfield" :data-id="hidrologi.id" :data-type="hidrologi.type" :data-name="hidrologi.name">
                                        </td>
                                        <td>{{ hidrologi.uom_name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import Select2 from 'v-select2-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {BTabs} from 'bootstrap-vue';
import $ from 'jquery';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    name: 'OperationInputData',
    components: {
        Navbar,
        Breadcrumb,
        OperationSidebar,
        Select2,
        Loading,
        BTabs,
        Datepicker,
        VueTimepicker
    },
    methods: {
        init(){
            this.hidrologis = [];
            this.is_loading = true;
            this.token = localStorage.getItem('token');

            // Function Get locations
            this.getLocations();


            // Only Number
            $('.only-number').keypress(function (e){
                var charCode = (e.which) ? e.which : e.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    return false;
                }
            });
        },
        destroy(id, index)
        {
            if(confirm('anda hendak menghapus data. anda yakin?'))
            {
                let form_data = new FormData();
                form_data.append('_method', 'DELETE');

                this.is_loading = true;

                axios.post(this.$endpoints.reference + 'operators/' + id + '?token=' + this.token, form_data)
                .then(() => {
                    this.items.data.splice(index, 1);
                    this.is_loading = false;
                }).catch(() => {
                    alert("error while deleting operator. please contact developer");
                    this.is_loading = false;
                });
            }
        },
        getResults(page = 1)
        {
            this.is_loading = true;
            axios.get(this.$endpoints.reference+'operators?page='+page+'&name='+this.q_name+'&code='+this.q_code+'&token='+this.token)
                .then(response =>{
                    this.items = response.data
                    this.is_loading = false;
                }).catch(() => {
                    alert("error while loading page. please contact developer");
                    this.is_loading = false;
                });
        },
        filter(event)
        {
            this.is_searched = true;
            this.is_loading = true;

            axios.get(this.$endpoints.reference+'operators?name='+this.q_name+'&code='+this.q_code+'&token='+this.token)
                .then(response =>{
                    this.items = response.data
                    this.is_loading = false;
                }).catch(() => {
                    alert("error while filtering page. please contact developer");
                    this.is_loading = false;
                });

            event.preventDefault();
        },
        clear()
        {
            this.is_searched = false;
            this.q_name = '';
            this.q_code = '';
            this.getResults();
        },
        getLocations()
        {
            // console.log(this.$route.params.type);
            axios.get(this.$endpoints.reference + 'lokasi/operasi' + '/' + this.type)
            .then((response) => {
                this.locations = [];
                for(var i = 0; i < response.data.data.length; i++){
                    this.locations.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name,
                        type_id: response.data.data[i].type_id,
                        type_name: response.data.data[i].type_name
                    })
                }

                this.is_loading = false;
            })
            .catch( () => {
                console.log('error');
            })
        },
        getLocationsById()
        {
            this.is_loading = true;
            axios.get(this.$endpoints.reference + 'data/lokasi' + '/' + this.location_id + '/' + this.type)
            .then((response) => {
                // this.is_loading = true;
                if(response.data.data.length > 0)
                {
                    this.hidrologis = response.data.data;
                }else{
                    this.hidrologis = [];
                }

                this.is_loading = false;
            }).catch(() => {
                console.log('error');
                this.is_loading = false;
            })
        },
        store(){
            var data = [];
            this.$refs.inputfield.forEach((v) => {
                data.push({
                    id: v.dataset.id,
                    type: v.dataset.type ?? 'number',
                    value: v.value,
                    name: v.dataset.name
                });
            });

            var fd = this.createFormData({
                location_id: this.location_id,
                location_name: this.getLocationName(),
                user_id: localStorage.getItem('id'),
                user_name: localStorage.getItem('name'),
                role: localStorage.getItem('role'),
                lapangan_officer_id: this.petugas_lapangan_id,
                lapangan_officer_name: this.getLapanganOfficerName(),
                input_officer_id: this.petugas_input_data_id,
                input_officer_name: this.getInputOfficerName(),
                date_input: moment(this.date_input).format('YYYY-MM-DD'),
                time_input: this.time_input,
                type_id: this.getTypeId(),
                type_name: this.getTypeName(),
                data: JSON.stringify(data)
            });
            
            this.is_loading = true;
            axios.post(this.$endpoints.operasi + 'batchsave?token=' + this.token, fd)
            .then((response) =>{
                this.is_loading = false;
                this.$refs.inputfield.forEach((v) => {
                    v.value = '';
                });

                if(response.data.length > 0){
                    this.$swal({
                        icon: "error",
                        title: "Terjadi kesalahan!",
                        html: "<ul><li>"+response.data.join("<li>")+"</li></ul>",
                        customClass: {
                            htmlContainer: "text-start"
                        }
                    });
                }else{
                    this.$swal('Pembaharuan data berhasil dilakukan');
                }

            }).catch((error)=>{
                this.is_loading = false;
                console.log(error)
            })
        },
        getLocationName(){
            var name = null;
            for(var i = 0; i < this.locations.length; i++){
                if(this.locations[i].id == this.location_id){
                    name = this.locations[i].text;
                }
            }

            return name;
        },
        getLapanganOfficerName(){
            let that = this;
            let name = '';
            document.querySelector('#fo').childNodes.forEach(node => {
                if(node.nodeName == 'OPTION'){
                    if(node.value == that.petugas_lapangan_id){
                        name = node.textContent;
                    }
                }
            });

            return name;
        },
        getInputOfficerName(){
            let that = this;
            let name = '';
            document.querySelector('#io').childNodes.forEach(node => {
                if(node.nodeName == 'OPTION'){
                    if(node.value == that.petugas_input_data_id){
                        name = node.textContent;
                    }
                }
            });

            return name;
        },
        getTypeId(){
            var id = null;
            for(var i = 0; i < this.locations.length; i++){
                if(this.locations[i].id == this.location_id){
                    id = this.locations[i].type_id;
                }
            }

            return id;
        },
        getTypeName(){
            var name = null;
            for(var i = 0; i < this.locations.length; i++){
                if(this.locations[i].id == this.location_id){
                    name = this.locations[i].type_name;
                }
            }

            return name;
        },
        customFormatter(date) 
        {
            return moment(date).format('DD/MM/YYYY');
        },
        setType(type){
            this.type = type;
            this.getLocations();
        }
    },
    filters:{
        capitalize(text){
            let result = [];
            let chunks = text.split(' ');
            for(let i = 0; i < chunks.length; i++){
                result.push(chunks[i].charAt(0).toUpperCase() + chunks[i].slice(1).toLowerCase());
            }

            console.log(result);

            return result.join(' ');
        }
    },
    data(){
        return {
            is_loading: false,
            token: null,
            items: {},
            name: '',
            q_name:'',
            q_code: '',
            is_searched: false,
            limit: 3,
            locations: [],
            selected:{
                location:''
            },
            hidrologis : [],
            records: [],
            field_officer: [],
            entry_officer:[],

            // Variable input
            location_id: '',
            petugas_input_data_id: '',
            petugas_lapangan_id: '',
            date_input: '',
            time_input: '',

            type: 'HIDROLOGI',
            orgFSelectSettings: {
                ajax: {
                    url: this.$endpoints.reference+'organizations/search?token='+this.token,
                    delay: 250,
                    processResults: (data) => {
                        var results = [];
                        for(let i = 0 ; i < data.length; i++){
                            results.push({
                                id: data[i].id,
                                text: data[i].name
                            });
                        }

                        return {
                            results
                        };
                    } 
                },
                minimumInputLength: 1
            },
            orgISelectSettings: {
                ajax: {
                    url: this.$endpoints.reference+'organizations/search?token='+this.token,
                    delay: 250,
                    processResults: (data) => {
                        var results = [];
                        for(let i = 0 ; i < data.length; i++){
                            results.push({
                                id: data[i].id,
                                text: data[i].name
                            });
                        }

                        return {
                            results
                        };
                    } 
                },
                minimumInputLength: 1
            },
        }
    },
    mounted(){
        this.init();

        axios.get(this.$endpoints.reference+'organizations?token='+this.token)
        .then(response =>{
            // this.officers = response.data
            for(var i in response.data.data){
                if(response.data.data[i].type == 'LAPANGAN'){
                    this.field_officer.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name
                    });
                }else if(response.data.data[i].type == 'INPUT'){
                    this.entry_officer.push({
                        id: response.data.data[i].id,
                        text: response.data.data[i].name
                    });
                }
            }
            this.is_loading = false;
        });
    }
}
</script>

<style>

</style>