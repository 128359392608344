<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div class="sidebar">
                <OperationSidebar />
            </div>
            <div id="main" class="main">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '#', text: 'Operasi'}]"/>
                <h2><b>Overview</b></h2>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-6">
                                        Peta
                                    </div>
                                    <div class="col-md-6">
                                        <Select2 class="vue-select2" :options="type" v-model="type_id" @change="changeType()" :settings="{width: '100%'}"></Select2>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <GmapMap
                                    :center="{lat: -6.5341128, lng: 107.3428813}"
                                    :zoom="8"
                                    map-type-id="roadmap"
                                    style="width: 100%; height: 300px"
                                    ref="mapRef">
                                        <GmapCluster>
                                            <GmapMarker
                                                v-for="(marker, index) in markers"
                                                :key="index"
                                                :icon="{url: require('../../assets/marker/icon-awlr-biru.png')}"
                                                :clickable="true"
                                                :position="{lat: parseFloat(marker.lng), lng:parseFloat(marker.lat)}"
                                                :title="marker.location_name + (marker.location_id == selectedMarker ? '-selected':'')"
                                                :ref="'marker_' + marker.location_id"
                                                @click="changeData(marker.location_id, marker.location_name, true);">
                                            </GmapMarker>
                                        </GmapCluster>
                                </GmapMap>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                Data Lokasi
                                <div class="float-end">
                                    <input type="text" v-model="cari" class="form-control" placeholder="Cari Lokasi Lain">
                                </div>
                            </div>
                            <div class="card-body">
                                <div style="height: 300px;overflow:auto">
                                    <table class="table" v-if="!is_loading_latest_data">
                                        <thead>
                                            <tr style="cursor: pointer;">
                                                <th width="10%">ID</th>
                                                <th>Nama / Lokasi</th>
                                                <th>Nilai Terakhir</th>
                                                <th>Jam Input Terakhir</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(periodik, index) in resultQuery" :key="index" v-bind:index="index" @click="changeData(periodik.location_id, periodik.location_name, true)">
                                                <td scope="row">{{ periodik.id }}</td>
                                                <td>{{ periodik.name }}<br /><span class="text-muted">{{ periodik.location_name }}</span></td>
                                                <td scope="row">{{ periodik.value }} <span class="text-muted">{{ periodik.uom_name }}</span></td>
                                                <td scope="row">{{ periodik.time_input }}<br/><span class="text-muted">{{ periodik.date_input }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="text-center" v-if="is_loading_latest_data">
                                        <b>Sedang memuat data terakhir...</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">Last Updated</div>
                            <div class="card-body">
                                <div style="height: 300px; overflow: auto">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Lokasi</th>
                                                <th>Jenis</th>
                                                <th>Jam Input Terakhir</th>
                                                <th>Total Data</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="(input, index) in inputdata" :key="index" v-bind:index="index">
                                            <tr>
                                                <td>{{ input.location_id }}</td>
                                                <td>{{ input.location_name }}</td>
                                                <td>{{ input.data[0].type_desc }}</td>
                                                <td>{{ input.data[0].last_updated_at | get_only_hour }}</td>
                                                <td>
                                                    <div class="progress">
                                                        <div class="progress-bar bg-success" :style="'width: ' + totalProgress(input.data[0]) + '%'"></div>
                                                        <div class="progress-bar bg-danger" :style="'width: ' + hutangProgress(input.data[0].data_count) + '%'"></div>
                                                        <div class="progress-bar"></div>
                                                    </div>
                                                    {{ input.data[0].data_count > dataPerPeriodic(input.data[0].type_desc) ? dataPerPeriodic(input.data[0].type_desc):input.data[0].data_count }} / {{ dataPerPeriodic(input.data[0].type_desc) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-6">
                                        Grafik {{ current_location_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <line-chart :chart-data="charts_data" :styles="chart_styles" :options="chart_options" v-if="type_id != 'KLIMATOLOGI'"></line-chart>
                                <bar-chart :chart-data="charts_data" :styles="chart_styles" :options="chart_options" v-if="type_id == 'KLIMATOLOGI'"></bar-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card" style="overflow-x: auto">
                            <div class="card-header">
                                Tabel Data {{ current_location_name }}
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width="20%">Jam Input</th>
                                            <th v-for="(periodic, index) in table_data" :key="index" class="sortme-pls">{{ periodic.periodic }}<br/><span style="font-weight:300; font-size: 12px">({{ periodic.uom }})</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(time, index) in charts_data.labels" :key="index">
                                            <td>{{ time }}</td>
                                            <td v-for="(periodic, i) in charts_data.datasets" :key="i">{{ periodic.data[index] == null ? '-':periodic.data[index] }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
import OperationSidebar from '../../components/OperationSidebar.vue'
import Select2 from 'v-select2-component';
import axios from 'axios';
import LineChart from '../../components/chart/LineChart.vue'
import BarChart from '../../components/chart/BarChart.vue'
import Loading from 'vue-loading-overlay';
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment'

export default {
    name: 'OperationDashboard',
    components: {
        Navbar,
        Breadcrumb,
        OperationSidebar,
        Select2,
        LineChart,
        BarChart,
        Loading,
        GmapCluster
    },
    computed: {
        resultQuery(){
            // return this.periodiks;
            
            if(this.cari){
                return this.periodiks.filter((item)=>{
                    return this.cari.toLowerCase().split(' ').every(v => item.location_name.toLowerCase().includes(v))
                })
            }else if(this.location_changed){
                return this.periodiks.filter(v => v.location_id == this.selectedMarker);
            }else{
                if(this.inputdata.length > 0){
                    return this.periodiks.filter(v => v.location_id == this.inputdata[0].location_id);
                }

                return [];
            }
        }
    },
    filters: {
        get_only_hour(value){
            return moment(value).format("HH:mm");
        }
    },
    methods: {
        dataPerPeriodic(type_desc){
            switch(type_desc){
                case "HIDROLOGI":
                case "PRODUKSI LISTRIK":
                    return (new Date()).getHours();

                case "KLIMATOLOGI":
                case "KUALITAS AIR":
                    return "1";
            }
        },
        totalProgress(input_data, total_data){
            if(typeof total_data === 'undefined'){
                total_data = 24;
            }

            const progress = input_data.data_count > this.dataPerPeriodic(input_data.type_desc) ? this.dataPerPeriodic(input_data.type_desc):input_data.data_count;

            return progress / total_data * 100;
        },
        hutangProgress(progress, total_data){
            if(typeof total_data === 'undefined'){
                total_data = 24;
            }

            let hutang = (new Date()).getHours() - progress;
            if(hutang > 0){
                return hutang / total_data * 100;
            }

            return 0;
        },
        changeType(){
            this.inputdata = [];
            this.charts_data = {
                datasets: [],
                labels: []
            };

            this.original_inputdata.forEach(data => {
                let found = false;
                let _data = {
                    location_name: data.location_name,
                    location_id: data.location_id,
                    data: []
                };

                data.data.forEach(d => {
                    if(d.type_desc == this.type_id){
                        found = true;
                        _data.data.push(d);
                    }
                });

                if(found){
                    this.inputdata.push(_data);
                }
            });

            this.getData();

            // this.is_loading = true;
            // // this.token = localStorage.getItem('token');

            // // Var
            // var q = [];
            // var q1 = [];

            // // Type
            // q.push("case=location");
            // q1.push("case=all");

            // // Type
            // if(this.type_id !== null){
            //     q.push("type=" + this.type_id);
            //     q1.push("type=" + this.type_id);
            // }

            // console.log(this.type_id)

            // axios.get(this.$endpoints.reference + 'periodik/location/data?token=' + this.token + '&per_page=' + this.per_page + '&' + q1.join('&'))
            //     .then(response => {
            //         this.periodiks = response.data;
            //         this.is_loading = false;
            //     });

            this.getDataInput(this.type_id)



        },
        defineIcon(fungsi, fisik){
            let value = (parseFloat(fungsi) + parseFloat(fisik)) / 2;
            if(value <= 50){
                return require('../../assets/icon/grade3.png');
            }else if(value > 50 && value < 70){
                return require('../../assets/icon/grade2.png');
            }else{
                return require('../../assets/icon/grade1.png');
            }
        },
        changeData(id, name, require_to_set_location_changed){
            this.selectedMarker = id;
            this.is_loading = true;
            this.current_location_name = name;

            if(require_to_set_location_changed){
                this.location_changed = true;
            }

            let marker = this.markers.filter(marker => marker.location_id == id);
            if(marker.length > 0){
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo({lat: parseFloat(marker[0].lng), lng: parseFloat(marker[0].lat)});
                    map.setZoom(12);
                })
            }

            // Variable
            var q  = [];
            this.token = localStorage.getItem('token');
            q.push('case=location_id');
            q.push('location_id=' + id);
            // API
            axios.get(this.$endpoints.reference + 'periodik/location/data?token=' + this.token + '&' + q.join('&'))
            .then(() => {
                axios.get(this.$endpoints.operasi + 'location/' + id + '/data')
                .then((response) => {
                    this.charts_data = response.data;
                    this.table_data = response.data.datasets;

                    if(this.type_id != 'HIDROLOGI'){
                        this.chart_options.scales.yAxes = [{
                            display: true,
                            position: "left",
                            id: "y-axis-1"
                        }]
                    }else{
                        this.chart_options.scales.yAxes = [{
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            scaleLabel: {
                                display: true,
                                labelString: "meter"
                            }
                        },{
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            scaleLabel: {
                                display: true,
                                labelString: "m3/detik"
                            }
                        }]
                    }

                    this.is_loading = false;
                })
            });
        },
        getData(){ // method to show data in the Peta and Data Lokasi box
            var q = [];
            // All Data
            q.push("case=all");
            // Type
            if(this.type_id == null){
                q.push("type=HIDROLOGI");
            }else{
                q.push("type=" + this.type_id);
            }

            this.is_loading = true;
            this.markers = [];

            axios.get(this.$endpoints.reference + 'periodik/location/data?token=' + this.token + '&' + q.join('&'))
            .then(response => {
                // this.periodiks = response.data;
                
                response.data.forEach((item) => {
                    let found = false;
                    for(let i = 0; i < this.markers.length; i++){
                        if(this.markers[i].location_id == item.location_id){
                            found = true;
                            break;
                        }
                    }

                    if(!found && item.lat !== null && item.lng !== null){
                        this.markers.push({
                            lat: item.lat,
                            lng: item.lng,
                            location_name: item.location_name,
                            location_id: item.location_id
                        });
                    }
                });

                this.is_loading = false;
                this.is_loading_latest_data = true;
                axios.get(this.$endpoints.operasi + 'periodik/get_latest_data?token=' + this.token)
                .then(response2 => {
                    let periodiks = [];

                    response.data.forEach(periodik => {
                        response2.data.forEach(data => {
                            if(periodik.id == data.periodic_id){
                                periodik.time_input = data.time_input;
                                periodik.date_input = data.date_input;
                                periodik.value = data.value;
                                periodik.uom_name = data.uom_name;
                            }
                        });

                        periodiks.push(periodik);
                    })

                    this.periodiks = periodiks;
                    this.is_loading_latest_data = false;
                    // this.filtered_periodiks = this.periodiks.filter(periodik => periodik.location_id == 7);
                })
                .catch(e => {
                    console.log(e.message);
                })
            });

        },
        getDataInput(type) // method to show data in Last Update box
        {
            var q = [];
            if(type == null){
                q.push("type=HIDROLOGI");
            }else{
                q.push("type=" + type);
            }

            this.is_loading = true;

            axios.get(this.$endpoints.operasi + 'periodik/inputdata?token=' + this.token + '&' + q.join('&'))
            .then(response => {
                this.original_inputdata = response.data;

                this.original_inputdata.forEach(ori => {
                    let found = false;
                    let _data = {
                        location_name: ori.location_name,
                        location_id: ori.location_id,
                        data: []
                    };

                    // loop data and filter current selected data type (HIDROLOGI | KLIMATOLOGI | KUALITAS AIR | PRODUKSI LISTRIK)
                    ori.data.forEach(d => {
                        if(d.type_desc == this.type_id){
                            found = true;
                            _data.data.push(d);
                        }
                    });

                    _data.data.sort((a, b) => {
                        const today = moment().format("YYYY-MM-DD");

                        let a_last_update = a.last_updated_at ?? (today + ' 00:00:00');
                        let b_last_update = b.last_updated_at ?? (today + ' 00:00:00');

                        return Date.parse(b_last_update) - Date.parse(a_last_update)
                    });

                    if(found){
                        this.inputdata.push(_data);
                    }
                });

                this.inputdata.sort((a, b) => {
                    var a_last_update, b_last_update;

                    const today = moment().format("YYYY-MM-DD");

                    if(a.data.length > 0){
                        a_last_update = a.data[0].last_updated_at ?? (today + ' 00:00:00');
                    }else{
                        a_last_update = (today + ' 00:00:00');
                    }

                    if(b.data.length > 0){
                        b_last_update = b.data[0].last_updated_at ?? (today + ' 00:00:00');
                    }else{
                        b_last_update = (today + ' 00:00:00');
                    }

                    console.log(a_last_update, b_last_update);

                    return Date.parse(b_last_update) - Date.parse(a_last_update)
                });

                // set selected marker by default to the latest updated location
                // this.selectedMarker = this.inputdata[0].location_id;
                this.changeData(this.inputdata[0].location_id, this.inputdata[0].location_name, false);

                this.is_loading = false;
            });
        },
        getInputDataToday(location, periodic){
            this.current_periodic_data.name = periodic.periodic_name;
            this.current_periodic_data.location_name = location.location_name;

            axios.get(this.$endpoints.operasi + 'inputdata/get_today_data/'+ location.location_id +'/'+ periodic.periodic_id+'?token=' + this.token)
            .then(response => {
                this.tabular = response.data;
                this.is_loading = false;
                if(response.data.length > 0){
                    let data = [];
                    let dates = [];
                    response.data.forEach((item) => {
                        data.push({
                            x: item.date_input + " " + item.time_input,
                            y: item.value
                        });

                        dates.push(item.date_input + " " + item.time_input);
                    });

                    dates.sort((a, b) => new Date(a) - new Date(b));

                    this.charts_harian = {
                        labels: dates,
                        datasets: [
                            {
                                label: this.current_periodic_data.name + " (" + response.data[0].uom_name + ")",
                                fill: false,
                                borderColor: '#007DBE',
                                data: data,
                                lineTension: 0
                            }
                        ]
                    };

                    localStorage.setItem('charts_harian', JSON.stringify(this.charts_harian));
                    this.charts_data = JSON.parse(localStorage.getItem('charts_harian'));
                }
            })
            .catch((e) => {
                alert("error while filtering page. please contact developer");
                console.log(e)
                this.is_loading = false;
            });
        }
    },
    data(){
        return {
            is_loading: false,
            is_loading_latest_data: false,
            periodiks: [],
            filtered_periodiks: [],
            markers: [],
            original_inputdata: [],
            inputdata: [],
            charts: [],
            labels: [], 
            per_page: 10,
            limit: 3,
            tabular:[],
            charts_harian: {
                labels: [],
                datasets: []
            },
            charts_mingguan: {
                labels: [],
                datasets: []
            },
            charts_bulanan: {
                labels: [],
                datasets: []
            },
            usedInt: [],
            is_chart_harian: false,
            is_chart_mingguan: false,
            is_chart_bulanan: false,
            selectedMarker: null,
            type_id: 'HIDROLOGI',
            current_periodic_data: {
                name: '',
                location_name: '',
                id: null
            },
            current_location_name: "",
            chart_periode: 'harian',
            chart_options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "meter"
                        }
                    },{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                            display: true,
                            labelString: "m3/detik"
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },
            table_data:[],
            token: null,
            chart_styles: {
                height: "300px"
            },
            charts_data: {
                datasets: [],
                labels: []
            },
            type: [
                {
                    id: 'HIDROLOGI',
                    text: 'HIDROLOGI',
                },
                {
                    id: 'KLIMATOLOGI',
                    text: 'KLIMATOLOGI',
                },
                {
                    id: 'KUALITAS AIR',
                    text: 'KUALITAS AIR',
                },
                {
                    id: 'PRODUKSI LISTRIK',
                    text: 'PRODUKSI LISTRIK',
                },
            ],
            cari: null,
            location_changed: false
        };
    },
    mounted(){
        this.token = localStorage.getItem('token') 
        
        this.getData();
        this.getDataInput();
        setInterval(() => {
            // // when auto refresh don't change selection
            // // so we keep the selection in a variable because method changeInputData by nature will reset the chart_periode selection to "harian"
            // // whenever any click on "Data Periodik" table row occured.
            // let chart_periode = this.chart_periode;

            // this.token = localStorage.getItem('token')

            // // exclude getData, this method will reset Data Periodik table
            // // this.getData();

            // if(this.selectedMarker){
            //     this.changeData(this.selectedMarker);
            // }

            // if(this.current_periodic_data.id){
            //     this.changeInputData(this.current_periodic_data);
            // }

            // // restore the chart_periode after being updated by changeInputData
            // this.chart_periode = chart_periode;
        }, this.$refresh_duration);

        // SORT TABLE
        // document.querySelectorAll('.sortme-pls').forEach(th => th.addEventListener('click', (() => {
        //     const table = th.closest('table');
        //     const tbody = table.querySelector('tbody');

        //     Array.from(tbody.querySelectorAll('tr'))
        //     .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
        //     .forEach(tr => tbody.appendChild(tr));
        // })));
    }
}
</script>

<style>
    @keyframes pulsate {
        from {
            transform: scale(0.25);
            opacity: 1.0;
        }
        95% {
            transform: scale(1.3);
            opacity: 0;
        }
        to {
            transform: scale(0.3);
            opacity: 0;
        }
    }

    div[title*="-selected"]{
        background: #0c0;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 0!important;
        overflow: visible!important;
    }

    div[title*="-selected"] img{
        display: none!important;
        width: 0;
        height: 0;
    }

    div[title*="-selected"]:after{
        content: '';
        display: block;
        animation: pulsate 1.5s ease-in-out infinite;
        background: transparent;
        
        -moz-border-radius:51px;
        -webkit-border-radius:51px;
        border-radius:51px;
        
        -moz-box-shadow:inset 0 0 5px #0f6, inset 0 0 5px #0f6, inset 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6;
        -webkit-box-shadow:inset 0 0 5px #0f6, inset 0 0 5px #0f6, inset 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6;
        box-shadow:inset 0 0 5px #0f6, inset 0 0 5px #0f6, inset 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6, 0 0 5px #0f6;
        
        height:51px!important;
        margin:-18px 0 0 -18px;
        width:51px!important;
        z-index: 9!important;
    }
</style>