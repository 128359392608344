<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <!-- Navbar -->
        <Navbar />

        <!-- Sidebar -->
        <SidebarSpatial />

        <main id="main" class="main">
            <!-- Breadcomp -->
            <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/telemeteri/', text: 'Telemetri'}, {url:'#', text: 'Diagnostik'}]" />
            <!-- END OF BREADCOMP -->

            <section class="section dashboard">
                <div class="nav nav-tabs">
                    <li class="nav-item">
                        <a href="" class="nav-link" :class="current_tab == 'radisa' ? 'active':''" @click.prevent="current_tab = 'radisa'">Radisa</a>
                    </li>
                </div>
                <div class="tab-content">
                    <div style="min-height: 500px" class="tab-pane bg-white p-3" :class="current_tab == 'radisa' ? 'active':''">
                        <div class="row border-bottom pb-3 mb-3">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Tanggal</label>
                                    <datepicker v-model="radisa.date" name="date_input" placeholder="Select date" input-class="form-control"></datepicker>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="">Jam mulai</label><br>
                                    <vue-timepicker v-model="radisa.time_start" input-class="form-control"></vue-timepicker>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="">Jam akhir</label><br>
                                    <vue-timepicker v-model="radisa.time_end" input-class="form-control"></vue-timepicker>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <br>
                                <button class="btn btn-primary" @click="checkRadisa">Periksa</button>
                            </div>
                        </div>
                        <pre v-html="radisa.result"></pre>
                    </div>
                </div>
            </section>
        </main>
    </div>

</template>

<script>

// import
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
// Component
import Navbar from '../../components/Navbar.vue'
import SidebarSpatial from '../../components/SidebarTelemeteri.vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
  import moment from 'moment'
//   import Select2 from 'v-select2-component';

export default {
    name: 'TelemtriDiagnostic',
    components: {
        Breadcrumb,
        Navbar,
        SidebarSpatial,
        Loading,
        Datepicker,
        VueTimepicker
    },
    data() {
        return {
            is_loading: false,
            current_tab: 'radisa',
            radisa: {
                url: "http://74.208.130.22:9099/api/rads-telemetri/measure/userID/XBA356PQ",
                date: new Date(),
                time_start: '00:00',
                time_end: '01:00',
                result: ''
            }
        }
    },
    methods: {
        checkRadisa(){
            const date = moment(this.radisa.date).format("YYYY-MM-DD");
            let url = `${this.radisa.url},${date}%20${this.radisa.time_start}:00,${date}%20${this.radisa.time_end}:00`;
            console.log(url)

            axios.get(url)
            .then(response => {
                if(Object.keys(response.data).length > 0){
                    let unique_locations = []
			
                    for(let k in response.data){
                        for(let l in response.data[k]){
                            if(Object.prototype.hasOwnProperty.call(response.data[k][l], 'Location_Name') && unique_locations.indexOf(response.data[k][l].Location_Name) === -1){
                                unique_locations.push(response.data[k][l].Location_Name);
                            }
                        }
                    }

                    this.radisa.result = unique_locations.map((loc, i) => `${i+1}. ${loc.trim()}`).join("\n");
                }else{
                    this.radisa.result = '<span class="text-danger"><b>TIDAK ADA DATA</b></span>';
                }
            })
        }
    }
}

</script>

<style>
.vue__time-picker,
.vue__time-picker input{
    width: 100%!important;
    height: 2.5em
}
</style>