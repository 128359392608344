<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
                <router-link class="nav-link" to="/sarpras/dashboard" inactive-class="collapsed">
                    <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                    <span>Overview</span>
                </router-link>
            </li>

            <!-- <li class="nav-item">
                <router-link class="nav-link" to="/sarpras/dashboard-field" inactive-class="collapsed">
                    <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                    <span>Overview (field)</span>
                </router-link>
            </li>
            
            <li class="nav-item">
                <router-link to="/sarpras/infrastructure/index" class="nav-link" inactive-class="collapsed">
                    <img src="../assets/icon/sarpras-drawer.png" alt="" style="width: 30px; padding-right: 5px;">
                    <span>Sarpras Item (field)</span>
                </router-link>
            </li> -->
            
            <li class="nav-item">
                <router-link to="/sarpras/jenis/index" class="nav-link" inactive-class="collapsed">
                    <img src="../assets/icon/color-reference.png" alt="" style="width: 30px; padding-right: 5px;">
                    <span>Referensi</span>
                </router-link>
            </li>
        </ul>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarSarpras'
    }
</script>