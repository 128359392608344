<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true"></loading>
        <!-- Navbar -->
        <Navbar />

        <!-- Sidebar -->
        <SidebarScada />
        <div class="main" id="main">
            <Breadcrumb :links="links" />
            <section class="section">
                <div class="document">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Lokasi</label>
                                <Select2 class="vue-select2" :options="scada_location_select2" v-model="location" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Tanggal mulai</label>
                                <Datepicker v-model="date_start" input-class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Tanggal akhir</label>
                                <Datepicker v-model="date_end" input-class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-primary mt-4" @click="getData">Tampilkan</button>
                        </div>
                    </div>
                </div>
                <div class="document">
                    <h4 class="border-bottom pb-3 mb-4">
                        <b>Data Historis <span v-if="selected_location !== null">{{ scada_locations[selected_location] }}</span></b>
                        <a href="" class="download btn btn-sm btn-primary float-end" @click.prevent="download"><img src="../../assets/icon/download.png" height="18" alt="download" class="me-2">Export Data</a>
                    </h4>
                    <template v-if="selected_location !== null">
                        Per halaman: <select v-model="per_page" @change="getData">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                        <br>
                        <br>
                        <table class="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Waktu</th>
                                <th v-for="(col, i) in translate_scada_columns[selected_location]" :key="i" v-html="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, j) in data.data" :key="j">
                                <td>{{ row.LocalTimeCol | formatDate }}</td>
                                <td v-for="(col, i) in translate_scada_columns[selected_location]" :key="i">
                                    {{ row[i] | pembulatan }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <b-pagination
                            :total-rows="data.total"
                            :per-page="per_page"
                            @change="getData"
                            pills></b-pagination>
                    </template>
                    <template v-else>
                        <h4 class="text-center">Silahkan pilih wilayah terlebih dahulu</h4>
                    </template>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
// import
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarScada from '../../components/SidebarScada.vue'
import Datepicker from 'vuejs-datepicker'
import { BPagination } from 'bootstrap-vue'
import axios from 'axios';
import moment from 'moment';
import Select2 from 'v-select2-component';
import { scada_locations, translate_scada_columns } from '../../constants'

export default {
    name: 'LocationScada',
    components:{
        Navbar,
        SidebarScada,
        Loading, 
        Breadcrumb,
        Datepicker,
        BPagination,
        Select2
    },
    data(){
        return {
            is_loading: false,
            links: [
                {url: '/dashboard', text:'Home'}, 
                {url: '/scada', text: 'Scada'}, 
                {url: '#', text: 'Seri Data'},
            ],
            scada_locations,
            per_page: 50,
            translate_scada_columns,
            date_start: null,
            date_end: null,
            data: {},
            location: null,
            selected_location: null, // wilayah terpilih setelah menekan tombol Tampilkan
        }
    },
    mounted(){
        this.getData(1);
    },
    methods: {
        getData(page){
            this.is_loading = true;
            this.selected_location = this.location;

            let ds, es;

            if(this.date_start !== null){
                ds = moment(this.date_start).format("YYYY-MM-DD");
            }

            if(this.date_end !== null){
                es = moment(this.date_end).format("YYYY-MM-DD");
            }

            axios.get(`${this.$endpoints.scada}/data/${this.location}`, {
                params: {
                    start_date: ds,
                    end_date: es,
                    per_page: this.per_page,
                    page
                }
            })
            .then(response => {
                this.data = response.data;
            })
            .finally(() => {
                this.is_loading = false;
            })
        },
        download(){
            this.is_loading = true;

            let ds, es;

            if(this.date_start !== null){
                ds = moment(this.date_start).format("YYYY-MM-DD");
            }

            if(this.date_end !== null){
                es = moment(this.date_end).format("YYYY-MM-DD");
            }

            axios.get(`${this.$endpoints.scada}/data/${this.location}`, {
                params: {
                    start_date: ds,
                    end_date: es,
                    per_page: this.per_page,
                    to_csv: 1
                },
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'manage_data.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.is_loading = false;
            })
            .catch(() => alert('file not found'));
        }
    },
    filters: {
        formatDate(val){
            moment.locale('id');
            return moment(val).format("DD MMM YYYY HH:mm:ss")
        },
        pembulatan(value){
            if(value === null || isNaN(value)) return '#null';

            return parseFloat(value).toFixed(2);
        }
    },
    computed: {
        scada_location_select2(){
            return scada_locations.map((location, i) => {
                return {
                    id: i,
                    text: location
                };
            })
        }
    }
}
</script>
<style>
.select2-selection,
.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 38px!important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 38px!important;
}
</style>