<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">

        <div class="d-flex align-items-center justify-content-between">
            <router-link to="/dashboard" class="logo d-flex align-items-center">
                <img src="../assets/sisda-logo.png" alt="" style="width: 110px;">
                <span class="d-none d-lg-block"></span>
            </router-link>
            <a :class="this.class" href="#" style="color: #ffffff; font-size: 25px" v-on:click="toggleSidebar()"></a>
        </div>
        <!-- End Logo -->

        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">

                <li class="nav-item dropdown">
                    <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                        <i class="bi bi-grid-3x3-gap"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                        <li class="dropdown-header" style="width: 400px; border-radius: 10px;">
                            <!-- Aplikasi -->
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="float-start">
                                       <h5><strong>Aplikasi</strong></h5>
                                    </div>
                                </div>
                            </div>

                            <!-- ==== Menu 1 ==== -->
                            <div class="row mt-2">
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/sarpras/dashboard">
                                        <img src="../assets/icon-navbar/icon-sarpras.png" alt="" style="width: 50px;">
                                        <br>
                                        Sarpras
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/operation/dashboard">
                                        <img src="../assets/icon-navbar/icon-operation.png" alt="" style="width: 50px;">
                                        <br>
                                        Operasi
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/skema">
                                        <img src="../assets/icon-navbar/icon-skema.png" alt="" style="width: 50px;">
                                        <br>
                                        Skema
                                    </router-link>
                                </div>
                                <!-- <div class="col-4">
                                    <a style="text-decoration: none; color: inherit" :href="'http://pm.jasatirta2.id/token_login?token='+this.token" class="routerlink">
                                        <img src="../assets/icon-navbar/icon-monitoring.png" alt="" style="width: 50px;">
                                        <br>
                                        Monitoring
                                    </a>
                                </div> -->
                            </div>

                            <!-- ==== Menu 2 ==== -->
                            <div class="row mt-2">
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/spatial">
                                        <img src="../assets/icon-navbar/icon-spatial.png" alt="" style="width: 50px;">
                                        <br>
                                        Spatial
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/telemeteri">
                                        <img src="../assets/icon-navbar/icon-telemeteri.png" alt="" style="width: 55px;">
                                        <br>
                                        Telemeteri
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/cctv">
                                        <img src="../assets/icon-navbar/icon-cctv.png" alt="" style="width: 50px;">
                                        <br>
                                        CCTV
                                    </router-link>
                                </div>
                            </div>
                            
                            <!-- ==== Menu 3 ==== -->
                            <div class="row mt-2">
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/plta">
                                        <img src="../assets/icon-navbar/icon-plta.png" alt="" style="width: 50px;">
                                        <br>
                                        PLTA
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/bendungan">
                                        <img src="../assets/icon-navbar/icon-bendungan.png" alt="" style="width: 50px;">
                                        <br>
                                        Bendungan
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/bmkg">
                                        <img src="../assets/icon-navbar/icon-bmkg.png" alt="" style="width: 50px;">
                                        <br>
                                        Cuaca BMKG
                                    </router-link>
                                </div>
                            </div>

                            <!-- ==== Menu 4 ==== -->
                            <div class="row mt-2">
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/scada/dashboard">
                                        <img src="../assets/logo-scada.png" alt="" style="width: 50px;">
                                        <br>
                                        SCADA
                                    </router-link>
                                </div>
                            </div>

                            <!-- ==== Menu 3 ==== -->
                            <div class="row mt-4">
                                <div class="col-sm-12">
                                    <div class="float-start">
                                       <h5><strong>Admin Tools</strong></h5>
                                    </div>
                                </div>
                            </div>

                            <!-- ==== Menu 2 ==== -->
                            <div class="row mt-2">
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/referensi/organisasi/index">
                                        <img src="../assets/icon-navbar/icon-reference.png" alt="" style="width: 50px;">
                                        <br>
                                        Referensi
                                    </router-link>
                                </div>
                                <div class="col-4">
                                    <router-link style="text-decoration: none; color: inherit" class="routerlink" to="/users/index">
                                        <img src="../assets/icon-navbar/icon-profile.png" alt="" style="width: 50px;">
                                        <br>
                                        Users
                                    </router-link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <!-- End Messages Nav -->
                <li class="nav-item dropdown pe-3">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <img src="../assets/users.png" alt="Profile" class="rounded-circle">
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ name }}</span>
                    </a><!-- End Profile Iamge Icon -->

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <div class="float-start">
                                <div class="row">
                                    <div class="col-sm-4 float-start">
                                        <img src="../assets/users.png" class="rounded-circle" alt="" style="width: 50px;">
                                    </div>
                                    <div class="col-sm-3 float-start">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h6>{{ name }}</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <span>{{ role }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <br>
                        <br>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-person"></i>
                                <span>Profile Saya</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-gear"></i>
                                <span>Pengaturan</span>
                            </a>
                        </li>
                        <li>
                            <a v-on:click="logout" class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Keluar</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
    export default{
        name: 'Navbar',
        data(){
            return {
				name: '',
                role: '',
                token:'',
                sidebar: false,
                class: 'fas fa-bars toggle-sidebar-bt',
                currentUrl: window.location.pathname
			}
        },
		mounted(){
			this.name = localStorage.getItem('name');
			this.role = localStorage.getItem('role');
            this.token = localStorage.getItem('token');
            
		},
        methods:{
            toggleSidebar()
            {
                const b = document.body;

                if(b.classList.contains('toggle-sidebar')){
                    b.classList.remove('toggle-sidebar');
                }else{
                    b.classList.add('toggle-sidebar');
                }
            }
        }
    }
</script>