<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div id="main" class="main ms-0">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '#', text: 'User'}, {url: '#', text:'List'}]"/>
                <h2><b>Manajemen Pengguna</b></h2>
                <!-- Filter -->
                <div class="row mb-3">
                    <div class="col-sm-12">
                        <form action="" autocomplete="off" v-on:submit="filter">
                            <!-- Is Search -->
                            <div v-if="is_searched" class="form-group">
                                <div class="row">
                                    <div class="col-md-11">
                                        <input type="text" name="name" class="form-control" v-model="q_name" placeholder="Search...">
                                    </div>
                                    <div class="col-md-1">
                                        <a href="#" class="btn w-100 btn-danger" v-on:click="clear"><i class="fa fa-times"></i> clear</a>
                                    </div>
                                </div>
                            </div>
                            <!-- Name -->
                            <div v-else class="form-group">
                                <input type="text" name="name" class="form-control" v-model="q_name" placeholder="Search...">
                            </div>
                        </form>
                    </div>
                </div>

                <div class="document">
                    <!-- Filter -->
                    <div class="float-right mb-3">
                        <router-link to="/users/create" class="btn btn-sm btn-primary me-2" v-if="getRole() !== 'guest'"><i class="fa fa-plus"></i> Add User</router-link>
                        <router-link to="/users/batch_edit" class="btn btn-sm btn-success" v-if="getRole() !== 'guest'"><i class="fa fa-pen"></i> Batch Edit</router-link>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>

                    <div id="filternya-cuk">
                        <b>Total terdapat {{ this.total_entries }} Data User</b>
                        <br>
                        <br>
                        <!-- Direktorat By Klik -->
                        <div class="row">
                            <div class="col-md-2">
                                Tampilkan Direktorat
                            </div>
                            <div class="col-md-10">
                                <!-- Loop Direktorat -->
                                <button type="button" class="btn btn-sm me-2" :class="this.is_allDirektorat ? 'btn-primary':'btn-secondary'" @click="allChannel">Semua</button>
                                <button type="button" class="btn btn-sm me-2" :class="direktorats_id.indexOf(direktorat.id) > -1 ? 'btn-primary':'btn-secondary' " v-for="direktorat in direktorats" :key="direktorat.id" @click="changeChannel(direktorat.id)">{{ direktorat.name }}</button>
                            </div>
                        </div>
                        <br>
                        <!-- Tampilkan Data Direktorat -->
                        <div class="row">
                            <div class="col-md-2">Tampilkan List Direktorat</div>
                            <div class="col-md-10">
                                <div class="form-group">
                                    <label for="">Tampilan Direktorat</label>
                                    <Select2 class="vue-select2" :options="sections" v-model="direktorat_id" @change="advanceFilter"></Select2>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <!-- Tampilkan Unit Wilayah -->
                        <div class="row">
                            <div class="col-md-2">Tampilkan Unit Wilayah</div>
                            <div class="col-md-10">
                                <!-- Loop Unit Wilayah -->
                                <button type="button" class="btn btn-sm me-2" :class="this.is_allUnit ? 'btn-primary':'btn-secondary'" @click="allUnit">Semua</button>
                                <button type="button" class="btn btn-sm me-2" :class="unit_wilayahs_id.indexOf(unit_wilayah.id) > -1 ? 'btn-primary':'btn-secondary' " v-for="unit_wilayah in unit_wilayahs" :key="unit_wilayah.id" @click="changeParentOrg(unit_wilayah.id)">{{ unit_wilayah.name }}</button>
                            </div>
                        </div>
                        <br>
                        <!-- Data Child Unit Wilayah -->
                        <div class="row">
                            <div class="col-md-2">Tampilkan Sub Unit Wilayah</div>
                            <div class="col-md-10">
                                <div class="form-group">
                                    <label for="">Tampilan Unit Wilayah</label>
                                    <Select2 class="vue-select2" :options="sections_row" v-model="unit_wilayah_id" @change="advanceFilter"></Select2>
                                </div>
                            </div>
                        </div>
                        <br>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                Tampilkan user
                                    <button class="btn me-1" :class="this.per_page == 10 ? 'btn-primary':'btn-secondary'" @click="setPerPage(10)">10</button>
                                    <button class="btn me-1" :class="this.per_page == 20 ? 'btn-primary':'btn-secondary'" @click="setPerPage(20)">20</button>
                                    <button class="btn me-1" :class="this.per_page == 50 ? 'btn-primary':'btn-secondary'" @click="setPerPage(50)">50</button>
                                    <button class="btn me-1" :class="this.per_page == 100 ? 'btn-primary':'btn-secondary'" @click="setPerPage(100)">100</button>
                                per halaman
                            </div>
                        </div>
                    </div>
                    
                    <br>
                    <div class="clearfix"></div>
                    <br>

                    <!-- Table -->
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th width="3%">ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Username</th>
                                <th>Last Login</th>
                                <th width="8%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in users.data" :key="user.id" v-bind:index="index">
                                <td>{{ user.id }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.role }}</td>
                                <td>{{ user.username }}</td>
                                <td>{{ user.login_at }}</td>
                                <td>
                                    <router-link :to="{path: '/users/edit/'+user.id}" class="btn btn-sm btn-primary me-1" v-if="getRole() !== 'guest'">
                                        <i class="fa fa-pen"></i>
                                    </router-link>
                                    <a href="#" class="btn btn-sm btn-danger" v-on:click="destroy(user.id, index)" v-if="getRole() !== 'guest'"><i class="fa fa-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination :data="users" :limit="limit" @pagination-change-page="getResults"></pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
// import OperationSidebar from '../../components/OperationSidebar.vue'
import Select2 from 'v-select2-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'UserIndex',
    components: {
        Navbar,
        Breadcrumb,
        // OperationSidebar,
        Select2,
        Loading
    },
    methods: {
        destroy(id, index){
            let fd = new FormData();
            fd.append('_method', 'DELETE');

            this.is_loading = true;

            axios.post(this.$endpoints.user+'users/'+id+'?token='+this.token, fd)
            .then(() => {
                this.users.data.splice(index, 1);
                this.is_loading = false;
            }).catch(() => {
                alert("error while deleting user. please contact developer");
                this.is_loading = false;
            });
        },
        getResults(page = 1){
            this.is_loading = true;
            this.is_advance_searched = true;
            // this.q_name = "";

            var q = [];

            if(this.q_name != null){
                q.push("name="+this.q_name);
            }

            // Direktorat
            if(this.direktorats_id.length > 0){
                q.push("direktorats_id="+this.direktorats_id);
            }
            
            if(this.direktorat_id !== null){
                q.push("direktorat_id="+this.direktorat_id);
            }

            // Unit Wilayah
            if(this.unit_wilayahs_id.length > 0){
                q.push("unit_wilayahs_id="+this.unit_wilayahs_id);
            }

            if(this.unit_wilayah_id !== null){
                q.push("unit_wilayah_id="+this.unit_wilayah_id);
            }

            if(q.length > 0){
                this.is_loading = true;
                axios.get(this.$endpoints.user+'users?token='+this.token+'&advance_filter=1&per_page='+this.per_page+'&page='+page+'&'+q.join('&'))
                // axios.get(this.$endpoints.user+'users?token='+this.token+'&advance_filter=1&per_page='+this.per_page+'&'+q.join('&'))
                // axios.get('http://sisda_user.localhost/'+'users?token='+this.token+'&advance_filter=1&per_page='+this.per_page+'&page='+page+'&'+q.join('&'))
                    .then(response =>{
                        this.users = response.data
                        this.total_entries = response.data.total;
                        this.is_loading = false;
                        // console.log(response.data)
                    }).catch(() => {
                        alert("error while filtering page. please contact developer");
                        this.is_loading = false;
                    });
            }else{
                this.items = {};
            }

        },
        filter(event){
            this.is_searched = true;
            this.is_loading = true;

            var q = [];
            q.push("filter=1");

            if(this.q_name != null){
                q.push("name=" + this.q_name);
            }

            axios.get(this.$endpoints.user+'users?per_page='+this.per_page+'&name='+this.q_name+'&token='+this.token+'&'+q.join('&'))
                .then(response =>{
                    this.users = response.data;
                    this.total_entries = response.data.total;
                    this.is_loading = false;
                }).catch(() => {
                    alert("error while filtering page. please contact developer");
                    this.is_loading = false;
                });

            event.preventDefault();
        },           
        advanceFilter(){
            this.is_advance_searched = true;
            this.q_name = "";

            var q = [];

            // Direktorat
            if(this.direktorats_id.length > 0){
                q.push("direktorats_id="+this.direktorats_id);
            }
            
            if(this.direktorat_id !== null){
                q.push("direktorat_id="+this.direktorat_id);
            }

            // Unit Wilayah
            if(this.unit_wilayahs_id.length > 0){
                q.push("unit_wilayahs_id="+this.unit_wilayahs_id);
            }

            if(this.unit_wilayah_id !== null){
                q.push("unit_wilayah_id="+this.unit_wilayah_id);
            }

            if(q.length > 0){
                this.is_loading = true;
                axios.get(this.$endpoints.user+'users?token='+this.token+'&advance_filter=1&per_page='+this.per_page+'&'+q.join('&'))
                // axios.get('http://sisda_user.localhost/'+'users?token='+this.token+'&advance_filter=1&per_page='+this.per_page+'&'+q.join('&'))
                    .then(response =>{
                        this.users = response.data
                        this.total_entries = response.data.total;
                        this.is_loading = false;
                    }).catch(() => {
                        alert("error while filtering page. please contact developer");
                        this.is_loading = false;
                    });
            }else{
                this.items = {};
            }

            // event.preventDefault();
        },
        changeChannel(id){
            this.sections = [];
            // this.setSearchCriteria('', null);

            if(id == null){
                for(var x = 0; x < this.direktorats.length; x++){
                    console.log(this.direktorats[x]);
                    this.setSearchCriteria('direktorats_id', this.direktorats[x].id);
                }
            }else{
                this.setSearchCriteria('direktorats_id', id);
            }

            if(this.direktorats_id.length > 0){
                this.is_loading = true;

                for(var i=0; i<this.direktorats_id.length; i++){
                    axios.get(this.$endpoints.user+'users/get_orgs/' + this.direktorats_id[i])
                    // axios.get('http://sisda_user.localhost/'+'users/get_orgs/' + this.direktorats_id[i])
                        .then((response) => {
                            for(var i=0; i<response.data.length; i++){
                                this.sections.push(response.data[i]);
                            }
                            this.is_loading = false;
                            console.log(this.data_direktorats)
                        }).catch(() => {
                            this.is_loading = false;
                            alert('there was an error. please contact developer.');
                        })
                }
            }

            this.advanceFilter();
        },
        allChannel(){
            this.sections = [];
            this.direktorat_id = '';

            this.is_allDirektorat = !this.is_allDirektorat;
            if(this.is_allDirektorat){
                this.direktorats_id = this.direktorats.map(function(v){
                    return v.id;
                });
            }else{
                this.direktorats_id = [];
            }

            // API
            for(var i=0; i<this.direktorats_id.length; i++)
            {
                axios.get(this.$endpoints.user+'users/get_orgs/' + this.direktorats_id[i])
                // axios.get('http://sisda_user.localhost/'+'users/get_orgs/' + this.direktorats_id[i])
                    .then((response) => {
                        for(var i=0; i<response.data.length; i++){
                            this.sections.push(response.data[i]);
                        }
                        this.is_loading = false;
                    }).catch(() => {
                        this.is_loading = false;
                        alert('there was an error. please contact developer.');
                    })
            }

            this.advanceFilter();
        },
        allUnit(){
            this.sections_row = [];
            this.unit_wilayah_id = '';

            this.is_allUnit = !this.is_allUnit;
            if(this.is_allUnit)
            {
                this.unit_wilayahs_id = this.unit_wilayahs.map(function(v){
                    return v.id;
                });
            }else{
                this.unit_wilayahs_id = [];
            }

            // API
            for(var i=0; i<this.unit_wilayahs_id.length; i++)
            {
                axios.get(this.$endpoints.user+'users/get_orgs/'+this.unit_wilayahs_id[i])
                // axios.get('http://sisda_user.localhost/'+'users/get_orgs/'+this.unit_wilayahs_id[i])
                    .then((response) => {
                        for(var i=0; i<response.data.length; i++){
                            this.sections_row.push(response.data[i]);
                        }
                        this.is_loading = false;
                    }).catch(() => {
                        this.is_loading = false;
                        alert('there was an error. please contact developer.');
                    })
            }

            this.advanceFilter();
        },
        setPerPage(pp){
            this.per_page = pp;
            if(this.is_searched || this.is_advance_searched){
                this.getResults();
            }
        },
        changeParentOrg(id){
            this.sections_row = [];
            
            if(id == null){
                for(var x = 0; x < this.unit_wilayahs.length; x++){
                    console.log(this.unit_wilayahs[x]);
                    this.setSearchCriteria('unit_wilayahs_id', this.unit_wilayahs[x].id);
                }
            }else{
                this.setSearchCriteria('unit_wilayahs_id', id);
            }

            if(this.unit_wilayahs_id.length > 0){
                this.is_loading = true;

                for(var i=0; i<this.unit_wilayahs_id.length; i++){
                    axios.get(this.$endpoints.user+'users/get_orgs/'+this.unit_wilayahs_id[i])
                    // axios.get('http://sisda_user.localhost/'+'users/get_orgs/'+this.unit_wilayahs_id[i])
                        .then((response) => {
                            for(var i=0; i<response.data.length; i++){
                                this.sections_row.push(response.data[i]);
                            }
                            this.is_loading = false;
                            // console.log(this.data_direktorats)
                        }).catch(() => {
                            this.is_loading = false;
                            alert('there was an error. please contact developer.');
                        })
                }
            }
            
            this.advanceFilter();
        },
        setSearchCriteria(key, value){
            if(Array.isArray(this[key])){
                var idx = this[key].indexOf(value)
                if(idx > -1){
                    this[key].splice(idx, 1);
                }else{
                    this[key].push(value);
                }
            }else{
                this[key] = value;
            }
        },
        clear(){
            this.is_searched = false;
            this.q_name = '';

            this.getResults();
        },
        clearAdvance(){
            this.is_advance_searched = false;
            this.org_id = null;
            this.type_id = null;
            this.direktorats_id = null;
            this.segment_id = null;
        },
        getData(event)
        {
            this.direktorat_id = event;
            // console.log(this.direktorat_id)
            axios.get(this.$endpoints.reference + 'organization/data/child/' + event + '?token=' + this.token)
                .then(response => {
                    this.data_direktorats = response.data.data
                })   
        },
        allData()
        {
            // axios.get(this.$endpoints.user + )
        }
    },
    data(){
        return {
            is_loading: false,
            is_fullpage: true,
            is_advance_searched: false,
            token: null,
            users: {},
            q_name:'',
            q_email:'',
            q_username:'',
            is_searched: false,

            // Variable Filter
            total_entries: 0,
            
            // Direktorat
            direktorats: [],
            direktorats_id: [],
            data_direktorats: [],
            direktorat_id: null,

            // Unit Wilayah
            unit_wilayahs: [],
            unit_wilayahs_id: [],
            unit_wilayah_id: null,
            per_page: 10,
            limit: 3,

            // Section
            sections: [],
            sections_row: [],

            // Boolean
            is_allDirektorat: false,
            is_allUnit: false,

            wrap_parent: [],
        };
    },
    mounted(){
        this.is_loading = true;
        this.token = localStorage.getItem('token');

        // User
        axios.get(this.$endpoints.user+'users?token='+this.token)
        .then(response =>{
            this.users = response.data;
            this.total_entries = response.data.total;
            this.is_loading = false;
        });

        axios.get(this.$endpoints.reference + 'organization/data/1?token=' + this.token)
        .then(response => {
            this.direktorats = response.data.data;
            console.log(response.data.data);
        });

        axios.get(this.$endpoints.reference + 'organization/data/2?token=' + this.token)
        .then(response => {
            this.unit_wilayahs = response.data.data;
            console.log(response)
        });
    }
}
</script>

<style>

</style>