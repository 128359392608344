<template>
  <div>
    <loading :active.sync="is_loading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    <!-- Navbar -->
    <Navbar/>

    <!-- Sidebar -->
    <SidebarReference/>

    <main id="main" class="main">
        <!-- Breadcomp -->
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: '>';">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Struktural</a></li>
                <li class="breadcrumb-item active">{{ name_reference }}</li>
            </ol>
            </nav>

            <div class="row">
                <div class="col-sm-6">
                    <nav style="--bs-breadcrumb-divider: '';">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/referensi/organisasi/index" class="mt-3">
                                    <strong><i class="bi bi-arrow-left"></i></strong>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <h1><strong>{{ name_reference }}</strong></h1>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <router-link to="/referensi/organisasi/index" class="btn btn-outline-danger" style="margin-right: 5px;">
                            <i class="fa fa-times"></i>
                            Batal
                        </router-link>
                        <button type="button" v-on:click="store" class="btn btn-primary">
                            <i class="fa fa-save"></i>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END OF BREADCOMP -->

        <section class="section dashboard">
            <div class="row mt-5">
            <!-- Left side columns -->
                <div class="col-lg-12">
                    <div class="row">
                    <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales">

                                <!-- ===== BODY ===== -->
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="card-title">{{ name_page }}</div>
                                        <form action="">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label for="">Nama</label>
                                                        <input type="text" class="form-control" name="Nama" v-model="name" placeholder="Nama Organisasi" required>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Induk Organisasi</label>
                                                        <Select2 class="vue-select2" :options="parents" v-model="parent_id" aria-placeholder="Induk Organisasi" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Tingkat Struktural</label>
                                                        <Select2 class="vue-select2" :options="structs" v-model="structural_level" aria-placeholder="Tingkat Struktural" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>

</template>

<script>

    // import
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import Select2 from 'v-select2-component'

    // Component
    import Navbar from '../../../components/Navbar.vue'
    import SidebarReference from '../../../components/SidebarReference.vue'
    
    export default{
        data(){
          return{
            name_reference: 'Tambah Referensi',
            name_page: 'Data Organisasi',

            // Need
            is_fullpage: true,
            is_loading: false,
            token: null,
            structs: [{text:'Direktorat', id: 'Direktorat'},{text:'Unit Kerja', id: 'Unit Kerja'}, {text:'Bidang', id: 'Bidang'}, {text:'Sub Bidang/Seksi', id: 'Sub Bidang/Seksi'}, {text:'Urusan/Sub Seksi', id: 'Urusan/Sub Seksi'}],
            parents: [],
            name: '',
            parent_id: '',
            structural_level:''
          }
        },
        mounted(){
            this.token = localStorage.getItem('token');
            this.is_loading = true;
            
			// get divisions
			axios.get(this.$endpoints.reference+'organizations?all=1')
			.then((response) => {
				let data = [];
				for(var i = 0; i < response.data.length; i++){
					var text = response.data[i].name;
					switch(response.data[i].indent){
						case 1:
							text = "- "+text;
							break;

						case 2:
							text = "-- "+text;
							break;

						case 3:
							text = "--- "+text;
							break;

						case 4:
							text = "---- "+text;
							break;
					}
					data.push({
						text,
						id: response.data[i].id
					})
				}
				this.parents = data;
				this.is_loading = false;
			})
			.catch(() => {
				this.is_loading = false;
				alert('there was an error. please contact developer.');
			})
        },
        components:{
            Navbar,
            SidebarReference,
            Loading,
            Select2,
        },
        methods:{
            store()
            {
				if(this.name.length > 0){
					let fd = this.createFormData({
						name: this.name,
						parent_id: this.parent_id,
						structural_level: this.structural_level
					});

					this.is_loading = true;

					axios.post(this.$endpoints.reference+'organizations?token='+this.token, fd)
					.then(() => {
						this.is_loading = false;
						this.$router.push({path:'/referensi/organisasi/index'});
					}).catch(() => {
						this.is_loading = false;
						alert('there was an error while adding data. please contact developer.');
					});
				}else{
					alert("Name could not be blank");
				}
			}
        }
    }

</script>

<style scoped>
    .btn-primary{
        background-color: #509ee3;    
    }

    .card{
        border-radius: 10px;
    }
    .form-control{
        border-radius: 10px;
    }
    .vue-select2{
        border-radius: 10px;
    }
    .text-red{
        color: #e23232;
    }


</style>